import { ArrowSmRightIcon, DuplicateIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import mixpanel from 'mixpanel-browser';

import Badge from '@common/Badge';
import { Menu, MenuItem } from '@common/Menu';
import IndeterminateCheckbox from '@common/table/IndeterminateCheckbox';
import {
  getAdminCreatorColumn,
  getAudienceSizeColumn,
  getCreatedOnColumn,
  getDeprecatedFacebookInterestsColumn,
  getIdColumn,
  getPlatformColumn,
  getTargetAudienceNameColumn,
} from '@common/table/TableColumns';

export default function getTargetAudienceTableColumns(
  ToolTip,
  isAdmin,
  history,
  isSelecting,
  selectedPlatform,
  {
    duplicateAdAudience,
    filterByLabel,
    openCopyDialog,
    openDeleteDialog,
  }
) {
  /* eslint-disable react/prop-types */
  const columns = [
    ...(isAdmin && isSelecting ? [{
      Cell: ({ row }) => (
        <IndeterminateCheckbox
          isDisabled={selectedPlatform && row.original.platform !== selectedPlatform}
          {...row.getToggleRowSelectedProps()}
        />
      ),
      hasSmallPadding: true,
      id: 'selection',
    }] : []),
    ...(isAdmin ? [getIdColumn()] : []),
    getTargetAudienceNameColumn(isAdmin),
    ...(!isAdmin ? [{
      accessor: 'labels',
      alignment: 'left',
      Cell: ({ cell: { value } }) => (
        <div className="space-y-2 space-x-1">
          {(value).sort().map(label => (
            <Badge
              key={label}
              onClick={() => filterByLabel(label)}
              size="small"
              text={label}
            />
          ))}
        </div>
      ),
      disableSortBy: true,
      Header: 'LABELS',
    }] : []),
    getPlatformColumn(),
    getAudienceSizeColumn(),
    getCreatedOnColumn(),
    getDeprecatedFacebookInterestsColumn(ToolTip),
    ...(isAdmin ? [getAdminCreatorColumn()] : []),
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => {
        const isManaged = !!original.parentId;

        return (
          <Menu size={isAdmin ? 'lg' : 'sm'} >
            {isAdmin && (
              <MenuItem
                icon={ArrowSmRightIcon}
                onClick={() => openCopyDialog(original)}
                title="Copy Target Audience for Another User"
              />
            )}
            {
              isManaged && (
                <div className="break-normal bg-blue-50 text-blue-600 text-left rounded px-4 py-2">
                  This audience is managed by Personalyze and cannot be modified.
                </div>
              )
            }
            {
              !isAdmin && !isManaged && (
                <>
                  <MenuItem
                    icon={PencilAltIcon}
                    onClick={() => history.push(`/audiences/${original.id}/edit`)}
                    title="Edit"
                  />
                  <MenuItem
                    icon={DuplicateIcon}
                    onClick={() => {
                      mixpanel.track('Duplicate Target Audience', { id: original.id });
                      duplicateAdAudience(original.id);
                    }}
                    title="Duplicate"
                  />
                </>
              )
            }
            <MenuItem
              icon={TrashIcon}
              isDangerous
              onClick={() => openDeleteDialog(original)}
              title="Delete"
            />
          </Menu >
        );
      },
      disableSortBy: true,
      Header: '',
      id: 'actions',
    },
  ];
  /* eslint-enable react/prop-types */
  return columns;
}
