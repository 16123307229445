export function scrollTop() {
  window.scrollTo(0, 0);
}

const TOUR_STYLES = {
  fontSize: '16px',
  borderRadius: '10px',
  padding: '40px',
  maxWidth: '500px',
  width: '500px',
  lineHeight: '1.5em',
  fontWeight: 500,
};

export default TOUR_STYLES;
