import { formatDistanceToNow } from 'date-fns';
import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import AdAccountsDialog from './AdAccountsDialog';
import CreateIdentityDialog from './CreateIdentityDialog';
import getIdentityTableColumns from './identityTableColumns';

import { deleteUserIdentity, getUser } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import DeleteDialog from '@common/DeleteDialog';
import ErrorMessage from '@common/ErrorMessage';
import Section from '@common/Section';
import Table from '@common/Table';
import { getUserAvatar } from '@utils/avatar';

export default function UserDetails() {
  // extract arguments
  const { userId } = useParams();

  // acquire history handler
  const history = useHistory();

  // query user
  const { data: user, error: errorLoadingUser, isFetching: isLoadingUser, refetch: refetchUser } = useQuery(
    ['user', userId],
    () => getUser(userId),
    { initialData: { identities: [], organization: {} } }
  );

  // modal states
  const [paramsOfCreateIdentityDialog, setParamsOfCreateIdentityDialog] = useState({ isOpen: false });
  const [paramsOfAdAccountsDialog, setParamsOfAdAccountsDialog] = useState({ isOpen: false });
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] = useState({ isOpen: false });

  // render campaign section
  const columns = getIdentityTableColumns({
    openAdAccountsDialog: identity => setParamsOfAdAccountsDialog({
      id: identity.id,
      isOpen: true,
      provider: identity.provider,
    }),
    openDeleteDialog: identity => setParamsOfDeleteDialog({
      id: identity.id,
      isOpen: true,
      name: identity.provider,
    }),
  });
  const currentSignIn = user.currentSignInAt ? `since ${formatDistanceToNow(new Date(user.currentSignInAt))}` : 'N/A';
  const lastSignIn = user.lastSignInAt ? `${formatDistanceToNow(new Date(user.lastSignInAt))} ago` : 'N/A';
  const invitation = user.invitationSentAt ? `${formatDistanceToNow(new Date(user.invitationSentAt))} ago` : 'N/A';
  return (
    <>
      <Section
        heading={(
          <div className="flex justify-end">
            <Button
              icon={ArrowSmLeftIcon}
              kind={ButtonKind.REGULAR}
              onClick={() => history.push('/admin/users')}
              title="Back to Users"
            />
            <Button
              hasLeftMargin
              isDisabled={!user.id}
              kind={ButtonKind.PRIMARY}
              onClick={() => history.push(`/admin/users/${user.id}/edit`)}
              title="Edit User"
            />
          </div>
        )}
        title={(
          <>
            <img
              alt={user.avatar ? '' : 'Default avatar'}
              className="rounded-full mr-4 h-12 w-12"
              src={getUserAvatar(user)}
            />
            <div>
              {user.name ? `${user.name} (id: ${user.id})` : 'Loading...'}
              <p className="text-base text-gray-500">
                {user.email} -
                <span className="ml-1">
                  <Button
                    kind={ButtonKind.LINK}
                    target={`/admin/organizations/${user.organization.id}`}
                    title={user.organization.name}
                  />
                </span>
              </p>
            </div>
          </>
        )}
      >
        <div className="my-2">
          {errorLoadingUser && <ErrorMessage message="Could not load user information." />}
        </div>
        <div>
          Currently signed in: {currentSignIn}
        </div>
        <div>
          Last signed in: {lastSignIn} ago
        </div>
        <div>
          Invite sent: {invitation}
        </div>

        <div className="my-4 text-right">
          <Button
            kind="primary"
            onClick={() => setParamsOfCreateIdentityDialog({ isOpen: true })}
            title="New Identity"
          />
        </div>

        <Table
          columns={columns}
          data={user.identities}
          entitiesName="identities"
          initiallySortedBy="id"
          isLoading={isLoadingUser}
          noDataMessage="No identities found."
        />
      </Section>

      <CreateIdentityDialog
        close={() => setParamsOfCreateIdentityDialog({ isOpen: false })}
        onUpdate={refetchUser}
        params={paramsOfCreateIdentityDialog}
        userId={userId}
      />
      <AdAccountsDialog
        close={() => setParamsOfAdAccountsDialog({ isOpen: false })}
        params={paramsOfAdAccountsDialog}
      />
      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ isOpen: false })}
        delete={identityId => deleteUserIdentity(userId, identityId)}
        entityName="identity"
        onUpdate={refetchUser}
        params={paramsOfDeleteDialog}
      />
    </>
  );
}
