import { MailIcon } from '@heroicons/react/outline';
import { GlobeIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

export default function InsightReportInputLabels(props) {
  const inputs = props.inputs;

  // handle countries
  let labels = [];
  let ageRanges = [];
  let handles = [];
  let minimalCount = '';

  if (!isEmpty(inputs.countryCodes)) {
    for (const countryCode of inputs.countryCodes) {
      const imageUrl = `https://storage.googleapis.com/topicdna-public/flags-iso/flat/16/${countryCode}.png`;
      labels.push(
        <img
          alt=""
          className="inline"
          key={imageUrl}
          src={imageUrl}
        />
      );
    }
  } else if (props.reportType === 'email_audience') {
    labels.push(
      <span key="mail">
        <MailIcon className="w-5 h-5 text-gray-400 inline mr-1" />
        {inputs.filename}
      </span>
    );
  } else {
    <GlobeIcon
      className="w-5 h-5 text-gray-400 inline"
      key="globe"
    />;
  }

  // handle gender
  if (inputs.gender) {
    const imageUrl = `https://storage.googleapis.com/topicdna-public/${inputs.gender.toLowerCase()}.png`;
    labels.push(
      <img
        alt=""
        className="ml-1 w-4 h-4 inline"
        key={inputs.gender}
        src={imageUrl}
      />
    );
  }

  // handle age ranges
  if (!isEmpty(inputs.ageRanges)) {
    ageRanges = ` ${inputs.ageRanges.join(', ')}`;
  }

  // handle Twitter handles
  if (inputs.twitterAccounts) {
    handles = ` ${inputs.twitterAccounts.map(account => `@${account.username}`).join(', ')}`;

    if (inputs.minimalCount > 1) {
      minimalCount = ` Follows ${inputs.minimalCount} of `;
      handles = `${minimalCount}${handles}`;
    }
  }

  if (inputs.tweetId) {
    handles = 'Tweet Engagers';
  }

  // render criteria labels
  return (
    <div className="text-gray-500">
      {labels && <span className="inline">{labels}</span>}
      {ageRanges && <span className="inline">{ageRanges}</span>}
      {handles && <span className="inline">{handles}</span>}
    </div>
  );
}

InsightReportInputLabels.propTypes = {
  inputs: PropTypes.object.isRequired,
  reportType: PropTypes.string,
};
