import { size } from 'lodash';
import PropTypes from 'prop-types';

const CLASS_NAMES = [
  'border',
  'border-gray-300',
  'focus:outline-none',
  'focus:z-10',
  'font-medium',
  'hover:bg-indigo-500',
  'hover:text-white',
  'inline-flex',
  'items-center',
  'px-4',
  'py-2',
  'relative',
  'text-sm',
];

export default function OptionGroup(props) {
  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      {props.options.map((option, index) => {
        // set up classes
        let classNames = [...CLASS_NAMES];
        if (index === 0) {
          classNames.push('rounded-l-md');
        }
        if (index !== 0) {
          classNames.push('-ml-px');
        }
        if (index === size(props.options) - 1) {
          classNames.push('rounded-r-md');
        }
        classNames.push(...(option.isActive ? ['bg-indigo-600', 'text-white'] : ['bg-white', 'text-gray-700']));

        // render options
        return (
          <button
            className={classNames.join(' ')}
            key={index}
            onClick={option.onClick}
            type="button"
          >
            {option.title}
          </button>
        );
      })}
    </span>
  );
}

OptionGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};
