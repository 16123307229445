const baseConfig: Config = {
  API_URL: '',
  /* eslint-disable no-template-curly-in-string */
  NODE_ENV: '${NODE_ENV}',
  REACT_APP_FIREBASE_API_KEY: '${REACT_APP_FIREBASE_API_KEY}',
  REACT_APP_GOOGLE_ANALYTICS_ID: '${REACT_APP_GOOGLE_ANALYTICS_ID}',
  REACT_APP_MIXPANEL_TOKEN: '${REACT_APP_MIXPANEL_TOKEN}',
  REACT_APP_RAVEN_URL: '${REACT_APP_RAVEN_URL}',
  REACT_APP_RELEASE_VERSION: '${REACT_APP_RELEASE_VERSION}',
  REACT_APP_SENTRY_ENV: '${REACT_APP_SENTRY_ENV}',
  REACT_APP_STRIPE_PUBLISHABLE_KEY: '${REACT_APP_STRIPE_PUBLISHABLE_KEY}',
  REACT_APP_WS_URL: '${REACT_APP_WS_URL}',
  /* eslint-enable no-template-curly-in-string */
};
const developmentConfig: Config = {
  API_URL: '',
  NODE_ENV: 'development',
  REACT_APP_FIREBASE_API_KEY: '',
  REACT_APP_GOOGLE_ANALYTICS_ID: 'G-WVK3Z6JX67',
  REACT_APP_MIXPANEL_TOKEN: '5951574b19fdc5aba9d3fc44041fe1b4',
  REACT_APP_RAVEN_URL: 'https://e9f4d3b0a4e948d19767e43bdf707605@sentry.io/108975',
  REACT_APP_RELEASE_VERSION: '',
  REACT_APP_SENTRY_ENV: 'development',
  REACT_APP_STRIPE_PUBLISHABLE_KEY: 'pk_test_6ALFeh51sUwiqvA7ybaHUTga',
  REACT_APP_WS_URL: 'ws://localhost:8080/cable',
};

const config = baseConfig.NODE_ENV.startsWith('${') ? developmentConfig : baseConfig;
config.API_URL = constructApiUrl();
export default config;

function constructApiUrl() {
  const location = window.location;
  let url: string;
  switch (config.NODE_ENV) {
    case 'staging':
      url = `${location.protocol}//api-beta.topicdna.com`;
      break;
    case 'production':
      url = `${location.protocol}//api.topicdna.com`;
      break;
    default:
      url = `${location.protocol}//${location.hostname}:4000`;
  }
  return url;
}

export interface Config {
  API_URL: string;
  NODE_ENV: string;
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_GOOGLE_ANALYTICS_ID: string;
  REACT_APP_MIXPANEL_TOKEN: string;
  REACT_APP_RAVEN_URL: string;
  REACT_APP_RELEASE_VERSION: string;
  REACT_APP_SENTRY_ENV: string;
  REACT_APP_STRIPE_PUBLISHABLE_KEY: string;
  REACT_APP_WS_URL: string;
}
