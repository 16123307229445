import { TrashIcon, ViewListIcon } from '@heroicons/react/solid';

import CopyToClipboard from '@common/CopyToClipboard';
import { Menu, MenuItem } from '@common/Menu';
import { getIdColumn, getPlatformColumn } from '@common/table/TableColumns';

const PLATFORMS_WITH_AD_ACCOUNTS = [
  'facebook', 'snapchat', 'twitter', 'tiktok', 'linkedin', 'pinterest', 'google',
];

export default function getOrganizationTableColumns({ openAdAccountsDialog, openDeleteDialog }) {
  /* eslint-disable react/prop-types */
  return [
    getIdColumn(),
    getPlatformColumn(),
    {
      accessor: 'uid',
      alignment: 'left',
      cellWidth: '200px',
      Header: 'UID',
      Cell: ({ row: { original } }) => (
        <span className="text-xs">
          {original.name || original.email}
        </span>
      ),
    },
    {
      accessor: 'accessToken',
      alignment: 'left',
      cellWidth: '250px',
      Header: 'ACCESS TOKEN',
      Cell: ({ cell: { value } }) => (
        <span className="text-xs">
          {value}
          <CopyToClipboard
            notificationText="Access token copied!"
            value={value}
          />
        </span>
      ),
    },
    {
      accessor: 'tokenSecret',
      alignment: 'left',
      cellWidth: '200px',
      Header: 'TOKEN SECRET',
      Cell: ({ cell: { value } }) => (
        value && (
          <span className="text-xs">
            {value}
            <CopyToClipboard
              notificationText="Token secret copied!"
              value={value}
            />
          </span>
        )
      ),
    },
    {
      accessor: 'refreshToken',
      alignment: 'left',
      cellWidth: '200px',
      Header: 'REFRESH TOKEN',
      Cell: ({ cell: { value } }) => (
        value && (
          <span className="text-xs">
            {value}
            <CopyToClipboard
              notificationText="Refresh token copied!"
              value={value}
            />
          </span>
        )
      ),
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <Menu>
          {PLATFORMS_WITH_AD_ACCOUNTS.includes(original.provider) && (
            <MenuItem
              icon={ViewListIcon}
              onClick={() => openAdAccountsDialog(original)}
              title="View Ad Accounts"
            />
          )}
          <MenuItem
            icon={TrashIcon}
            isDangerous
            onClick={() => openDeleteDialog(original)}
            title="Delete Identity"
          />
        </Menu>
      ),
      disableSortBy: true,
      Header: '',
      id: 'actions',
    },
  ];
  /* eslint-enable react/prop-types */
}
