import { UsersIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

import Card from '@common/Card';

export default function SummaryPersonas(props) {
  return (
    <Card
      subtitle="This audience can be divided into the following personas"
      title="Personas"
    >
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {props.personas.map((persona, index) => (
          <div
            className="flex flex-col bg-white overflow-hidden border rounded-lg"
            key={index}
          >
            <div className="flex-grow px-4 py-5 sm:p-6">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-indigo-800 rounded-md p-3">
                  <UsersIcon className="h-6 w-6 text-white" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dd className="flex items-baseline">
                    <div className="text-lg font-medium text-gray-900">{persona[0]}</div>
                  </dd>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    {persona[persona.length - 1].toFixed(1)}%
                  </dt>
                </div>
              </div>
            </div>
          </div>
        ))}
      </dl>
    </Card>
  );
}

SummaryPersonas.propTypes = {
  personas: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))).isRequired,
};
