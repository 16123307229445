import PropTypes from 'prop-types';

import BrandIcon from '@common/BrandIcon';
import { WizardStep } from '@common/Wizard';
import Badge, { BadgeContext, BadgeSize } from '@common/Badge';

export default function SelectionStep(props) {
  return (
    <WizardStep
      hasNoForwardButton
      {...props}
    >
      <h1 className="text-xl font-semibold">
        {props.header}
      </h1>

      <div className="mt-4 bg-white shadow sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {props.options.map(option => {
            const StepIcon = option.icon;
            return (
              <li
                className="py-2 flex hover:bg-gray-50 cursor-pointer"
                key={option.value}
                onClick={() => {
                  props.onSelect(option);
                  props._goForward();
                }}
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    {option.brandIcon && <BrandIcon brand={option.brandIcon} />}
                    {option.icon && (
                      <div className="mx-auto flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                        <StepIcon className="w-6 h-6 text-indigo-500" />
                      </div>
                    )}
                    <div className="ml-5 flex flex-col">
                      <span className="text-sm font-medium text-gray-900">
                        {option.title}
                      </span>
                      {option.description && (
                        <span className="text-sm text-gray-500">
                          {option.description}
                        </span>
                      )}
                    </div>
                    {option.beta && (
                      <div className="ml-2">
                        <Badge
                          context={BadgeContext.HIGHLIGHT}
                          size={BadgeSize.SMALL}
                          text="Beta"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </WizardStep>
  );
}

SelectionStep.propTypes = {
  _goForward: PropTypes.func,
  header: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};
