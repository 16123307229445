import { CalendarIcon } from '@heroicons/react/solid';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import Tabs from '@common/Tabs';
import CopyToClipboard from '@common/CopyToClipboard';
import InsightReportInputLabels from '@components/shared/InsightReportInputLabels';
import AudiencePlaceholder from '@images/audience_placeholder.png';
import { formatMonthDayYear } from '@utils/formattingUtils';

function InsightsHeaderView(props) {
  const createdAt = props.insightReport.createdAt ? formatMonthDayYear(props.insightReport.createdAt) : '';
  return (
    <div>
      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <div className="max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-full">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    alt=""
                    className="h-16 w-16 rounded-full"
                    src={get(props.insightReport, 'avatar.default') || AudiencePlaceholder}
                  />
                  <span
                    aria-hidden="true"
                    className="absolute inset-0 shadow-inner rounded-full"
                  />
                </div>
              </div>
              <div>
                <span className="text-xl font-medium text-gray-900">{props.insightReport.name}</span>
                <div className="flex flex-col sm:flex-row sm:flex-wrap sm:mt-0">
                  <div className="mt-2 mr-6 flex items-center text-sm text-gray-500">
                    <InsightReportInputLabels inputs={props.insightReport.inputs} />
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                    Generated on {createdAt}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <CopyToClipboard
                      isTrackedLink
                      notificationText="Copied to clipboard"
                      text="Copy URL"
                      value={`${window.location.host}/insight_reports/${props.insightReport.id}/`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 inline-flex items-center justify-end">
          {props.buttons}
        </div>
      </div>
      {props.tabs && (
        <Tabs
          activeTabIndex={props.activeTabIndex}
          tabs={props.tabs}
        />
      )}
    </div>
  );
}

InsightsHeaderView.propTypes = {
  activeTabIndex: PropTypes.number,
  buttons: PropTypes.node.isRequired,
  insightReport: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.object),
};
export default InsightsHeaderView;
