import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { createUserIdentity } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import FormBuilder from '@common/FormBuilder';

const PLATFORMS = [
  { label: 'Meta', value: 'facebook' },
  { label: 'Google', value: 'google' },
  { label: 'LinkedIn', value: 'linkedin' },
  { label: 'Pinterest', value: 'pinterest' },
  { label: 'Snapchat', value: 'snapchat' },
  { label: 'TikTok', value: 'tiktok' },
  { label: 'Twitter', value: 'twitter' },
];

export default function CreateIdentityDialog(props) {
  // create identity
  const { isLoading: isCreating, error: errorCreatingIdentity, mutate } = useMutation(
    () => createUserIdentity(
      props.userId,
      {
        accessToken: values.accessToken,
        provider: values.platform.value,
        refreshToken: values.refreshToken,
        tokenSecret: values.tokenSecret,
      }
    ),
    {
      onSuccess: () => {
        props.onUpdate();
        props.close();
      },
    }
  );

  // set up the form
  const {
    control,
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({ mode: 'onChange' });
  const values = watch();

  // set up form fields
  const fields = [
    {
      label: 'Platform',
      name: 'platform',
      options: PLATFORMS,
      required: 'Please select a platform',
      type: 'choice',
    },
    {
      isTextArea: true,
      label: 'Access Token',
      name: 'accessToken',
      required: 'Please enter an access token',
      type: 'text',
    },
    {
      isTextArea: true,
      label: 'Token Secret',
      name: 'tokenSecret',
      type: 'text',
    },
    {
      isTextArea: true,
      label: 'Refresh Token',
      name: 'refreshToken',
      type: 'text',
    },
  ];

  // render form
  return (
    <Dialog
      close={props.close}
      hasNoButtons
      isOpen={props.params.isOpen}
      title="Create New Identity"
    >
      <div className="my-2">
        {errorCreatingIdentity && <ErrorMessage message="Could not create identity." />}
      </div>

      <div>
        <FormBuilder
          control={control}
          errors={errors}
          fields={fields}
          getValues={getValues}
          register={register}
          setValue={setValue}
          touchedFields={touchedFields}
        />
      </div>
      <div className="mt-4">
        <Button
          isDisabled={isCreating}
          isFullWidth
          isLoading={isCreating}
          kind={ButtonKind.SUCCESS}
          onClick={() => handleSubmit(mutate)()}
          title="Create Identity"
        />
      </div>
    </Dialog>
  );
}

CreateIdentityDialog.propTypes = {
  close: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};
