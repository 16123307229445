import PropTypes from 'prop-types';

import { InformationCircleIcon } from '@heroicons/react/outline';

export default function NoDataMessage(props) {
  return (
    <div className="my-4">
      <div className="rounded-md bg-blue-50 p-4 border border-blue-200 shadow-sm">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-6 w-6 text-blue-700" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-base text-blue-700">
              {props.message}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

NoDataMessage.propTypes = {
  message: PropTypes.string,
};
