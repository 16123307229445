import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { notifyError, notifySuccess } from '@utils/toaster';

export default function ConfirmDialog(props) {
  // delete audience
  const { error, mutate, isLoading: isUpdating } = useMutation(
    () => props.perform(props.params),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Operation succeeded!' });
        props.close();
        if (props.onUpdate) {
          props.onUpdate();
        }
      },
      onError: error => notifyError({ title: 'Operation failed. Please try again.' }),
    }
  );

  // render modal
  return (
    <Dialog
      close={props.close}
      isDangerous
      isOpen={props.params.isOpen}
      isUpdating={isUpdating}
      onSubmit={mutate}
      submitTitle="Yes"
      title={typeof props.title === 'function' ? props.title(props.params) : props.title}
      updatingTitle="Updating..."
    >
      {error && <ErrorMessage message={error} />}
      <div className="mt-4 mb-8 text-gray-900">
        {props.message(props.params)}
      </div>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  params: PropTypes.object.isRequired,
  perform: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};
