import { issueRequest } from '@utils/httpUtils';

export async function getCampaignGroup(campaignGroupId: number): Promise<CampaignGroup> {
  const response = await issueRequest<CampaignGroup>(`/v3/campaign_groups/${campaignGroupId}`);
  return response.payload;
}

export interface CampaignGroup {
  id: number;
}
