import Button from '@common/Button';
import { getDetailedUserColumn } from '@common/table/TableColumns';

export function getOrganizationMembersTableColumns(
  organizationCreatorId,
  isUserOrganizationCreator,
  {
    openDeleteOrganizationMemberModal,
  }
) {
  /* eslint-disable react/prop-types */
  return [
    getDetailedUserColumn(false),
    {
      accessor: 'removeMember',
      Cell: ({ row: { original } }) => isUserOrganizationCreator && original.id !== organizationCreatorId && (
        <Button
          onClick={() => openDeleteOrganizationMemberModal(original)}
          title="Remove"
        />
      ),
      disableSortBy: true,
    },
  ];
  /* eslint-enable react/prop-types */
}
