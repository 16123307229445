import PropTypes from 'prop-types';

import Button, { ButtonKind } from '@common/Button';
import FormAvatar from '@common/FormAvatar';
import FormField from '@common/FormField';
import AudiencePlaceholder from '@images/audience_placeholder.png';

export default function GenericCreateInsightReport(props) {
  return (
    <>
      <div className="my-8">
        <div className="my-4">
          <FormField
            error={props.errors.name}
            label="Name your report (optional)"
            name="name"
            register={() => props.register('name')}
            type="text"
          />
        </div>

        <FormAvatar
          avatar={props.avatar}
          isUploading={props.isUploadingAvatar}
          label="Photo"
          name="avatar"
          onSelection={props.onAvatarSelection}
          placeholder={AudiencePlaceholder}
          setIsUploading={props.setIsUploadingAvatar}
          setValue={props.setValue}
        />
      </div>
      <div className="mt-8 grid grid-cols-1">
        <div className="text-right">
          <Button
            isDisabled={props.isCreationDisabled}
            isLoading={props.isUpdating}
            kind={ButtonKind.PRIMARY}
            title={props.isUpdating ? 'Creating...' : 'Create Insight Report'}
            type="submit"
          />
        </div>
      </div>
    </>
  );
}

GenericCreateInsightReport.propTypes = {
  avatar: PropTypes.string,
  errors: PropTypes.object.isRequired,
  isCreationDisabled: PropTypes.bool.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isUploadingAvatar: PropTypes.bool.isRequired,
  onAvatarSelection: PropTypes.func,
  register: PropTypes.func.isRequired,
  setIsUploadingAvatar: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
