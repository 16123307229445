import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getUsers } from '@api/user';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { FormAsyncDropdown } from '@common/FormDropdowns';
import SelectUserOption from '@common/reactSelect/SelectUserOption';
import SelectUserValue from '@common/reactSelect/SelectUserValue';
import { getUserName } from '@utils/generalUtils';

export default function SelectUserDialog(props) {
  // selected user
  const [user, setUser] = useState({});
  useEffect(
    () => {
      if (props.params.isOpen) {
        setUser({});
      }
    },
    [props.params.isOpen]
  );

  // render modal
  return (
    <Dialog
      close={props.close}
      isDisabled={isEmpty(user)}
      isOpen={props.params.isOpen}
      isUpdating={props.isUpdating}
      onSubmit={() => props.onSubmit(user.value)}
      submitTitle={props.submitTitle}
      title={props.title}
    >
      {props.errorUpdating && <ErrorMessage message={props.errorUpdating.message} />}
      <div className="my-4">
        <FormAsyncDropdown
          label="Select a user"
          loadOptions={(query, callback) =>

          // we should not return anything here
            getUsers(0, query).then(response =>
              callback(response.payload.map(user => ({ label: getUserName(user), value: user.id, ...user })))
            ) && undefined
          }
          onSelection={setUser}
          option={SelectUserOption}
          placeholder="Type to search..."
          singleValue={SelectUserValue}
          value={isEmpty(user) ? null : user}
        />
      </div>
    </Dialog>
  );
}

SelectUserDialog.propTypes = {
  close: PropTypes.func.isRequired,
  errorUpdating: PropTypes.string,
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  submitTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
