import * as OutlineIcons from '@heroicons/react/outline';
import * as SolidIcons from '@heroicons/react/solid';

export enum DialogIconContext {
  DANGEROUS = 'danger',
  PRIMARY = 'primary',
  REGULAR = 'regular',
  SUCCESS = 'success',
  WARNING = 'warning'
}

const CONTEXT_COLORS: Record<DialogIconContext, string> = {
  [DialogIconContext.DANGEROUS]: 'red',
  [DialogIconContext.PRIMARY]: 'indigo',
  [DialogIconContext.REGULAR]: 'grey',
  [DialogIconContext.SUCCESS]: 'green',
  [DialogIconContext.WARNING]: 'yellow',
};

export interface DialogIconProps {
  context?: DialogIconContext;
  icon: keyof typeof SolidIcons | keyof typeof OutlineIcons;
  outline?: boolean;
}

export default function DialogIcon(props: DialogIconProps): JSX.Element {
  const Icon = (props.outline ? OutlineIcons : SolidIcons)[props.icon];
  const contextColor = CONTEXT_COLORS[props.context || DialogIconContext.REGULAR];
  return (
    <div className={`mx-auto flex items-center justify-center w-32 h-32 rounded-full bg-${contextColor}-50`}>
      <Icon className={`w-16 h-16 text-${contextColor}-500`} />
    </div>
  );
}
