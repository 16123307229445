import { getIdColumn, getPlatformColumn } from '@common/table/TableColumns';

export default function getAdAccountTableColumns() {
  /* eslint-disable react/prop-types */
  return [
    getIdColumn(),
    getPlatformColumn(),
    {
      accessor: 'name',
      alignment: 'left',
      Header: 'NAME',
    },
    {
      accessor: 'adAccountId',
      alignment: 'left',
      Header: 'AD ACCOUNT ID',
    },
  ];
  /* eslint-enable react/prop-types */
}
