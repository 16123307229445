import { subDays } from 'date-fns';
import { ArrowSmLeftIcon, CalculatorIcon, DownloadIcon, PencilIcon, RefreshIcon } from '@heroicons/react/solid';
import { get, has, isNil } from 'lodash';
import mixpanel from 'mixpanel-browser';

// import pptxgen from 'pptxgenjs';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { triggerCampaignGroupRecalculation, triggerCampaignGroupReporting } from '@api/campaignGroups';
import { getCampaignGroup } from '@api/campaignGroup';
import BrandIcon from '@common/BrandIcon';
import Button, { ButtonKind } from '@common/Button';
import CopyToClipboard from '@common/CopyToClipboard';
import DownloadDocumentDialog from '@common/DownloadDocumentDialog';
import ErrorMessage from '@common/ErrorMessage';
import { Menu, MenuItem } from '@common/Menu';
import Section from '@common/Section';
import Table from '@common/Table';
import CampaignGroupListener from '@common/WebSocket/CampaignGroupListener';
import CrawlingIndicator from '@components/campaigns/CrawlingIndicator';
import { UserInfoContext } from '@hoc/withUserInfo';
import {
  formatDate,
  formatMetricName,
  formatMoneyAmount,
  formatMonthDay,
  formatPercentage,
  formatTimestamp,
} from '@utils/formattingUtils';

import { notifyError, notifySuccess } from '@utils/toaster';
import { getCampaignGroupExcelUrl } from '@api/analysis';
import getCampaignGroupAdSetTableColumns from './campaignGroupAdSetTableColumns';
import { updateCampaignGroup } from '@api/admin';

export function HeaderCell({ title }) {
  return <td className="border border-gray-300 px-2 py-4 font-semibold">{title}</td>;
}

HeaderCell.propTypes = {
  title: PropTypes.string,
};

export function TitleCell({ title, description }) {
  return (
    <td className="border border-gray-300 px-2 py-4 font-semibold text-base w-72">
      <p>{title}</p>
      {description &&
        (
          <span className="font-normal text-sm text-gray-500">
            {description}
          </span>
        )
      }
    </td>
  );
}

TitleCell.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};

// function generatePresentation(campaignGroup) {
//   // collect contents of the table
//   const data = campaignGroup.data;
//   const cprVsOtherProspecting = data.cprTdnaVsOtherProspecting;
//   const cprVsBenchmark = data.cprTdnaVsBenchmark;
//   const rows = [
//     [
//       { text: campaignGroup.name, options: { color: '4f45e4' } },
//       { text: 'TopicDNA' },
//       { text: 'vs. Non-TopicDNA' },
//       { text: 'Total' },
//     ],
//     [
//       { text: 'Total Results' },
//       {
//         text: [
//           { text: data.resultsTdna.toLocaleString() },
//           { text: ` (${formatPercentage(data.resultsPropTdnaProspecting * 100, 0)})`, options: { fontSize: 8 } },
//         ],
//       },
//       {
//         text: [
//           { text: data.resultsOtherProspecting.toLocaleString() },
//           {
//             text: ` (${formatPercentage((data.resultsPropOtherProspectingTotal) * 100, 0)})`,
//                      options: { fontSize: 8 },
//           },
//         ],
//       },
//       { text: data.resultsProspecting.toLocaleString() },
//     ],
//     [
//       { text: 'Total Spend', options: { fontFace: 'Calibri' } },
//       {
//         text: [
//           { text: formatMoneyAmount(data.spendTdna, data.currency) },
//           { text: ` (${formatPercentage(data.spendPropTdnaProspecting * 100, 0)})`, options: { fontSize: 8 } },
//         ],
//       },
//       {
//         text: [
//           { text: formatMoneyAmount(data.spendOtherProspecting, data.currency) },
//           { text: ` (${formatPercentage((data.spendPropOtherProspectingTotal) * 100, 0)})`,
//              options: { fontSize: 8 }
// },
//         ],
//       },
//       { text: formatMoneyAmount(data.spendProspecting, data.currency) },
//     ],
//     [
//       { text: 'Last 7 Days Performance' },
//       {
//         text: [
//           {
//             text: data.cprLastWTdna,
//             options: {
//               color: data.cprWowChange > 0 ? 'ff0000' : '00ff00',
//             },
//           },
//           {
//             text: `\n${formatMonthDay(subDays(new Date(), 7))} - ${formatMonthDay(subDays(new Date(), 1))}`,
//             options: { fontSize: 8 },
//           },
//         ],
//       },
//       {
//         text: [
//           { text: data.cprLastWOtherProspecting },
//           {
//             text: `\n${formatMonthDay(subDays(new Date(), 7))} - ${formatMonthDay(subDays(new Date(), 1))}`,
//             options: { fontSize: 8 },
//           },
//         ],
//       },
//       { text: '' },
//     ],
//     [
//       { text: 'Lifetime Performance Summary' },
//       {
//         text: [
//           {
//             text: formatMoneyAmount(data.cprTdna, data.currency) + '\n',
//             options: {
//               bold: true,
//               color: data.cprTdnaVsOther > 0 ? 'ff0000' : '00ff00',
//               fontSize: 16,
//             },
//           },
//           { text: 'Cost per result', options: { fontSize: 8 } },
//         ],
//       },
//       {
//         text: [
//           {
//             text: formatMoneyAmount(data.cprOtherProspecting, data.currency) + '\n',
//             options: {
//               bold: true,
//               color: cprVsOtherProspecting > 0 ? 'ff0000' : '00ff00',
//               fontSize: 16,
//             },
//           },
//           { text: 'TDNA vs. Non-TDNA - ', options: { fontSize: 8 } },
//           {
//             text: `${cprVsOtherProspecting > 0 ? '↑' : '↓'} ${formatPercentage(cprVsOtherProspecting * 100, 0)}`,
//             options: {
//               color: cprVsOtherProspecting > 0 ? 'ff0000' : '00ff00',
//               fontSize: 8,
//             },
//           },
//         ],
//       },
//       {
//         text: [
//           {
//             text: formatMoneyAmount(campaignGroup.clientCprBenchmark, data.currency) + '\n',
//             options: { bold: true, fontSize: 16 },
//           },
//           { text: 'TDNA vs. Client Benchmark - ', options: { fontSize: 8 } },
//           {
//             text: `${cprVsBenchmark > 0 ? '↑' : '↓'} ${formatPercentage(cprVsBenchmark * 100, 0)}`,
//             options: {
//               color: cprVsBenchmark > 0 ? 'ff0000' : '00ff00',
//               fontSize: 8,
//             },
//           },
//         ],
//       },
//     ],
//   ];

//   // construct presentation
//   // eslint-disable-next-line new-cap
//   const presentation = new pptxgen();
//   const slide = presentation.addSlide();
//   slide.addText(
//     campaignGroup.name,
//     {
//       x: 0,
//       y: 0.5,
//       w: '100%',
//       align: 'center',
//       color: '4f45e4',
//       fontFace: 'Calibri',
//     }
//   );
//   slide.addTable(
//     rows,
//     {
//       y: 1,
//       border: { color: 'eeeeee', pt: 1 },
//       colW: [3, 2, 2, 2],
//       fontFace: 'Calibri',
//     }
//   );
//   slide.addTable(
//     [['TopicDNA Comments', '']],
//     {
//       y: 3,
//       h: 0.8,
//       border: { color: 'cccccc', pt: 1 },
//       colW: [2, 7],
//       fontFace: 'Calibri',
//     }
//   );
//   slide.addTable(
//     [['Recommended Actions', '']],
//     {
//       y: 4,
//       border: { color: 'cccccc', pt: 1 },
//       colW: [2, 7],
//       fontFace: 'Calibri',
//     }
//   );
//   presentation.writeFile({ fileName: 'campaign_group.pptx' });
// }

function Summary({ campaignGroup, isAdmin }) {
  const last7Days = `${formatMonthDay(subDays(new Date(), 7))} - ${formatMonthDay(subDays(new Date(), 1))}`;
  const last7DaysPrior = `${formatMonthDay(subDays(new Date(), 14))} - ${formatMonthDay(subDays(new Date(), 8))}`;

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const commentsRef = useRef();
  const actionsRef = useRef();
  useEffect(
    () => {
      if (!isNil(commentsRef.current) && !isNil(actionsRef.current)) {
        commentsRef.current.value = campaignGroup.comments;
        actionsRef.current.value = campaignGroup.actions;
      }
    },
    [!isNil(commentsRef.current) && !isNil(actionsRef.current)]
  );

  const { mutate } = useMutation(
    updateCampaignGroup,
    {
      onSuccess: () => {
        setIsUpdating(false);
        setIsEditing(false);
      },
    }
  );

  useEffect(() => {
    mixpanel.track('View Campaign Performance', {
      id: campaignGroup.id,
      platform: campaignGroup.platform,
    });
  }, [campaignGroup]);

  const endDate = campaignGroup.data.endDate && campaignGroup.data.endDate !== 'ongoing'
    ? formatDate(campaignGroup.data.endDate)
    : 'ongoing';
  return (
    <>
      <table className="table-fixed min-w-full divide-y divide-gray-400 bg-white">
        <thead>
          <tr className="text-lg">
            <HeaderCell title={`${formatDate(campaignGroup.data.startDate)} - ${endDate}`} />
            <HeaderCell title="TopicDNA Prospecting" />
            <HeaderCell title="vs. Non-TopicDNA Prospecting" />
            <HeaderCell title="Total Prospecting" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <TitleCell
              description={
                <div>
                  <p className="mb-2">{formatMetricName(campaignGroup.data.resultsKey, true)}</p>
                  <span className="text-xs text-red-600">Includes today&apos;s data which may be incomplete</span>
                </div>
              }
              title="Results"
            />
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">{campaignGroup.data.resultsTdna.toLocaleString()}
                <span className="ml-1 text-sm">
                  ({formatPercentage(campaignGroup.data.resultsPropTdnaProspecting * 100, 0)})
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {campaignGroup.data.resultsOtherProspecting.toLocaleString()}
                <span className="ml-1 text-sm">
                  ({formatPercentage((campaignGroup.data.resultsPropOtherProspectingTotal) * 100, 0)})
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">{campaignGroup.data.resultsProspecting.toLocaleString()}</p>
            </td>
          </tr>
          <tr>
            <TitleCell
              description={
                <div>
                  <span className="text-xs text-red-600">Includes today&apos;s data which may be incomplete</span>
                </div>
              }
              title="Spend"
            />
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {formatMoneyAmount(campaignGroup.data.spendTdna, campaignGroup.data.currency)}
                <span className="ml-1 text-sm">
                  ({formatPercentage(campaignGroup.data.spendPropTdnaProspecting * 100, 0)})
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {
                  formatMoneyAmount(
                    campaignGroup.data.spendOtherProspecting, campaignGroup.data.currency
                  )
                }
                <span className="ml-1 text-sm">
                  ({formatPercentage((campaignGroup.data.spendPropOtherProspectingTotal) * 100, 0)})
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {formatMoneyAmount(campaignGroup.data.spendProspecting, campaignGroup.data.currency)}
              </p>
            </td>
          </tr>
          <tr>
            <TitleCell
              description={
                <div>
                  <p className="mb-2">{formatMetricName(campaignGroup.data.resultsKey, true)}</p>
                  <span className="text-xs text-red-600">Includes today&apos;s data which may be incomplete</span>
                </div>
              }
              title="Lifetime Cost Per Result"
            />
            <td className="border border-gray-300 px-2 py-4">
              <p className={
                `text-3xl font-bold text-${campaignGroup.data.cprTdnaVsOtherProspecting > 0 ? 'red' : 'green'
                }-600`}
              >
                {formatMoneyAmount(campaignGroup.data.cprTdna, campaignGroup.data.currency)}
              </p>
              <span className="text-sm text-gray-500">
                Cost per result
              </span>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-3xl font-bold">
                {formatMoneyAmount(campaignGroup.data.cprOtherProspecting, campaignGroup.data.currency)}
              </p>
              <span className="text-sm text-gray-500">
                TDNA vs. Non-TDNA -
                <>
                  <span className={
                    `mx-1 text-${campaignGroup.data.cprTdnaVsOtherProspecting > 0 ? 'red' : 'green'}-600`
                  }
                  >
                    {`${campaignGroup.data.cprTdnaVsOther > 0 ? '↑' : '↓'}
                    ${formatPercentage(campaignGroup.data.cprTdnaVsOtherProspecting * 100, 0)}`}
                  </span>
                </>
              </span>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-3xl font-bold">
                {
                  campaignGroup.clientCprBenchmark ? formatMoneyAmount(
                    campaignGroup.clientCprBenchmark, campaignGroup.data.currency
                  ) : '-'
                }
              </p>
              <span className="text-sm text-gray-500">
                TDNA vs. Client Benchmark
                {campaignGroup.clientCprBenchmark && (
                  <>
                    <span className={
                      `mx-1 text-${campaignGroup.data.cprTdnaVsBenchmark > 0 ? 'red' : 'green'}-600`
                    }
                    >
                      {`${campaignGroup.data.cprTdnaVsBenchmark > 0 ? '↑' : '↓'}
                    ${formatPercentage(campaignGroup.data.cprTdnaVsBenchmark * 100, 0)}`}
                    </span>
                  </>
                )
                }
              </span>
            </td>
          </tr>
          <tr>
            <TitleCell title="Last 7 days Performance" />
            <td className="border border-gray-300 px-2 py-4">
              <p className={`text-xl text-${campaignGroup.data.cprWowChange > 0 ? 'red' : 'green'}-600`}>
                {formatMoneyAmount(campaignGroup.data.cprLastWTdna, campaignGroup.data.currency)}
              </p>
              <span className="text-sm text-gray-500">
                {last7Days}
              </span>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {formatMoneyAmount(campaignGroup.data.cprLastWOtherProspecting, campaignGroup.data.currency)}
              </p>
              <span className="text-sm text-gray-500">
                {last7Days}
              </span>
            </td>
            <td className="border border-gray-300 px-2 py-4" />
          </tr>
          <tr>
            <TitleCell
              description={formatMetricName(campaignGroup.data.resultsKey, true)}
              title="WoW Results"
            />
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {formatPercentage(campaignGroup.data.resultsWowChangePctTdna * 100, 1)}
              </p>
              <p>{`${last7Days}: `}
                <span className="font-semibold text-indigo-700">
                  {campaignGroup.data.resultsLastWTdna}
                </span>
              </p>
              <p>vs.</p>
              <p>
                {`${last7DaysPrior}: `}
                <span className="font-semibold text-indigo-700">
                  {campaignGroup.data.resultsLastWTdna - campaignGroup.data.resultsWowChangeTdna}
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {formatPercentage(campaignGroup.data.resultsWowChangePctOtherProspecting * 100, 1)}
              </p>
              <p>{`${last7Days}: `}
                <span className="font-semibold text-indigo-700">
                  {campaignGroup.data.resultsLastWOtherProspecting}
                </span>
              </p>
              <p>vs.</p>
              <p>
                {`${last7DaysPrior}: `}
                <span className="font-semibold text-indigo-700">
                  {
                    campaignGroup.data.resultsLastWOtherProspecting -
                    campaignGroup.data.resultsWowChangeOtherProspecting
                  }
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4" />
          </tr>
          <tr>
            <TitleCell
              description={formatMetricName(campaignGroup.data.resultsKey, true)}
              title="WoW Cost Per Result"
            />
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {formatPercentage(campaignGroup.data.cprWowChangePctTdna * 100, 1)}
              </p>
              <p>{`${last7Days}: `}
                <span className="font-semibold text-indigo-700">
                  {formatMoneyAmount(campaignGroup.data.cprLastWTdna, campaignGroup.data.currency)}
                </span>
              </p>
              <p>vs.</p>
              <p>
                {`${last7DaysPrior}: `}
                <span className="font-semibold text-indigo-700">
                  {
                    formatMoneyAmount(
                      campaignGroup.data.cprLastWTdna - campaignGroup.data.cprWowChangeTdna,
                      campaignGroup.data.currency
                    )
                  }
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4">
              <p className="text-xl">
                {formatPercentage(campaignGroup.data.cprWowChangePctOtherProspecting * 100, 1)}
              </p>
              <p>{`${last7Days}: `}
                <span className="font-semibold text-indigo-700">
                  {formatMoneyAmount(campaignGroup.data.cprLastWOtherProspecting, campaignGroup.data.currency)}
                </span>
              </p>
              <p>vs.</p>
              <p>
                {`${last7DaysPrior}: `}
                <span className="font-semibold text-indigo-700">
                  {
                    formatMoneyAmount(
                      (
                        campaignGroup.data.cprLastWOtherProspecting - campaignGroup.data.cprWowChangeOtherProspecting
                      ), campaignGroup.data.currency
                    )
                  }
                </span>
              </p>
            </td>
            <td className="border border-gray-300 px-2 py-4" />
          </tr>

          <tr>
            <TitleCell title="TopicDNA Comments" />
            <td
              className="border border-gray-300 px-2 py-1"
              colSpan={3}
            >
              {isAdmin ? (
                <textarea
                  className="w-full items-center align-middle border-0"
                  onFocus={() => setIsEditing(true)}
                  ref={commentsRef}
                  rows={6}
                />
              ) : <p className="whitespace-pre-wrap px-2 py-4">{campaignGroup.comments}</p>}
            </td>
          </tr>
          <tr>
            <TitleCell title="Recommended Actions" />
            <td
              className="border border-gray-300 px-2 py-1"
              colSpan={3}
            >
              {isAdmin ? (
                <textarea
                  className="w-full items-center align-middle border-0"
                  onFocus={() => setIsEditing(true)}
                  ref={actionsRef}
                  rows={6}
                />
              ) : <p className="whitespace-pre-wrap px-2 py-4">{campaignGroup.actions}</p>}
            </td>
          </tr>
        </tbody>
      </table>
      {isEditing && (
        <div className="text-right px-2 py-4 space-x-2">
          <Button
            onClick={() => {
              commentsRef.current.value = campaignGroup.comments;
              actionsRef.current.value = campaignGroup.actions;
              setIsEditing(false);
            }}
            title="Cancel"
          />
          <Button
            isDisabled={isUpdating}
            kind={ButtonKind.PRIMARY}
            onClick={() => {
              setIsUpdating(true);
              mutate({
                actions: actionsRef.current.value,
                comments: commentsRef.current.value,
                id: campaignGroup.id,
              });
            }}
            title={isUpdating ? 'Updating...' : 'Update'}
          />
        </div>
      )}
    </>
  );
}

Summary.propTypes = {
  campaignGroup: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

function renderLifetimeRankedAdSetsTable(adSets, currency) {
  const columns = getCampaignGroupAdSetTableColumns(currency);
  return (
    <Table
      columns={columns}
      data={adSets}
      entitiesName="ad sets"
      initiallySortedBy="spend"
      isInitiallySortedByDesc
      isScrollable
      noDataMessage="No ad sets found."
    />
  );
}

function renderWowAdSetPerformance(adSets, currency) {
  const last7Days = `${formatMonthDay(subDays(new Date(), 7))} - ${formatMonthDay(subDays(new Date(), 1))}`;
  const last7DaysPrior = `${formatMonthDay(subDays(new Date(), 14))} - ${formatMonthDay(subDays(new Date(), 8))}`;

  return (
    <table className="bg-white min-w-full divide-y divide-gray-200 border">
      <thead>
        <tr>
          <th />
          <th
            className="p-4 border"
            colSpan={2}
          >{last7Days}</th>
          <th
            className="p-4 border"
            colSpan={2}
          >{last7DaysPrior}</th>
          <th />
        </tr>
        <tr>
          <th className="border py-2">Ad Set</th>
          <th className="border py-2 px-2 text-right">Results</th>
          <th className="border py-2 px-2 text-right">Cost Per Result</th>
          <th className="border py-2 px-2 text-right">Results</th>
          <th className="border py-2 px-2 text-right">Cost Per Result</th>
          <th className="border py-2 px-2 text-right">% Change</th>
        </tr>
      </thead>
      <tbody>
        {
          adSets.map(adSet => {
            return (
              <tr
                className={String(adSet.adsetName).toLowerCase().includes('dna') ? 'bg-green-200' : ''}
                key={adSet.id}
              >
                <td className="border px-2 py-4">
                  <p>{adSet.adsetName}</p>
                  <span className="text-gray-600 text-xs">{adSet.campaignName}</span>
                </td>
                <td className="border px-2 py-4 text-right">{adSet.resultsLastW}</td>
                <td className="border px-2 py-4 text-right">{formatMoneyAmount(adSet.cprLastW, currency)}</td>
                <td className="border px-2 py-4 text-right">{adSet.resultsLastW - adSet.resultsWowChange}</td>
                <td className="border px-2 py-4 text-right">
                  {
                    adSet.cprWowChange
                      ? formatMoneyAmount(adSet.cprLastW - adSet.cprWowChange, currency)
                      : '-'
                  }
                </td>
                <td className="border px-2 py-4 text-right">{formatPercentage(adSet.cprWowChangePct * 100, 0)}</td>
              </tr>
            );
          }
          )
        }
      </tbody>
    </table>
  );
}
export default function CampaignGroupDetails(props) {
  // extract arguments
  const { campaignGroupId } = useParams();

  // acquire user data and history handler
  const { userInfo } = useContext(UserInfoContext);
  const history = useHistory();
  const queryClient = useQueryClient();

  // state
  const [paramsOfDownloadDialog, setParamsOfDownloadDialog] = useState({ isOpen: false });

  // query campaign group
  const {
    data: campaignGroup,
    error: errorLoadingCampaignGroup,
    isFetching: isLoadingCampaignGroup,
  } = useQuery(
    ['campaignGroup', campaignGroupId],
    () => getCampaignGroup(campaignGroupId),
    {
      initialData: {},
      onError: ({ status }) => {
        if (status === 404 || status === 403) {
          history.replace('/');
        }
      },
      retry: false,
    }
  );

  // trigger campaign group reporting
  const { mutate: triggerReporting } = useMutation(
    () => {
      queryClient.setQueryData(['campaignGroup', campaignGroupId], { ...campaignGroup, crawlStatus: 'pending' });
      triggerCampaignGroupReporting(campaignGroupId);
    },
    {
      onSuccess: response => {
        if (response) {
          queryClient.setQueryData(['campaignGroup', campaignGroupId], response);
        }
      },
      onError: () => notifyError({ title: 'Recrawl failed. Please try again' }),
    }
  );

  // trigger campaign group recalculation
  const { mutate: triggerRecalculation } = useMutation(
    () => {
      queryClient.setQueryData(['campaignGroup', campaignGroupId], { ...campaignGroup, crawlStatus: 'pending' });
      triggerCampaignGroupRecalculation(campaignGroupId);
    },
    {
      onSuccess: response => {
        if (response) {
          queryClient.setQueryData(['campaignGroup', campaignGroupId], response);
        }
      },
      onError: () => notifyError({ title: 'Recalculation failed. Please try again' }),
    }
  );

  // render campaign group details
  return (
    <>
      <Section
        heading={
          <div className="flex justify-end">
            <Button
              icon={ArrowSmLeftIcon}
              kind={ButtonKind.REGULAR}
              onClick={() => {
                mixpanel.track('Back Button Click', { section: 'Campaign Group Details' });
                history.replace(
                  props.isAdmin ? '/admin/campaign_performance?restore=1' : '/campaign_performance?restore=1'
                );
              }}
              title="Back to Campaign Performance"
            />
            {/* <Button
              hasLeftMargin
              kind={ButtonKind.SUCCESS}
              onClick={() => generatePresentation(campaignGroup)}
              title="Download PPT"
            /> */}
            <Menu
              placement="bottom-end"
              size="lg"
              title="Actions"
            >
              <MenuItem
                icon={DownloadIcon}
                onClick={() => {
                  mixpanel.track('Download Campaign Group Excel', { from: 'Campaign Group' });
                  setParamsOfDownloadDialog({ campaignGroupId, isOpen: true });
                }}
                title="Download Excel Report"
              />
              {(props.isAdmin || userInfo.id === get(campaignGroup, 'creator.id')) && (
                <MenuItem
                  icon={PencilIcon}
                  onClick={() =>
                    history.push(props.isAdmin
                      ? `/admin/campaign_performance/${campaignGroupId}/edit`
                      : `/campaign_performance/${campaignGroupId}/edit`
                    )
                  }
                  title="Edit"
                />
              )}
              <MenuItem
                icon={RefreshIcon}
                onClick={() => {
                  mixpanel.track('Recrawl Campaign Group', { from: 'Campaign Group' });
                  notifySuccess({ title: 'Recrawling data...' });
                  triggerReporting();
                }}
                title="Recrawl Data"
              />
              <MenuItem
                icon={CalculatorIcon}
                onClick={() => {
                  mixpanel.track('Recalculate Campaign Group', { from: 'Campaign Group' });
                  notifySuccess({ title: 'Recalculating data...' });
                  triggerRecalculation();
                }}
                title="Recalculate Data"
              />
            </Menu>
          </div>
        }
        title={
          <div>
            <h1>{campaignGroup.name}</h1>
            <div className="mt-1 flex items-center align-middle">
              <div>
                {campaignGroup.platform && (
                  <BrandIcon
                    brand={campaignGroup.platform}
                    size="small"
                  />
                )}
                <span className="ml-2 text-lg text-gray-700">
                  {campaignGroup.adAccount && campaignGroup.adAccount.name}
                </span>
              </div>
              <div className="font-normal text-gray-500 text-sm">
                <CopyToClipboard
                  isTrackedLink
                  notificationText="Copied to clipboard"
                  text="Copy URL"
                  value={
                    `${window.location.host}/campaign_performance/${campaignGroup.id}/`
                  }
                />
              </div>
            </div>
            {!isLoadingCampaignGroup &&
              <div className="mt-2 font-normal text-sm text-gray-500">
                {campaignGroup.lastSuccessfulCrawlAt && (
                  <div>
                    Data refreshed on {formatTimestamp(campaignGroup.lastSuccessfulCrawlAt)}.
                  </div>
                )}
              </div>
            }
          </div>
        }
      >
        <div className="my-2">
          {errorLoadingCampaignGroup && <ErrorMessage message="Could not load campaign group information." />}
          {campaignGroup.crawlStatus === 'failed' && <ErrorMessage message="Last crawl failed." />}
        </div>
        {isLoadingCampaignGroup
          ? 'Fetching information...'
          : campaignGroup.lastSuccessfulCrawlAt
            ? (
              <Summary
                campaignGroup={campaignGroup}
                isAdmin={props.isAdmin}
              />
            ) : campaignGroup.crawlStatus === 'pending'
              ? <CrawlingIndicator title="Retrieving latest data..." />
              : ''
        }
        {
          campaignGroup.data && (
            <>
              <div className="mb-3">
                <h2 className="mt-10 text-xl font-semibold">
                  Lifetime Ranked Ad Sets
                </h2>
                <p className="text-red-500">Includes today&apos;s data which may be incomplete</p>
              </div>
              {renderLifetimeRankedAdSetsTable(campaignGroup.data.rankedAdsets, campaignGroup.data.currency)}
            </>
          )
        }
        {
          campaignGroup.data && (
            <>
              <h2 className="mt-10 mb-3 text-xl font-semibold">WoW Ad Set Performance</h2>
              {renderWowAdSetPerformance(campaignGroup.data.adsets, campaignGroup.data.currency)}
            </>
          )
        }
      </Section>

      <DownloadDocumentDialog
        close={() => setParamsOfDownloadDialog({ ...paramsOfDownloadDialog, isOpen: false })}
        params={paramsOfDownloadDialog}
        query={({ campaignGroupId }) => getCampaignGroupExcelUrl(campaignGroupId)}
        queryKey={() => ['campaignGroupExcelUrl', campaignGroupId]}
      />

      <CampaignGroupListener
        responseCallback={response => {
          if (has(response, 'id') && response.id.toString() === campaignGroupId) {
            notifySuccess({ title: 'Campaign group has updated data!' });
            queryClient.setQueryData(['campaignGroup', campaignGroupId], response);
          }
        }}
        userUid={userInfo.uid}
      />
    </>
  );
}

CampaignGroupDetails.propTypes = {
  isAdmin: PropTypes.bool,
};
