import 'rc-slider/assets/index.css';

import { GlobeIcon, OfficeBuildingIcon, PlusCircleIcon, UserIcon } from '@heroicons/react/outline';
import { AtSymbolIcon, SearchIcon } from '@heroicons/react/solid';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import styled from 'styled-components';

import Badge, { BadgeContext, BadgeSize } from '@common/Badge';
import Button, { ButtonKind } from '@common/Button';
import BrandIcon from '@common/BrandIcon';
import FormField from '@common/FormField';
import OptionGroup from '@common/OptionGroup';
import COLORS from '@utils/colorsUtils';
import { getHighlightedText, optional } from '@utils/generalUtils';

const SearchTableAffinityRange = styled.div`
  &&& {
    position: absolute;
    left: 310px;
    top: 67px;
    &&& i {
      color: ${COLORS.indigo[500]};
    }
  }
`;

const SearchTableFollowersRange = styled.div`
  &&& {
    position: absolute;
    left: 730px;
    top: 67px;
    &&& i {
      color: ${COLORS.indigo[500]};
    }
  }
`;

const DisabledLink = styled.a`
  &&&& {
    color: ${COLORS.indigo[200]};
  }
`;

function renderName(ToolTip, row, filterValue, openCategorizationDialog) {
  const name = getHighlightedText(row.name, filterValue);
  return (
    <ToolTip
      content={(
        <p>Affinity: {row.affinity.toFixed(2)}x</p>
      )}
    >
      <div className="flex items-center space-x-3">
        <div className="flex-shrink-0">
          <img
            alt=""
            className="h-8 w-8 rounded-full"
            src={row.avatar}
          />
        </div>
        <div className="min-w-0 flex-1">
          <span className="font-medium text-gray-900">
            {isNil(openCategorizationDialog) ? name : (
              <Button
                kind={ButtonKind.LINK}
                onClick={() => openCategorizationDialog(row)}
                title={name}
              />
            )}
            {openCategorizationDialog && (
              <a
                href={`https://www.twitter.com/${row.username}`}
                rel="noreferrer"
                target="_blank"
              >
                <AtSymbolIcon className="ml-2 inline w-4 h-4 text-gray-600" />
              </a>
            )}
            {openCategorizationDialog && (
              <a
                href={`https://www.google.com/search?q=${encodeURIComponent(name)}`}
                rel="noreferrer"
                target="_blank"
              >
                <SearchIcon className="ml-2 inline w-4 h-4 text-gray-600" />
              </a>
            )}
            {!openCategorizationDialog && row.notInGeneralAudience && (
              <ToolTip
                content={
                  <div className="max-w-xs">
                    Unique accounts are accounts that are followed by this particular audience but are not followed by
                    the average person in the country this insight is based upon.
                  </div>
                }
                isHtml
              >
                <span className="ml-2">
                  <Badge
                    context="highlight"
                    size="small"
                    text="Unique"
                  />
                </span>
              </ToolTip>
            )}
          </span>
          {!openCategorizationDialog && (
            <p className="text-gray-500">
              {row.classification && row.category ? (
                <>
                  {getHighlightedText(row.classification, filterValue)}
                  {' - '}
                  {getHighlightedText(row.lastLevelCategories, filterValue)}
                </>
              ) : row.classification ? (
                getHighlightedText(row.classification, filterValue)
              ) : (
                getHighlightedText(row.lastLevelCategories, filterValue)
              )}
            </p>
          )}
        </div>
      </div>
    </ToolTip>
  );
}

function renderLinks(ToolTip, value) {
  return (
    <div className="flex">
      <div className="mr-4">
        <a
          href={value.website}
          rel="noopener noreferrer"
          target="_blank"
        >
          <GlobeIcon className="w-5 h-5 text-indigo-400" />
        </a>
      </div>

      <div className="mr-4">
        <a
          href={`https://www.twitter.com/${value.username}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <BrandIcon
            brand="twitter"
            color="#818CF8"
            size="small"
          />
        </a>
      </div>
      <div className="mr-4">
        {value.instagramHandle ? (
          <a
            href={`https://www.instagram.com/${value.instagramHandle}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <BrandIcon
              brand="instagram"
              color="#818CF8"
              size="small"
            />
          </a>
        ) : (
          <ToolTip content="Instagram account not available.">
            <DisabledLink>
              <BrandIcon
                brand="instagram"
                color="#E0E7FF"
                size="small"
              />
            </DisabledLink>
          </ToolTip>
        )}
      </div>
    </div>
  );
}

function ExternalNameFilter({ column: { filterValue = [], setFilter } }) {
  return (
    <FormField
      icon={SearchIcon}
      onChange={event => setFilter(event.target.value)}
      placeholder="Search Accounts"
      type="text"
      value={filterValue[0] || ''}
    />
  );
}

ExternalNameFilter.propTypes = {
  column: PropTypes.object.isRequired,
};

function ExternalAffinityFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const affinityMarks = {
    0: 0,
    5: '5',
    10: '10',
    20: '20',
    40: '40',
    60: '60',
    80: '80',
    100: '100+',
  };

  const affinityRatio = {
    0: 0,
    5: 5,
    10: 10,
    20: 20,
    40: 40,
    60: 60,
    80: 80,
    100: 999,
  };

  return (
    <SearchTableAffinityRange>
      <div style={{ width: 350 }}>
        <p className="text-left font-semibold">Affinity</p>
        <Range
          allowCross={false}
          defaultValue={[0, 100]}
          marks={affinityMarks}
          onChange={value => {
            const minAffinity = affinityRatio[value[0]];
            const maxAffinity = affinityRatio[value[1]];
            setFilter([minAffinity, maxAffinity]);
          }}
          step={null}
        />
      </div>
    </SearchTableAffinityRange>
  );
}

ExternalAffinityFilter.propTypes = {
  column: PropTypes.object.isRequired,
};

function ExternalFollowersFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const followersMarks = {
    0: 0,
    20: '1,000',
    40: '10,000',
    60: '100,000',
    80: '500,000',
    100: '500,000+',
  };

  const followersRatio = {
    0: 0,
    20: 1000,
    40: 10000,
    60: 100000,
    80: 500000,
    100: 9999999999,
  };

  return (
    <SearchTableFollowersRange>
      <div style={{ width: 350 }}>
        <p className="text-left font-semibold">Follower Count</p>
        <Range
          allowCross={false}
          defaultValue={[0, 100]}
          marks={followersMarks}
          onChange={value => {
            const minFollowers = followersRatio[value[0]];
            const maxFollowers = followersRatio[value[1]];
            setFilter([minFollowers, maxFollowers]);
          }}
          step={null}
        />
      </div>
    </SearchTableFollowersRange>
  );
}

ExternalFollowersFilter.propTypes = {
  column: PropTypes.object.isRequired,
};

export default function getTopAccountTableColumns(
  ToolTip,
  isSimplified,
  { openCategorizationDialog, openInterestDialog, updateCategory }
) {
  /* eslint-disable react/prop-types */
  return [
    {
      accessor: row => row,
      alignment: 'left',
      Cell: ({ cell: { value }, column: { filterValue } }) =>
        renderName(ToolTip, value, filterValue, openCategorizationDialog),
      cellWidth: '250px',
      id: 'name',
      Header: 'NAME',
      sortType: (first, second) => first.original.name.toLowerCase().localeCompare(second.original.name.toLowerCase()),
    },
    ...optional(!isNil(updateCategory), [
      {
        accessor: 'categoryName',
        alignment: 'left',
        Cell: ({ cell: { value }, row: { original } }) => (
          <>
            <OptionGroup
              options={[
                {
                  isActive: value === 'account',
                  title: '?',
                },
                {
                  isActive: value === 'person',
                  onClick: () => updateCategory(original.entityId, 'person'),
                  title: <UserIcon className="w-4 h-4" />,
                },
                {
                  isActive: value === 'organization',
                  onClick: () => updateCategory(original.entityId, 'organization'),
                  title: <OfficeBuildingIcon className="w-4 h-4" />,
                },
              ]}
            />
          </>
        ),
        cellWidth: '150px',
        Header: 'CATEGORY',
      },
      {
        accessor: 'lastLevelTopics',
        alignment: 'left',
        Cell: ({ cell: { value } }) => value.map((item, index) => (
          <Badge
            key={index}
            size={BadgeSize.SMALL}
            text={item}
          />
        )),
        cellWidth: '150px',
        Header: 'TOPICS',
      },
      {
        accessor: 'facebookInterestName',
        alignment: 'left',
        Cell: ({ cell: { value }, row: { original } }) => (
          <>
            {value && (
              <Badge
                context={original.facebookInterestDeprecatedAt ? BadgeContext.DANGEROUS : BadgeContext.INFO}
                size={BadgeSize.SMALL}
                text={value}
              />
            )}
            <PlusCircleIcon
              className="inline w-6 h-6 ml-2 text-gray-400 hover:text-gray-600 hover:cursor-pointer"
              onClick={() => openInterestDialog(original)}
            />
          </>
        ),
        cellWidth: '125px',
        Header: 'META INTEREST',
      },
    ]),
    ...optional(!isSimplified && isNil(updateCategory), {
      accessor: row => row,
      alignment: 'left',
      Cell: ({ cell: { value } }) => renderLinks(ToolTip, value),
      disableGlobalFilter: true,
      disableSortBy: true,
      Header: 'LINKS',
      isSorted: false,
    }),
    {
      accessor: 'affinity',
      alignment: 'right',
      Cell: ({ cell: { value } }) => (
        <div className={value >= 0 ? 'text-green-500' : 'text-red-500'}>
          {value.toFixed(1)}x
        </div>
      ),
      cellWidth: '125px',
      disableGlobalFilter: true,
      Header: () => (
        <ToolTip content="Affinity measures how much this audience likes the account against the average person.">
          <span className="inline-block">AFFINITY</span>
        </ToolTip>
      ),
      sortType: 'number',
    },
    ...optional(!isSimplified && isNil(updateCategory), {
      accessor: 'followersCount',
      alignment: 'right',
      Cell: ({ cell: { value } }) => value.toLocaleString(),
      cellWidth: '125px',
      disableGlobalFilter: true,
      Header: 'FOLLOWERS',
    }),
  ];
  /* eslint-enable react/prop-types */
}
