import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { updateInsightReport } from '@api/insightReports';
import Dialog, { DialogSize } from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import FormAvatar from '@common/FormAvatar';
import FormField from '@common/FormField';
import AudiencePlaceholder from '@images/audience_placeholder.png';
import { notifyError, notifySuccess } from '@utils/toaster';

const STORED_AVATAR = new RegExp('^https?://');

export default function EditInsightReportModal(props) {
  // extract arguments
  const { isOpen, insightReport = {} } = props.params;

  // update report
  const { error, mutate, isLoading: isUpdating } = useMutation(
    data => updateInsightReport({
      avatar: STORED_AVATAR.test(data.avatar) ? null : data.avatar,
      id: insightReport.id,
      name: data.name,
    }),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Report updated!' });
        props.close();
        props.onUpdate();
      },
      onError: error => notifyError({ title: 'Could not update. Please try again.' }),
    }
  );

  // set up the form
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({ mode: 'onChange' });
  useEffect(
    () => {
      if (isOpen) {
        reset({ name: insightReport.name, avatar: insightReport.avatar.default });
      }
    },
    [isOpen]
  );
  const avatar = watch('avatar');

  // avatar upload status
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);

  // render modal
  return (
    <Dialog
      close={props.close}
      hasForm
      isOpen={isOpen}
      isUpdating={isUpdating}
      onSubmit={handleSubmit(mutate)}
      size={DialogSize.EXTRA_LARGE}
      submitTitle="Save"
      title="Edit Insight Report"
      updatingTitle="Updating..."
    >
      {error && <ErrorMessage message={error.message} />}
      <div className="my-4">
        <FormField
          error={errors.name}
          isDisabled={isUpdating}
          label="Name"
          name="name"
          placeholder="Report name"
          register={() => register('name', { required: 'Report name is required' })}
          type="text"
        />
      </div>
      <FormAvatar
        avatar={avatar}
        isUploading={isUploadingAvatar}
        label="Photo"
        name="avatar"
        placeholder={AudiencePlaceholder}
        setIsUploading={setIsUploadingAvatar}
        setValue={setValue}
      />
    </Dialog>
  );
}

EditInsightReportModal.propTypes = {
  close: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
