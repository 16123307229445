export default function getAdAccountTableColumns() {
  /* eslint-disable react/prop-types */
  return [
    {
      accessor: row => row,
      alignment: 'left',
      Header: 'NAME',
      Cell: ({ row: { original } }) => (
        <>
          <div className="font-medium text-base">{original.name}</div>
          <div className="text-gray-500">{original.id}</div>
        </>
      ),
    },
  ];
  /* eslint-enable react/prop-types */
}
