import { PlayIcon, UsersIcon } from '@heroicons/react/outline';
import { formatDistanceToNow } from 'date-fns';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';

import { getRunningCampaigns } from '@api/admin';
import Button, { ButtonKind } from '@common/Button';
import Card from '@common/Card';
import Section from '@common/Section';
import { useQuery } from 'react-query';
import { formatMoneyAmount } from '@utils/formattingUtils';
import { FormDropdown } from '@common/FormDropdowns';
import { isEmpty, isNil, orderBy, sortBy, uniq } from 'lodash';
import { useState } from 'react';

const NO_ORGANIZATION_CAMPAIGNS = '(No Organization)';

export default function RunningCampaigns() {
  // query running campaigns
  const { data: adAccounts, isFetching: isLoadingRunningCampaigns } = useQuery(
    ['runningCampaigns'],
    getRunningCampaigns,
    {
      initialData: [],
      select: data => sortBy(data, 'name'),
    }
  );

  // determine organizations
  const organizations = uniq(adAccounts.flatMap(item => item.organizations)).sort();

  if (adAccounts.some(item => isEmpty(item.organizations))) {
    organizations.push(NO_ORGANIZATION_CAMPAIGNS);
  }

  // determine campaigns by organization
  let campaignsByOrganization = {};

  organizations.forEach(organization => {
    const organizationAdAccounts = adAccounts.filter(item => {
      return item.organizations.includes(organization);
    });

    campaignsByOrganization[organization] = organizationAdAccounts
      .flatMap(item => item.campaigns.length)
      .reduce((a, b) => a + b, 0);
  });

  const noOrganizationAdAccounts = adAccounts.filter(item => isEmpty(item.organizations));
  const campaignsWithNoOrganization = noOrganizationAdAccounts
    .flatMap(item => item.campaigns.length)
    .reduce((a, b) => a + b, 0);

  const [selectedOrganization, setSelectedOrganization] = useState();

  const history = useHistory();

  // render content
  return (
    <>
      <Section
        heading={
          <div className="w-80">
            <FormDropdown
              isClearable
              onSelection={setSelectedOrganization}
              options={
                organizations.map(item => {
                  return {
                    label: `${item} (${pluralize('campaign', item === NO_ORGANIZATION_CAMPAIGNS
                      ? campaignsWithNoOrganization : campaignsByOrganization[item], true)})`,
                    value: item,
                  };
                })
              }
              placeholder="Filter by organization..."
              value={selectedOrganization}
            />
          </div>
        }
        icon={PlayIcon}
        title="Running Campaigns"
      >
        <div className="space-y-10">

          {adAccounts.length > 0
            ? adAccounts.filter(item =>
              isNil(selectedOrganization) ||
              (selectedOrganization.value === NO_ORGANIZATION_CAMPAIGNS && isEmpty(item.organizations)) ||
              item.organizations.includes(selectedOrganization.value)
            )
              .map(adAccount => (
                <Card
                  actions={
                    <>
                      <div className="text-lg flex justify-end">
                        {pluralize('campaign', adAccount.campaigns.length, true)}
                      </div>
                      <div className="text-right">
                        {isEmpty(adAccount.organizations) ? 'No organization' : adAccount.organizations.join(', ')}
                      </div>
                    </>
                  }
                  key={adAccount.id}
                  subtitle={adAccount.id}
                  title={adAccount.name}
                >
                  {sortBy(adAccount.campaigns, 'name').map(campaign => (
                    <div
                      className={
                        `${isEmpty(campaign.campaignGroups) ? 'bg-red-100' : 'bg-indigo-50'} rounded px-2 py-4 my-4`
                      }
                      key={campaign.id}
                    >
                      <div className="flex items-center">
                        <div className="flex-1">
                          <h3 className="text-semibold text-indigo-700 text-base">{campaign.name}</h3>
                          <p className="text-indigo-500">
                            {campaign.id} •
                            {campaign.stopTime
                              ? ` ${formatDistanceToNow(new Date(campaign.stopTime))} left`
                              : ' Running'}
                          </p>
                        </div>
                        <div className="text-right">
                          <p className="text-lg text-green-500">
                            {
                              campaign.dailyBudget !== 0 || campaign.lifetimeBudget !== 0
                                ? campaign.dailyBudget !== 0
                                  ? formatMoneyAmount(campaign.dailyBudget / 100, adAccount.currency)
                                  : formatMoneyAmount(campaign.lifetimeBudget / 100, adAccount.currency)
                                : 'Budget on ad set level'
                            }
                          </p>
                          <span>
                            {
                              campaign.dailyBudget !== 0 || campaign.lifetimeBudget !== 0
                                ? campaign.dailyBudget !== 0 ? 'Daily' : 'Lifetime'
                                : ''
                            }
                          </span>
                        </div>
                      </div>

                      <div className="mt-4 items-center">
                        <span className="font-semibold">
                          {pluralize('TopicDNA Ad Set', campaign.adsets.length, true)}
                        </span>
                        {
                          orderBy(campaign.adsets, 'last7dSpend', 'desc').map(adSet => (
                            <div
                              className="bg-gray-50 border border-gray-300 rounded-lg my-2 px-2 py-4"
                              key={adSet.id}
                            >
                              <div className="flex items-center">
                                <div className="flex-1">
                                  <p className="text-gray-700 font-semibold">{adSet.name}</p>
                                  <p className="text-gray-500">{adSet.id}</p>
                                </div>
                                <div className="text-right ml-10">
                                  <p className="text-base text-gray-700 font-semibold" >
                                    {formatMoneyAmount(adSet.last7dSpend, adAccount.currency)} this month
                                  </p>
                                  <span className="text-gray-500">
                                    {
                                      campaign.dailyBudget === 0 && campaign.lifetimeBudget === 0
                                        ? adSet.dailyBudget !== 0
                                          ? `${formatMoneyAmount(adSet.dailyBudget / 100, adAccount.currency)} daily`
                                          : `${formatMoneyAmount(adSet.lifetimeBudget / 100, adAccount.currency)
                                          } lifetime`
                                        : 'CBO'
                                    }
                                  </span>
                                </div>
                              </div>
                              {adSet.audienceId && (
                                <span className="align-middle">
                                  <UsersIcon className="h-4 w-4 inline mr-2" />
                                  <Button
                                    key={adSet.audienceId}
                                    kind={ButtonKind.LINK}
                                    onClick={() =>
                                      history.push(`/admin/audiences/${adSet.audienceId}`)
                                    }
                                  >
                                    {adSet.audienceName}
                                  </Button>
                                </span>
                              )}
                            </div>
                          ))
                        }
                      </div>
                      {
                        !isEmpty(campaign.campaignGroups) && (
                          <>
                            <p className="mt-4 text-indigo-600 text-sm inline-block">
                              In {pluralize('campaign group', campaign.campaignGroups.length)}:
                            </p>
                            {campaign.campaignGroups.map(campaignGroup => (
                              <Button
                                hasLeftMargin
                                key={campaignGroup.id}
                                kind={ButtonKind.LINK}
                                onClick={() =>
                                  history.push(`/admin/campaign_performance/${campaignGroup.id}`)
                                }
                              >
                                {campaignGroup.name}
                              </Button>
                            ))
                            }
                          </>
                        )
                      }
                    </div>
                  ))}
                </Card>
              ))
            : (
              <div>{isLoadingRunningCampaigns ? 'Retrieving running campaigns...' : 'No running campaigns.'}</div>
            )
          }
        </div>
      </Section>
    </>
  );
}
