import PropTypes from 'prop-types';

export default function SelectUserValue({ data }) {
  const DEFAULT_CLASS_NAMES = [
    'cursor-pointer',
    'flex',
    'flex-grow-0',
    'row-start-1',
    'row-end-1',
    'col-start-1',
    'col-end-3',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'items-center',
    'px-2',
    'py-2',
    'relative',
    'shadow-sm',
    'space-x-1',
    'text-sm',
  ];


  return (
    <div className={DEFAULT_CLASS_NAMES.join(' ')}>
      <div className="flex-shrink-0">
        <img
          alt=""
          className="w-8 h-8 my-1 mr-2 rounded-full"
          src={data.avatar.thumb}
        />
      </div>
      <div className="flex-1 min-w-0">
        <span className="font-medium text-gray-900">
          {data.name} ({data.email})
        </span>
        <p className="text-sm text-gray-500 truncate">{data.organization.name}</p>
      </div>
    </div>
  );
}

SelectUserValue.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
    }),
    email: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};
