import Flag from '@common/Flag';
import { CellWithPercentage } from '@common/table/TableCells';

export default function getPercentageTableColumns(title, extra) {
  /* eslint-disable react/prop-types */
  const columns = [
    {
      accessor: 'value',
      alignment: 'right',
      Cell: ({ cell: { value } }) => (
        <CellWithPercentage
          numberOfDigits={0}
          value={value}
        />
      ),
      cellWidth: '50px',
      disableSortBy: true,
      hasSmallPadding: true,
      Header: () => null,
      id: 'percentage-value',
    },
    {
      accessor: row => row,
      Cell: ({ cell: { value } }) => value.name || 'Unknown',
      disableSortBy: true,
      hasSmallPadding: true,
      Header: () => null,
      id: 'name',
    },
  ];
  if (extra) {
    columns.splice(
      1,
      0,
      {
        accessor: 'extra',
        Cell: ({ cell: { value } }) => extra === 'image' ? (
          <img
            alt=""
            className="w-10 h-10"
            src={value}
          />
        ) : (
          <Flag countryCode={value} />
        ),
        cellWidth: '36px',
        disableSortBy: true,
        hasSmallPadding: true,
        Header: () => null,
        id: 'extra',
      }
    );
  }
  return title ? [{ Header: title, columns: columns }] : columns;
  /* eslint-enable react/prop-types */
}
