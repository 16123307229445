import { get, round } from 'lodash';

import COLORS from '@utils/colorsUtils';

export default function getTopInterestsPieChartConfig(
  drillupCallback,
  drilldownCallback,
  firstLevelSeries,
  secondLevelSeries
) {
  return {
    chart: {
      type: 'pie',
      margin: [70, 0, 0, 0],
      events: {
        drilldown: event => {
          const name = get(event, 'point.name');
          if (!event.seriesOptions && secondLevelSeries[name]) {
            event.target.addSingleSeriesAsDrilldown(event.point, secondLevelSeries[name]);
            event.target.applyDrilldown();
            drilldownCallback(name);
          }
        },
        drillup: drillupCallback,
      },
    },
    series: firstLevelSeries.map(series => {
      return {
        name: series.name,
        data: series.data,
        animation: true,
      };
    }),
    drilldown: {
      activeAxisLabelStyle: {
        textDecoration: 'none',
      },
      activeDataLabelStyle: {
        textDecoration: 'none',
      },
      animation: true,
      drillUpButton: {
        position: {
          align: 'right',
          y: 0,
        },
        relativeTo: 'spacingBox',
        theme: {
          fill: COLORS.indigo[50],
          'stroke-width': 0,
          r: 4,
          states: {
            hover: {
              fill: COLORS.indigo[100],
            },
            select: {
              fill: COLORS.indigo[100],
            },
          },
          style: {
            color: COLORS.indigo[900],
            fontWeight: 'normal',
          },
        },
      },
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            const style = 'font-weight: normal; stroke-width: 0px;';
            return '<span style="' + style + '">' + this.point.name + ': ' + round(this.y, 1) + '%</span>';
          },
          style: {
            fontSize: '13px',
            fontWeight: 'normal',
            color: COLORS.indigo[500],
          },
        },
        label: {
          style: { fontWeight: 'normal' },
        },
        pointWidth: 12,
      },
    },
  };
}
