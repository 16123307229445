import PropTypes from 'prop-types';

export default function TableLoader(props) {
  return [...Array(5).keys()].map(index => (
    <tr
      className="whitespace-normal break-all bg-white"
      key={index}
    >
      {props.columns.map(column => {
        return (
          <td

            key={column.id}
            size={column.cellWidth}
          >
            <div className="motion-safe:animate-pulse flex space-x-4">
              <div className="m-2 flex-1 space-y-4 py-1">
                <div className="h-4 bg-gray-100 rounded w-3/4 border"></div>
              </div>
            </div>
          </td>
        );
      })}
    </tr>
  ));
}

TableLoader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
};
