import { AnnotationIcon, ArrowSmLeftIcon, DocumentIcon, SparklesIcon } from '@heroicons/react/outline';
import mixpanel from 'mixpanel-browser';
import { useContext, useState } from 'react';

import AudienceSuggestions from './AudienceSuggestions';
import KeywordAudienceForm from './KeywordAudienceForm';
import RequestAudienceForm from './RequestAudienceForm';
import FromScratchForm from './FromScratchForm';

import Button, { ButtonKind } from '@common/Button';
import Section from '@common/Section';
import { Wizard, WizardStep } from '@common/Wizard';
import SelectionStep from '@common/wizard/SelectionStep';
import { UserInfoContext } from '@hoc/withUserInfo';
import { useHistory } from 'react-router-dom';

const PLATFORMS = [
  {
    brandIcon: 'facebook',
    title: 'Meta',
    value: 'facebook',
    beta: false,
  },
  {
    brandIcon: 'twitter',
    title: 'Twitter',
    value: 'twitter',
    beta: false,
  },
  {
    value: 'tiktok',
    brandIcon: 'tiktok',
    title: 'TikTok',
    beta: false,
  },
  {
    brandIcon: 'snapchat',
    title: 'Snapchat',
    value: 'snapchat',
    beta: false,
  },
  {
    value: 'linkedin',
    brandIcon: 'linkedin',
    title: 'LinkedIn',
    beta: false,
  },
  {
    value: 'dv360',
    brandIcon: 'dv360',
    title: 'Display & Video 360',
    beta: true,
  },
  {
    value: 'pinterest',
    brandIcon: 'pinterest',
    title: 'Pinterest',
    beta: true,
  },
];

const METHODS = [
  {
    description: 'Build your audience from the ground up',
    icon: DocumentIcon,
    title: 'From scratch',
    value: 'manual',
  },
  {
    description: 'Get audience suggestions from 1 or 2 keywords',
    icon: SparklesIcon,
    title: 'From Keywords',
    value: 'keyword',
  },
  {
    description: 'Tell us who you want to target',
    icon: AnnotationIcon,
    title: 'Ask the Team',
    value: 'request',
  },
];

export default function CreateAudience() {
  const history = useHistory();

  // set up state
  const { userInfo } = useContext(UserInfoContext);
  const [platform, setPlatform] = useState();
  const [method, setMethod] = useState();
  const [audienceParams, setAudienceParams] = useState({});

  // logic for showing steps
  const pinterestOptions = userInfo.features.createPinterestAudience ? [METHODS[0], METHODS[2]] : [METHODS[2]];

  // render form
  return (
    <>
      <Section
        heading={
          <div className="flex justify-end">
            <Button
              icon={ArrowSmLeftIcon}
              kind={ButtonKind.REGULAR}
              onClick={() => {
                mixpanel.track('Back Button Click', { section: 'Create Audience' });
                history.replace('/audiences/');
              }}
              title="Back to Audiences"
            />
          </div>
        }
        title="Create a Target Audience"
      >
        <div className="mt-4 bg-white shadow sm:rounded-lg px-10 py-8">
          <Wizard>
            <SelectionStep
              header="Which advertising platform would you like this audience to be targeted on?"
              onSelect={option => {
                setPlatform(option.value);
                setMethod(null);
              }}
              options={PLATFORMS}
              title="Select a Target Platform"
            />
            <SelectionStep
              header="How would you like to create this audience?"
              onSelect={option => setMethod(option.value)}
              options={
                platform === 'facebook' ? METHODS
                  : platform === 'pinterest' ? pinterestOptions
                    : [METHODS[0], METHODS[2]]
              }
              title="Choose an Input Method"
            />
            {/* placeholder step */}
            {!method && (
              <WizardStep>
                dummy
              </WizardStep>
            )}
            {method === 'manual' && <FromScratchForm platform={platform} />}
            {method === 'keyword' && <KeywordAudienceForm setAudienceParams={setAudienceParams} />}
            {method === 'keyword' && <AudienceSuggestions audienceParams={audienceParams} />}
            {method === 'request' && <RequestAudienceForm platform={platform} />}
          </Wizard>
        </div>
      </Section>
    </>
  );
}
