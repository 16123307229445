import { issueRequest } from '@root/utils/httpUtils';

export async function searchDetailedInterests(platform: string, query: string): Promise<Array<FacebookInterest>> {
  const response = await issueRequest<Array<FacebookInterest>>(
    `/v3/platforms/${platform}/targeting_options/detailed_interests`,
    { queryParams: { query } }
  );
  return response.payload;
}

export interface FacebookInterest {
  name: string;
  type: string;
  id: string;
}
