import config from '@root/config';
import { UserAccount } from '@utils/commonUtils';
import { getPagedResults, issueRequest, Method } from '@utils/httpUtils';

import { Identity } from './authentication';

export async function updateUserRole(userId: number, isAdmin: boolean): Promise<void> {
  await issueRequest<void>(`/v3/users/${userId}`, {
    body: { user: { admin: isAdmin } },
    method: Method.PUT,
  });
}

export async function createUser(user: UserAccount): Promise<void> {
  await issueRequest<void>('/v3/users', {
    body: user,
    method: Method.POST,
  });
}

export async function createUserIdentity(userId: number, identity: Identity): Promise<void> {
  await issueRequest<void>(`/v3/users/${userId}/identities`, {
    body: { identity },
    method: Method.POST,
  });
}

export async function deleteUser(userId: number): Promise<void> {
  await issueRequest<void>(`/v3/users/${userId}`, {
    method: Method.DELETE,
  });
}

export async function deleteUserIdentity(userId: number, identityId: number): Promise<void> {
  await issueRequest<void>(`/v3/users/${userId}/identities/${identityId}`, {
    method: Method.DELETE,
  });
}

export async function getUser(userId: number): Promise<UserAccount> {
  const response = await issueRequest<UserAccount>(`/v3/users/${userId}`);
  return response.payload;
}

export const getUsers = getPagedResults<UserAccount>('/v3/users');

export async function resetUserPassword(email: string): Promise<void> {
  await issueRequest<void>('/auth/password?config_name=default', {
    body: { email, redirect_url: `${config.API_URL}/forgot_password` },
    method: Method.POST,
  });
}

export async function resetUserPreferences(userId: number): Promise<void> {
  await issueRequest<void>(`/v3/users/${userId}/preferences`, {
    body: { user: { preference_attributes: { reset: true } } },
    method: Method.PUT,
  });
}

export async function updateUser(user: UserAccount): Promise<void> {
  await issueRequest<void>(`/v3/users/${user.id}`, {
    body: { user },
    method: Method.PUT,
  });
}

export async function updateUserPassword(
  resetPasswordToken: string,
  password: string,
  passwordConfirmation: string
): Promise<void> {
  await issueRequest<void>('/auth/password', {
    body: { password, passwordConfirmation, resetPasswordToken },
    method: Method.PUT,
  });
}

export async function updateUserPreferences(userId: number, preferences: { [key: string]: string }): Promise<void> {
  await issueRequest<void>(`/v3/users/${userId}/preferences`, {
    body: { user: { preference_attributes: preferences } },
    method: Method.PUT,
  });
}
