import PropTypes from 'prop-types';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

export default function AudienceGenerationListener(props) {
  if (props.userUid) {
    return (
      <ActionCableConsumer
        channel={{ channel: 'AudienceGenerationChannel', uid: props.userUid }}
        onReceived={props.responseCallback}
      />
    );
  }
  return props.children || null;
}

AudienceGenerationListener.propTypes = {
  children: PropTypes.node,
  responseCallback: PropTypes.func.isRequired,
  userUid: PropTypes.string,
};
