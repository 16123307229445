import { size } from 'lodash';

import { searchLanguages, searchLocations, searchSkills, searchTitles } from '@api/targeting_old';
import { sortHits } from '@utils/generalUtils';

const AGES = [18, 25, 35, 55];

export default function getLinkedInFields(values, setValue) {
  // adjust minimal or maximal age if necessary
  if (values.minAge && values.maxAge && values.minAge.value > values.maxAge.value) {
    const index = AGES.findIndex(age => age === values.minAge.value);
    const nearest = index === size(AGES) - 1 ? null : AGES[index + 1] - 1;
    setValue('maxAge', nearest ? { label: nearest.toString(), value: nearest } : null);
  }
  if (!values.minAge && values.maxAge) {
    const nearest = AGES[AGES.findIndex(age => age === values.maxAge.value - 1) - 1];
    setValue('minAge', { label: nearest.toString(), value: nearest });
  }

  // return fields
  const minAgeOptions = AGES.map(age => ({ label: age.toString(), value: age }));
  const maxAgeOptions = AGES.filter(age => !values.minAge || age > values.minAge.value)
    .map(age => ({ label: (age - 1).toString(), value: age - 1 }));
  return [
    {
      label: 'Gender',
      name: 'gender',
      options: [
        { label: 'All Genders', value: '' },
        { label: 'Female', value: 'female' },
        { label: 'Male', value: 'male' },
      ],
      placeholder: 'Select a gender',
      type: 'choice',
      width: 4,
    },
    {
      label: 'Minimum Age',
      name: 'minAge',
      options: minAgeOptions,
      type: 'choice',
      width: 4,
    },
    {
      isClearable: true,
      label: 'Maximum Age',
      name: 'maxAge',
      options: maxAgeOptions,
      type: 'choice',
      width: 4,
    },
    {
      empty: 'No country found',
      isMulti: true,
      label: 'Countries',
      name: 'countries',
      options: query => searchLocations('linkedin', query).then(countries => {
        const mapped = countries.map(country => ({ label: country.name, value: country.id }));
        return sortHits(mapped, 'label', query);
      }),
      placeholder: 'Select a country',
      type: 'lookup',
    },
    {
      empty: 'No language found',
      isMulti: true,
      label: 'Languages',
      name: 'languages',
      options: query => searchLanguages('linkedin', query).then(languages => {
        const mapped = languages.map(language => ({ label: language.name, value: language.id }));
        return sortHits(mapped, 'label', query);
      }),
      placeholder: 'Select a language',
      type: 'lookup',
    },
    {
      empty: 'No skill found',
      isMulti: true,
      label: 'Skills',
      name: 'skills',
      options: query => searchSkills('linkedin', query).then(skills => {
        const mapped = skills.map(skill => ({ label: skill.name, value: skill.id }));
        return sortHits(mapped, 'label', query);
      }),
      placeholder: 'Select a skill',
      type: 'lookup',
    },
    {
      empty: 'No title found',
      isMulti: true,
      label: 'Titles',
      name: 'titles',
      options: query => searchTitles('linkedin', query).then(titles => {
        const mapped = titles.map(title => ({ label: title.name, value: title.id }));
        return sortHits(mapped, 'label', query);
      }),
      placeholder: 'Select a title',
      type: 'lookup',
    },
  ];
}
