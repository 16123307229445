import { isNil, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Entity, getEntity, searchEntities, updateEntity } from '@api/entity';
import { TopSocialAccount } from '@api/insightReport';
import Button, { ButtonKind } from '@common/Button';
import { CommonDialogProps } from '@common/commonTypes';
import Dialog, { DialogSize } from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { FormAsyncDropdown } from '@common/FormDropdowns';
import { findInstanceGroupId, Option } from '@utils/commonUtils';
import { sortHits } from '@utils/generalUtils';
import { notifySuccess } from '@utils/notification';

const CANNOT_MAP_ID = 4500926;

type FacebookInterestOption = Option<number> & {
  audienceSize: number; name: string, type: string, deprecatedAt: string | null
};

export interface EditFacebookInterestDialogProps extends CommonDialogProps<EditFacebookInterestDialogParams> {
  onUpdate: ({ entityId, facebookInterestAudienceSize, facebookInterestId, facebookInterestName }: {
    entityId: number;
    facebookInterestAudienceSize?: number;
    facebookInterestId?: number;
    facebookInterestName?: string;
    facebookInterestDeprecatedAt?: string | null;
  }) => void;
}

export interface EditFacebookInterestDialogParams {
  socialAccount?: TopSocialAccount;
}

export default function EditFacebookInterestDialog(props: EditFacebookInterestDialogProps): JSX.Element {
  // currently selected interest
  const [selectedFacebookInterest, setSelectedFacebookInterest] = useState<FacebookInterestOption>();

  // query the entity
  const { data: entity } = useQuery<Entity>(
    ['entity', props.params.socialAccount?.entityId],
    () => getEntity(props.params.socialAccount!.entityId),
    {
      enabled: !isNil(props.params.socialAccount),
    }
  );

  // update entity
  const { error: updateEntityError, mutate: _updateEntity } = useMutation(
    async (facebookInterest: FacebookInterestOption) => {
      await updateEntity({
        id: props.params.socialAccount!.entityId,
        instanceGroups: [
          {
            attributes: [{
              confidence: 1,
              name: 'facebook_interest',
              value: facebookInterest.value,
            }],
            id: findInstanceGroupId(entity!, 'facebook_interest'),
          },
        ],
      });
      return facebookInterest;
    },
    {
      onSuccess: (facebookInterest: FacebookInterestOption) => {
        notifySuccess('Entity was updated.');
        props.onUpdate({
          entityId: props.params.socialAccount!.entityId,
          facebookInterestAudienceSize: facebookInterest.audienceSize,
          facebookInterestId: facebookInterest.value,
          facebookInterestName: facebookInterest.name,
          facebookInterestDeprecatedAt: facebookInterest.deprecatedAt,
        });
        props.close();
      },
    }
  );

  useEffect(
    () => {
      if (props.params.isOpen) {
        setSelectedFacebookInterest(undefined);
      }
    },
    [props.params.isOpen]
  );

  // render dialog
  const name = props.params.socialAccount?.name;
  return (
    <Dialog
      close={props.close}
      isDisabled={isNil(selectedFacebookInterest)}
      isOpen={props.params.isOpen}
      onSubmit={() => _updateEntity(selectedFacebookInterest!)}
      size={DialogSize.LARGE}
      submitTitle="Update"
      title="Update Meta Interests"
    >
      <div className="space-y-2">
        {!isNil(updateEntityError) && <ErrorMessage message={JSON.stringify(updateEntityError)} />}
        <div className="my-8">
          <FormAsyncDropdown
            defaultInputValue={props.params.socialAccount?.name}
            hasInitialLoad
            isOpenInitially
            label={`Meta Interest to Assign to ${name!}`}
            loadOptions={(value: string, callback: (value: Array<Option>) => void) => {
              void searchEntities('facebook_interest', value === '' ? props.params.socialAccount?.name ?? '' : value)
                .then(entityInterests => {
                  const mapped = entityInterests.filter(
                    entitySearchResult => !entitySearchResult.deprecatedAt
                  ).map(
                    entitySearchResult => (
                      {
                        audienceSize: entitySearchResult.audienceSize,
                        label: `${entitySearchResult.name} (${startCase(entitySearchResult.type)})`,
                        name: entitySearchResult.name,
                        type: entitySearchResult.type,
                        value: entitySearchResult.entityId,
                        deprecatedAt: entitySearchResult.deprecatedAt,
                      } as FacebookInterestOption
                    )
                  );
                  return sortHits(mapped, 'label', value) as Array<Option>;
                })
                .then(callback);
            }}
            onSelection={setSelectedFacebookInterest}
            placeholder="Select a Meta interest..."
            value={selectedFacebookInterest}
          />
        </div>
        <div className="bg-gray-100 p-4 rounded-lg">
          <p className="mb-4">
            If there is no known Meta interest for {name}, click the button so we know if we&apos;ve already checked
            this account. You can always reverse this later if you find an interest.
          </p>
          <Button
            kind={ButtonKind.DANGEROUS}
            onClick={() =>
              _updateEntity({
                audienceSize: 0,
                label: 'cannot-map',
                name: 'cannot-map',
                type: 'interests',
                value: CANNOT_MAP_ID,
                deprecatedAt: null,
              })
            }
            title="No Meta Interest for this account"
          />
        </div>
      </div>
    </Dialog>
  );
}
