import { formatDistanceToNow } from 'date-fns';
import { ArrowSmRightIcon, TrashIcon } from '@heroicons/react/solid';

import BrandIcon from '@common/BrandIcon';
import { Menu, MenuItem } from '@common/Menu';
import { getDetailedUserColumn, getIdColumn } from '@common/table/TableColumns';

export default function getOrganizationTableColumns({ openDeleteDialog, openTransferDialog }) {
  /* eslint-disable react/prop-types */
  return [
    getIdColumn(),
    getDetailedUserColumn(true),
    {
      accessor: 'lastSignInAt',
      alignment: 'center',
      Cell: ({ cell: { value } }) => value ? `${formatDistanceToNow(new Date(value))} ago` : 'No',
      Header: 'SIGNED IN',
    },
    {
      accessor: 'identities',
      alignment: 'left',
      Cell: ({ cell: { value } }) => value.map(item => (
        <span
          className="mr-2"
          key={item.provider}
        >
          <BrandIcon
            brand={item.provider}
            size="small"
          />
        </span>
      )),
      Header: 'IDENTITIES',
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <Menu size="lg">
          <MenuItem
            icon={ArrowSmRightIcon}
            onClick={() => openTransferDialog(original)}
            title="Transfer User to Another Organization"
          />
          <MenuItem
            icon={TrashIcon}
            isDangerous
            onClick={() => openDeleteDialog(original)}
            title="Delete"
          />
        </Menu>
      ),
      disableSortBy: true,
      Header: '',
      id: 'actions',
    },
  ];
  /* eslint-enable react/prop-types */
}
