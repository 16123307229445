import { capitalize, get, includes, isEmpty, isNil, size, uniqBy } from 'lodash';
import mixpanel from 'mixpanel-browser';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import { getDv360Fields, useDv360Base } from './dv360Fields';
import { calculateDv360Targeting, getDv360Defaults } from './dv360Targeting';
import getFacebookFields from './facebookFields';
import { calculateFacebookTargeting, getFacebookDefaults } from './facebookTargeting';
import getLinkedInFields from './linkedInFields';
import { calculateLinkedInTargeting, getLinkedInDefaults } from './linkedInTargeting';
import { getPinterestFields, usePinterestBase } from './pinterestFields';
import { calculatePinterestTargeting, getPinterestDefaults } from './pinterestTargeting';
import { getSnapchatFields, useSnapchatBase } from './snapchatFields';
import { calculateSnapchatTargeting, getSnapchatDefaults } from './snapchatTargeting';
import { getTikTokFields, useTikTokBase } from './tikTokFields';
import { calculateTikTokTargeting, getTikTokDefaults } from './tikTokTargeting';
import { getTwitterFields, useTwitterBase } from './twitterFields';
import { calculateTwitterTargeting, getTwitterDefaults } from './twitterTargeting';

import { getRecentLabels } from '@api/audiences';
import { getInsightReports, getTargetingSuggestions } from '@api/insightReports';
import { getTargetingAudienceSize } from '@api/targeting_old';
import Badge from '@common/Badge';
import BrandIcon from '@common/BrandIcon';
import Button, { ButtonKind } from '@common/Button';
import FormAvatar from '@common/FormAvatar';
import FormBuilder from '@common/FormBuilder';
import { FormAsyncDropdown, FormCreatableDropdown, FormDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import Slider from '@common/Slider';
import StackedCards from '@common/StackedCards';
import { UserInfoContext } from '@hoc/withUserInfo';
import AudiencePlaceholder from '@images/audience_placeholder.png';
import { isNone } from '@utils/generalUtils';
import SelectInsightReportOption from '@common/reactSelect/SelectInsightReportOption';
import SelectInsightReportValue from '@common/reactSelect/SelectInsightReportValue';

const STORED_AVATAR = new RegExp('^https?://');
const FUNCTIONS = {
  dv360: {
    calculate: calculateDv360Targeting,
    getDefaults: getDv360Defaults,
    getFields: getDv360Fields,
    useBase: useDv360Base,
  },
  facebook: {
    calculate: calculateFacebookTargeting,
    getDefaults: getFacebookDefaults,
    getFields: getFacebookFields,
    useBase: () => ({}),
  },
  linkedin: {
    calculate: calculateLinkedInTargeting,
    getDefaults: getLinkedInDefaults,
    getFields: getLinkedInFields,
    useBase: () => ({}),
  },
  pinterest: {
    calculate: calculatePinterestTargeting,
    getDefaults: getPinterestDefaults,
    getFields: getPinterestFields,
    useBase: usePinterestBase,
  },
  snapchat: {
    calculate: calculateSnapchatTargeting,
    getDefaults: getSnapchatDefaults,
    getFields: getSnapchatFields,
    useBase: useSnapchatBase,
  },
  tiktok: {
    calculate: calculateTikTokTargeting,
    getDefaults: getTikTokDefaults,
    getFields: getTikTokFields,
    useBase: useTikTokBase,
  },
  twitter: {
    calculate: calculateTwitterTargeting,
    getDefaults: getTwitterDefaults,
    getFields: getTwitterFields,
    useBase: useTwitterBase,
  },
};

export default function AudienceEditor(props) {
  // acquire user data
  const { userInfo } = useContext(UserInfoContext);

  // get platform identities, functions and audience data
  const identities = userInfo.identities.filter(identity => identity.provider === props.platform);
  const { calculate, getDefaults, getFields, useBase } = FUNCTIONS[props.platform];
  const adAudience = props.adAudience || {};

  // get recent labels
  const { data: recentLabels } = useQuery(
    ['recentLabels'],
    () => getRecentLabels()
  );

  // set up the form
  const {
    control,
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      avatar: get(adAudience, 'avatar.default'),
      identity: size(identities) !== 0 ? { value: identities[0].id } : undefined,
      labels: (adAudience.labels || []).map(label => ({ label, value: label })),
      name: adAudience.name,
      ...getDefaults(adAudience.unifiedTargeting, adAudience.deprecatedFacebookInterests || []),
    },
    mode: 'onChange',
  });
  const values = watch();

  // slider status
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [isDv360SliderOpen, setIsDv360SliderOpen] = useState(false);

  // generate fields
  const base = useBase(values);
  const fields = getFields(
    values,
    setValue,
    base,
    adAudience.deprecatedFacebookInterests || [],
    isSliderOpen ? undefined : () => setIsSliderOpen(true)
  );
  const isTargetingValid = !fields.some(field => field.error || errors[field.name] ||
    (field.required && isNone(values[field.name]) && !(field.name in touchedFields))
  );

  // query audience size
  const targeting = calculate(values, adAudience.deprecatedFacebookInterests || []);
  const { data: audienceSize, isFetching: isLoadingAudienceSize } = useQuery(
    ['targetingAudienceSize', props.platform, targeting],
    () => getTargetingAudienceSize(props.platform, targeting),
    {
      enabled: isTargetingValid,
      initialData: { maximalAudienceSize: 0, minimalAudienceSize: 0 },
    }
  );

  // set submit function
  props.setSubmit(callback => {
    setIsSubmitted(true);
    if (!fields.some(field => field.error)) {
      handleSubmit(() => callback({
        avatar: STORED_AVATAR.test(values.avatar) ? undefined : values.avatar,
        labels: values.labels.map(label => label.label),
        maximalAudienceSize: audienceSize.maximalAudienceSize,
        minimalAudienceSize: audienceSize.minimalAudienceSize,
        name: values.name,
        ...targeting,
      }))();
    }
  });

  // avatar uploading state, counts and submit flag
  const [isUploading, setIsUploading] = useState(false);
  const [counts, setCounts] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [selectedInsightReport, setSelectedInsightReport] = useState(props.adAudience?.insightReport);
  const [selectedSegmentationFile, setSelectedSegmentationFile] = useState();
  const [selectedSegment, setSelectedSegment] = useState();
  const [selectedSuggestions, setSelectedSuggestions] = useState();

  // query insight report
  const { data: targetingSuggestions, isFetching: isLoadingTargetingSuggestions } = useQuery(
    ['targetingSuggestions', (selectedInsightReport || {}).id],
    () => getTargetingSuggestions(selectedInsightReport.id, props.platform),
    {
      enabled: isNil(props.adAudience?.insightReport) && !isNil(selectedInsightReport),
      initialData: { segments: {} },
    }
  );

  const baseData = props.adAudience?.unifiedTargeting?.segments || targetingSuggestions?.segments;
  const currentSegments = baseData[selectedSegmentationFile?.value] || {};
  const currentSuggestions = currentSegments[selectedSegment?.value] || {};
  const interestSlotCount = props.platform === 'facebook'
    ? Object.entries(values)
      .filter(([key, value]) => key.startsWith('interest__') && !isEmpty(value))
      .reduce(
        (acc, [key, value]) => {
          const index = parseInt(key.split('__')[1], 10);
          return Math.max(acc, index + 1);
        },
        0
      )
    : 0;

  // render form
  const touched = isSubmitted
    ? Object.fromEntries(
      fields.filter(field => field.type === 'repeatable').map(field => [`${field.mainName}__0`, true])
    )
    : touchedFields;
  return (
    <>
      <div className="grid grid-cols-12 space-x-6 divide-x">
        <div className="col-span-7">
          {size(identities) > 1 && (
            <FormDropdown
              control={control}
              label="Identity"
              name="identity"
              options={identities.map(identity => ({ label: identity.name, value: identity.id }))}
              placeholder="Select an identity"
              value={values.identity}
            />
          )}
          <FormBuilder
            control={control}
            counts={counts}
            errors={errors}
            fields={fields}
            getValues={getValues}
            register={register}
            setCounts={setCounts}
            setValue={setValue}
            touchedFields={touched}
          />
          {/* <div className="my-4 p-4 bg-gray-100 rounded-lg whitespace-pre">
              {JSON.stringify(targeting, null, 2)}
              </div> */
          }

          {props.platform === 'dv360' && (
            <div className="mt-4">
              <Button
                onClick={() => setIsDv360SliderOpen(true)}
                title="Get DV360 Suggestions"
              />
            </div>
          )}
        </div>
        <div className="col-span-5 space-y-4 pl-6">
          <div>
            <h2 className="text-lg text-gray-500">
              Audience size
            </h2>
            <p className="text-xl font-semibold text-gray-900">
              <span className="mr-2"><BrandIcon brand={props.platform} /></span>
              {['dv360', 'pinterest'].includes(props.platform) ? 'Not available on this platform'
                : isLoadingAudienceSize
                  ? 'Loading...'
                  : audienceSize.minimalAudienceSize.toLocaleString() +
                  (audienceSize.minimalAudienceSize !== audienceSize.maximalAudienceSize
                    ? ' - ' + audienceSize.maximalAudienceSize.toLocaleString() + ' people'
                    : ' people')
              }
            </p>
          </div>

          <hr />

          <div>
            <FormField
              error={errors.name}
              isDisabled={props.isUpdating}
              label="Name"
              name="name"
              placeholder="Audience name"
              register={() => register('name', { required: 'Audience name is required' })}
              type="text"
            />
          </div>
          <div>
            <FormAvatar
              avatar={values.avatar}
              isUploading={isUploading}
              label="Photo"
              name="avatar"
              placeholder={AudiencePlaceholder}
              setIsUploading={setIsUploading}
              setValue={setValue}
            />
          </div>

          <div>
            <FormCreatableDropdown
              control={control}
              label="Labels"
              name="labels"
              onChange={value => setValue('labels', value, { shouldValidate: true })}
              placeholder={
                <p>Enter labels separated by a comma</p>
              }
              value={values.labels || []}
            />
            {!isEmpty(recentLabels) && (
              <>
                <div className="mt-4 font-medium text-sm">Recently used labels</div>
                <div className="space-x-2 space-y-2">
                  {recentLabels.map((label, index) => (
                    <Badge
                      key={label}
                      onClick={() => {
                        if (!includes(values.labels.map(item => item.value), label)) {
                          setValue('labels', values.labels.concat([{ label, value: label }]));
                        }
                      }}
                      text={label}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
          <hr />
          <div className="mt-8">
            {props.children}
          </div>
        </div>
      </div>

      <Slider
        actions={(
          <>
            <Button
              onClick={() => setIsSliderOpen(false)}
              title="Cancel"
            />
            {props.platform === 'facebook' && (
              <Button
                hasLeftMargin
                isDisabled={isNil(selectedSuggestions)}
                kind={ButtonKind.PRIMARY}
                onClick={() => {
                  mixpanel.track('Add Suggestions', { to: 'New Layer' });
                  setValue(`interest__${interestSlotCount}`, selectedSuggestions.interests);
                  setIsSliderOpen(false);
                }}
                title="Add to New Layer"
              />
            )}
            <Button
              hasLeftMargin
              isDisabled={isNil(selectedSuggestions)}
              kind={ButtonKind.PRIMARY}
              onClick={() => {
                mixpanel.track('Add Suggestions', { to: 'Current Layer' });
                if (props.platform === 'facebook') {
                  if (interestSlotCount === 0) {
                    setValue('interest__0', selectedSuggestions.interests);
                  } else {
                    const key = `interest__${interestSlotCount - 1}`;
                    setValue(key, uniqBy([...values[key], ...selectedSuggestions.interests], 'value'));
                  }
                } else {
                  setValue('interests', uniqBy([...values['interests'], ...selectedSuggestions.interests], 'value'));
                }
                setIsSliderOpen(false);
              }}
              title="Add"
            />
          </>
        )}
        close={() => setIsSliderOpen(false)}
        isOpen={isSliderOpen}
        title="Get Interest Suggestions"
      >
        <div className="space-y-4">
          <div>
            <FormAsyncDropdown
              hasInitialLoad
              loadOptions={(query, callback) =>
                getInsightReports(0, query).then(response => callback(response.payload.data)) && undefined
              }
              onClear={() => {
                setSelectedSegmentationFile(null);
                setSelectedSegment(null);
              }}
              onSelection={setSelectedInsightReport}
              option={SelectInsightReportOption}
              placeholder="Select an insight report..."
              singleValue={SelectInsightReportValue}
              value={selectedInsightReport}
            />
          </div>
          <div>
            <FormDropdown
              isDisabled={isEmpty(baseData)}
              isLoading={isLoadingTargetingSuggestions}
              onSelection={setSelectedSegmentationFile}
              options={Object.keys(baseData).map(item =>
                ({ label: `File with ${pluralize('segment', item, true)}`, value: item })
              )}
              placeholder="Select an audience segmentation file..."
              value={selectedSegmentationFile}
            />
          </div>
          <FormDropdown
            isDisabled={isNil(selectedSegmentationFile)}
            onSelection={setSelectedSegment}
            options={Object.keys(currentSegments).map(item => ({ label: `Audience segment ${item}`, value: item }))}
            placeholder="Select an audience segment..."
            value={selectedSegment}
          />
          <hr />
          <div>
            <StackedCards
              isDisabled={isNil(selectedSegment)}
              onSelection={setSelectedSuggestions}
              options={Object.entries(currentSuggestions).map(([id, value]) => {
                const interests = props.platform === 'facebook' ? value.facebook : value.twitter;
                const numberOfInterests = pluralize('interest', size(interests), true);
                const sampleInterests = interests.slice(0, 5).map(interest => interest.name).join(', ');

                return ({
                  description: isEmpty(interests)
                    ? `No ${capitalize(props.platform)} interests available to select`
                    : `${numberOfInterests} including: ${sampleInterests}`,
                  isDisabled: isEmpty(interests),
                  interests: interests.map(interest => ({
                    label: interest.name,
                    name: interest.name,
                    type: 'interests',
                    value: interest.id,
                  })),
                  label: `${value.l1Topic} - ${value.l2Topic}`,
                  value: id,
                });
              })}
              value={selectedSuggestions}
            />
          </div>
        </div>
      </Slider>

      {/* DV360 Slider */}
      {props.platform === 'dv360' && (
        <Slider
          actions={(
            <>
              <Button
                onClick={() => setIsDv360SliderOpen(false)}
                title="Cancel"
              />
              <Button
                hasLeftMargin
                isDisabled={isNil(selectedSuggestions)}
                kind={ButtonKind.PRIMARY}
                onClick={() => {
                  // Filter the selected suggestions by type = 'interests'
                  const selectedInterestSuggestions = selectedSuggestions.interests.filter(
                    interest => interest.type === 'interests'
                  );

                  // Filter the selected suggestions by type = 'google_audiences'
                  const selectedGoogleAudienceSuggestions = selectedSuggestions.interests.filter(
                    interest => interest.type === 'google_audiences'
                  );

                  // Filter the selected suggestions by type = 'broad_keywords'
                  const selectedBroadKeywordsSuggestions = selectedSuggestions.interests.filter(
                    interest => interest.type === 'broad_keywords'
                  );

                  // Filter the selected suggestions by type = 'urls'
                  const selectedUrlsSuggestions = selectedSuggestions.interests.filter(
                    interest => interest.type === 'urls'
                  );

                  mixpanel.track('Add Suggestions', { to: 'Current Layer' });
                  setValue('interest', uniqBy([...values['interest'], ...selectedInterestSuggestions], 'value'));


                  setValue(
                    'googleAudiences',
                    uniqBy([...values['googleAudiences'], ...selectedGoogleAudienceSuggestions], 'value')
                  );

                  setValue(
                    'broadKeywords',
                    uniqBy([...values['broadKeywords'], ...selectedBroadKeywordsSuggestions], 'value')
                  );

                  setValue('urls', uniqBy([...values['urls'], ...selectedUrlsSuggestions], 'value'));

                  setIsDv360SliderOpen(false);
                }}
                title="Add"
              />
            </>
          )}
          close={() => setIsDv360SliderOpen(false)}
          isOpen={isDv360SliderOpen}
          title="Get DV360 Suggestions"
        >
          <div className="space-y-4">
            <div>
              <FormAsyncDropdown
                hasInitialLoad
                loadOptions={(query, callback) =>
                  getInsightReports(0, query).then(response => callback(response.payload.data)) && undefined
                }
                onClear={() => {
                  setSelectedSegmentationFile(null);
                  setSelectedSegment(null);
                }}
                onSelection={setSelectedInsightReport}
                option={SelectInsightReportOption}
                placeholder="Select an insight report..."
                singleValue={SelectInsightReportValue}
                value={selectedInsightReport}
              />
            </div>
            <div>
              <FormDropdown
                isDisabled={isEmpty(baseData)}
                isLoading={isLoadingTargetingSuggestions}
                onSelection={setSelectedSegmentationFile}
                options={Object.keys(baseData).map(item =>
                  ({ label: `File with ${pluralize('segment', item, true)}`, value: item })
                )}
                placeholder="Select an audience segmentation file..."
                value={selectedSegmentationFile}
              />
            </div>
            <FormDropdown
              isDisabled={isNil(selectedSegmentationFile)}
              onSelection={setSelectedSegment}
              options={Object.keys(currentSegments).map(item => ({ label: `Audience segment ${item}`, value: item }))}
              placeholder="Select an audience segment..."
              value={selectedSegment}
            />
            <hr />
            <div>
              <StackedCards
                isDisabled={isNil(selectedSegment)}
                onSelection={setSelectedSuggestions}
                options={Object.entries(currentSuggestions).map(([id, value]) => {
                  // If the value.dv360 property is not undefined or null, then use that
                  const interests = value.dv360 || [];
                  const numberOfInterests = pluralize('interest', size(interests), true);
                  const sampleInterests = interests.slice(0, 5).map(interest => interest.name).join(', ');

                  return ({
                    description: isEmpty(interests)
                      ? `No ${capitalize(props.platform)} interests available to select`
                      : `${numberOfInterests} including: ${sampleInterests}`,
                    isDisabled: isEmpty(interests),
                    interests: interests.map(interest => ({
                      label: interest.name,
                      name: interest.name,
                      type: interest.type,
                      value: interest.id,
                    })),
                    label: `${value.l1Topic} - ${value.l2Topic}`,
                    value: id,
                  });
                })}
                value={selectedSuggestions}
              />
            </div>
          </div>
        </Slider>
      )}
    </>
  );
}

AudienceEditor.propTypes = {
  adAudience: PropTypes.object,
  children: PropTypes.node.isRequired,
  isUpdating: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setSubmit: PropTypes.func.isRequired,
};
