import {
  LogoutIcon,
  OfficeBuildingIcon,
  PuzzleIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/outline';
import { useContext } from 'react';
import { NavLink as Link, useHistory } from 'react-router-dom';

import { OrganizationDataContext } from '@hoc/withOrganizationData';
import { classNames } from '@utils/generalUtils';
import { UserInfoContext } from '@hoc/withUserInfo';
import { Menu, MenuItem } from '@common/Menu';
import { getUserName } from '@utils/commonUtils';
import { checkNotNull } from '@root/utils/assertions';
import { getUserAvatar } from '@root/utils/avatar';

export default function NavbarUserMenu(): JSX.Element {
  // preparations
  const history = useHistory();
  const { logout, setUserInfo, userInfo } = useContext(UserInfoContext);
  checkNotNull(userInfo, 'User info');
  const { organization } = useContext(OrganizationDataContext);

  // render user menu
  return (
    <Menu
      data-tut="reactour_user_nav"
      isDark={userInfo.isCurrentlyAdmin}
      placement="bottom-end"
      title={
        <>
          <span className="sr-only">
            Open user menu
          </span>
          <div className="flex items-center text-right">
            <div className="mr-3">
              <span className="text-sm font-medium text-white">
                {getUserName(userInfo)}
              </span>
              <p className="text-xs font-medium text-indigo-200">
                {organization?.name}
              </p>
            </div>
            <div>
              <img
                alt=""
                className="inline-block h-9 w-9 rounded-full"
                src={getUserAvatar(userInfo)}
              />
            </div>
          </div>
        </>
      }
    >
      <MenuItem
        custom={(active: boolean) => (
          <Link
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 ',
              'group flex w-full items-center px-4 py-4 rounded-md mb-2'
            )}
            to="/user/profile"
          >
            <div>
              <img
                alt=""
                className="inline-block h-9 w-9 rounded-full"
                src={getUserAvatar(userInfo)}
              />
            </div>
            <div className="ml-3 ">
              <span className="font-medium text-gray-700 group-hover:text-indigo-700 break-all">
                {getUserName(userInfo)}
              </span>
              <p className="text-sm text-gray-500 group-hover:text-indigo-700">
                {organization?.name}
              </p>
            </div>
          </Link>
        )}
      />
      {userInfo.admin && (
        <div className="py-2">
          <MenuItem
            icon={SwitchHorizontalIcon}
            isDangerous
            onClick={() => {
              setUserInfo({ ...userInfo, isCurrentlyAdmin: !userInfo.isCurrentlyAdmin });
              history.push(userInfo.isCurrentlyAdmin ? '/audiences' : '/admin/users');
            }}
            title={`Switch to ${userInfo.isCurrentlyAdmin ? 'Normal' : 'Admin'} Mode`}
          />
        </div>
      )}
      <div className="py-2">
        <MenuItem
          icon={PuzzleIcon}
          onClick={() => history.push('/user/connected_platforms')}
          title="Connected Platforms"
        />
        <MenuItem
          icon={OfficeBuildingIcon}
          onClick={() => history.push('/user/organization')}
          title="Organization"
        />
      </div>
      {/* <div className="py-2">
        <MenuItem
          custom={(active: boolean) => (
            <a
              className={classNames(
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700 ',
                'group flex items-center px-4 py-4 rounded-md hover:text-indigo-700'
              )}
              href="https://help.topicdna.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <SupportIcon
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              />
              Help Center
            </a>
          )}
        />
      </div> */}
      <div className="pt-2 ">
        <MenuItem
          icon={LogoutIcon}
          onClick={logout}
          title="Sign Out"
        />
      </div>
    </Menu>
  );
}
