import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { CalendarIcon, PencilIcon, TagIcon, UserGroupIcon } from '@heroicons/react/solid';
import { capitalize, get, isEmpty } from 'lodash';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import getTargetedAdSetTableColumns from './targetedAdSetTableColumns';
import InterestSuggestions from './InterestSuggestions';
import Targeting from './Targeting';

import { getAdAudience } from '@api/adAudience';
import { getTargetedAdSets, updateAdSet } from '@api/audiences';
import BrandIcon from '@common/BrandIcon';
import Button, { ButtonKind } from '@common/Button';
import Card from '@common/Card';
import CopyToClipboard from '@common/CopyToClipboard';
import DialogIcon from '@common/DialogIcon';
import DialogWithIcon from '@common/DialogWithIcon';
import Loader from '@common/Loader';
import Table from '@common/Table';
import { UserInfoContext } from '@hoc/withUserInfo';
import { platformAdSetUrls } from '@utils/campaignsUtils';
import { formatMonthDayYear } from '@utils/formattingUtils';

import AdSetSelector from '../AdSetSelector';

export default function AdAudienceDetailsView(props) {
  // extract arguments
  const { adAudienceId } = useParams();

  // acquire history handler and user data
  const history = useHistory();
  const { userInfo } = useContext(UserInfoContext);

  // query ad audience
  const { data: adAudience, isFetching: isLoadingAdAudience, refetch: refetchAdAudience } = useQuery(
    ['audience', adAudienceId],
    () => getAdAudience(adAudienceId),
    {
      initialData: { targeting: {} },
      onError: ({ status }) => {
        if (status === 404 || status === 403) {
          history.replace('/');
        }
      },
      retry: false,
    }
  );

  // query ad sets using the audience
  const { data: targetedAdSets, isFetching: isLoadingTargetedAdSets, refetch: refetchTargetedAdSets } = useQuery(
    ['targetedAdSets', adAudienceId],
    () => getTargetedAdSets(adAudienceId),
    { initialData: [] }
  );

  // update ad set
  const { isLoading: isUpdating, mutate } = useMutation(
    () => {
      mixpanel.track('Target Audience Update', {
        id: adAudience.id,
        name: adAudience.name,
        platform: targetAdSet.platform,
        ad_account: targetAdSet.adAccountName,
        ad_set: targetAdSet.label,
        campaign: targetAdSet.campaignName,
      });

      return updateAdSet({
        adAccountId: targetAdSet.adAccountId,
        adAccountName: targetAdSet.adAccountName,
        adSetId: targetAdSet.value,
        adSetName: targetAdSet.label,
        adAudienceId,
        adAudienceName: adAudience.name,
        campaignId: targetAdSet.campaignId,
        campaignName: targetAdSet.campaignName,
        identityId: targetAdSet.identity,
        insertionOrderId: targetAdSet.insertionOrderId,
        insertionOrderName: targetAdSet.insertionOrderName,
        organizationId: targetAdSet.organizationId,
        organizationName: targetAdSet.organizationName,
        platform: adAudience.platform,
        userId: targetAdSet.userId,
      });
    },
    {
      onSuccess: () => setParamsOfSuccessModal({ isOpen: true }),
    }
  );

  // modal state
  const [targetAdSet, setTargetAdSet] = useState({});
  const [paramsOfInterestSuggestionsModal, setParamsOfInterestSuggestionsModal] = useState({ isOpen: false });
  const [paramsOfSuccessModal, setParamsOfSuccessModal] = useState({ isOpen: false });
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);

  // render view
  const capitalizedPlatform = adAudience.platform === 'dv360' ? 'DV360' : capitalize(adAudience.platform);
  const hasRelevantIdentity = !props.isAdmin
    ? userInfo.identities.some(identity => (identity.provider === adAudience.platform) ||
      (identity.provider === 'google' && adAudience.platform === 'dv360'))
    : false;
  return (
    <div>
      <div className="grid grid-cols-8 mb-4">
        <div className="col-span-5">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div>
                {get(adAudience, 'avatar.default') && (
                  <img
                    alt=""
                    className="h-16 w-16 rounded-full"
                    src={adAudience.avatar.default}
                  />
                )}
              </div>
            </div>
            <div>
              <span className="text-xl font-medium text-gray-900">
                {adAudience.name}
              </span>
              <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:space-x-4">
                <div className="flex items-center text-sm text-gray-500">
                  {adAudience.name && (
                    <BrandIcon
                      brand={adAudience.platform}
                      color="#6b7280"
                      size="small"
                    />
                  )}
                </div>

                <div className="flex items-center text-sm text-gray-500">
                  {!isLoadingAdAudience && (
                    <>
                      <UserGroupIcon className="w-5 h-5" />
                      <span className="ml-1">
                        {adAudience.minimalAudienceSize
                          ? `${adAudience.minimalAudienceSize.toLocaleString()} -
                        ${adAudience.maximalAudienceSize.toLocaleString()} people`
                          : 'Not available'}
                      </span>
                    </>
                  )}
                </div>

                {adAudience.labels && adAudience.labels.length > 0 && (
                  <div className="flex items-center text-sm text-gray-500">
                    <TagIcon className="w-5 h-5" />
                    <span className="ml-1">
                      {adAudience.labels.join(', ')}
                    </span>
                  </div>
                )}

                <div className="flex items-center text-sm text-gray-500">
                  <CalendarIcon className="w-5 h-5" />
                  <span className="ml-1">
                    {/* Get the created at formatted */}
                    {adAudience.createdAt && formatMonthDayYear(adAudience.createdAt)}
                  </span>
                </div>

                <div className="flex items-center text-sm text-gray-500">
                  <CopyToClipboard
                    isTrackedLink
                    notificationText="Copied to clipboard"
                    text="Copy URL"
                    value={`${window.location.host}/audiences/${adAudience.id}/`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3">
          {adAudience.name && (
            <div className="flex items-center justify-end">
              <Button
                icon={ArrowSmLeftIcon}
                kind={ButtonKind.REGULAR}
                onClick={() => history.push('/audiences/?restore=1')}
                title="Back to Audiences"
              />
              {!adAudience.parentId && (
                <Button
                  hasLeftMargin
                  icon={PencilIcon}
                  kind={ButtonKind.PRIMARY}
                  onClick={() => history.push(`/audiences/${adAudienceId}/edit`)}
                  title="Edit Audience"
                />
              )}
            </div>
          )}
        </div>
      </div>

      {isLoadingAdAudience ? (
        <Loader loadingText="Retrieving audience to target..." />
      ) : (
        <div className="grid grid-cols-3 gap-x-4">
          <div className="col-span-2">
            <Card
              actions={!isEmpty(adAudience.deprecatedFacebookInterests) && (
                <span
                  className="cursor-pointer text-red-500 font-bold text-3xl"
                  onClick={() => setParamsOfInterestSuggestionsModal({ isOpen: true })}
                >
                  !
                </span>
              )}
              subtitle="This is the targeting criteria for your target audience."
              title="Target Audience Details"
            >
              <div data-tut={'reactour_targeting_criteria'}>
                <Targeting
                  deprecatedInterests={adAudience.deprecatedFacebookInterests}
                  platform={adAudience.platform}
                  targeting={adAudience.unifiedTargeting}
                />
              </div>
            </Card>
            <div className="mt-4">
              <Card title="Ad sets using this audience">
                <Table
                  columns={getTargetedAdSetTableColumns()}
                  data={targetedAdSets}
                  isLoading={isLoadingTargetedAdSets}
                  noDataMessage="No ad sets using this audience yet."
                />
              </Card>
            </div>
          </div>
          <div>
            <div className="bg-white border-gray-200 border rounded-md">
              <div className="bg-indigo-100 w-full p-6">
                <h1 className="text-left text-xl font-semibold text-indigo-900">
                  Use this audience in your campaign
                </h1>
                <p className="my-4 text-gray-600">
                  Choose an ad set and we will update its targeting criteria with this audience.
                </p>
                <AdSetSelector
                  adAudienceId={adAudienceId}
                  clearTarget={() => setTargetAdSet({})}
                  isAdmin={props.isAdmin}
                  isTokenInvalid={isTokenInvalid}
                  platform={adAudience.platform}
                  setIsTokenInvalid={setIsTokenInvalid}
                  setTarget={setTargetAdSet}
                  targetAdSet={targetAdSet}
                />
                {(props.isAdmin || hasRelevantIdentity) && (
                  <Button
                    isDisabled={isEmpty(targetAdSet) || isUpdating}
                    isFullWidth
                    isLoading={isUpdating}
                    kind={ButtonKind.PRIMARY}
                    onClick={mutate}
                    title={`Update ${adAudience.platform === 'dv360' ? 'Line Item' : 'Ad Set'} Targeting`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <InterestSuggestions
        adAudience={adAudience}
        close={() => setParamsOfInterestSuggestionsModal({ isOpen: false })}
        deprecatedInterests={adAudience.deprecatedFacebookInterests}
        onUpdate={() => {
          setParamsOfInterestSuggestionsModal({ ...paramsOfInterestSuggestionsModal, isOpen: false });
          refetchAdAudience();
        }}
        params={paramsOfInterestSuggestionsModal}
      />

      <DialogWithIcon
        close={() => {
          setParamsOfSuccessModal({ ...paramsOfSuccessModal, isOpen: false });
          refetchTargetedAdSets();
        }}
        description={
          `You can view your ad set on ${capitalizedPlatform} ` +
          'or use the same targeting criteria for a different ad set.'
        }
        hasNoButtons
        icon={
          <div className="mb-4">
            <DialogIcon
              context="success"
              icon="CheckIcon"
              outline
            />
          </div>
        }
        isOpen={paramsOfSuccessModal.isOpen}
        title={`Your ${capitalizedPlatform} ad set was updated!`}
      >
        <div className="mt-8">
          <Button
            onClick={() => {
              mixpanel.track('Back Button Click', { section: 'Target Audience Details' });
              setParamsOfSuccessModal({ ...paramsOfSuccessModal, isOpen: false });
              refetchTargetedAdSets();
            }}
            title="Go Back to Audience"
          />
          <Button
            hasLeftMargin
            isTargetExternal
            kind={ButtonKind.PRIMARY}
            onClick={() => {
              mixpanel.track('View Ad Set Button Click', { platform: adAudience.platform });
              setParamsOfSuccessModal({ ...paramsOfSuccessModal, isOpen: false });
            }}
            target={paramsOfSuccessModal.isOpen
              ? platformAdSetUrls(
                adAudience.platform,
                targetAdSet.organizationId,
                targetAdSet.adAccountId,
                targetAdSet.campaignId,
                targetAdSet.insertionOrderId,
                targetAdSet.value
              )
              : undefined}
            title={`View on ${capitalizedPlatform}`}
          />
        </div>
      </DialogWithIcon>
    </div>
  );
}

AdAudienceDetailsView.propTypes = {
  isAdmin: PropTypes.bool,
};
