import PropTypes from 'prop-types';

export default function AuthenticationLoadingHOC(WrappedComponent) {
  const HOC = props => (props.isAuthenticationLoading ? <div /> : <WrappedComponent {...props} />);

  HOC.propTypes = {
    isAuthenticationLoading: PropTypes.bool,
  };

  return HOC;
}

AuthenticationLoadingHOC.propTypes = {
  WrappedComponent: PropTypes.element,
};
