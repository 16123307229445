import { useHistory } from 'react-router-dom';

const AlreadyRegistered = () => {
  const history = useHistory();

  return (
    <p className="mt-4 text-sm font-medium">
      Already registered?{'  '}
      <a
        className="text-sm text-indigo-600 hover:text-indigo-500"
        href="/"
        onClick={() => history.push('/')}
      >
        Sign in
      </a>
    </p>
  );
};

export { AlreadyRegistered as PureComponent };
export default AlreadyRegistered;
