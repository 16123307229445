import { issueRequest } from '@root/utils/httpUtils';
import { commonFetch } from '@utils/fetchUtils';

export function getAdAccounts(platform, identityId, organizationId) {
  return issueRequest(
    `/v3/platforms/${platform}/ad_accounts`,
    {
      queryParams: { identityId, organizationId },
    }
  ).then(response => response.payload);
}

export function getCampaigns(platform, identityId, adAccountId) {
  return commonFetch(`/v3/platforms/${platform}/campaigns?identity_id=${identityId}&ad_account_id=${adAccountId}`);
}

export function getInsertionOrders(platform, identityId, adAccountId, campaignId) {
  return issueRequest(
    `/v3/platforms/${platform}/insertion_orders`,
    {
      queryParams: { identityId, adAccountId, campaignId },
    }
  ).then(response => response.payload);
}


export function getAdSets(platform, identityId, adAccountId, campaignId, insertionOrderId) {
  return issueRequest(
    `/v3/platforms/${platform}/ad_sets`,
    {
      queryParams: { identityId, adAccountId, campaignId, insertionOrderId },
    }
  ).then(response => response.payload);
}

export function getPlatformOrganizations(platform, identityId) {
  return commonFetch(`/v3/platforms/${platform}/organizations?identity_id=${identityId}`);
}
