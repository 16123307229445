import { capitalize, startCase } from 'lodash';
import { useMutation } from 'react-query';

import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { notifyError, notifySuccess } from '@utils/notification';

import { CommonDialogProps } from './commonTypes';

export interface DeleteDialogParams {
  id?: number;
  name?: string;
}

export interface DeleteDialogProps extends CommonDialogProps<DeleteDialogParams> {
  delete: (id: number) => Promise<void>;
  entityName: string;
  onUpdate: () => unknown;
}

export default function DeleteDialog(props: DeleteDialogProps): JSX.Element {
  // delete entity
  const { error, mutate, isLoading: isUpdating } = useMutation(
    () => props.delete(props.params.id!),
    {
      onSuccess: () => {
        notifySuccess(`${capitalize(props.entityName)} removed!`);
        props.close();
        props.onUpdate();
      },
      onError: () => notifyError(`Could not remove ${props.entityName}. Please try again.`),
    }
  );

  // render modal
  return (
    <Dialog
      close={props.close}
      isDangerous
      isOpen={props.params.isOpen}
      isUpdating={isUpdating}
      onSubmit={mutate}
      submitTitle="Delete"
      title={`Delete ${startCase(props.entityName)}`}
      updatingTitle="Deleting..."
    >
      {error !== null && <ErrorMessage message={error as string} />}
      <div className="mt-4 mb-8 text-gray-900">
        Are you sure you want to delete <span className="font-medium">{props.params.name}</span>?
        This action cannot be undone.
      </div>
    </Dialog>
  );
}
