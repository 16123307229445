import { includes, isArray, isNil, isPlainObject, range, size } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Button, { ButtonKind } from '@common/Button';
import { FormAsyncDropdown, FormCreatableDropdown, FormDropdown, FormTreeDropdown } from '@common/FormDropdowns';
import FormError from '@common/FormError';
import FormField from '@common/FormField';
import FormSlider from '@common/FormSlider';
import FormToggle from '@common/FormToggle';

const WIDTH_CLASSES = [
  'col-span-1',
  'col-span-2',
  'col-span-3',
  'col-span-4',
  'col-span-5',
  'col-span-6',
  'col-span-7',
  'col-span-8',
  'col-span-9',
  'col-span-10',
  'col-span-11',
  'col-span-12',
];


function getValueDigest(value) {
  if (isNil(value)) {
    return 'nil';
  }
  if (isArray(value)) {
    return value.map(getValueDigest).join('|');
  }
  if (isPlainObject(value)) {
    return getValueDigest(value.value);
  }
  return value;
}

export function getDigest(fields, values) {
  const fieldNames = fields.filter(field => field.type !== 'repeatable').map(field => field.name);
  return Object.entries(values)
    .sort()
    .filter(([key, value]) => key.includes('__') || includes(fieldNames, key))
    .map(([key, value]) => getValueDigest(value))
    .join('|');
}

function getRules(validator) {
  if (!validator) {
    return undefined;
  }
  return { validate: typeof validator === 'string' ? value => (size(value) === 0 ? validator : undefined) : validator };
}

function renderItem(context, item, baseIndex) {
  // simple types
  const fullName = item.name + (baseIndex !== undefined ? `__${baseIndex}` : '');
  const label = item.secondaryLabel && baseIndex ? item.secondaryLabel : item.label;
  if (item.type === 'choice') {
    const options = typeof item.options === 'function' ? item.options(baseIndex) : item.options;
    const Dropdown = options.some(option => option.children) ? FormTreeDropdown : FormDropdown;
    return (
      <Dropdown
        control={context.control}
        error={context.errors[fullName]}
        isClearable={item.isClearable}
        isCollapsed={item.isCollapsed}
        isDisabled={typeof item.isDisabled === 'function' ? item.isDisabled(baseIndex) : item.isDisabled}
        isExtensible={item.isExtensible}
        isMulti={item.isMulti}
        label={label}
        name={fullName}
        options={options}
        placeholder={item.placeholder}
        rules={getRules(item.required)}
        value={context.values[fullName]}
      />
    );
  }
  if (item.type === 'lookup') {
    return (
      <>
        <FormAsyncDropdown
          control={context.control}
          error={context.errors[fullName]}
          isExtensible={item.isExtensible}
          isMulti={item.isMulti}
          label={label}
          loadOptions={(value, callback) => item.options(value).then(callback) && undefined}
          multiValueLabel={item.multiValueLabel}
          name={fullName}
          noOptionsMessage={item.empty}
          option={item.selectSearchOption}
          placeholder="Type to search"
          rules={getRules(item.required)}
          value={context.values[fullName]}
        />
        {item.auxiliaryLink && (
          <Button
            isDisabled={isNil(item.auxiliaryLink.onClick)}
            kind={ButtonKind.LINK}
            onClick={item.auxiliaryLink.onClick}
            title={item.auxiliaryLink.title}
          />
        )}
      </>
    );
  }
  if (item.type === 'phrases') {
    return (
      <FormCreatableDropdown
        control={context.control}
        isMulti
        label={label}
        name={fullName}
        onChange={value => context.setValue(fullName, value, { shouldValidate: true })}
        placeholder={item.placeholder}
        value={context.values[fullName] || []}
      />
    );
  }
  if (item.type === 'slider') {
    return (
      <FormSlider
        label={label}
        maximum={item.maximum}
        minimum={item.minimum}
        name={fullName}
        register={() => context.register(fullName)}
        value={context.values[fullName] || item.minimum}
      />
    );
  }
  if (item.type === 'text') {
    return (
      <div className="mt-4">
        <FormField
          error={context.errors[fullName]}
          label={label}
          name={fullName}
          placeholder={item.placeholder}
          register={() => context.register(fullName, { required: item.required, validate: item.validate })}
          type={item.isTextArea ? 'textarea' : 'text'}
        />
      </div>
    );
  }
  if (item.type === 'toggle') {
    return (
      <FormToggle
        control={context.control}
        label={label}
        name={fullName}
        primaryTitle={item.primary}
        secondaryTitle={item.secondary}
      />
    );
  }

  // complex types
  if (item.type === 'repeatable') {
    // determine number of nested items
    let count = Math.max(context.counts[item.mainName] || 0, 1);
    let isTouched = false;
    for (const [name, value] of Object.entries(context.values)) {
      if (name.startsWith(item.mainName + '__') && size(value)) {
        count = Math.max(count, parseInt(name.substring(item.mainName.length + 2), 10) + 1);
        isTouched = isTouched || value;
      }
    }
    isTouched = isTouched || `${item.mainName}__0` in context.touchedFields;

    // render items
    return (
      <>
        {range(0, count).map(index => (
          <div
            key={index}
          >
            {renderItems(context, item.items, index)}
            {index === count - 1 && context.values[`${item.mainName}__${index}`] && (
              <Button
                kind={ButtonKind.LINK}
                onClick={() => context.setCounts({ ...context.counts, [item.mainName]: count + 1 })}
                title={
                  <span className="mr-4">
                    {item.addNewTitle || `Add new ${item.mainName}`}
                  </span>
                }
              />
            )}
            {index === count - 1 && item.auxiliaryLink && (
              <Button
                isDisabled={isNil(item.auxiliaryLink.onClick)}
                kind={ButtonKind.LINK}
                onClick={item.auxiliaryLink.onClick}
                title={item.auxiliaryLink.title}
              />
            )}
          </div>
        ))}
        {item.error && isTouched && <FormError message={item.error} />}
      </>
    );
  }

  // auxiliary content
  if (item.type === 'auxiliary' && item.content) {
    return item.content;
  }
}

function shouldShow(item, baseIndex) {
  return !item.skip || (typeof item.skip === 'function' && !item.skip(baseIndex));
}

function renderItems(context, items, baseIndex) {
  return items.some(item => item.width) ? (
    <div className="grid grid-cols-12 gap-4">
      {items.map((item, index) => {
        const show = shouldShow(item, baseIndex);
        return show || item.width ? (
          <div
            className={WIDTH_CLASSES[(item.width || 12) - 1]}
            key={index}
          >
            {show && renderItem(context, item, baseIndex)}
          </div>
        ) : null;
      })}
    </div>
  ) : items.filter(item => shouldShow(item, baseIndex)).map((item, index) => (
    <React.Fragment key={index}>
      {renderItem(context, item, baseIndex)}
    </React.Fragment>
  ));
}

export default function FormBuilder(props) {
  const context = {
    control: props.control,
    counts: props.counts,
    errors: props.errors,
    register: props.register,
    setCounts: props.setCounts,
    setValue: props.setValue,
    touchedFields: props.touchedFields,
    values: props.getValues(),
  };
  return renderItems(context, props.fields);
}

FormBuilder.propTypes = {
  control: PropTypes.object.isRequired,
  counts: PropTypes.object,
  errors: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  getValues: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setCounts: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  touchedFields: PropTypes.object.isRequired,
};
