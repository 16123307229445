import { includes } from 'lodash';
import PropTypes from 'prop-types';

function shouldShow(item, relevantTopics) {
  return relevantTopics[0] === 'All Topics' || item.topics.some(itemTopic => includes(relevantTopics, itemTopic));
}

export default function InterestsAccountsTable(props) {
  return (
    <>
      <h4 className="font-medium text-lg text-gray-900">Top Accounts - {props.topic}</h4>
      {(
        <div className="mt-6 flow-root">
          <ul className="-my-4 divide-y divide-gray-200">
            {props.accounts
              .filter(item => shouldShow(item, props.relevantTopics))
              .slice(0, 8)
              .map((user, index) => (
                <li
                  className="flex items-center py-4 space-x-3"
                  key={index}
                >
                  <div className="flex-shrink-0">
                    <img
                      alt=""
                      className="h-8 w-8 rounded-full"
                      src={user.avatar}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <span className="text-sm font-medium text-gray-900">{user.name}</span>
                    <p className="text-sm text-gray-500">{user.category}</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
}

InterestsAccountsTable.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  relevantTopics: PropTypes.arrayOf(PropTypes.string).isRequired,
  topic: PropTypes.string.isRequired,
};
