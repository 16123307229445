import Cookie from 'browser-cookies';
import { initializeApp } from 'firebase/app';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import mixpanel from 'mixpanel-browser';
import pluralize from 'pluralize';
import { ActionCableProvider } from '@thrash-industries/react-actioncable-provider';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import WebFont from 'webfontloader';

import CacheBuster from './CacheBuster';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routing from './routes/Routing';

import Button, { ButtonKind } from '@common/Button';
import ErrorImage from '@images/error.png';
import config from '@root/config';
import { classNames } from '@utils/generalUtils';
import withUserInfo from '@hoc/withUserInfo';

// Allow admin to impersonate
if (Cookie.get('authHeaders')) {
  const authHeaders = JSON.parse(Cookie.get('authHeaders'));

  localStorage.clear();
  localStorage.setItem('access-token', authHeaders['access-token']);
  localStorage.setItem('client', authHeaders['client']);
  localStorage.setItem('expiry', authHeaders['expiry']);
  localStorage.setItem('resource-class', authHeaders['resource-class']);
  localStorage.setItem('token-type', authHeaders['token-type']);
  localStorage.setItem('uid', authHeaders['uid']);

  Cookie.erase('authHeaders', { domain: window.location.hostname });
}

reportWebVitals();

WebFont.load({
  google: {
    families: ['Inter:400,500,600,700'],
  },
});

// Register plural rules
pluralize.addUncountableRule('reach');

if (config.NODE_ENV === 'production') {
  const appConfig = {
    apiKey: config.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'personalyze-188017.firebaseapp.com',
    databaseURL: 'https://personalyze-188017.firebaseio.com',
    projectId: 'personalyze-188017',
    storageBucket: 'personalyze-188017.appspot.com',
    messagingSenderId: '546505636115',
  };

  initializeApp(appConfig);
}

ReactGA.initialize([{
  trackingId: config.REACT_APP_GOOGLE_ANALYTICS_ID,
  gaOptions: {

    //https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
  },
}]);

mixpanel.init(config.REACT_APP_MIXPANEL_TOKEN, { debug: true });

// verifyCredentials(store);

const target = document.getElementById('root');

createRoot(target).render(
  <ErrorBoundary
    fallback={({ error, componentStack, resetError }) => (
      <div className="max-w-4xl mx-auto mt-12">
        <div className="text-center">
          <h2 className="text-2xl font-medium text-gray-900">Uh oh, something went wrong.</h2>
          <p className="mt-1 text-gray-500">
            {error.toString()}
          </p>

          <img
            alt="Error"
            className="w-64 mx-auto"
            src={ErrorImage}
          />

          <div className="mt-4">
            <Button
              kind={ButtonKind.PRIMARY}
              onClick={resetError}
              title="Click here to reset!"
            />
          </div>
        </div>

        <Disclosure
          as="div"
          className="mt-12 pt-6 border-t border-gray-200"
        >
          {({ open }) => (
            <>
              <dt className="text-lg">
                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                  <span className="font-medium text-gray-900">View Stacktrace (Advanced)</span>
                  <span className="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      aria-hidden="true"
                      className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                    />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel
                as="dd"
                className="mt-4 border border-gray-200 py-6 px-4 overflow-y-auto h-64 bg-gray-50 rounded"
              >
                <div className="">
                  {componentStack}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    )}
  >
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) {
          return null;
        }
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        const Component = withUserInfo(() => (
          <ActionCableProvider url={config.REACT_APP_WS_URL}>
            <BrowserRouter>
              <Routing />
            </BrowserRouter>
          </ActionCableProvider>
        ));
        return <Component />;
      }}
    </CacheBuster>
  </ErrorBoundary>
);
