import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import DialogIcon from '@common/DialogIcon';
import DialogWithIcon from '@common/DialogWithIcon';
import { notifyError } from '@utils/toaster';

export default function DownloadDialog(props) {
  // fetch document
  useQuery(
    props.queryKey(props.params),
    () => props.query(props.params),
    {
      enabled: props.params.isOpen,
      onError: () => notifyError({ title: 'Document generation failed. Please try again.' }),
      onSuccess: response => {
        window.open(props.extractDocumentUrl(response));
        props.close();
      },
    }
  );

  // render modal
  return (
    <DialogWithIcon
      close={props.close}
      description={
        'The download will start automatically. ' +
        'If you do not see the document, please check your pop-up blocker settings.'
      }
      icon={
        <div className="mb-4">
          <DialogIcon
            context="primary"
            icon="DocumentIcon"
            outline
          />
        </div>
      }
      isOpen={props.params.isOpen}
      onSubmit={props.close}
      title="Your document is being generated."
    />
  );
}

DownloadDialog.propTypes = {
  close: PropTypes.func.isRequired,
  extractDocumentUrl: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  query: PropTypes.func.isRequired,
  queryKey: PropTypes.func.isRequired,
};
