import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { getOrganizations } from '@api/admin';
import { updateUser } from '@api/user';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { FormAsyncDropdown } from '@common/FormDropdowns';

export default function TransferDialog(props) {
  // selected organization
  const [organization, setOrganization] = useState({});
  useEffect(
    () => {
      if (props.params.isOpen) {
        setOrganization({});
      }
    },
    [props.params.isOpen]
  );

  // update organization
  const { error: errorUpdatingUser, mutate, isLoading: isUpdatingUser } = useMutation(
    () => updateUser(props.params.id, { organizationId: organization.value }),
    { onSuccess: () => props.onUpdate(organization.value) }
  );

  // render modal
  return (
    <Dialog
      close={props.close}
      isDisabled={isEmpty(organization)}
      isOpen={props.params.isOpen}
      isUpdating={isUpdatingUser}
      onSubmit={mutate}
      submitTitle="Yes, transfer"
      title="Transfer User to Another Organization"
    >
      {errorUpdatingUser && <ErrorMessage message={errorUpdatingUser.message} />}
      <div className="my-4">
        <FormAsyncDropdown
          label={`Select an organization to transfer ${props.params.name} to`}
          loadOptions={(query, callback) =>

            // we should not return anything here
            getOrganizations(0, query).then(response =>
              callback(response.payload.map(organization => ({ label: organization.name, value: organization.id })))
            ) && undefined
          }
          onSelection={setOrganization}
          placeholder="Type to search..."
          value={isEmpty(organization) ? null : organization}
        />
      </div>
    </Dialog>
  );
}

TransferDialog.propTypes = {
  close: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
