import { ExternalLinkIcon } from '@heroicons/react/outline';

import { CellWithAvatar } from '@common/table/TableCells';
import { platformAdSetUrls } from '@utils/campaignsUtils';
import { formatDate } from '@utils/formattingUtils';
import { getUserAvatar } from '@utils/avatar';

export default function getTargetedAdSetTableColumns() {
  /* eslint-disable react/prop-types */
  return [
    {
      accessor: 'updatedAt',
      alignment: 'left',
      Cell: ({ cell: { value } }) => {
        return (
          <div className="text-sm">
            {formatDate(value)}
          </div>
        );
      },
      cellWidth: '110px',
      Header: 'ADDED',
    },
    {
      accessor: 'user',
      alignment: 'center',
      disableSortBy: true,
      Cell: ({ cell: { value } }) => (
        <CellWithAvatar
          src={getUserAvatar(value)}
          toolTip={value.name}
        />
      ),
      Header: 'CREATOR',
    },
    {
      accessor: 'campaignName',
      alignment: 'left',
      Cell: ({ row: { original }, cell: { value } }) => {
        return (
          <div className="flex items-center">
            <div>
              <span className="text-sm text-gray-700 group-hover:text-gray-900">
                {value}
              </span>
              <div className="text-sm text-gray-500 group-hover:text-gray-700">
                {original.adAccountName}
              </div>
            </div>
          </div>
        );
      },
      Header: 'CAMPAIGN',
    },
    {
      accessor: 'adSetName',
      alignment: 'left',
      Cell: ({ row: { original }, cell: { value } }) => {
        if (original.adAccountId) {
          return (
            <a
              className="flex items-center text-indigo-500 hover:text-indigo-600"
              href={
                platformAdSetUrls(
                  original.platform,
                  original.organizationId,
                  original.adAccountId,
                  original.campaignId,
                  original.insertionOrderId,
                  original.adSetId
                )
              }
              rel="noreferrer"
              target="_blank"
            >
              <span className="flex-1 text-sm">
                {value}
              </span>

              <ExternalLinkIcon className="inline-block w-4 h-4" />
            </a>
          );
        }

        return <div className="text-sm">{value}</div>;
      },
      Header: 'AD SET',
    },
  ];
  /* eslint-enable react/prop-types */
}
