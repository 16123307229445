import { chunk } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import Badge from '@common/Badge';
import Card from '@common/Card';
import { ToolTipContext } from '@hoc/withToolTip';

function FavAccountsSection({ data }) {
  const ToolTip = useContext(ToolTipContext);

  return chunk(data, 3).map((row, rowIndex) =>
    row.map((rowData, index) => (
      <div key={`favorite-account-topic-${rowIndex * 3 + index}`}>
        <div className="py-4">
          <span className="text-base font-semibold text-gray-900">{rowData.topic}</span>
          <div className="mt-6 flow-root">
            <ul className="-my-4 divide-y divide-gray-200">
              {(rowData.data || []).map((topicData, index) => (
                <ToolTip
                  content={`Affinity: ${topicData.affinity.toFixed(2)}x`}
                  key={`favorite-account-follower-${index + rowIndex}`}
                >
                  <li className="flex items-center py-4 space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        alt=""
                        className="h-8 w-8 rounded-full"
                        src={topicData.avatar}
                      />
                    </div>
                    <div className="min-w-0 flex-1">
                      <span className="font-medium text-gray-900">
                        {topicData.name}{' '}
                        {topicData.notInGeneralAudience && (
                          <ToolTip
                            content={
                              <div className="max-w-sm">
                                Unique accounts are accounts that are followed by this particular audience but are not
                                followed by the average person in the country this insight is based upon.
                              </div>
                            }
                            isHtml
                          >
                            <span className="ml-2">
                              <Badge
                                context="highlight"
                                size="small"
                                text="Unique"
                              />
                            </span>
                          </ToolTip>
                        )}
                      </span>
                      <p className="text-gray-500">@{topicData.username}</p>
                    </div>
                  </li>
                </ToolTip>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ))
  );
}

export default function SummaryFavoriteAccounts(props) {
  return (
    <>
      <div className="mb-4">
        <Card
          subtitle="What accounts is this audience interested in?"
          title="Favourite People & Celebrities"
        >
          <div className="grid grid-cols-3 gap-6">
            <FavAccountsSection data={props.favoritePeople} />
          </div>
        </Card>
      </div>

      <Card
        subtitle="What accounts is this audience interested in?"
        title="Favourite Brands, Groups & Organizations"
      >
        <div className="grid grid-cols-3 gap-6">
          <FavAccountsSection data={props.favoriteBrands} />
        </div>
      </Card>
    </>
  );
}

SummaryFavoriteAccounts.propTypes = {
  favoriteBrands: PropTypes.arrayOf(PropTypes.object).isRequired,
  favoritePeople: PropTypes.arrayOf(PropTypes.object).isRequired,
};
