import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

import AudienceSection from '@components/audiences/AudienceSection';
import CreateAudience from '@components/audiences/builders/CreateAudience';
import AdAudienceDetailsView from '@components/audiences/details/AdAudienceDetailsView';
import UpdateAudience from '@components/audiences/details/UpdateAudience';
import withBreadcrumb from '@hoc/withBreadcrumb';

// create custom Sentry route component
const BreadcrumbRoute = withBreadcrumb(withSentryRouting(Route));

export default function AudienceRoutes(props) {
  return (
    <Switch>
      <BreadcrumbRoute
        exact
        path={props.match.path}
        render={() => <AudienceSection />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}new`}
        render={() => <CreateAudience />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}:adAudienceId/`}
        render={() => <AdAudienceDetailsView />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}:adAudienceId/edit`}
        render={() => <UpdateAudience />}
      />

      <Redirect
        from="/*"
        to="/"
      />
    </Switch>
  );
}

AudienceRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};
