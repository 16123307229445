import PropTypes from 'prop-types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { createAudienceRequest } from '@api/audiences';
import DialogIcon from '@common/DialogIcon';
import DialogWithIcon from '@common/DialogWithIcon';
import ErrorMessage from '@common/ErrorMessage';
import { FormDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import { WizardStep } from '@common/Wizard';
import { countryOptions } from '@utils/countryUtils';

export default function RequestAudienceForm(props) {
  // acquire the history handler
  const history = useHistory();

  // set up the form
  const { control, handleSubmit, register, watch } = useForm({
    mode: 'onChange',
  });
  const isValid = !!watch('request') && (watch('countries') || []).length !== 0;

  // submit the audience request
  const { error, isLoading, mutate } = useMutation(
    data => createAudienceRequest({
      platform: props.platform,
      description: data.request,
      countryCodes: data.countries.map(country => country.value),
    }),
    {
      onSuccess: () => setParamsOfSuccessModal(true),
    }
  );

  // modal state
  const [paramsOfSuccessModal, setParamsOfSuccessModal] = useState(false);

  // render the wizard step
  return (
    <WizardStep
      forwardButtonTitle="Submit Targeting Request"
      hasForm
      isForwardButtonDisabled={!isValid}
      isLoading={isLoading}
      onSubmit={handleSubmit(mutate)}
      title="Create the Target Audience"
      {...props}
    >
      {error && <ErrorMessage message={error.message} />}
      <h1 className="text-xl font-semibold">
        Tell us the interests of your target audience
      </h1>
      <div className="my-6">
        <div className="my-4">
          <FormField
            label="Who are you looking to target (age, gender, interests) and why?"
            name="request"
            placeholder='e.g. "we would like to target basketball fans aged 18-35 in the UK"'
            register={() => register('request', { required: 'Targeting description is required' })}
            type="textarea"
          />
        </div>
        <div className="my-6">
          <FormDropdown
            control={control}
            isMulti
            isSearchable
            label="Which countries will this audience be activated in?"
            name="countries"
            noOptionsMessage="No countries found."
            options={countryOptions}
            placeholder="Select a country"
          />
        </div>
      </div>

      <DialogWithIcon
        close={() => setParamsOfSuccessModal(false)}
        description="We've received your request and will get in touch soon."
        icon={
          <div className="mb-4">
            <DialogIcon
              context="success"
              icon="CheckIcon"
              outline
            />
          </div>
        }
        isOpen={paramsOfSuccessModal}
        onSubmit={() => history.push('/audiences/')}
        submitTitle="Go to Audience Library"
        title="Target audience request submitted!"
      />
    </WizardStep>
  );
}

RequestAudienceForm.propTypes = {
  platform: PropTypes.string,
};
