import { SyntheticEvent, useContext, useState } from 'react';

import { SocialAccount, TopSocialAccount } from '@api/insightReport';
import Button, { ButtonKind } from '@common/Button';
import { UserInfoContext } from '@hoc/withUserInfo';
import { AUDIENCE_PLACEHOLDER } from '@utils/commonUtils';

import ReplaceSocialAccountDialog, { ReplaceSocialAccountDialogProps } from './ReplaceSocialAccountDialog';
import Popover from '@common/Popover';

function renderAccountCard(
  isAdmin: boolean | undefined,
  socialAccount: SocialAccount,
  openReplaceDialog: () => void
): JSX.Element {
  return (
    <Popover
      content={
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center space-x-4 lg:space-x-6">
            <img
              alt={socialAccount.name}
              className="w-12 h-12 rounded"
              onError={(event: SyntheticEvent<HTMLImageElement>) => {
                if (event.currentTarget.src !== AUDIENCE_PLACEHOLDER) {
                  event.currentTarget.onerror = null;
                  event.currentTarget.src = AUDIENCE_PLACEHOLDER;
                }
              }}
              src={socialAccount.avatar.replace('_normal', '')}
            />
            <div className="font-medium">
              <span className="text-base">
                {socialAccount.name}
              </span>
              <span className="block text-sm text-gray-500">
                @{socialAccount.username}
              </span>
            </div>
            <hr />
            {isAdmin && (
              <Button
                kind={ButtonKind.REGULAR}
                onClick={openReplaceDialog}
                title="Replace"
              />
            )}
          </div>
        </div>
      }
    >
      <div className="inline-block items-center">
        <img
          alt={socialAccount.username}
          className="h-20 w-20 rounded mx-auto"
          onError={(event: SyntheticEvent<HTMLImageElement>) => {
            if (event.currentTarget.src !== AUDIENCE_PLACEHOLDER) {
              event.currentTarget.onerror = null;
              event.currentTarget.src = AUDIENCE_PLACEHOLDER;
            }
          }}
          src={socialAccount.avatar.replace('_normal', '')}
        />
        <p className="mt-2 text-center text-xs text-gray-500 truncate">
          {socialAccount.username}
        </p>
      </div>
    </Popover>
  );
}

export interface BrandWallProps {
  allSocialAccounts: Array<TopSocialAccount>;
  avatar: string;
  mainSocialAccounts: Array<TopSocialAccount>;
  preferredType?: string;
}

export default function BrandWall(props: BrandWallProps): JSX.Element {
  // preparations
  const { userInfo } = useContext(UserInfoContext);

  // state
  const [topSocialAccounts] = useState<Array<TopSocialAccount | SocialAccount>>([...props.mainSocialAccounts]);
  const [paramsOfReplaceDialog, setParamsOfReplaceDialog] = useState<ReplaceSocialAccountDialogProps['params']>(
    { isOpen: false }
  );

  // render wall
  let offset = 0;
  return (
    <>
      <div
        className="mx-auto p-6 w-5-6 shadow overflow-hidden rounded-md"
        style={{ backgroundColor: 'white', minWidth: '600px' }}
      >
        <div className="grid grid-cols-8 gap-6">
          {[16, 3, 0, 3, 3, 3, 16].map(count => {
            // render the main image
            if (count === 0) {
              return (
                <div
                  className="col-span-2 row-span-2 flex items-center -ml-10"
                  key="999"
                >
                  <img
                    alt="persona"
                    className="rounded-full shadow-md border-white border-4 mx-auto"
                    src={props.avatar}
                  />
                </div>
              );
            }

            // render account avatars
            offset += count;
            return topSocialAccounts.slice(offset - count, offset).map((account, index) => {
              const absoluteIndex = offset - count + index;
              return (
                <div key={absoluteIndex}>
                  {renderAccountCard(
                    userInfo?.admin,
                    account,
                    () => setParamsOfReplaceDialog({ isOpen: true, index: absoluteIndex })
                  )}
                </div>
              );
            });
          })}
        </div>
      </div>

      <ReplaceSocialAccountDialog
        close={() => setParamsOfReplaceDialog({ isOpen: false })}
        mainSocialAccounts={topSocialAccounts}
        onSelect={(socialAccount: SocialAccount) => {
          topSocialAccounts[paramsOfReplaceDialog.index!] = socialAccount;
          setParamsOfReplaceDialog({ isOpen: false });
        }}
        params={paramsOfReplaceDialog}
        preferredType={props.preferredType}
        topSocialAccounts={props.allSocialAccounts}
      />
    </>
  );
}
