import { format } from 'date-fns';
import { startCase } from 'lodash';
import pluralize from 'pluralize';

const SUFFIXES = 'KMB';

export function formatAgeRange(minAge, maxAge) {
  return !!minAge && (maxAge ? `${minAge} - ${maxAge}` : `${minAge}+`);
}

export function formatCount(value) {
  if (!value) {
    return 0;
  }
  for (const scale of [2, 1, 0]) {
    const limit = Math.pow(10, (scale + 1) * 3);
    if (value >= limit) {
      return `${(value / limit).toFixed(1)}${SUFFIXES[scale]}`;
    }
  }
  return value.toString();
}

export function formatDate(value) {
  return format(value ? new Date(value) : new Date(), 'EEE, MMM d');
}

export function formatFractional(value) {
  return value.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
}

export function formatMetricName(metric, plural = false) {
  if (plural) {
    return pluralize(startCase(metric.replace('omni_', '').toLowerCase()));
  }
  return startCase(metric.replace('omni_', '').toLowerCase());
}

export function formatMoneyAmount(value, currency) {
  // if no currency was specified
  if (!currency) {
    return parseFloat(value).toFixed(2) || 0;
  }

  // format value
  return new Intl.NumberFormat(
    undefined,
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: value >= 0.01 ? 2 : 4,
    }
  )
    .format(value);
}

export function formatMonthDayYear(value) {
  return format(new Date(value), 'MMM dd, yyyy');
}

export function formatMonthDay(value) {
  return format(new Date(value), 'MMM d');
}

export function formatPercentage(value, numberOfDigits = 2) {
  return (value / 100).toLocaleString(undefined, {
    minimumFractionDigits: numberOfDigits,
    maximumFractionDigits: numberOfDigits,
    style: 'percent',
  });
}

export function formatTimestamp(value) {
  return format(new Date(value), 'MMM d, yyyy @ h:mm aaa');
}
