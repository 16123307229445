import { capitalize, countBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import AdSetSelector from '../AdSetSelector';

import { updateAdSets } from '@api/audiences';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { UserInfoContext } from '@hoc/withUserInfo';

export default function BulkUpdateModal(props) {
  // extract arguments
  const { adAudiences = [], isOpen = false } = props.params;

  // acquire user data
  const { userInfo } = useContext(UserInfoContext);

  // update ad set
  const { error, isLoading: isUpdating, mutateAsync, reset } = useMutation(
    () => updateAdSets({
      adAccountId: targetAdSets[0].adAccountId,
      adAccountName: targetAdSets[0].adAccountName,
      campaignId: targetAdSets[0].campaignId,
      campaignName: targetAdSets[0].campaignName,
      identityId: targetAdSets[0].identity,
      mapping: targetAdSets.map((targetAdSet, index) => ({
        adAudienceId: adAudiences[index].id,
        adAudienceName: adAudiences[index].name,
        adSetId: targetAdSet.value,
        adSetName: targetAdSet.label,
      })),
      platform,
    }),
    { onSuccess: props.close }
  );

  // modal state
  const emptySelection = adAudiences.map(() => ({}));
  const [targetAdSets, setTargetAdSets] = useState(emptySelection);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);

  useEffect(
    () => {
      if (isOpen) {
        setTargetAdSets(emptySelection);
        reset();
      }
    },
    [isOpen]
  );

  // check that no ad set is selected for multiple audiences
  const counts = countBy(targetAdSets, targetAdSet => targetAdSet.value);
  const errors = targetAdSets
    ? targetAdSets.map(targetAdSet =>
      targetAdSet.value && counts[targetAdSet.value] > 1 ? 'This ad set is selected for multiple audiences' : null
    )
    : null;
  const isValid = errors.every(error => error === null) && targetAdSets.every(targetAdSet => targetAdSet.value);

  // render modal
  const platform = isEmpty(adAudiences) ? '' : adAudiences[0].platform;
  const hasRelevantIdentity = userInfo.identities.some(identity => identity.provider === platform);
  return (
    <Dialog
      close={props.close}
      gaName="bulkAdSetUpdate"
      hasNoButtons={!hasRelevantIdentity}
      isDisabled={!isValid}
      isOpen={isOpen}
      isUpdating={isUpdating}
      onClose={wasUpdated => {
        if (wasUpdated) {
          props.onUpdate();
        }
      }}
      onSubmit={data => mutateAsync(data).then(() => true).catch(() => false)}
      size="2xl"
      submitTitle="Update Ad Sets"
      title="Bulk Ad Set Updater"
      updatingTitle="Updating Ad Sets"
    >
      {error && <ErrorMessage message={error.message} />}
      {!hasRelevantIdentity && (
        <div className="my-6 text-xl">
          In order to bulk update your {capitalize(platform)} campaigns,
          we&apos;ll need access to your {capitalize(platform)} ads account.
        </div>
      )
      }
      <AdSetSelector
        adAudiences={adAudiences}
        clearTarget={() => setTargetAdSets(emptySelection)}
        errors={errors}
        isTokenInvalid={isTokenInvalid}
        platform={platform}
        setIsTokenInvalid={setIsTokenInvalid}
        setTarget={setTargetAdSets}
        shouldHideAdSetSelectionLabel
        targetAdSets={targetAdSets}
      />
    </Dialog>
  );
}

BulkUpdateModal.propTypes = {
  close: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
