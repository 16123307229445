import { ReactNode } from 'react';

export interface CardProps {

  /** Actions. */
  actions: ReactNode;

  /** Card content. */
  children: ReactNode;

  /** Card subtitle. When used, <code>title</code> should be specified, too. */
  subtitle?: string;

  /** Card title. */
  title?: string;
}

export default function Card(props: CardProps): JSX.Element {
  return (
    <div className="bg-white rounded-lg shadow">
      {props.title && (
        <div className="-ml-4 -mt-2 px-4 py-5 flex items-center justify-between flex-wrap">
          <div className="ml-4 mt-2">
            <span className="text-lg leading-6 font-medium text-gray-900">
              {props.title}
            </span>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {props.subtitle}
            </p>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            {props.actions}
          </div>
        </div>
      )}
      <div className={`${props.title !== undefined || props.subtitle !== undefined ? 'border-t border-gray-200' : ''}`}>
        <div className="py-6 px-5 sm:p-6">
          {props.children}
        </div>
      </div>
    </div>
  );
}
