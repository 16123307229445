import { OfficeBuildingIcon, PencilIcon, PlusIcon, TrashIcon, UserIcon } from '@heroicons/react/outline';
import { differenceBy, find, isEmpty, isNil, sortBy, uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';

import { Change, Changes, Entity, getEntity, getEntityHistory, InstanceGroup, updateEntity } from '@api/entity';
import { TopSocialAccount } from '@api/insightReport';
import Badge, { BadgeContext, BadgeSize } from '@common/Badge';
import { CommonDialogProps } from '@common/commonTypes';
import Dialog, { DialogSize } from '@common/Dialog';
import { FormDropdown } from '@common/FormDropdowns';
import ErrorMessage from '@common/ErrorMessage';
import FormField from '@common/FormField';
import OptionGroup from '@common/OptionGroup';
import SelectTopicOption from '@common/reactSelect/SelectTopicOption';
import Tabs from '@common/Tabs';
import { findInstanceGroupId, Option } from '@utils/commonUtils';
import { formatTimestamp } from '@utils/formattingUtils';
import { notifySuccess } from '@utils/notification';
import topics from '@utils/topics';

type TopicOption = Option<number>;
type FilterOption = {
  data: {
    path: string
  };
}

const TABS = [
  { name: 'Target Interests' },
  { name: 'Entity Links' },
  { name: 'Edit History' },
];
const MAX_RECENT_TOPIC_COUNT = 5;
const TOPICS: Array<TopicOption> = sortBy(
  topics.map(topic => ({ label: topic.name, path: topic.path, value: topic.entityId })),
  ['path', 'label']
);
const TOPIC_IDS: Record<string, number> = Object.fromEntries(topics.map(topic => [topic.name, topic.entityId]));
const URL_FIELDS: Array<{ label: string, name: string, path?: RegExp, placeholder: string }> = [
  {
    label: 'Website URL',
    name: 'website',
    placeholder: 'www.google.com',
  },
  {
    label: 'Facebook URL',
    name: 'facebook',
    path: new RegExp('^https://www\\.facebook\\.com/.+$'),
    placeholder: 'www.facebook.com/example',
  },
  {
    label: 'Instagram URL',
    name: 'instagram',
    path: new RegExp('^https://www\\.instagram\\.com/.+$'),
    placeholder: 'www.instagram.com/example',
  },
  {
    label: 'LinkedIn URL',
    name: 'linkedin',
    path: new RegExp('^https://www\\.linkedin\\.com/.+$'),
    placeholder: 'www.linkedin.com/example',
  },
  {
    label: 'Reddit URL',
    name: 'reddit',
    path: new RegExp('^https://www\\.reddit\\.com/r/.+$'),
    placeholder: 'www.reddit.com/r/example',
  },
  {
    label: 'Twitch URL',
    name: 'twitch',
    path: new RegExp('^https://www\\.twitch\\.tv/.+$'),
    placeholder: 'www.twitch.tv/example',
  },
  {
    label: 'Wikipedia URL',
    name: 'wikipedia',
    path: new RegExp('^https://en\\.wikipedia\\.org/wiki/.+$'),
    placeholder: 'en.wikipedia.com/wiki/',
  },
  {
    label: 'YouTube URL',
    name: 'youtube',
    path: new RegExp('^https://www\\.youtube\\.com/c/.+$'),
    placeholder: 'www.youtube.com/c/example',
  },
];

export interface EditTopAccountDialogProps extends CommonDialogProps<EditTopAccountDialogParams> {
  onUpdate: ({ categoryName, entityId, topics }: {
    categoryName?: string;
    entityId?: number;
    topics?: Array<string>;
  }) => void;
}

export interface EditTopAccountDialogParams {
  socialAccount?: TopSocialAccount;
}

export default function EditTopAccountDialog(props: EditTopAccountDialogProps): JSX.Element {
  function getTopicInstanceGroups(): Array<InstanceGroup> {
    const existingTopics = new Set(props.params.socialAccount!.topics);
    const inserts = selectedTopics.flatMap(option => {
      const found = existingTopics.delete(option.label);
      return found ? [] : [{ attributes: [{ confidence: 1, name: 'topic', value: option.value }] }];
    });
    const removals = [...existingTopics].map(topic =>
      ({ attributes: [], id: findInstanceGroupId(entity!, 'topic', TOPIC_IDS[topic]) })
    );
    return [...inserts, ...removals];
  }

  function getUrlInstanceGroups(): Array<InstanceGroup> {
    return URL_FIELDS.flatMap(urlField => {
      const url = (getValues(urlField.name) ?? '') as string;
      if (url === '' && !(urlField.name in instanceGroupMappings.current)) {
        return [];
      }
      const attributes = url !== '' ? [{ confidence: 1, name: 'url', value: getValues(urlField.name) as string }] : [];
      return [{ attributes, id: instanceGroupMappings.current[urlField.name] }];
    });
  }

  function renderEditHistoryItem(change: Change): JSX.Element {
    const { timestamp, updatedBy, action, attributeName, attributeValue } = change;
    return (
      <div className="flex-shrink-0 group block mb-2">
        <div className="flex items-center">
          <div className="bg-gray-100 p-1 rounded-full">
            {
              action === 'Created' ? (
                <PlusIcon
                  className="text-green-500 h-4 w-4"
                />
              ) : action === 'Updated' ? (
                <PencilIcon
                  className="text-gray-500 h-4 w-4"
                />
              ) : (

                // Deleted
                <TrashIcon
                  className="text-red-500 h-4 w-4"
                />
              )
            }
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
              <Badge
                context={BadgeContext.HIGHLIGHT}
                size={BadgeSize.SMALL}
                text={attributeName}
              />
              {
                attributeName === 'handle_image' ? (
                  <img
                    alt="profile"
                    className="ml-2 inline rounded-full h-6 w-6"
                    src={attributeValue}
                  />
                ) : attributeName === 'url' ? (
                  <a
                    className="ml-2"
                    href={attributeValue}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {attributeValue}
                  </a>
                ) : (
                  <span className="ml-2">
                    {attributeValue}
                  </span>
                )
              }

            </p>
            <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
              {updatedBy} on {formatTimestamp(timestamp)}
            </p>
          </div>
        </div>
      </div>
    );
  }

  // general state
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // state for the first tab
  const [recentTopics, setRecentTopics] = useState<Array<TopicOption>>([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>('account');
  const [selectedTopics, setSelectedTopics] = useState<Array<TopicOption>>([]);

  // form for the second tab
  const { getValues, register, setValue } = useForm({ mode: 'onChange' });
  const instanceGroupMappings = useRef<Record<string, number>>({});

  // query the entity
  const { data: entity } = useQuery<Entity>(
    ['entity', props.params.socialAccount?.entityId],
    () => getEntity(props.params.socialAccount!.entityId),
    {
      enabled: !isNil(props.params.socialAccount),
      onSuccess: entity => {
        // collect URLs
        const urlInstanceGroups = entity.instanceGroups
          .filter(instanceGroup => instanceGroup.attributes[0].name === 'url');

        // set up URL fields and instance group mappings
        instanceGroupMappings.current = {};
        for (const urlInstanceGroup of urlInstanceGroups) {
          const url = urlInstanceGroup.attributes[0].value as string;
          const matcher = URL_FIELDS.find(urlField => !isNil(urlField.path) && urlField.path.test(url));
          const target = !isNil(matcher) ? matcher.name : URL_FIELDS[0].name;
          if (!(target in instanceGroupMappings)) {
            setValue(target, url);
            instanceGroupMappings.current[target] = urlInstanceGroup.id!;
          }
        }
      },
    }
  );

  // query the history of the entity
  const { data: entityHistory } = useQuery<Array<Changes>>(
    ['entityHistory', props.params.socialAccount?.entityId],
    () => getEntityHistory(props.params.socialAccount!.entityId),
    {
      enabled: !isNil(props.params.socialAccount),
      initialData: [],
    }
  );

  // update entity
  const { error: updateEntityError, mutate: _updateEntity } = useMutation(
    () => updateEntity({
      id: props.params.socialAccount!.entityId,
      category: selectedCategoryName,
      instanceGroups: [...getTopicInstanceGroups(), ...getUrlInstanceGroups()],
    }),
    {
      onSuccess: () => {
        notifySuccess('Entity was updated.');
        props.onUpdate({
          categoryName: selectedCategoryName,
          entityId: props.params.socialAccount!.entityId,
          topics: selectedTopics.map(topic => topic.label),
        });
        props.close();
      },
    }
  );

  // reset state when dialog is opened
  useEffect(
    () => {
      if (props.params.isOpen) {
        // set category name
        setSelectedCategoryName(props.params.socialAccount!.categoryName);

        // set topics
        const topicOptions = props.params.socialAccount!.topics.map(topic =>
          ({ label: topic, value: TOPIC_IDS[topic] })
        );
        setSelectedTopics(topicOptions);
      }
    },
    [props.params.isOpen]
  );

  // render dialog
  const name = props.params.socialAccount?.name;
  return (
    <Dialog
      close={props.close}
      isOpen={props.params.isOpen}
      onSubmit={_updateEntity}
      size={DialogSize.EXXTRA_LARGE}
      submitTitle="Update"
      title={!isNil(name) ? `Edit ${name}` : undefined}
    >
      <Tabs
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        tabs={TABS}
      />
      {activeTabIndex === 0 && (
        <div className="grid grid-cols-1 gap-y-8">
          {!isNil(updateEntityError) && <ErrorMessage message={JSON.stringify(updateEntityError)} />}
          <div className="flex items-center space-x-2">
            <div>
              <p className="font-medium">Account type:</p>
            </div>
            <div>
              <OptionGroup
                options={[
                  {
                    isActive: selectedCategoryName === 'account',
                    title: '?',
                  },
                  {
                    isActive: selectedCategoryName === 'person',
                    onClick: () => setSelectedCategoryName('person'),
                    title: <UserIcon className="w-4 h-4" />,
                  },
                  {
                    isActive: selectedCategoryName === 'organization',
                    onClick: () => setSelectedCategoryName('organization'),
                    title: <OfficeBuildingIcon className="w-4 h-4" />,
                  },
                ]}
              />
            </div>
          </div>
          <div>
            <p className="font-medium">Topics to assign to {name}</p>
            <FormDropdown
              filterOption={(option: FilterOption, input: string) => {
                const path = option.data.path.toLowerCase();
                return path.includes(input.toLowerCase());
              }}
              isMulti
              onSelection={(options: Array<TopicOption>) => {
                const newTopics = sortBy(differenceBy(options, selectedTopics, 'value'), 'label');
                for (const topic of recentTopics) {
                  if (newTopics.length < MAX_RECENT_TOPIC_COUNT && isNil(find(newTopics, ['value', topic.value]))) {
                    newTopics.push(topic);
                  }
                }
                setRecentTopics(sortBy(newTopics, 'label'));
                setSelectedTopics(options);
              }}
              option={SelectTopicOption}
              options={TOPICS}
              placeholder="Select a topic..."
              value={selectedTopics}
            />
            <p className="text-red-600 text-xs">These topics will override the existing topics.</p>
          </div>
          {!isEmpty(recentTopics) &&
            (
              <div>
                <p className="font-medium mb-2">Recently selected topics</p>
                {recentTopics.map(topic => (
                  <Badge
                    key={topic.value}
                    onClick={() => setSelectedTopics(uniqBy([...selectedTopics, topic], 'value'))}
                    size={BadgeSize.SMALL}
                    text={topic.label}
                  />
                ))}
              </div>
            )
          }
        </div>
      )}
      <div className="grid grid-cols-2 gap-4">
        {activeTabIndex === 1 && URL_FIELDS.map(urlField => (
          <div key={urlField.name}>
            <FormField
              label={urlField.label}
              placeholder={urlField.placeholder}
              register={() => register(urlField.name)}
              type="text"
            />
          </div>
        ))}
      </div>
      {activeTabIndex === 2 && entityHistory!.map(entry => (
        <div
          className="flex flex-col"
          key={entry.date}
        >
          <h1 className="text-lg font-bold">{entry.date}</h1>
          {entry.changes.map((change, index) => (
            <div key={index}>
              {renderEditHistoryItem(change)}
            </div>
          ))}
          <hr />
        </div>
      ))}
    </Dialog>
  );
}
