import { commonFetch, getPagedResults } from '@utils/fetchUtils';

export const getAdAudiences = getPagedResults('/v3/target_audiences');

export function createAdAudience(platform, adAudience) {
  return commonFetch('/v3/target_audiences', {
    body: { platform, ...adAudience },
    method: 'POST',
  });
}

export function createAudienceRequest(audienceRequest) {
  return commonFetch('/v3/audience_requests', {
    body: audienceRequest,
    hasEmptyResponse: true,
    method: 'POST',
  });
}

export function createKeywordAudience(keywordAudience) {
  return commonFetch('/v3/platforms/facebook/audience_generator/target_audiences', {
    body: keywordAudience,
    hasEmptyResponse: true,
    method: 'POST',
  });
}

export function copyTargetAudience(targetAudienceId, userId) {
  return commonFetch(`/v3/target_audiences/${targetAudienceId}/copy?user_id=${userId}`, {
    body: { userId },
    method: 'POST',
  });
}

export function deleteTargetAudience(id) {
  return commonFetch(`/v3/target_audiences/${id}`, {
    method: 'DELETE',
  });
}

export function generateAudienceSuggestions(data) {
  return commonFetch('/v3/platforms/facebook/audience_generator/target_audiences/preview', {
    body: data,
    method: 'POST',
  });
}

export function getTargetedAdSets(adAudienceId) {
  return commonFetch(`/v3/target_audiences/${adAudienceId}/ad_sets`);
}

export function getInterestSuggestions(adAudienceId) {
  return commonFetch(`/v3/target_audiences/${adAudienceId}/targeting_suggestions`);
}

export function updateAdAudience(adAudienceId, adAudience) {
  return commonFetch(`/v3/target_audiences/${adAudienceId}`, {
    body: adAudience,
    method: 'PUT',
  });
}

export function getRecentLabels() {
  return commonFetch('/v3/recent_labels');
}

export function updateAdSet({
  adAccountId,
  adAccountName,
  adAudienceId,
  adSetId,
  adSetName,
  campaignId,
  campaignName,
  identityId,
  organizationId,
  organizationName,
  insertionOrderId,
  insertionOrderName,
  platform,
  userId,
}) {
  return commonFetch(`/v3/platforms/${platform}/ad_sets/${adSetId}`, {
    body: {
      adAccountId: adAccountId.toString(),
      adAccountName,
      adSetName,
      campaignId: campaignId.toString(),
      campaignName,
      identityId: identityId.toString(),
      insertionOrderId,
      insertionOrderName,
      organizationId,
      organizationName,
      targetAudienceId: adAudienceId.toString(),
      userId,
    },
    hasEmptyResponse: true,
    method: 'PUT',
  });
}

export function updateAdSets({
  adAccountId,
  adAccountName,
  campaignId,
  campaignName,
  identityId,
  platform,
  mapping,
}) {
  return commonFetch(`/v3/platforms/${platform}/ad_sets/bulk_update`, {
    body: {
      adAccountId,
      adAccountName,
      campaignId,
      campaignName,
      identityId: identityId.toString(),
      mapping,
    },
    hasEmptyResponse: true,
    method: 'PUT',
  });
}
