import PropTypes from 'prop-types';

import Tabs from './Tabs';

export default function Section(props) {
  const SectionIcon = props.icon;
  return (
    <>
      <div className="mb-4">
        <div className="flex sm:flex items-center justify-between">
          <div className="min-w-0">

            <h1 className="text-2xl font-semibold leading-6 text-gray-900 flex items-center">
              <span>{props.icon && <SectionIcon className="w-10 h-10 mr-2" />}</span> {props.title}
            </h1>
          </div>
          <div className="sm:mt-0 sm:ml-4">{props.heading}</div>
        </div>
      </div>
      {props.tabs && (
        <Tabs
          activeTabIndex={props.activeTabIndex}
          tabs={props.tabs}
        />
      )}
      {props.subHeading}
      {props.children}
    </>
  );
}

Section.propTypes = {
  activeTabIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
  'data-tut': PropTypes.string,
  heading: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  subHeading: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.node.isRequired,
};
