import { isNil, startCase } from 'lodash';

import { Entity } from '@api/entity';

export const AUDIENCE_PLACEHOLDER = 'https://storage.googleapis.com/topicdna-public/audience_placeholder.png';

export enum Platform {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter'
}

export interface Option<T = string> {
  label: string;
  value: T;
}

export interface UserAccount {
  admin: boolean;
  avatar?: { default?: string; thumb?: string };
  email: string;
  firstName?: string;
  id: number;
  lastName?: string;
  name?: string;
  organization: Organization;
}

export interface Organization {
  id: number;
  name: string;
}

export interface InsightReport {
  avatar?: { default?: string; thumb?: string };
  id: number;
  inputs: Array<InsightReportInput>;
  name: string;
  reportType: string;
}

export interface InsightReportInput {
  gender?: 'female' | 'male';
  minimalCount: number;
}

export interface TargetAudience {
  avatar?: { default?: string; thumb?: string };
  id: number;
  name: string;
  parentId: number;
  unifiedTargeting: Record<string, string>;
}

export function getUserName(userAccount: UserAccount): string {
  return userAccount.name ?? userAccount.email;
}

export function getBreadcrumb(pathname: string): string {
  return pathname
    .split('/')
    .splice(1)
    .filter(path => !Number.isInteger(Number(path)))
    .map(path => startCase(path.replace(/[0-9]/g, '')))
    .join(' > ');
}

export function findInstanceGroupId(
  entity: Entity,
  attributeName: string,
  attributeValue?: string | number
): number | undefined {
  const instanceGroup = entity.instanceGroups.find(instanceGroup =>
    instanceGroup.attributes.find(attribute =>
      attribute.name === attributeName && (isNil(attributeValue) || attribute.value === attributeValue)
    )
  );
  return instanceGroup?.id;
}

export function convertBlobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}
