import { size, sortBy, trimStart } from 'lodash';
import { useQuery } from 'react-query';

import { searchDetailedInterests } from '@api/targeting';
import { searchActionCategories, searchHashtags, searchInterests, searchLocations } from '@api/targeting_old';
import { sortHits } from '@utils/generalUtils';

const AGES = [13, 18, 25, 35, 45, 55];

export function useTikTokBase(values) {
  function collectChildren(children) {
    const mapped = children.map(topicId => {
      const topic = topics[topicId];
      return {
        children: collectChildren(topic.children),
        label: topic.name,
        value: topic.id,
      };
    });
    return sortBy(mapped, 'label');
  }

  // query countries
  const { data: countries, isFetching: isLoadingCountries } = useQuery(
    ['getTikTokCountries'],
    () => searchLocations('tiktok', ''),
    {
      initialData: [],
      select: countries => sortBy(
        countries.map(country => ({ label: country.name, value: country.id })),
        'label'
      ),
    }
  );

  // query topics
  const { data: topics, isFetching: isLoadingTopics } = useQuery(
    ['getTikTokInterests'],
    () => searchInterests('tiktok', ''),
    {
      initialData: [],
      select: topics => topics.reduce(
        (result, topic) => {
          result[topic.id] = topic;
          return result;
        },
        {}
      ),
    }
  );

  // prepare interest options
  const topicOptions = collectChildren(Object.values(topics).filter(topic => topic.level === 1).map(topic => topic.id));

  // query creator categories
  const { data: creatorCategories, isFetching: isLoadingCreatorCategories } = useQuery(
    ['getTikTokCreatorCategories'],
    () => searchActionCategories('tiktok', 'creator'),
    {
      initialData: [],
    }
  );

  // prepare creator category options
  const creatorCategoryOptions = [];
  for (const interest of sortBy(creatorCategories, 'path')) {
    let current = creatorCategoryOptions;
    const levels = trimStart(interest.path, '/').split('/');
    for (const [index, item] of levels.entries()) {
      let target = current.find(target => target.label === item);
      const isLast = index === size(levels) - 1;
      if (!target) {
        target = {
          children: [],
          isDisabled: !isLast,
          label: item,
          value: isLast ? interest.id : levels.slice(0, index + 1).join('|'),
        };
        current.push(target);
      }
      current = target.children;
    }
  }

  // query video categories
  const { data: videoCategories, isFetching: isLoadingVideoCategories } = useQuery(
    ['getTikTokVideoCategories'],
    () => searchActionCategories('tiktok', 'video'),
    {
      initialData: [],
    }
  );

  // prepare video category options
  const videoCategoryOptions = [];
  for (const interest of sortBy(videoCategories, 'path')) {
    let current = videoCategoryOptions;
    const levels = trimStart(interest.path, '/').split('/');
    for (const [index, item] of levels.entries()) {
      let target = current.find(target => target.label === item);
      const isLast = index === size(levels) - 1;
      if (!target) {
        target = {
          children: [],
          isDisabled: !isLast,
          label: item,
          value: isLast ? interest.id : levels.slice(0, index + 1).join('|'),
        };
        current.push(target);
      }
      current = target.children;
    }
  }

  // prepare country options
  return {
    countries, isLoadingCountries,
    topicOptions, isLoadingTopics,
    creatorCategoryOptions, isLoadingCreatorCategories,
    videoCategoryOptions, isLoadingVideoCategories,
  };
}

export function getTikTokFields(values, setValue, base) {
  // adjust minimal or maximal age if necessary
  if (values.minAge && values.maxAge && values.minAge.value > values.maxAge.value) {
    const index = AGES.findIndex(age => age === values.minAge.value);
    const nearest = index === size(AGES) - 1 ? null : AGES[index + 1] - 1;
    setValue('maxAge', nearest ? { label: nearest.toString(), value: nearest } : null);
  }
  if (!values.minAge && values.maxAge) {
    const nearest = AGES[AGES.findIndex(age => age === values.maxAge.value - 1) - 1];
    setValue('minAge', { label: nearest.toString(), value: nearest });
  }

  // destructure base
  const {
    countries, isLoadingCountries,
    topicOptions, isLoadingTopics,
    creatorCategoryOptions, isLoadingCreatorCategories,
    videoCategoryOptions, isLoadingVideoCategories,
  } = base;

  // return fields
  const minAgeOptions = AGES.map(age => ({ label: age.toString(), value: age }));
  const maxAgeOptions = AGES.filter(age => !values.minAge || age > values.minAge.value)
    .map(age => ({ label: (age - 1).toString(), value: age - 1 }));
  return [
    {
      label: 'Gender',
      name: 'gender',
      options: [
        { label: 'All Genders', value: '' },
        { label: 'Female', value: 'female' },
        { label: 'Male', value: 'male' },
      ],
      placeholder: 'Select a gender',
      type: 'choice',
      width: 4,
    },
    {
      label: 'Minimum Age',
      name: 'minAge',
      options: minAgeOptions,
      type: 'choice',
      width: 4,
    },
    {
      isClearable: true,
      label: 'Maximum Age',
      name: 'maxAge',
      options: maxAgeOptions,
      type: 'choice',
      width: 4,
    },
    {
      empty: 'No country found',
      isDisabled: isLoadingCountries,
      isMulti: true,
      label: 'Countries',
      name: 'countries',
      options: countries,
      placeholder: 'Select a location',
      required: 'Choose at least one country',
      type: 'choice',
    },
    {
      empty: 'No topic found',
      isDisabled: isLoadingTopics,
      isMulti: true,
      label: 'Topic',
      name: 'topics',
      options: topicOptions,
      type: 'choice',
    },
    {
      empty: 'No interest found',
      isMulti: true,
      label: 'Interest',
      name: 'interest',
      options: query => searchDetailedInterests('tiktok', query).then(interests => {
        const mapped = interests.map(interest => ({ label: interest.name, value: interest.id }));
        return sortHits(mapped, 'label', query);
      }),
      type: 'lookup',
    },
    {
      empty: 'No creator categories found',
      isCollapsed: true,
      isDisabled: isLoadingCreatorCategories,
      isMulti: true,
      isSearchable: true,
      label: 'Users Who Follow Creators Posting These Categories',
      name: 'creatorCategories',
      options: creatorCategoryOptions,
      type: 'choice',
    },
    {
      empty: 'No video categories found',
      isCollapsed: true,
      isDisabled: isLoadingVideoCategories,
      isMulti: true,
      isSearchable: true,
      label: 'Users Who Watched Videos Featuring These Categories',
      name: 'videoCategories',
      options: videoCategoryOptions,
      type: 'choice',
    },
    {
      empty: 'No hashtags found',
      isMulti: true,
      label: 'Users Who\'ve Watched Videos With These Hashtags',
      name: 'hashtags',
      options: query => searchHashtags('tiktok', query).then(interests => {
        const mapped = interests.map(interest => ({ label: interest.name, value: interest.id }));
        return sortHits(mapped, 'label', query);
      }),
      type: 'lookup',
    },
  ];
}
