import IndeterminateCheckbox from '@common/table/IndeterminateCheckbox';

export default function getCampaignTableColumns() {
  /* eslint-disable react/prop-types */
  return [
    {
      Cell: ({ row }) => (
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      ),
      hasSmallPadding: true,
      id: 'selection',
    },
    {
      accessor: 'name',
      alignment: 'left',
      Header: 'CAMPAIGN NAME',
    },
  ];
  /* eslint-enable react/prop-types */
}
