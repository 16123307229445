import React, { ComponentType, ReactNode, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';

class Refresher {
  timer: ReturnType<typeof setTimeout> | null;

  constructor() {
    this.timer = null;
  }

  clear() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
  }

  schedule() {
    this.clear();
    this.timer = setTimeout(() => ReactTooltip.rebuild(), 250);
  }
}

export type ToolTipType = (props: ToolTipProps) => JSX.Element;

export const ToolTipContext = React.createContext<ToolTipType>(() => <div />);

export interface ToolTipProps {
  children: string | JSX.Element,
  content: string | JSX.Element,
  isButton?: boolean,
  isDisabled?: boolean,
}

export default function withToolTip<T>(WrappedComponent: ComponentType): (props: T) => ReactNode {
  return (originalProps: T) => {
    // define tooltip component
    function ToolTip(props: ToolTipProps): JSX.Element {
      // schedule a refresh if necessary
      useEffect(() => {
        if (!props.isDisabled) {
          refresher.schedule();
        }
      });

      // if tooltip is disabled, do nothing
      if (props.isDisabled) {
        return (
          <>
            {props.children}
          </>
        );
      }

      // add tooltip-related attributes to the child element
      const isHtml = typeof props.content !== 'string';
      const text = isHtml ? ReactDOMServer.renderToStaticMarkup(props.content as JSX.Element) : props.content;
      const attributes = {
        'data-tip': text,
        'data-html': isHtml,
        'data-event': props.isButton ? 'click' : null,
        'data-place': props.isButton ? 'bottom' : null,
      };
      return typeof props.children === 'string' ? (
        <span {...attributes}>
          {props.children}
        </span>
      ) : React.cloneElement(props.children, attributes);
    }

    // create refresher
    const refresher = new Refresher();
    useEffect(() => refresher.clear.bind(refresher));

    // render component
    return (
      <ToolTipContext.Provider value={ToolTip}>
        <WrappedComponent {...originalProps} />
      </ToolTipContext.Provider>
    );
  };
}
