import { importQueue } from '@api/queue';
import { CommonDialogParams, CommonDialogProps } from '@common/commonTypes';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import pluralize from 'pluralize';
import { ErrorResponse } from '@utils/httpUtils';
import { notifySuccess } from '@utils/notification';
import { useMutation } from 'react-query';

export default function ImportDialog(props: ImportDialogProps): JSX.Element {
  // import queue
  const {
    error: errorImportingQueue,
    isLoading: isImportingQueue,
    mutate: doImportQueue,
  } = useMutation<void, ErrorResponse>(
    data => importQueue(props.params.queueId!),
    {
      onSuccess: () => {
        notifySuccess('Import started!');
        props.close();
      },
    }
  );

  // render dialog
  return (
    <Dialog
      close={props.close}
      isOpen={props.params.isOpen}
      isUpdating={isImportingQueue}
      onSubmit={doImportQueue}
      submitTitle="Import"
      title="Import Queue"
    >
      {errorImportingQueue && <ErrorMessage message={errorImportingQueue.message} />}
      <div className="mt-4 space-y-4">
        <p>Are you sure you want to import the queue? This will </p>
        <p>There are {pluralize('unprocessed item', props.params.unprocessedItems, true)} in it.</p>
      </div>
    </Dialog>
  );
}

export type ImportDialogProps = CommonDialogProps<ImportDialogParams>;

export interface ImportDialogParams extends CommonDialogParams {
  queueId?: number;
  queueName?: string;
  unprocessedItems?: number;
}
