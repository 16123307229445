import { CheckCircleIcon, RefreshIcon } from '@heroicons/react/outline';
import { isEmpty, size, xor } from 'lodash';
import mixpanel from 'mixpanel-browser';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Fragment, useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Puff } from 'svg-loaders-react';

import { createKeywordAudience, generateAudienceSuggestions } from '@api/audiences';
import Button from '@common/Button';
import ErrorMessage from '@common/ErrorMessage';
import AudienceGenerationListener from '@common/WebSocket/AudienceGenerationListener';
import { WizardStep } from '@common/Wizard';
import { UserInfoContext } from '@hoc/withUserInfo';
import COLORS from '@utils/colorsUtils';
import { notifyError, notifySuccess } from '@utils/toaster';

const SUGGESTION_CLASSES = [
  'bg-white',
  'border',
  'border-gray-300',
  'focus-within:ring-2',
  'focus-within:ring-indigo-500',
  'focus-within:ring-offset-2',
  'hover:border-gray-400',
  'px-6',
  'py-5',
  'relative',
  'rounded-lg',
  'shadow-sm',
];
const SELECTED_SUGGESTION_CLASSES = ['ring-2', 'ring-indigo-700', 'ring-offset-2'];

function renderAudience(audience, audienceSize, index, isSelected, toggleSelection) {
  return (
    <div
      className={[...SUGGESTION_CLASSES, ...[isSelected ? SELECTED_SUGGESTION_CLASSES : []]].join(' ')}
      key={index}
      onClick={toggleSelection}
    >
      <div className="mb-4">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h2 className="text-sm text-gray-500 uppercase tracking-wider font-medium">
              Audience {index + 1}
            </h2>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <span className={`${isSelected ? 'text-green-600' : 'text-gray-300'}`}>
              {isSelected ? 'Selected ' : ''}
              <CheckCircleIcon className="inline h-8 w-8" />
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-3">
        <div className="flex-1 min-w-0">
          <div
            className="focus:outline-none"
            href="#"
          >
            <span
              aria-hidden="true"
              className="absolute inset-0"
            />
            <span className="text-lg font-medium text-gray-900">
              {audienceSize.toLocaleString()} people
            </span>
            <p className="mt-4 text-base text-gray-500">People interested in</p>
            {audience.map((interests, layerIndex) => {
              return (
                <Fragment key={layerIndex}>
                  <div className="p-2 my-2 bg-indigo-50 rounded-lg break-words">
                    {interests.map((interest, interestIndex) => (
                      <>
                        <span className="font-medium text-indigo-900">{interest.name}</span>
                        {interestIndex < interests.length - 1 && (
                          <span className="mx-2 text-xs text-gray-600">OR</span>
                        )}
                      </>
                    ))}
                  </div>
                  {layerIndex < audience.length - 1 && (
                    <span className="text-xs text-gray-600">AND must also be interested in</span>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function AudienceSuggestions(props) {
  // acquire history handler and user data
  const history = useHistory();
  const { userInfo } = useContext(UserInfoContext);

  // retry generation of suggestions
  const {
    error: errorRequestingSuggestions,
    mutate: requestSuggestions,
  } = useMutation(data => generateAudienceSuggestions(props.audienceParams));

  // create audience
  const { error: errorCreatingAudience, isLoading: isCreatingAudience, mutate: createAudience } = useMutation(
    data => {
      const ageRange = !isEmpty(props.audienceParams.ageRanges) ? props.audienceParams.ageRanges[0] : '-';
      const minAge = parseInt(ageRange.split('-')[0], 10);
      const maxAge = parseInt(ageRange.split('-')[1], 10);
      const targetAudiences = selectedAudienceIndices.map(index => {
        const interests = keywordAudiences.audiences[index].map(interests =>
          ({ interests: interests.map(interest => ({ ...interest, type: 'interests' })) })
        );
        return ({
          include: [
            { countries: props.audienceParams.countries },
            ...(props.audienceParams.gender ? [{ gender: props.audienceParams.gender }] : []),
            ...interests,
            ...(minAge ? [{ minAge }] : []),
            ...(maxAge ? [{ maxAge }] : []),
          ],
        });
      });
      return createKeywordAudience({
        phrases: props.audienceParams.phrases,
        targetAudiences,
      });
    },
    {
      onSuccess: () => {
        mixpanel.track('Create Target Audience', {
          platform: 'facebook',
          audience_type: 'From Keywords',
          keywords: props.audienceParams.phrases,
        });
        notifySuccess({ title: 'Audience created' });
        history.replace('/audiences/');
      },
      onFailure: () => notifyError({ title: 'Audience create error' }),
    }
  );

  // suggestion status
  const [loadingState, setLoadingState] = useState('Generating audience suggestions');
  const [suggestionFailed, setSuggestionFailed] = useState(false);
  const [keywordAudiences, setKeywordAudiences] = useState();
  const [selectedAudienceIndices, setSelectedAudienceIndices] = useState([]);

  // render suggestions
  return (
    <WizardStep
      forwardButtonTitle="Create Target Audience"
      isForwardButtonDisabled={loadingState !== null || size(selectedAudienceIndices) === 0}
      isLoading={isCreatingAudience}
      onSubmit={createAudience}
      title="Create the Target Audience"
      {...props}
    >
      <div>
        {errorCreatingAudience && <ErrorMessage message={errorCreatingAudience.message} />}
        <h1 className="text-xl font-semibold">
          Review the Audience Suggestions
        </h1>
      </div>

      {(errorRequestingSuggestions || suggestionFailed) && (
        <div className="rounded bg-red-50 px-5 py-10 text-red-700 text-center">
          <h2 className="font-medium text-xl text-red-700">
            Could not generate suggestions. Please try again.
          </h2>
          <p className="text-base mt-2 mb-4">
            If it still fails, try another keyword.
          </p>
          <Button
            icon={RefreshIcon}
            onClick={() => {
              setLoadingState('Generating audience suggestions');
              setSuggestionFailed(false);
              requestSuggestions();
            }}
            title="Generate suggestions again"
          />
        </div>
      )}
      {loadingState && (
        <div className="rounded bg-blue-50 px-5 py-10 mt-8 text-blue-700 text-center">
          <h2 className="font-medium text-xl">{loadingState}...</h2>
          <p className="text-base mt-2">Please wait. This could take up to 30 seconds.</p>
          <Puff
            className="mx-auto mt-8 h-10"
            stroke={COLORS.blue[600]}
          />
        </div>
      )}
      {keywordAudiences && (
        <div className="rounded bg-indigo-50 p-6 mt-8">
          <h2 className="text-2xl mb-4 font-semibold text-indigo-900 text-center">
            Click to select any of these{' '}
            {`${pluralize('Facebook audience', props.audienceParams.audienceCount, true)}`} to create
          </h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            {keywordAudiences.audiences.map((audience, index) => {
              const isSelected = selectedAudienceIndices.includes(index);
              return renderAudience(
                audience,
                keywordAudiences.audienceSizes[index],
                index,
                isSelected,
                () => setSelectedAudienceIndices(xor(selectedAudienceIndices, [index])));
            })}
          </div>
        </div>
      )}

      <AudienceGenerationListener
        responseCallback={response => {
          if ('stage' in response) {
            setLoadingState(response.stage);
          } else if (response.status === 'OK') {
            setLoadingState(null);
            setSuggestionFailed(false);
            setKeywordAudiences(response);
          } else {
            setLoadingState(null);
            setSuggestionFailed(true);
          }
        }}
        userUid={userInfo.uid}
      />
    </WizardStep>
  );
}

AudienceSuggestions.propTypes = {
  audienceParams: PropTypes.object,
};
