import { commonFetch, getPagedResults } from '@utils/fetchUtils';

export function archiveCampaignGroup(campaignGroupId, shouldArchive) {
  return commonFetch(`/v3/campaign_groups/${campaignGroupId}`, {
    body: { status: shouldArchive ? 'archived' : 'active' },
    method: 'PUT',
  });
}

export function createCampaignGroup(campaignGroup) {
  return commonFetch('/v3/campaign_groups', {
    body: campaignGroup,
    method: 'POST',
  });
}

export function deleteCampaignGroup(campaignGroupId) {
  return commonFetch(`/v3/campaign_groups/${campaignGroupId}`, {
    hasEmptyResponse: true,
    method: 'DELETE',
  });
}

export const getCampaignGroups = getPagedResults('/v3/campaign_groups');

export function triggerCampaignGroupReporting(campaignGroupId) {
  return commonFetch(`/v3/campaign_groups/${campaignGroupId}/refresh`, {
    method: 'PUT',
  });
}

export function triggerCampaignGroupRecalculation(campaignGroupId) {
  return commonFetch(`/v3/campaign_groups/${campaignGroupId}/recalculate`, {
    method: 'PUT',
  });
}

export function updateCampaignGroup(campaignGroupId, campaignGroup) {
  return commonFetch(`/v3/campaign_groups/${campaignGroupId}`, {
    body: campaignGroup,
    method: 'PUT',
  });
}
