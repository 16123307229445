import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { DuplicateIcon } from '@heroicons/react/outline';

import { notifySuccess } from '@utils/toaster';

export default function CopyToClipboard(props) {
  const { notificationText, value } = props;

  return (
    <span
      className="hover:text-gray-600 hover:cursor-pointer"
      onClick={() => {
        mixpanel.track('Copy to Clipboard', { value });
        navigator.clipboard.writeText(
          props.isTrackedLink ? `${value}?utm_source=topicdna&utm_medium=share` : value
        ).then(
          () => notifySuccess({ title: notificationText })
        );
      }}
    >
      <DuplicateIcon
        className="inline ml-2 mr-1 h-5 w-5"
      />
      {props.text}
    </span>
  );
}

CopyToClipboard.propTypes = {
  isTrackedLink: PropTypes.bool,
  notificationText: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
};
