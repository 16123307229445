import { size } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import { getHighlightedText } from '@utils/generalUtils';
import { ToolTipContext } from '@hoc/withToolTip';

export default function Truncated(props) {
  const ToolTip = useContext(ToolTipContext);
  return (
    <ToolTip
      content={
        <div className="max-w-sm">
          {props.content}
        </div>
      }
      isDisabled={size(props.content) <= props.maxContentSize}
      isHtml
    >
      {props.content ? (
        <span className="break-all">
          {getHighlightedText(props.content, props.filter, props.maxContentSize)}
        </span>
      ) : (
        props.children || null
      )}
    </ToolTip>
  );
}

Truncated.propTypes = {
  children: PropTypes.node,
  content: PropTypes.string,
  filter: PropTypes.string,
  maxContentSize: PropTypes.number.isRequired,
};
