import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { createCampaignGroup, updateCampaignGroup } from '@api/campaignGroups';
import { getCustomMetrics } from '@api/organization';
import ErrorMessage from '@common/ErrorMessage';
import { FormDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import { WizardStep } from '@common/Wizard';
import { notifySuccess } from '@utils/toaster';

export default function FinalizeCampaignGroup(props) {
  // acquire history handler
  const history = useHistory();

  // set up the form
  const { control, formState: { errors }, handleSubmit, register, setValue } = useForm({ mode: 'onChange' });
  useEffect(
    () => {
      setValue('name', get(props.campaignGroup, 'name'));
      setValue('benchmarkCpr', get(props.campaignGroup, 'clientCprBenchmark'));
      setValue('customMetric', { value: get(props.campaignGroup, 'customMetricId') });
    },
    [props.campaignGroup]
  );

  // query custom metrics
  const { data: customMetrics, error: errorLoadingCustomMetrics, isFetching: isLoadingCustomMetrics } = useQuery(
    ['customMetrics', get(props.data, 'selectedOrganizationId'), get(props.data, 'platform')],
    () => getCustomMetrics(props.data.selectedOrganizationId, props.data.platform),
    {
      initialData: [],
      select: data => data.map(item => ({ label: item.name, value: item.key })),
    }
  );

  // submit the audience request
  const { error, isLoading, mutate } = useMutation(
    data => props.campaignGroup
      ? updateCampaignGroup(
        props.campaignGroup.id,
        {
          clientCprBenchmark: data.benchmarkCpr,
          customMetricId: get(data.customMetric, 'value'),
          name: data.name,
          ...props.data,
        }
      )
      : createCampaignGroup({
        clientCprBenchmark: data.benchmarkCpr,
        customMetricId: get(data.customMetric, 'value'),
        name: data.name,
        ...props.data,
      }),
    {
      onSuccess: campaignGroup => {
        notifySuccess({ title: `Campaign group was ${props.campaignGroup ? 'updated' : 'created'} successfully` });
        history.push(props.isAdmin
          ? `/admin/campaign_performance/${campaignGroup.id}`
          : `/campaign_performance/${campaignGroup.id}`
        );
      },
    }
  );

  // render content
  const currencySymbol = new Intl.NumberFormat(
    undefined,
    {
      style: 'currency',
      currency: props.data.currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  ).format(0).replace(/\d/g, '').trim();
  return (
    <WizardStep
      forwardButtonTitle={`${props.campaignGroup ? 'Update' : 'Create'} Campaign Group`}
      hasForm
      isLoading={isLoading}
      onSubmit={handleSubmit(mutate)}
      title="Finalize the Campaign Group"
      {...props}
    >
      {error && <ErrorMessage message={error.message} />}
      {errorLoadingCustomMetrics && <ErrorMessage message={errorLoadingCustomMetrics.message} />}

      <FormField
        error={errors.name}
        label="Campaign Group Name"
        name="name"
        placeholder="Enter the campaign group name"
        register={() => register('name', { required: 'Campaign group name is required' })}
        type="text"
      />
      <div className="mt-4">
        <FormField
          error={errors.benchmarkCpr}
          label={`Enter a client benchmark CPR (${currencySymbol})`}
          name="benchmarkCpr"
          placeholder="e.g. 45"
          register={() => register('benchmarkCpr')}
          step={0.001}
          type="number"
        />
      </div>
      <div className="mt-4">
        <FormDropdown
          control={control}
          error={errors.customMetric}
          isDisabled={isLoadingCustomMetrics}
          label="Optionally select a custom metric"
          name="customMetric"
          options={customMetrics}
        />
      </div>
    </WizardStep>
  );
}

FinalizeCampaignGroup.propTypes = {
  campaignGroup: PropTypes.object,
  data: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
};
