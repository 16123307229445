import { BadgeCheckIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

import { addDefaultImage } from '@utils/influencersUtils';

export function verifiedCheck(verified) {
  if (verified) {
    return <BadgeCheckIcon className="h-5 w-5 text-blue-500 inline" />;
  }
}

export default function SelectSearchOption({ data, innerProps, isFocused }) {
  const searchOptionClassNames = [
    'bg-white',
    'cursor-pointer',
    'flex',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'hover:bg-gray-100',
    'items-center',
    'px-6',
    'py-5',
    'relative',
    'shadow-sm',
    'space-x-3',
  ];

  return (
    <div
      className={searchOptionClassNames.join(' ')}
      focused={isFocused ? 'true' : null}
      key={data.username}
      {...innerProps}
    >
      <div className="flex-shrink-0">
        <img
          alt=""
          className="rounded-full w-10 h-10 float-right bg-cover"
          onError={addDefaultImage}
          src={data.avatar || data.photoUrl}
        />
      </div>
      <div className="flex-1 min-w-0">
        <span className="text-sm font-medium text-gray-900">
          {data.name || data.label} {verifiedCheck(data.verified)}
        </span>
        <p className="text-sm text-gray-500 truncate">
          @{data.username} - {data.followersCount.toLocaleString()} followers
        </p>
      </div>
    </div>
  );
}

SelectSearchOption.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    description: PropTypes.string,
    followersCount: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    username: PropTypes.string,
    verified: PropTypes.bool,
  }),
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
};
