import { MapIcon } from '@heroicons/react/outline';
import { useState } from 'react';

import getInterestTableColumns from './interestTableColumns';

import { getInterests } from '@api/admin';
import OptionGroup from '@common/OptionGroup';
import RemoteTable from '@common/RemoteTable';
import Section from '@common/Section';

export default function InterestAdminSection() {
  // state
  const [isShowingDeprecated, setIsShowingDeprecated] = useState(true);

  // render admin section
  return (
    <Section
      icon={MapIcon}
      title="Interests Explorer"
    >
      <RemoteTable
        columns={getInterestTableColumns(isShowingDeprecated)}
        entitiesName="interests"
        extra={
          <OptionGroup
            options={[
              {
                isActive: isShowingDeprecated,
                onClick: () => setIsShowingDeprecated(true),
                title: 'Deprecated',
              },
              {
                isActive: !isShowingDeprecated,
                onClick: () => setIsShowingDeprecated(false),
                title: 'Added',
              },
            ]}
          />
        }
        isSearchable
        query={getInterests(isShowingDeprecated)}
        queryKey={`interests_${isShowingDeprecated ? 'deprecated' : 'added'}`}
      />
    </Section>
  );
}
