import { get, includes, sortBy, uniq } from 'lodash';
import { useQueries } from 'react-query';

import { CONVERSATION_TYPES, EVENT_TYPES } from './data/twitterData';

import { searchConversations, searchEvents, searchInterests, searchLocations } from '@api/targeting_old';
import SelectSearchOption from '@common/reactSelect/SelectSearchOption';
import { SelectedValueWithIcon } from '@common/reactSelect/SelectValue';
import { collectRepeatableValues, sortHits } from '@utils/generalUtils';
import { searchSocialInfluencers } from '@api/miscellaneous';
import { Platform } from '@root/utils/commonUtils';

const AGES = [
  { min: 13, max: [24, 34, 49, 54] },
  { min: 18, max: [24, 34, 49, 54] },
  { min: 21, max: [34, 49, 54] },
  { min: 25, max: [49, 54] },
  { min: 35, max: [49, 54] },
  { min: 50, max: [] },
];

const LOCATION_TYPE_MAPPING = {
  CITIES: 'city',
  COUNTRIES: 'country',
  METROS: 'metro',
  POSTAL_CODES: 'postalCode',
  REGIONS: 'region',
};

export function useTwitterBase(values) {
  // collect conversation types
  const repeatables = collectRepeatableValues(values);
  const conversationTypes = Object.entries(
    repeatables.map((item, index) => [get(item, 'conversationType.value'), index])
      .filter(item => item[0])
      .reduce(
        (result, item) => {
          (result[item[0]] || (result[item[0]] = [])).push(item[1]);
          return result;
        },
        {}
      )
  );

  // query conversations
  const conversationResults = Object.fromEntries(
    useQueries(
      conversationTypes.map(([type, indices]) => ({
        queryKey: ['getTwitterConversations', type],
        queryFn: () => searchConversations('twitter', type),
        select: conversations => sortBy(
          conversations.map(conversation => ({ label: conversation.name, value: conversation.id })),
          'label'
        ),
      }))
    ).flatMap((result, index) => conversationTypes[index][1].map(targetIndex => [targetIndex, result]))
  );

  // collect event types
  const eventTypes = Object.entries(
    repeatables.map((item, index) => [get(item, 'eventType.value'), index])
      .filter(item => item[0])
      .reduce(
        (result, item) => {
          (result[item[0]] || (result[item[0]] = [])).push(item[1]);
          return result;
        },
        {}
      )
  );

  // query conversations
  const eventResults = Object.fromEntries(
    useQueries(
      eventTypes.map(([type, indices]) => ({
        queryKey: ['getTwitterEvents', type],
        queryFn: () => searchEvents('twitter', type),
        select: events => sortBy(events.map(event => ({ label: event.name, value: event.id })), 'label'),
      }))
    ).flatMap((result, index) => eventTypes[index][1].map(targetIndex => [targetIndex, result]))
  );

  // query events
  return { conversationResults, eventResults };
}

export function getTwitterFields(values, setValue, base, _dummy, showSuggestions) {
  // adjust minimal or maximal age if necessary
  if (values.minAge && values.maxAge && values.minAge.value > values.maxAge.value) {
    const nearest = AGES.find(entry => entry.min === values.minAge.value).max[0];
    setValue('maxAge', nearest ? { label: nearest.toString(), value: nearest } : null);
  }
  if (!values.minAge && values.maxAge) {
    const nearest = AGES.find(entry => includes(entry.max, values.maxAge.value)).min;
    setValue('minAge', { label: nearest.toString(), value: nearest });
  }

  // destructure base
  const { conversationResults, eventResults } = base;

  // return fields
  const minAgeOptions = AGES.map(entry => ({ label: entry.min.toString(), value: entry.min }));
  const maxAges = values.minAge
    ? AGES.find(entry => entry.min === values.minAge.value).max
    : uniq(AGES.flatMap(entry => entry.max));
  const maxAgeOptions = maxAges.map(age => ({ label: age.toString(), value: age }));
  return [
    {
      empty: 'No location found',
      isMulti: true,
      label: 'Location',
      name: 'locations',
      options: query => searchLocations('twitter', query).then(locations => {
        const mapped = locations.map(location => ({
          label: location.name,
          type: LOCATION_TYPE_MAPPING[location.locationType],
          value: location.id,
        }));
        return sortHits(mapped, 'label', query);
      }),
      placeholder: 'Select a location',
      required: value => !value.some(item => item.type === 'country') ? 'Choose at least one country' : undefined,
      type: 'lookup',
    },
    {
      label: 'Gender',
      name: 'gender',
      options: [
        { label: 'All Genders', value: '' },
        { label: 'Female', value: 'female' },
        { label: 'Male', value: 'male' },
      ],
      placeholder: 'Select a gender',
      type: 'choice',
      width: 4,
    },
    {
      label: 'Minimum Age',
      name: 'minAge',
      options: minAgeOptions,
      type: 'choice',
      width: 4,
    },
    {
      isClearable: true,
      label: 'Maximum Age',
      name: 'maxAge',
      options: maxAgeOptions,
      type: 'choice',
      width: 4,
    },
    {
      empty: 'No topic found',
      isMulti: true,
      label: 'Topics',
      name: 'topics',
      options: query => searchInterests('twitter', query).then(interests => {
        const mapped = interests.map(interest => ({ label: interest.name, value: interest.id }));
        return sortHits(mapped, 'label', query);
      }),
      type: 'lookup',
    },
    {
      auxiliaryLink: {
        onClick: showSuggestions,
        title: 'Get suggestions',
      },
      empty: 'No account found',
      isMulti: true,
      label: 'Interests',
      multiValueLabel: SelectedValueWithIcon,
      name: 'interests',
      options: query => searchSocialInfluencers(Platform.TWITTER, query).then(accounts => {
        const mapped = accounts.map(account => ({
          avatar: account.avatar,
          followersCount: account.followersCount,
          label: account.name,
          username: account.username,
          value: account.id,
          verified: account.verified,
        }));
        return sortHits(mapped, 'label', query);
      }),
      placeholder: 'Select an account',
      selectSearchOption: SelectSearchOption,
      type: 'lookup',
    },
    {
      isMulti: true,
      label: 'Broad keywords',
      name: 'broadKeywords',
      placeholder: 'Type a keyword',
      type: 'phrases',
    },
    {
      isMulti: true,
      label: 'Phrase keywords',
      name: 'phraseKeywords',
      placeholder: 'Type a keyword',
      type: 'phrases',
    },
    {
      items: [
        {
          label: 'Conversation Type',
          name: 'conversationType',
          options: CONVERSATION_TYPES,
          placeholder: 'Choose a type',
          type: 'choice',
          width: 4,
        },
        {
          empty: 'No conversations found',
          isDisabled: index => conversationResults[index].isFetching,
          isMulti: true,
          label: 'Conversation',
          name: 'conversation',
          options: index => conversationResults[index].data || [],
          placeholder: 'Choose a conversations',
          skip: index => !values[`conversationType__${index}`],
          type: 'choice',
          width: 8,
        },
      ],
      mainName: 'conversation',
      type: 'repeatable',
    },
    {
      items: [
        {
          label: 'Event Type',
          name: 'eventType',
          options: EVENT_TYPES,
          placeholder: 'Choose a type',
          type: 'choice',
          width: 4,
        },
        {
          empty: 'No events found',
          isDisabled: index => eventResults[index].isFetching,
          isMulti: true,
          label: 'Event',
          name: 'event',
          options: index => eventResults[index].data || [],
          placeholder: 'Choose an event',
          skip: index => !values[`eventType__${index}`],
          type: 'choice',
          width: 8,
        },
      ],
      mainName: 'event',
      type: 'repeatable',
    },
  ];
}
