import PropTypes from 'prop-types';

import FormError from './FormError';
import FormLabel from './FormLabel';

const CLASS_NAMES = [
  'block',
  'border-gray-300',
  'focus:ring-indigo-500',
  'focus:border-indigo-500',
  'mt-1',
  'rounded-md',
  'shadow-sm',
  'text-sm',
  'w-full',
];

export default function FormField(props) {
  const Icon = props.icon;
  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      <div className={props.width && `col-span-${props.width}`}>
        {props.prefix}
        {props.icon && <Icon className="mr-3 h-4 w-4 text-gray-400" />}
        {props.type !== 'textarea' ? (
          <input
            className={CLASS_NAMES.join(' ')}
            disabled={props.isDisabled}
            max={props.max}
            min={props.min}
            onChange={event => props.onChange(event.target.value)}
            step={props.step}
            type={props.type}
            {...(props.placeholder ? { placeholder: props.placeholder } : {})}
            {...(props.register ? props.register() : null)}
          />
        ) : (
          <textarea
            className={CLASS_NAMES.join(' ')}
            disabled={props.isDisabled}
            onChange={props.onChange}
            {...(props.placeholder ? { placeholder: props.placeholder } : {})}
            {...(props.register ? props.register() : null)}
          />
        )}
      </div>
      {props.error && <FormError message={props.error.message} />}
    </>
  );
}

FormField.propTypes = {
  error: PropTypes.object,
  icon: PropTypes.func,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  register: PropTypes.func,
  step: PropTypes.number,
  type: PropTypes.string.isRequired,
  width: PropTypes.string,
};
