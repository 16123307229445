import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getAssignedItems, getQueueDetails, submitValues, unassignItems } from '@api/queue';
import { getUsers } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import ErrorMessage from '@common/ErrorMessage';
import { FormAsyncDropdown } from '@common/FormDropdowns';
import SelectUserOption from '@common/reactSelect/SelectUserOption';
import SelectUserValue from '@common/reactSelect/SelectUserValue';
import Section from '@common/Section';
import Table from '@common/Table';
import { QueueDetails, QueueItem } from '@models/queue';
import { getUserName, Option, UserAccount } from '@utils/commonUtils';
import { ErrorResponse } from '@utils/httpUtils';

import getQueueItemTableColumns from './queueTableItemColumns';

export default function QueueItems(): JSX.Element {
  const { queueId } = useParams<{ queueId: string }>();
  const parsedQueueId = parseInt(queueId, 10);

  // user selection state
  type UserOption = Option<number> & UserAccount;
  const [selectedUser, setSelectedUser] = useState<UserOption>();

  // query queue details
  const { data: queueDetails, isFetching: isLoadingQueueDetails } = useQuery<QueueDetails, ErrorResponse>(
    ['queueDetails', parsedQueueId],
    () => getQueueDetails(parsedQueueId)
  );

  // query queue items
  const {
    data: queueItems = [], // to avoid undefined in the type of queueItems
    error: errorLoadingQueueItems,
    isFetching: isLoadingQueueItems,
    refetch: refetchQueueItems,
  } = useQuery<QueueItem[], ErrorResponse>(
    ['assignedItems', parsedQueueId, selectedUser?.value],
    () => getAssignedItems(parsedQueueId, selectedUser!.value),
    {
      enabled: !isNil(selectedUser),
      initialData: [],
    }
  );

  // unassign items
  const {
    error: errorUnassigningItems,
    isLoading: isUnassigningItems,
    mutate: doUnassignItems,
  } = useMutation<void, ErrorResponse>(
    () => unassignItems(parsedQueueId, selectedUser!.value),
    {
      onSuccess: () => refetchQueueItems(),
    }
  );

  // manage values
  const [values, setValues] = useState<Record<number, Record<string, Option<string>>>>({});
  const {
    error: errorSubmittingValues,
    isLoading: isSubmittingValues,
    mutate: doSubmitValues,
  } = useMutation<void, ErrorResponse>(
    async () => {
      const transformedValues = Object.fromEntries(
        Object.entries(values)
          .map(([entityId, attributes]) =>
            [
              entityId,
              Object.fromEntries(
                Object.entries(attributes).map(([attributeName, attributeValue]) =>
                  [attributeName, attributeValue.value]
                )
              ),
            ]
          )
      );
      await submitValues(parsedQueueId, transformedValues);
    },
    { onSuccess: () => refetchQueueItems() }
  );

  // render content
  return (
    <>
      <Section
        heading={(
          <div className="flex justify-end">
            <Button
              icon={ArrowSmLeftIcon}
              kind={ButtonKind.REGULAR}
              target="/admin/queues"
              title="Back to Queues"
            />
          </div>
        )}
        title={isLoadingQueueDetails ? '' : `${queueDetails!.queue.name} (id: ${queueDetails!.queue.id!})`}
      >
        {!isNil(errorLoadingQueueItems) && <ErrorMessage message={errorLoadingQueueItems.message} />}
        {!isNil(errorUnassigningItems) && <ErrorMessage message={errorUnassigningItems.message} />}
        {!isNil(errorSubmittingValues) && <ErrorMessage message={errorSubmittingValues.message} />}

        <FormAsyncDropdown
          label="Get items assigned for"
          loadOptions={(query: string, callback: (data: Array<UserOption>) => void) => {
            // we should return undefined
            void getUsers(0, query).then(response =>
              callback(response.payload.map(user => ({ ...user, label: getUserName(user), value: user.id })))
            );
          }}
          name="user"
          onSelection={setSelectedUser}
          option={SelectUserOption}
          placeholder="Type to search for a user..."
          singleValue={SelectUserValue}
          value={selectedUser}
        />

        {selectedUser && (
          <>
            <Table
              columns={getQueueItemTableColumns(queueDetails?.attributeNames ?? [], values, setValues)}
              data={queueItems}
              isLoading={isLoadingQueueItems}
            />
            <div className="text-right mt-2 space-x-2">
              <Button
                isDisabled={isNil(selectedUser)}
                isLoading={isUnassigningItems}
                kind={ButtonKind.DANGEROUS}
                onClick={doUnassignItems}
                title="Return Items to Queue"
              />
              <Button
                isDisabled={isNil(selectedUser)}
                isLoading={isSubmittingValues}
                kind={ButtonKind.PRIMARY}
                onClick={doSubmitValues}
                title="Submit Verifications"
              />
            </div>
          </>
        )}
      </Section>
    </>
  );
}
