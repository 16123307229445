import { commonFetch } from '@utils/fetchUtils';

export function getTargetingAudienceSize(platform, targeting) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/reach_estimate`, {
    body: targeting,
    method: 'POST',
  });
}

export function searchActionCategories(platform, type) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/action_categories?type=${type}`);
}

export function searchAudiences(platform) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/audiences`);
}

export function searchConversations(platform, conversationType) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/conversations?conversation_type=${conversationType}`);
}

export function searchEvents(platform, eventType) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/events?event_type=${eventType}`);
}

export function searchInterests(platform, query, countryCode = '') {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/interests?` +
    `query=${query}&country_code=${countryCode}`);
}

export function searchHashtags(platform, query) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/hashtags?` +
    `query=${query}`);
}

export function searchLanguages(platform, query) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/languages?query=${query}`);
}

export function searchLocations(platform, query) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/locations?query=${query}`);
}

export function searchSkills(platform, query) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/skills?query=${query}`);
}

export function searchTitles(platform, query) {
  return commonFetch(`/v3/platforms/${platform}/targeting_options/titles?query=${query}`);
}
