import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import { deleteUserIdentity } from '@api/user';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { UserInfoContext } from '@hoc/withUserInfo';
import { notifyError, notifySuccess } from '@utils/toaster';

export default function DisconnectPlatformModal({ close, identityId, isOpen, name, platform, userId }) {
  function onSubmit() {
    setIsUpdating(true);

    deleteUserIdentity(userId, identityId)
      .then(response => {
        setNewUserData(response);
        notifySuccess({ title: 'Account removed!' });
        close();
      })
      .catch(error => {
        notifyError({ title: 'Could not remove. Please try again.' });
        setError(error.message);
      })
      .finally(() => setIsUpdating(false));
  }

  function updateUserData() {
    if (newUserData !== null) {
      setUserInfo(newUserData);
    }
  }

  const { setUserInfo } = useContext(UserInfoContext);

  // state for data submission
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [newUserData, setNewUserData] = useState(null);

  return (
    <Dialog
      close={close}
      isDangerous
      isOpen={isOpen}
      isUpdating={isUpdating}
      onClose={updateUserData}
      onSubmit={onSubmit}
      submitTitle="Remove"
      title="Remove Account"
      updatingTitle="Removing..."
    >
      {error && <ErrorMessage message={error} />}
      <div className="mt-4">
        Are you sure you want to remove {name}&apos;s {capitalize((platform || '').replace('_', ' '))} account from your
        connected accounts?
      </div>
    </Dialog>
  );
}

DisconnectPlatformModal.propTypes = {
  close: PropTypes.func.isRequired,
  identityId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string,
  platform: PropTypes.string,
  userId: PropTypes.number,
};
