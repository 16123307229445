import PropTypes from 'prop-types';

function renderCell(cell, props) {
  const { isDisabled, isSelected } = props;

  // determine class names and rendering type
  const classNames = cell.column.hasSmallPadding ? ['p-2'] : ['px-6', 'py-4'];
  if (cell.column.alignment) {
    classNames.push(`text-${cell.column.alignment}`);
  }
  classNames.push('whitespace-normal');
  classNames.push('break-all');
  if (isSelected) {
    classNames.push('bg-green-200');
  } else if (isDisabled) {
    classNames.push('bg-gray-200');
  } else {
    classNames.push('bg-white');
  }

  let type = cell.isAggregated ? 'Aggregated' : cell.isPlaceHolder ? 'RepeatedCell' : 'Cell';

  // render table cell
  return (
    <td
      className={classNames.join(' ')}
      key={cell.row.id}
      {...cell.getCellProps({ style: { minWidth: cell.column.cellWidth, maxWidth: cell.column.cellWidth } })}
    >
      {cell.render(type)}
    </td>
  );
}

export default function TableRow(props) {
  return (
    <>
      <tr
        className={props.hasSmallText ? ['text-sm'] : []}
        key={props.row.id}
        onClick={props.onRowClick ? () => props.onRowClick(props.row.id) : null}
      >
        {props.row.cells.map(cell => renderCell(cell, props))}
      </tr>
      {props.row.isExpanded && props.subComponent && (
        <tr
          key={`sub-${props.row.id}`}
        >
          <td colSpan={props.columnCount}>
            {props.subComponent({ row: props.row })}
          </td>
        </tr>
      )}
    </>
  );
}

TableRow.propTypes = {
  columnCount: PropTypes.number.isRequired,
  hasSmallPadding: PropTypes.bool,
  hasSmallText: PropTypes.bool,
  isSelected: PropTypes.bool,
  onRowClick: PropTypes.func,
  row: PropTypes.object,
  subComponent: PropTypes.func,
};
