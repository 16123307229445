import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { ReactNode } from 'react';

export interface SliderProps {
  actions: ReactNode;
  children: ReactNode;
  close: () => void;
  isOpen: boolean;
  title: string;
}

export default function Slider(props: SliderProps): JSX.Element {
  return (
    <Transition.Root
      as={React.Fragment}
      show={props.isOpen}
    >
      <Dialog
        as="div"
        className="relative z-10"
        onClose={props.close}
      >
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {props.title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              className={`
                                rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2
                                focus:ring-indigo-500
                              `}
                              onClick={props.close}
                              type="button"
                            >
                              <XIcon
                                aria-hidden="true"
                                className="h-6 w-6"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {props.children}
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      {props.actions}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
