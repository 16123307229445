import { capitalize, get, isEmpty, size, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import GoogleButton from 'react-google-button';

import { validateToken } from '@api/authentication';
import {
  getAdAccounts,
  getAdSets,
  getCampaigns,
  getInsertionOrders,
  getPlatformOrganizations,
} from '@api/structure';
import { getUsers } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import Dialog, { DialogSize } from '@common/Dialog';
import DialogIcon from '@common/DialogIcon';
import { FormAsyncDropdown, FormDropdown } from '@common/FormDropdowns';
import SelectUserOption from '@common/reactSelect/SelectUserOption';
import SelectUserValue from '@common/reactSelect/SelectUserValue';
import { UserInfoContext } from '@hoc/withUserInfo';


import { getUserName } from '@utils/generalUtils';
import { oAuthSignIn } from '@root/utils/oAuth';

export default function AdSetSelector(props) {
  function renderAdSetDropdown(platform, userId, value, setValue, error) {
    const isEnabledForDv360 = platform === 'dv360' && isEmpty(insertionOrder);
    const isEnabledForOtherPlatforms = platform !== 'dv360' && isEmpty(campaign);

    const entity = platform === 'dv360' ? 'line item' : 'ad set';
    const article = platform === 'dv360' ? 'a' : 'an';

    return (
      <FormDropdown
        error={error && { message: error }}
        isDisabled={(isEnabledForDv360 || isEnabledForOtherPlatforms) || isLoadingAdSets}
        isOptionDisabled={option => option.expired}
        label={props.shouldHideAdSetSelectionLabel ? '' : `Select ${article} ${entity}`}
        onSelection={setValue}
        options={sortBy(
          adSets.map(adSet => ({
            adAccountId: adAccount.value,
            adAccountName: adAccount.label,
            campaignId: campaign.value,
            campaignName: campaign.label,
            insertionOrderId: insertionOrder.value,
            insertionOrderName: insertionOrder.label,
            identity: identity.value,
            label: adSet.name || 'Untitled',
            organizationId: organization.value,
            organizationName: organization.label,
            userId,
            value: adSet.id,
          })),
          option => option.label.toLowerCase()
        )}
        placeholder={isLoadingAdSets ? `Loading ${entity} information` : `Select ${article} ${entity}`}
        value={isEmpty(value) ? null : value}
      />
    );
  }

  // acquire user data
  const { setUserInfo, userInfo } = useContext(UserInfoContext);
  const [targetUser, setTargetUser] = useState({});

  // determine identities and active state
  const identities = (!props.isAdmin ? userInfo.identities : get(targetUser, 'identities', [])).filter(
    identity => identity.provider === props.platform || (identity.provider === 'google' && props.platform === 'dv360')
  );
  const isActive = props.adAudienceId || !isEmpty(props.adAudiences);

  // selection states
  const [organization, setOrganization] = useState({});
  const [adAccount, setAdAccount] = useState({});
  const [campaign, setCampaign] = useState({});
  const [insertionOrder, setInsertionOrder] = useState({});
  const [identity, setIdentity] = useState({});
  useEffect(() => {
    if (isEmpty(identity) && size(identities) === 1) {
      setIdentity({ value: identities[0].id });
    }
  }, [identities]);


  // fetch organizations
  const { data: organizations, isFetching: isLoadingOrganizations } = useQuery(
    ['organizations', props.platform, identity.value],
    () => getPlatformOrganizations(props.platform, identity.value),
    {
      enabled: isActive && ['snapchat', 'dv360'].includes(props.platform) && !isEmpty(identity),
      initialData: [],
      onError: error => {
        props.setIsTokenInvalid(true);
        // eslint-disable-next-line no-console
        console.log('Fetching organizations failed:', error);
      },
    }
  );

  // fetch ad accounts
  const { data: adAccounts, isFetching: isLoadingAdAccounts } = useQuery(
    ['adAccounts', props.platform, identity.value, organization.value],
    () => getAdAccounts(props.platform, identity.value, organization.value),
    {
      enabled:
        isActive &&
        ((!['snapchat', 'dv360'].includes(props.platform) && !isEmpty(identity)) ||
          (['snapchat', 'dv360'].includes(props.platform) && !isEmpty(organization))),
      initialData: [],
      onError: error => {
        props.setIsTokenInvalid(true);
        // eslint-disable-next-line no-console
        console.log('Fetching ad accounts failed:', error);
      },
    }
  );

  // fetch campaigns of an ad account
  const { data: campaigns, isFetching: isLoadingCampaigns } = useQuery(
    ['campaigns', props.platform, identity.value, adAccount.value],
    () => getCampaigns(props.platform, identity.value, adAccount.value),
    {
      enabled: isActive && !isEmpty(adAccount),
      initialData: [],
    }
  );

  // fetch insertion orders of a campaign
  const { data: insertionOrders, isFetching: isLoadingInsertionOrders } = useQuery(
    ['insertionOrders', props.platform, identity.value, adAccount.value, campaign.value],
    () => getInsertionOrders(props.platform, identity.value, adAccount.value, campaign.value),
    {
      enabled: isActive && (['dv360'].includes(props.platform) && !isEmpty(campaign)),
      initialData: [],
    }
  );


  // fetch ad sets of a campaign
  const { data: adSets, isFetching: isLoadingAdSets } = useQuery(
    ['adSets', props.platform, identity.value, adAccount.value, campaign.value],
    () => getAdSets(
      props.platform,
      identity.value,
      adAccount.value,
      campaign.value,
      insertionOrder ? insertionOrder.value : null
    ),
    {
      enabled: isActive &&
        ((!['dv360'].includes(props.platform) && !isEmpty(campaign)) ||
          (['dv360'].includes(props.platform) && !isEmpty(insertionOrder))),
      initialData: [],
    }
  );

  const capitalizedPlatform = props.platform === 'facebook' ? 'Meta' : capitalize(props.platform);

  const SignInButton = ({ platform, uid }) => {
    if (platform !== 'dv360') {
      return (
        <Button
          isFullWidth
          kind={ButtonKind.SUCCESS}
          onClick={async () => {
            await oAuthSignIn(platform, uid);
            const updatedUserInfo = await validateToken();
            setUserInfo(updatedUserInfo);
          }}
          title={`Connect your ${capitalizedPlatform} account`}
        />
      );
    } else {
      return (
        <GoogleButton
          onClick={async () => {
            await oAuthSignIn('google', uid);
            const updatedUserInfo = await validateToken();
            setUserInfo(updatedUserInfo);
          }
          }
        />
      );
    }
  };

  SignInButton.propTypes = {
    platform: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  };

  // render selector
  return size(identities) === 0 && !props.isAdmin ? (
    <SignInButton
      platform={props.platform}
      uid={userInfo.uid}
    />
  ) : (
    <>
      <div className="mb-4">
        {props.isAdmin && (
          <FormAsyncDropdown
            label="Select a user"
            loadOptions={(query, callback) =>

              // we should not return anything here
              getUsers(0, query).then(response =>
                callback(
                  response.payload.map(userInfo => ({ label: getUserName(userInfo), value: userInfo.id, ...userInfo }))
                )
              ) && undefined
            }
            onSelection={option => {
              setTargetUser(option);
              setIdentity({});
              setAdAccount({});
              setCampaign({});
              props.clearTarget();
            }}
            option={SelectUserOption}
            placeholder="Type to search..."
            singleValue={SelectUserValue}
            value={isEmpty(targetUser) ? null : targetUser}
          />
        )}
      </div>
      <div className="mb-4">
        {size(identities) > 1 && (
          <FormDropdown
            isDisabled={props.isAdmin && isEmpty(targetUser)}
            label={`Select a ${capitalizedPlatform} credential`}
            onSelection={option => {
              setIdentity(option);
              setAdAccount({});
              setCampaign({});
              props.clearTarget();
            }}
            options={identities.map(identity => ({ label: identity.name, value: identity.id }))}
            placeholder="Select a social account identity"
            value={isEmpty(identity) ? null : identity}
          />
        )}
      </div>
      {['snapchat', 'dv360'].includes(props.platform) && (
        <div className="mb-4">
          <FormDropdown
            isDisabled={
              (props.isAdmin && isEmpty(targetUser)) ||
              isLoadingOrganizations ||
              (size(identities) > 1 && isEmpty(identity))
            }
            label="Select an organization"
            onSelection={option => {
              setOrganization(option);
              setAdAccount({});
              setCampaign({});
              props.clearTarget();
            }}
            options={sortBy(
              organizations.map(organization => ({ label: organization.name, value: organization.id })),
              option => option.label.toLowerCase()
            )}
            placeholder={
              isLoadingOrganizations
                ? 'Loading organization information'
                : !isEmpty(identity) && isEmpty(organizations)
                  ? 'No organizations found for this platform'
                  : 'Select an organization'
            }
            value={isEmpty(organization) ? null : organization}
          />
        </div>
      )}
      <div className="mb-4">
        <FormDropdown
          isDisabled={
            isLoadingAdAccounts ||
            (props.platform !== 'snapchat' &&
              ((props.isAdmin && isEmpty(targetUser)) || (size(identities) > 1 && isEmpty(identity)))) ||
            (['snapchat', 'dv360'].includes(props.platform) && isEmpty(organization))
          }
          label="Select an ad account"
          onSelection={option => {
            setAdAccount(option);
            setCampaign({});
            props.clearTarget();
          }}
          options={sortBy(
            adAccounts.map(adAccount => ({ label: adAccount.name, value: adAccount.id })),
            option => option.label.toLowerCase()
          )}
          placeholder={
            isLoadingAdAccounts
              ? 'Loading ad account information'
              : !isEmpty(identity) && isEmpty(adAccounts)
                ? 'No accounts found for this platform'
                : 'Select an ad account'
          }
          value={isEmpty(adAccount) ? null : adAccount}
        />
      </div>
      <div className="mb-4">
        <FormDropdown
          isDisabled={isLoadingCampaigns || isEmpty(adAccount)}
          isOptionDisabled={option => option.expired || option.advertisingChannelType === 'VIDEO'}
          label="Select a campaign"
          onSelection={option => {
            setCampaign(option);
            props.clearTarget();
          }}
          options={sortBy(
            campaigns.map(campaign => ({ label: campaign.name, value: campaign.id })),
            option => option.label.toLowerCase()
          )}
          placeholder={isLoadingCampaigns ? 'Loading campaign information' : 'Select a campaign'}
          value={isEmpty(campaign) ? null : campaign}
        />
      </div>

      {['dv360'].includes(props.platform) && (
        <div className="mb-4">
          <FormDropdown
            isDisabled={isLoadingInsertionOrders || isEmpty(campaign)}
            label="Select an insertion order"
            onSelection={option => {
              setInsertionOrder(option);
              props.clearTarget();
            }}
            options={sortBy(
              insertionOrders.map(io => ({ label: io.name, value: io.id })),
              option => option.label.toLowerCase()
            )}
            placeholder={isLoadingInsertionOrders ? 'Loading insertion orders' : 'Select an IO'}
            value={isEmpty(insertionOrder) ? null : insertionOrder}
          />
        </div>
      )}

      {props.adAudienceId && (
        <div className="mb-4">
          {renderAdSetDropdown(props.platform, get(targetUser, 'value'), props.targetAdSet, props.setTarget)}
        </div>
      )}
      <hr />
      {props.shouldHideAdSetSelectionLabel && (
        <h2 className="font-semibold text-lg my-4">Match the audiences to the ad set you want to update:</h2>
      )}
      {props.adAudiences &&
        props.adAudiences.map((adAudience, targetIndex) => (
          <div
            className="mb-4 grid grid-cols-1 items-center"
            key={targetIndex}
          >
            <div className="font-medium">{adAudience.name}</div>
            <div>
              {renderAdSetDropdown(
                props.platform,
                get(targetUser, 'value'),
                props.targetAdSets[targetIndex],
                value =>
                  props.setTarget(props.targetAdSets.map((item, index) => (index === targetIndex ? value : item))),
                props.errors[targetIndex]
              )}
            </div>
          </div>
        ))}

      <Dialog
        close={() => props.setIsTokenInvalid(false)}
        hasNoButtons
        isOpen={props.isTokenInvalid}
        onSubmit={() => oAuthSignIn(props.platform, userInfo.uid, () => props.setIsTokenInvalid(false))}
        size={DialogSize.LARGE}
        submitTitle={`Connect to ${capitalizedPlatform}`}
        title={
          <div className="mb-4">
            <DialogIcon
              context="primary"
              icon="LightningBoltIcon"
              outline
            />
          </div>
        }
      >
        <h2 className="text-xl font-semibold text-gray-900">Reconnect your account</h2>
        <div className="text-gray-700 my-4">
          Your {capitalizedPlatform} Ads account needs to be reconnected so we can continue to update your campaigns.
        </div>
      </Dialog>
    </>
  );
}

AdSetSelector.propTypes = {
  adAudienceId: PropTypes.string,
  adAudiences: PropTypes.arrayOf(PropTypes.object),
  clearTarget: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  isAdmin: PropTypes.bool,
  isTokenInvalid: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  setIsTokenInvalid: PropTypes.func.isRequired,
  setTarget: PropTypes.func,
  shouldHideAdSetSelectionLabel: PropTypes.bool,
  targetAdSet: PropTypes.object,
  targetAdSets: PropTypes.arrayOf(PropTypes.object),
};
