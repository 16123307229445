import { get, sortBy } from 'lodash';
import { useContext, useState } from 'react';

import DeleteOrganizationMemberModal from './DeleteOrganizationMemberModal';
import { getOrganizationMembersTableColumns } from './organizationMembersTableColumns';

import Card from '@common/Card';
import Table from '@common/Table';
import { OrganizationDataContext } from '@hoc/withOrganizationData';
import { UserInfoContext } from '@hoc/withUserInfo';

export default function OrganizationMemberTable() {
  // acquire user and organization data
  const { userInfo } = useContext(UserInfoContext);
  const { organization } = useContext(OrganizationDataContext);

  // extract data
  const members = sortBy(
    get(organization, 'members', []),
    member => member.firstName ? member.firstName.toLowerCase() : member.email
  );
  const organizationCreatorId = get(organization, 'owner.id');
  const isUserOrganizationCreator = organizationCreatorId === userInfo.id;

  // state for member removal
  const [paramsOfDeleteOrganizationMemberModal, setParamsOfDeleteOrganizationMemberModal] = useState({});

  // render table
  return (
    <>
      <section>
        <Card title="Team Members">
          <Table
            columns={getOrganizationMembersTableColumns(
              organizationCreatorId,
              isUserOrganizationCreator,
              { openDeleteOrganizationMemberModal: member => setParamsOfDeleteOrganizationMemberModal({ member }) }
            )}
            data={members}
            pageSize={10000}
          />
        </Card>
      </section>

      <DeleteOrganizationMemberModal
        close={() => setParamsOfDeleteOrganizationMemberModal({})}
        params={paramsOfDeleteOrganizationMemberModal}
      />
    </>
  );
}
