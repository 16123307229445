import PropTypes from 'prop-types';
import { CheckIcon } from '@heroicons/react/outline';

const EXAMPLE_USERS = [
  {
    id: '8012096352',
    handle: 'Marjory19',
    email: 'Sandra_Stiedemann@gmail.com',
  },
  {
    id: '9756479035',
    handle: 'Dayna.Abbott',
    email: 'Tyrel44@hotmail.com',
  },
  {
    id: '2377560525',
    handle: 'Aileen_Parisian',
    email: 'Pansy.Kassulke63@yahoo.com',
  },
];

const TWITTER_VALIDATIONS = [
  'One single column',
  'No headers',
  'New line separated',
  'One type of data',
];
const EMAIL_VALIDATIONS = [...TWITTER_VALIDATIONS, 'At least 100 entries'];

export default function FileInfoColumn(props) {
  return (
    <div className="bg-blue-50 p-4 rounded-lg">
      <h2 className="text-lg font-medium text-blue-600">Your file should look like this</h2>
      <table className="w-full border bg-white border-gray-200">
        <thead className="border">
          <tr>
            <th className="border bg-gray-100 p-2" />
            <th className="border bg-gray-100 p-2">A</th>
            <th className="border bg-gray-100 p-2">B</th>
            <th className="border bg-gray-100 p-2">C</th>
          </tr>
        </thead>
        <tbody>
          {EXAMPLE_USERS.map((user, index) => (
            <tr key={index}>
              <td className="p-2 border font-semibold bg-gray-100">{index + 1}</td>
              <td className="p-2 border text-sm">{user[props.isEmail ? 'email' : 'handle']}</td>
              <td className="p-2 border" />
              <td className="p-2 border" />
            </tr>
          ))}
          <tr>
            <td className="p-2 font-semibold bg-gray-100">4</td>
            <td className="p-2 border">...</td>
            <td className="p-2 border" />
            <td className="p-2 border" />
          </tr>
        </tbody>
      </table>
      <dl className="mt-4">
        {(props.isEmail ? EMAIL_VALIDATIONS : TWITTER_VALIDATIONS).map((validation, index) => (
          <div
            className="relative"
            key={index}
          >
            <dt>
              <CheckIcon className="absolute h-6 w-6 text-green-500" />
              <p className="ml-9 text-base leading-6 text-gray-900">
                {validation}
              </p>
            </dt>
          </div>
        ))}
      </dl>
    </div>
  );
}

FileInfoColumn.propTypes = {
  isEmail: PropTypes.bool,
};
