import PropTypes from 'prop-types';

import BrandIcon from '@common/BrandIcon';

export default function SelectIdentityOption({ data, innerProps, isFocused }) {
  const searchOptionClassNames = [
    'bg-white',
    'cursor-pointer',
    'flex',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'hover:bg-gray-100',
    'items-center',
    'px-6',
    'py-5',
    'relative',
    'shadow-sm',
    'space-x-3',
  ];

  return (
    <div
      className={searchOptionClassNames.join(' ')}
      focused={isFocused ? 'true' : null}
      key={data.id}
      {...innerProps}
    >
      <div className="flex-shrink-0 mr-2">
        <BrandIcon
          brand={data.provider}
          size="small"
        />
      </div>
      <div className="flex-1 min-w-0">
        <span className="text-sm font-medium text-gray-900">
          {data.name}
        </span>
        <p className="text-sm text-gray-500 truncate">{data.email}</p>
      </div>
    </div>
  );
}

SelectIdentityOption.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    provider: PropTypes.string,
  }),
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
};
