import { UserCircleIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import getUserTableColumns from './userTableColumns';

import { updateUserRole, deleteUser, getUsers, resetUserPreferences } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import ConfirmDialog from '@common/ConfirmDialog';
import DeleteDialog from '@common/DeleteDialog';
import RemoteTable from '@common/RemoteTable';
import Section from '@common/Section';

export default function UserAdminSection() {
  // acquire history handler
  const history = useHistory();

  // modal state
  const refetch = useRef(() => true);
  const [paramsOfChangeRoleDialog, setParamsOfChangeRoleDialog] = useState({ isOpen: false });
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] = useState({ isOpen: false });
  const [paramsOfResetUserPreferencesDialog, setParamsOfResetUserPreferencesDialog] = useState({ isOpen: false });

  // render user admin section
  const columns = getUserTableColumns({
    openDeleteDialog: user => setParamsOfDeleteDialog({
      id: user.id,
      isOpen: true,
      name: user.name,
    }),
    openChangeRoleDialog: user => setParamsOfChangeRoleDialog({
      admin: user.admin,
      id: user.id,
      isOpen: true,
      name: user.name,
    }),
    openResetUserPreferencesDialog: user => setParamsOfResetUserPreferencesDialog({
      id: user.id,
      isOpen: true,
      name: user.name,
    }),
  });
  return (
    <>
      <Section
        heading={(
          <div className="flex justify-end">
            <Button
              kind={ButtonKind.PRIMARY}
              onClick={() => history.push('/admin/users/new')}
              title="New User"
            />
          </div>
        )}
        icon={UserCircleIcon}
        title="Users"
      >
        <RemoteTable
          columns={columns}
          entitiesName="users"
          isSearchable
          query={getUsers}
          queryKey="users"
          setRefetch={refetcher => {
            refetch.current = refetcher;
          }}
        />
      </Section>

      <ConfirmDialog
        close={() => setParamsOfResetUserPreferencesDialog({ isOpen: false })}
        message={params => (
          <>
            Are you sure you want to reset <span className="medium-font">{params.name}</span>&apos;s preferences?
          </>
        )}
        params={paramsOfResetUserPreferencesDialog}
        perform={params => resetUserPreferences(params.id)}
        title="Reset user preferences"
      />
      <ConfirmDialog
        close={() => setParamsOfChangeRoleDialog({ isOpen: false })}
        message={params => (
          <>
            Are you sure you want to {params.admin ? 'revoke' : 'grant'} the administrator role{' '}
            {params.admin ? 'from' : 'to'} <span className="medium-font">{params.name}</span>?
          </>
        )}
        onUpdate={refetch.current}
        params={paramsOfChangeRoleDialog}
        perform={params => updateUserRole(params.id, !params.admin)}
        title={params => params.admin ? 'Revoke Admin Role' : 'Grant Admin Role'}
      />
      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ isOpen: false })}
        delete={userId => deleteUser(userId)}
        entityName="user"
        onUpdate={refetch.current}
        params={paramsOfDeleteDialog}
      />
    </>
  );
}
