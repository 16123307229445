import { compact, flatten, isEmpty, isNil, map, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { SocialAccount, TopSocialAccount } from '@api/insightReport';
import { searchSocialInfluencers } from '@api/miscellaneous';
import { CommonDialogProps } from '@common/commonTypes';
import Dialog, { DialogSize } from '@common/Dialog';
import { FormAsyncDropdown, FormDropdown } from '@common/FormDropdowns';
import SelectSearchOption from '@common/reactSelect/SelectSearchOption';
import { SelectedValueWithIcon } from '@common/reactSelect/SelectValue';
import Table from '@common/Table';
import Search from '@common/table/Search';
import { getAffinityColumn, getSocialAccountNameColumn } from '@common/table/TableColumns';
import Tabs from '@common/Tabs';
import { Option, Platform } from '@utils/commonUtils';

const TABS = [
  { name: 'Search Twitter' },
  { name: 'Search Top Accounts' },
];
const ALL_TOPICS_OPTION: Option<string | undefined> = { label: 'All topics', value: undefined };
const TYPE_OPTIONS: Array<Option> = [
  { label: 'People', value: 'p' },
  { label: 'Organizations', value: 'n' },
];

type SearchTerm = { term: string, callback: Callback }
type Callback = (options: Array<SocialAccountOption>) => void;
type SocialAccountOption = SocialAccount & { value?: number };

export interface ReplaceSocialAccountDialogProps extends CommonDialogProps<ReplaceSocialAccountDialogParams> {
  mainSocialAccounts: Array<SocialAccount>;
  onSelect: (socialAccount: SocialAccount) => void;
  preferredType?: string;
  topSocialAccounts: Array<TopSocialAccount>;
}

export interface ReplaceSocialAccountDialogParams {
  index?: number;
}

export default function ReplaceSocialAccountDialog(props: ReplaceSocialAccountDialogProps): JSX.Element {
  // general state
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedSocialAccount, setSelectedSocialAccount] = useState<SocialAccountOption>();

  // state for the first tab
  const [search, setSearch] = useState<SearchTerm>();

  // state for the second tab
  const [searchTerm, setSearchTerm] = useState<string>();
  const [selectedTopic, setSelectedTopic] = useState(ALL_TOPICS_OPTION);
  const [selectedType, setSelectedType] = useState<Option<string>>();
  const [filteredSocialAccounts, setFilteredSocialAccounts] = useState<Array<SocialAccount>>([]);

  // reset state when dialog is opened
  useEffect(
    () => {
      if (props.params.isOpen) {
        setSelectedSocialAccount(undefined);
        setSearch(undefined);
        setSearchTerm(undefined);
        setSelectedTopic(ALL_TOPICS_OPTION);
        setSelectedType(!isNil(props.preferredType)
          ? TYPE_OPTIONS.find(option => option.value === props.preferredType)
          : undefined);
      }
    },
    [props.params.isOpen]
  );

  // refresh table when search criteria changed
  const mainSocialAccountIds = new Set(props.mainSocialAccounts.map(socialAccount => socialAccount.id));
  useEffect(
    () => {
      if (props.params.isOpen) {
        const updated = props.topSocialAccounts.filter(account =>
          !mainSocialAccountIds.has(account.id) &&
          (isNil(selectedTopic.value) || account.topics.includes(selectedTopic.value)) &&
          (isNil(selectedType) || account.type === selectedType.value) &&
          (isEmpty(searchTerm) || account.name.toLocaleLowerCase().includes(searchTerm!.toLocaleLowerCase()))
        );
        setFilteredSocialAccounts(updated);
      }
    },
    [props.params.isOpen, searchTerm, selectedTopic, selectedType]
  );

  // search Twitter accounts
  useQuery(
    ['searchSocialInfluencer', 'twitter', search?.term],
    () => searchSocialInfluencers(Platform.TWITTER, search!.term),
    {
      enabled: activeTabIndex === 0 && search !== undefined,
      onSuccess: response => {
        const filteredList = response.map(item => ({ ...item, value: item.id }))
          .filter(item => !mainSocialAccountIds.has(item.value));
        search?.callback(filteredList);
      },
    }
  );

  // get unique topics
  const topics = uniq(flatten(compact(map(props.topSocialAccounts, 'topics')))).sort();
  const topicOptions = topics.map(topic => ({ label: topic, value: topic }));

  // render dialog
  return (
    <Dialog
      close={props.close}
      isDisabled={selectedSocialAccount === undefined}
      isOpen={props.params.isOpen}
      onSubmit={() => props.onSelect(selectedSocialAccount!)}
      size={DialogSize.EXXXTRA_LARGE}
      submitTitle="Replace"
      title="Replace Account"
    >
      <Tabs
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        tabs={TABS}
      />
      {activeTabIndex === 0 && (
        <FormAsyncDropdown
          label="Search for a Twitter Account"
          loadOptions={(value: string, callback: Callback) => setSearch({ term: value, callback })}
          name="socialAccount"
          noOptionsMessage="No accounts found."
          onSelection={setSelectedSocialAccount}
          option={SelectSearchOption}
          placeholder="Search Twitter accounts by name or @handle..."
          singleValue={selectedSocialAccount && SelectedValueWithIcon}
          value={selectedSocialAccount ?? {}}
        />
      )}
      {activeTabIndex === 1 && (
        <>
          <div className="flex space-x-2">
            <div className="w-64">
              <Search
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </div>
            <div className="w-64">
              <FormDropdown
                onSelection={setSelectedTopic}
                options={topicOptions}
                value={selectedTopic}
              />
            </div>
            <div className="w-64">
              <FormDropdown
                isClearable
                onSelection={setSelectedType}
                options={TYPE_OPTIONS}
                placeholder="Account type"
                value={selectedType}
              />
            </div>
          </div>
          <Table
            columns={[
              getSocialAccountNameColumn(),
              getAffinityColumn(),
            ]}
            data={filteredSocialAccounts}
            initiallySortedBy="affinity"
            isInitiallySortedByDesc
            onRowClick={(rowId: string) => {
              setSelectedSocialAccount(filteredSocialAccounts[parseInt(rowId, 10)]);
              return true;
            }}
            pageSize={5}
          />
        </>
      )}
    </Dialog>
  );
}
