import { CollectionIcon } from '@heroicons/react/outline';
import { capitalize, get, size } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import getCampaignGroupTableColumns from './campaignGroupTableColumns';

import { getCampaignGroups as getAdminCampaignGroups } from '@api/admin';
import { archiveCampaignGroup, deleteCampaignGroup, getCampaignGroups } from '@api/campaignGroups';
import Button, { ButtonKind } from '@common/Button';
import ConfirmDialog from '@common/ConfirmDialog';
import DeleteDialog from '@common/DeleteDialog';
import { FormDropdown } from '@common/FormDropdowns';
import Placeholder from '@common/Placeholder';
import SelectOrganizationOption from '@common/reactSelect/SelectOrganizationOption';
import Section from '@common/Section';
import PlatformSelector from '@components/shared/PlatformSelector';
import RemoteTable from '@common/RemoteTable';
import { UserInfoContext } from '@hoc/withUserInfo';
import NoCampaignGroupsPlaceholder from '@images/no_campaign_groups.png';
import useViewState from '@utils/viewState';
import DownloadDocumentDialog from '@common/DownloadDocumentDialog';
import { getCampaignGroupExcelUrl } from '@api/analysis';

const EMOJIS = {
  warning: '⛔',
  monitor: '⚠️',
  awesome: '🔥',
  neutral: '',
};

function calculateSummary(item) {
  const performance = item.cprPerformance;
  let summary;
  if (performance > 0.50) {
    summary = 'warning';
  } else if (performance > 0) {
    summary = 'monitor';
  } else if (performance < -0.1) {
    summary = 'awesome';
  } else {
    summary = 'neutral';
  }
  return { ...item, summary };
}

export default function CampaignGroupSection(props) {
  // acquire history manager, user data and organization data
  const history = useHistory();
  const { userInfo: userData } = useContext(UserInfoContext);

  // view state
  const { setState, state } = useViewState('main', { status: { label: 'Active', value: 'active' } });

  // get table reference
  const tableRef = useRef({});

  // modal states
  const [paramsOfArchiveDialog, setParamsOfArchiveDialog] = useState({ isOpen: false });
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] = useState({ isOpen: false });
  const [paramsOfDownloadDialog, setParamsOfDownloadDialog] = useState({ isOpen: false });
  const [availableAdAccounts, setAvailableAdAccounts] = useState([]);
  const [availableOrganizations, setAvailableOrganizations] = useState([]);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [availableSummaries, setAvailableSummaries] = useState([]);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [isDataEmpty, setIsDataEmpty] = useState();

  // render section
  const columns = getCampaignGroupTableColumns(
    EMOJIS,
    props.isAdmin,
    history,
    props.isAdmin ? undefined : userData.id,
    props.isAdmin ? get(state.organization, 'value') : undefined,
    {
      openArchiveDialog: campaignGroup =>
        setParamsOfArchiveDialog({
          id: campaignGroup.id,
          isOpen: true,
          name: campaignGroup.name,
          status: campaignGroup.status,
        }),
      openDeleteDialog: campaignGroup =>
        setParamsOfDeleteDialog({ id: campaignGroup.id, isOpen: true, name: campaignGroup.name }),
      openDownloadDialog: campaignGroup =>
        setParamsOfDownloadDialog({ campaignGroupId: campaignGroup.id, isOpen: true }),
    }
  );
  return (
    <>
      <Section
        heading={
          <div className="flex justify-end">
            <Button
              kind={ButtonKind.PRIMARY}
              target={props.isAdmin ? '/admin/campaign_performance/new' : '/campaign_performance/new'}
              title="New Campaign Group"
            />
          </div>
        }
        icon={CollectionIcon}
        title="Campaign Performance"
      >
        {isDataEmpty === true ? (
          <Placeholder
            description="Monitor currently running campaigns and download report templates."
            placeholder={NoCampaignGroupsPlaceholder}
            title="Quickly check your performance"
          />
        ) : (
          <>
            <RemoteTable
              columns={columns}
              entitiesName="campaign groups"
              extra={
                <>
                  {props.isAdmin && (
                    <div className="w-48">
                      <FormDropdown
                        isClearable
                        isDisabled={isDataEmpty === undefined}
                        onSelection={option => setState({ ...state, organization: option })}
                        option={SelectOrganizationOption}
                        options={availableOrganizations}
                        placeholder="Organization"
                        value={state.organization}
                      />
                    </div>
                  )}

                  <div className="w-48">
                    <PlatformSelector
                      isDisabled={isDataEmpty === undefined}
                      onSelect={option => setState({ ...state, platform: option })}
                      platforms={availablePlatforms}
                      value={state.platform}
                    />
                  </div>
                  <div className="w-64">
                    <FormDropdown
                      isClearable
                      isDisabled={isDataEmpty === undefined}
                      onSelection={option => setState({ ...state, adAccount: option })}
                      options={availableAdAccounts}
                      placeholder="Ad account"
                      value={state.adAccount}
                    />
                  </div>
                  <div>
                    <FormDropdown
                      isClearable
                      isDisabled={isDataEmpty === undefined}
                      onSelection={option => setState({ ...state, summary: option })}
                      options={availableSummaries}
                      placeholder="Summary"
                      value={state.summary}
                    />
                  </div>
                  <div>
                    <FormDropdown
                      isClearable
                      isDisabled={isDataEmpty === undefined}
                      onSelection={option => setState({ ...state, status: option })}
                      options={availableStatuses}
                      placeholder="Status"
                      value={state.status}
                    />
                  </div>
                </>
              }
              filters={[
                { id: 'organization', value: get(state.organization, 'value') },
                { id: 'platform', value: get(state.platform, 'value') },
                { id: 'ad_account', value: get(state.adAccount, 'value') },
                { id: 'summary', value: get(state.summary, 'value') },
                { id: 'status', value: get(state.status, 'value') },
              ]}
              isSearchable
              query={(...args) => (props.isAdmin ? getAdminCampaignGroups : getCampaignGroups)(...args)
                .then(response => {
                  const {
                    payload: { adAccounts, data, organizations, platforms, statuses, summaries },
                    meta,
                  } = response;
                  setAvailableAdAccounts(
                    adAccounts.map(adAccount => ({ label: adAccount.name, value: adAccount.id }))
                  );
                  const organizationOptions = organizations.map(organization => ({
                    ...organization,
                    label: organization.name,
                    value: organization.id,
                  }));
                  setAvailableOrganizations(organizationOptions);
                  if (size(organizationOptions) === 1) {
                    setState({ ...state, organization: organizationOptions[0] });
                  }
                  setAvailablePlatforms(platforms);
                  setAvailableStatuses(statuses.map(status => ({ label: capitalize(status), value: status })));
                  setAvailableSummaries(summaries.map(summary =>
                    ({ label: EMOJIS[summary] + ' ' + capitalize(summary), value: summary }))
                  );
                  return { payload: data.map(calculateSummary), meta };
                })}
              queryKey="campaignGroups"
              ref={tableRef}
              sessionStorageKey="table"
              setIsDataEmpty={setIsDataEmpty}
            />
          </>
        )}
      </Section>

      <DownloadDocumentDialog
        close={() => setParamsOfDownloadDialog({ ...paramsOfDownloadDialog, isOpen: false })}
        params={paramsOfDownloadDialog}
        query={({ campaignGroupId }) => getCampaignGroupExcelUrl(campaignGroupId)}
        queryKey={({ campaignGroupId }) => ['campaignGroupExcelUrl', campaignGroupId]}
      />
      <ConfirmDialog
        close={() => setParamsOfArchiveDialog({ isOpen: false })}
        message={params => (
          <>
            Are you sure you want to {params.status === 'active' ? 'archive' : 'unarchive'} campaign group{' '}
            <span className="medium-font">{params.name}</span>?
          </>
        )}
        onUpdate={tableRef.current.refetch}
        params={paramsOfArchiveDialog}
        perform={params => archiveCampaignGroup(params.id, params.status === 'active')}
        title={`${paramsOfArchiveDialog.status === 'active' ? 'Archive' : 'Unarchive'} Campaign Group`}
      />
      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ isOpen: false })}
        delete={campaignGroupId => deleteCampaignGroup(campaignGroupId)}
        entityName="campaign group"
        onUpdate={tableRef.current.refetch}
        params={paramsOfDeleteDialog}
      />
    </>
  );
}

CampaignGroupSection.propTypes = {
  isAdmin: PropTypes.bool,
};
