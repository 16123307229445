import { getPersonaLibrary } from '@api/admin';
import { UserCircleIcon } from '@heroicons/react/outline';
import { sortBy } from 'lodash';
import { useQuery } from 'react-query';

// import Card from '@common/Card';
import Section from '@common/Section';
import Table from '@common/Table';

import getPersonaLibraryTableColumns from './personaLibraryTableColumns';

export default function PersonaLibrary() {
  // query persona library
  const { data: personas, isFetching: isLoadingPersonaLibrary } = useQuery(
    ['personaLibrary'],
    getPersonaLibrary,
    {
      initialData: [],
      select: data => sortBy(data, 'topic', 'subtopic', 'name'),
    }
  );

  const columns = getPersonaLibraryTableColumns({ isAdmin: true });

  return (
    <>
      <Section
        icon={UserCircleIcon}
        title="Persona Library"
      >
        <div>
          {personas.length > 0
            ? (
              <Table
                columns={columns}
                data={personas}
                entitiesName="personas"
                initiallySortedBy="topic"
                isLoading={isLoadingPersonaLibrary}
                isSearchable
                noDataMessage="No personas."
              />
            )
            : (
              <div>{isLoadingPersonaLibrary ? 'Retrieving personas...' : 'No personas.'}</div>
            )
          }
        </div>
      </Section>
    </>
  );
}
