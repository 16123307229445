import { capitalize, isEmpty, size, startCase } from 'lodash';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';

import defaultAvatar from '@images/default_avatar.png';
import female from '@images/female.png';
import male from '@images/male.png';
import { formatAgeRange } from '@utils/formattingUtils';
import { getTargetingItem, getTargetingItems } from '@utils/generalUtils';

/*function renderAccounts(title, accounts) {
  return (
    <div className="grid grid-cols-4">
      <div className="col-span-1">
        <h4 className="font-medium mb-2">
          {title}
        </h4>
      </div>
      <div className="col-span-3">
        <div className="grid grid-cols-2">
          {accounts.map((account, index) => {
            return (
              <div
                className="py-4 flex items-center"
                key={index}
              >
                <img
                  alt=""
                  className="h-8 w-8 rounded-full"
                  src={account.avatar}
                />
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    {truncate(account.name || account.fullName, { length: 25 })}
                  </p>
                  <p className="text-sm text-gray-500">
                    @{account.username}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}*/

function renderCountries(targeting) {
  const countries = (getTargetingItem(targeting, 'countries') || []).map(item => item.name);
  return !isEmpty(countries) && (
    <>
      <div>
        <h4 className="text-gray-900 font-medium mb-2">
          {pluralize('Country', size(countries))}
        </h4>
      </div>
      <div className="col-span-3">
        <p>
          {countries.join(', ')}
        </p>
      </div>
    </>
  );
}

function renderDemographics(targeting) {
  const parentalStatus = getTargetingItem(targeting, 'parentalStatus');
  const minAge = getTargetingItem(targeting, 'minAge');
  const maxAge = getTargetingItem(targeting, 'maxAge');

  // const languages = (getTargetingItem(targeting, 'languages') || []).map(country => country.name);
  return (
    <>
      <div>
        <span className="text-gray-900 font-medium mb-2">
          Demographics
        </span>
      </div>
      <div>
        {renderGender(getTargetingItem(targeting, 'gender'))}
      </div>
      <div>
        {minAge && renderTags('Age Range', [formatAgeRange(minAge, maxAge)])}
      </div>
      <div>
        {parentalStatus && (
          <>
            <h4 className="font-medium mb-2">
              Parental Status
            </h4>
            {startCase(parentalStatus)}
          </>
        )}
      </div>
      {/* <div>
        {languages && renderTags('Language', languages || [])}
      </div> */}
    </>
  );
}

function renderFacebookInterests(title, subtitle, items, key, deprecatedInterests) {
  return (
    <React.Fragment key={key}>
      <div>
        <h4 className="font-medium mb-2">
          {title}
        </h4>
      </div>
      <div className="col-span-3">
        <div>
          <p className="font-medium text-gray-900">
            {subtitle}
          </p>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {item.id in deprecatedInterests
                ? <span style={{ color: 'red' }}>{item.name}</span>
                : item.name}
              {index === size(items) - 1 ? '' : index === size(items) - 2 ? ' or ' : ', '}
            </React.Fragment>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

function renderGender(gender) {
  return (
    <>
      <h4 className="font-medium mb-2">Gender</h4>
      {(gender ? [gender] : ['male', 'female']).map(item => (
        <div
          className="mb-2"
          key={item}
        >
          {gender !== 'other' && (
            <img
              alt={item}
              className="inline w-8 h-8 rounded-full"
              src={item === 'male' ? male : item === 'female' ? female : defaultAvatar}
            />
          )}
          <span className="ml-2">
            {capitalize(item)}
          </span>
        </div>
      ))}
    </>
  );
}

function renderItems(title, items, key) {
  return !isEmpty(items) && (
    <React.Fragment key={key}>
      <div>
        <h4 className="text-gray-900 font-medium mb-2">
          {pluralize(title, size(items))}
        </h4>
      </div>
      <div className="col-span-3">
        {items.map(item => item.name).join(', ')}
      </div>
    </React.Fragment>
  );
}

function renderTags(title, tags) {
  return (
    <>
      <h4 className="font-medium mb-2">
        {title}
      </h4>
      {tags.map(tag => (
        <p key={tag}>
          {tag}
        </p>
      ))}
    </>
  );
}

function renderDv360Targeting(targeting) {
  return (
    <div className="gap-3 grid grid-cols-4">
      {renderDemographics(targeting)}
      {renderCountries(targeting)}
      {renderItems('Topics', getTargetingItem(targeting, 'topics'))}
      {renderItems('Google Audiences', getTargetingItem(targeting, 'googleAudiences'))}
      {renderItems('Interests', getTargetingItem(targeting, 'interests'))}
      {renderItems('Keywords', getTargetingItem(targeting, 'broadKeywords'))}
      {renderItems('URLs', getTargetingItem(targeting, 'urls'))}
    </div>
  );
}


function renderFacebookTargeting(targeting, deprecatedInterests) {
  const excludedInterests = (getTargetingItem(targeting, 'interests', false) || []);
  return (
    <div className="gap-3 grid grid-cols-4">
      {renderDemographics(targeting)}
      {renderCountries(targeting)}
      {getTargetingItems(targeting, 'interests').map((item, index) => {
        const label = index === 0 ? 'People who match' : 'And must also match';
        return renderFacebookInterests(label, 'Interests', item, index, deprecatedInterests);
      })}
      {!isEmpty(excludedInterests) &&
        renderFacebookInterests('Exclusions', 'Interests', excludedInterests, undefined, deprecatedInterests)}
    </div>
  );
}

function renderLinkedinTargeting(targeting) {
  return (
    <div className="gap-3 grid grid-cols-4">
      {renderDemographics(targeting)}
      {renderCountries(targeting)}
      {renderItems('Title', getTargetingItem(targeting, 'titles'))}
      {renderItems('Skill', getTargetingItem(targeting, 'skills'))}
    </div>
  );
}

function renderPinterestTargeting(targeting) {
  return (
    <div className="gap-3 grid grid-cols-4">
      {renderDemographics(targeting)}
      {renderCountries(targeting)}
      {renderItems('Topics', getTargetingItem(targeting, 'topics'))}
      {renderItems('Interests', getTargetingItem(targeting, 'interests'))}
    </div>
  );
}


function renderSnapchatTargeting(targeting) {
  return (
    <div className="gap-3 grid grid-cols-4">
      {renderDemographics(targeting)}
      {renderCountries(targeting)}
      {renderItems('Persona', getTargetingItem(targeting, 'personas'))}
    </div>
  );
}

function renderTikTokTargeting(targeting) {
  return (
    <div className="gap-3 grid grid-cols-4">
      {renderDemographics(targeting)}
      {renderCountries(targeting)}
      {renderItems('Topics', getTargetingItem(targeting, 'topics'))}
      {renderItems('Interests', getTargetingItem(targeting, 'interests'))}
      {renderItems('Creator Interactions', getTargetingItem(targeting, 'creatorCategories'))}
      {renderItems('Video Interactions', getTargetingItem(targeting, 'videoCategories'))}
      {renderItems('Hashtag Interactions', getTargetingItem(targeting, 'hashtags'))}
    </div>
  );
}

function renderTwitterTargeting(targeting) {
  return (
    <div className="gap-3 grid grid-cols-4">
      {renderDemographics(targeting)}
      {renderCountries(targeting)}
      {renderItems('Interests', getTargetingItem(targeting, 'interests'))}
      {renderItems('Conversations', getTargetingItem(targeting, 'conversations'))}
      {renderItems('Events', getTargetingItem(targeting, 'events'))}
      {renderItems('Broad keywords', getTargetingItem(targeting, 'broadKeywords'))}
      {renderItems('Phrase keywords', getTargetingItem(targeting, 'phraseKeywords'))}
      {renderItems('Topics', getTargetingItem(targeting, 'topics'))}
    </div>
  );
}

export default function Targeting(props) {
  switch (props.platform) {
    case 'dv360':
      return renderDv360Targeting(props.targeting);
    case 'facebook':
      return renderFacebookTargeting(props.targeting, props.deprecatedInterests);
    case 'linkedin':
      return renderLinkedinTargeting(props.targeting);
    case 'pinterest':
      return renderPinterestTargeting(props.targeting);
    case 'snapchat':
      return renderSnapchatTargeting(props.targeting);
    case 'tiktok':
      return renderTikTokTargeting(props.targeting);
    case 'twitter':
      return renderTwitterTargeting(props.targeting);
    default:
      return null;
  }
}

Targeting.propTypes = {
  deprecatedInterests: PropTypes.object,
  platform: PropTypes.string.isRequired,
  targeting: PropTypes.object.isRequired,
};
