import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

const DEFAULT_CLASSES = [
  'cursor-default',
  'font-medium',
  'inline-flex',
  'items-center',
  'rounded-full',
  'whitespace-nowrap',
];
const SMALL_CLASSES = ['px-2.5', 'py-0.5', 'text-xs'];
const REGULAR_CLASSES = ['px-3', 'py-1', 'text-sm'];
const LEFT_MARGIN_MODIFIERS = ['ml-1'];
const CLICK_MODIFIERS = ['cursor-pointer'];

export enum BadgeContext {
  DANGEROUS = 'dangerous',
  HIGHLIGHT = 'highlight',
  REGULAR = 'regular',
  SUCCESS = 'success',
  WARNING = 'warning',
}

const CONTEXT_CLASSES: Record<BadgeContext, Array<string>> = {
  [BadgeContext.DANGEROUS]: ['bg-red-100', 'text-red-800', 'hover:text-red-900'],
  [BadgeContext.HIGHLIGHT]: ['bg-pink-100', 'text-pink-700', 'hover:text-pink-800'],
  [BadgeContext.REGULAR]: ['bg-gray-100', 'text-gray-800', 'hover:text-gray-900'],
  [BadgeContext.SUCCESS]: ['bg-green-100', 'text-green-800', 'hover:text-green-900'],
  [BadgeContext.WARNING]: ['bg-yellow-100', 'text-yellow-800', 'hover:text-yellow-900'],
};

export interface BadgeProps {

  /** Badge text. Either this or <code>text</code> should be specified. */
  children?: ReactNode,

  /** Badge color. */
  color?: string;

  /** Badge context. */
  context?: BadgeContext;

  /** Tutorial ID */
  'data-tut'?: string;

  /** True if add a margin to the left. */
  hasLeftMargin?: boolean;

  /** True if add a close button. */
  isRemovable?: boolean;

  /** Kind of the badge. */
  kind?: BadgeKind;

  /** Function to call when the badge is clicked. <code>kind</code> must be REGULAR. */
  onClick?: () => void;

  /** Badge size. */
  size?: BadgeSize;

  /** Path or URL to navigate to when the badge is clicked. <code>kind</code> must be LINK. */
  target?: string;

  /** Badge text. Either this or <code>children</code> should be specified. */
  text?: string,
}

export enum BadgeKind {
  LINK = 'link',
  REGULAR = 'regular',
}

export enum BadgeSize {
  SMALL = 'small',
  REGULAR = 'regular',
}

export default function Badge(props: BadgeProps): JSX.Element {
  // determine context classes
  const contextClasses = CONTEXT_CLASSES[props.context ?? BadgeContext.REGULAR];

  // determine classes
  const classes = [...DEFAULT_CLASSES, ...contextClasses];
  classes.push(...(props.size === BadgeSize.SMALL ? SMALL_CLASSES : REGULAR_CLASSES));
  if (props.hasLeftMargin) {
    classes.push(...LEFT_MARGIN_MODIFIERS);
  }
  if (props.target !== undefined || props.onClick !== undefined) {
    classes.push(...CLICK_MODIFIERS);
  }

  // render badge
  return props.kind === BadgeKind.LINK ? (
    <Link
      className={classes.join(' ')}
      to={{ pathname: props.target }}
    >
      {props.text ?? props.children}
    </Link>
  ) : (
    <span
      className={classes.join(' ')}
      data-tut={props['data-tut']}
      onClick={props.onClick}
      style={{ color: props.color }}
    >
      {props.text ?? props.children}
      {props.isRemovable && <span className={`ml-2 ${contextClasses.join(' ')}`}>x</span>}
    </span>
  );
}
