import PropTypes from 'prop-types';

import Button, { ButtonKind } from '@common/Button';

export default function Pagination(props) {
  return (
    <div className="bg-white rounded-b-lg px-4 py-3 flex items-center justify-between border-t border-gray-200">
      <div>
        Showing <span className="font-medium">{props.pageIndex * props.pageSize + 1}</span> to{' '}
        <span className="font-medium">
          {Math.min((props.pageIndex + 1) * props.pageSize, props.totalRowCount)}
        </span> of{' '}
        <span className="font-medium">{props.totalRowCount.toLocaleString()}</span> results
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <Button
          isDisabled={!props.hasPreviousPage}
          kind={ButtonKind.REGULAR}
          onClick={props.previousPage}
          size="small"
          title="Previous"
        />
        <Button
          hasLeftMargin
          isDisabled={!props.hasNextPage}
          kind={ButtonKind.REGULAR}
          onClick={props.nextPage}
          size="small"
          title="Next"
        />
      </div>
    </div>
  );
}

Pagination.propTypes = {
  hasNextPage: PropTypes.bool.isRequired,
  hasPreviousPage: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  totalRowCount: PropTypes.number.isRequired,
};
