import COLORS from '@utils/colorsUtils';

export const selectStyles = {
  clearIndicator: (base, state) => ({
    ...base,
    fontSize: '10px',
    cursor: 'pointer',
    color: state.isFocused ? COLORS.grey[500] : COLORS.grey[400],
  }),
  control: (base, state) => ({
    ...base,
    '*': {
      boxShadow: 'none !important',
    },
    border: `1px solid ${COLORS.grey[300]}`,
    borderRadius: '0.375rem',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    width: 0,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  multiValue: (base, { data }) => ({
    ...base,
    backgroundColor: data.isDangerous ? COLORS.red[100] : COLORS.grey[100],
    color: COLORS.grey[700],
    fontSize: '16px',
    stroke: 'none',
  }),
  multiValueRemove: styles => ({
    ...styles,
    cursor: 'pointer',
    color: COLORS.grey[400],
    ':hover': {
      color: COLORS.white,
    },
  }),
  placeholder: styles => ({ ...styles, color: COLORS.grey[400] }),
};
