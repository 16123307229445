import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import getAgeByGenderChartConfig from './ageByGenderChartConfig';
import getPercentageTableColumns from './percentageTableColumns';

import Card from '@common/Card';
import Chart from '@common/Chart';
import Table from '@common/Table';
import female from '@images/female.png';
import male from '@images/male.png';

function renderDemographicsTable(title, data) {
  const formatted = data.slice(0, 10)
    .map(data => data
      ? { name: data[0], value: data[data.length - 1], extra: data[1] }
      : { name: '', value: 0, extra: '' }
    );
  return (
    <div>
      {!isEmpty(formatted) && (
        <>
          <h3 className="mb-2 text-base font-semibold">{title}</h3>
          <Table
            columns={getPercentageTableColumns(null, 'flag')}
            data={formatted.filter(item => item.value.toFixed(1) > 0.5)}
            isHeaderless
          />
        </>
      )}
    </div>
  );
}

export default function SummaryDemographics(props) {
  // prepare data
  const genderData = (isEmpty(props.genders) ? [['All Genders', 100]] : props.genders)
    .map(row => ({ name: row[0], value: row[1], extra: row[0] === 'Male' ? male : female }));
  const ageByGenderData = [props.ageByGender['male'], props.ageByGender['female']];

  // render demographic section
  return (
    <Card
      subtitle="What is the makeup of this audience?"
      title="Demographics"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-4">
          <div className="mb-8">
            <h3 className="text-base font-semibold">
              Gender
            </h3>
            <Table
              columns={getPercentageTableColumns(null, genderData[0].name !== 'All Genders' ? 'image' : null)}
              data={genderData}
              isHeaderless
            />
          </div>
          {!isEmpty(props.ageByGender) && (
            <div className="mt-4">
              <h3 className="text-base font-semibold">
                Age Range
              </h3>
              <Chart config={getAgeByGenderChartConfig(ageByGenderData)} />
            </div>
          )}
        </div>
        <div className="col-span-8">
          <div className="grid grid-cols-3 gap-4">
            {renderDemographicsTable('Countries', props.countries)}
            {renderDemographicsTable('Regions', props.regions)}
            {renderDemographicsTable('Cities', props.cities)}
          </div>
        </div>
      </div>
    </Card>
  );
}

SummaryDemographics.propTypes = {
  ageByGender: PropTypes.object.isRequired,
  cities: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  genders: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
};
