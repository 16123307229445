import { CheckIcon, PlusIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import Tippy from '@tippyjs/react';

import { getAdAudience } from '@api/adAudience';
import { addPersonaLibraryAudience, requestPersonaAudience } from '@api/admin';
import BrandIcon, { BrandIconSize } from '@common/BrandIcon';
import { GenericCell } from '@common/table/TableCells';
import AudiencePlaceholder from '@images/audience_placeholder.png';
import { formatCount } from '@utils/formattingUtils';
import { notifySuccess } from '@utils/toaster';

const PlatformAudienceCell = ({ isAdmin, platform, personaId, personaName, audienceId }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [requested, setRequested] = useState(false);

  const prefix = isAdmin ? '/admin/' : '/';
  const platformIcon = platform === 'Meta' ? 'facebook' : platform.toLowerCase();

  // Format interests criteria
  const formatExampleTargeting = targeting => {
    const interests = targeting.find(t => {
      return 'interests' in t;
    });

    const exampleInterests = interests['interests'].map(i => {
      return i.name;
    });

    return exampleInterests.slice(0, 10).join(', ');
  };

  // Get ad audience info
  const { data: adAudience, isFetching: isLoadingAdAudience, refetch: refetchAdAudience } = useQuery(
    ['audience', audienceId],
    () => getAdAudience(audienceId),
    {
      initialData: { targeting: {} },
      retry: false,
      enabled: false,
    }
  );

  // Add Persona Audience to Audience Library
  const addPersonaAudienceToLibrary = useMutation(
    () => addPersonaLibraryAudience(audienceId),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Audience added to your library' });
      },
    }
  );

  // Send a persona audience request
  const personaAudienceRequest = useMutation(
    () => requestPersonaAudience(personaId, platform),
    {
      onSuccess: () => {
        notifySuccess({ title: `${platform} audience requested! We'll be in touch shortly.` });
      },
    }
  );

  if (isAdmin && audienceId === 0) {
    return null;
  }

  if (isAdmin) {
    return (
      <Link
        className="flex items-center justify-center"
        title="View Audience"
        to={`${prefix}audiences/${audienceId}/`}
      >
        <CheckIcon className="h-5 w-5 text-green-500" />
      </Link>
    );
  }

  if (audienceId === 0) {
    return (
      <span
        className={
          `${requested
            ? 'text-green-600 hover:text-green-600'
            : 'text-indigo-200 hover:text-indigo-700'} text-xs cursor-pointer`
        }
        onClick={() => {
          setRequested(true);
          personaAudienceRequest.mutate();
        }}
      >
        {requested ? 'Requested' : 'Request'}
      </span>
    );
  }

  const platformButton = isHovered ? (
    <div
      className="inline cursor-pointer"
      onClick={() => addPersonaAudienceToLibrary.mutate()}
    >
      <PlusIcon className="h-3 w-3 text-green-500 inline" />
      <span className="text-xs text-green-500">Add</span>
    </div>
  ) : (
    <BrandIcon
      brand={platformIcon}
      size={BrandIconSize.SMALL}
    />
  );


  return (
    <div
      className="flex items-center justify-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Tippy
        content={
          isLoadingAdAudience ? (
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <h2 className="text-lg">Retrieving audience data...</h2>
            </div>
          ) : (
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
              <dt>
                <div className="rounded-md">
                  <BrandIcon
                    brand={platformIcon}
                    size={BrandIconSize.REGULAR}
                  />
                </div>
                <p className="my-4 text-xl font-semibold tracking-tight text-gray-900">
                  {personaName}
                </p>
              </dt>

              {adAudience.minimalAudienceSize !== 0 && (
                <>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
                    {formatCount(adAudience.minimalAudienceSize)} - {formatCount(adAudience.maximalAudienceSize)}
                  </dd>
                  <dt className="truncate text-sm font-medium text-gray-500">people in audience</dt>
                </>
              )}

              <p className="mt-4">
                <span className="text-gray-500 font-medium">Example interests include:</span><br />
                {adAudience.unifiedTargeting && formatExampleTargeting(adAudience.unifiedTargeting.include)}
              </p>
            </div>
          )
        }
        onShow={() => {
          refetchAdAudience();
        }}
      >
        {platformButton}
      </Tippy>
    </div>
  );
};

PlatformAudienceCell.propTypes = {
  audienceId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  personaId: PropTypes.number.isRequired,
  personaName: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
};

export default function getPersonaLibraryTableColumns({ isAdmin }) {
  const prefix = isAdmin ? '/admin/' : '/';

  /* eslint-disable react/prop-types */
  return [
    {
      accessor: 'topic',
      alignment: 'left',
      Header: 'TOPIC',
      Cell: ({ row: { original } }) => (
        <p className="text-xs">{original.topic}</p>
      ),
    },
    {
      accessor: 'subtopic',
      alignment: 'left',
      Header: 'SUBTOPIC',
      Cell: ({ row: { original } }) => (
        <p className="text-xs">{original.subtopic}</p>
      ),
    },
    {
      accessor: 'name',
      alignment: 'left',
      Header: 'PERSONA',
      Cell: ({ row: { original } }) => (
        <GenericCell
          image={original.avatar?.default ?? AudiencePlaceholder}
        >
          <Tippy content={
            <div className="overflow-hidden rounded-lg bg-white px-2 py-2 shadow">
              <h2 className="text-xs">Click to view insight in a new tab.</h2>
            </div>
          }
          >
            <Link
              className="text-indigo-600 hover:text-indigo-900"
              target="_blank"
              to={`${prefix}insight_reports/${original.insightReportId}/`}
            >
              {original.name}
            </Link>
          </Tippy>
        </GenericCell>

      ),
    },
    {
      accessor: 'facebookAdAudienceId',
      alignment: 'center',
      Header: 'META',
      Cell: ({ row: { original } }) => (
        <PlatformAudienceCell
          audienceId={original.facebookAdAudienceId}
          isAdmin={isAdmin}
          personaId={original.id}
          personaName={original.name}
          platform="Meta"
        />
      ),
    },
    {
      accessor: 'dv360AdAudienceId',
      alignment: 'center',
      Header: 'DV360',
      Cell: ({ row: { original } }) => (
        <PlatformAudienceCell
          audienceId={original.dv360AdAudienceId}
          isAdmin={isAdmin}
          personaId={original.id}
          personaName={original.name}
          platform="DV360"
        />
      ),
    },
    {
      accessor: 'tiktokAdAudienceId',
      alignment: 'center',
      Header: 'TikTok',
      Cell: ({ row: { original } }) => (
        <PlatformAudienceCell
          audienceId={original.tiktokAdAudienceId}
          isAdmin={isAdmin}
          personaId={original.id}
          personaName={original.name}
          platform="TikTok"
        />
      ),
    },
    {
      accessor: 'twitterAdAudienceId',
      alignment: 'center',
      Header: 'TWITTER',
      Cell: ({ row: { original } }) => (
        <PlatformAudienceCell
          audienceId={original.twitterAdAudienceId}
          isAdmin={isAdmin}
          personaId={original.id}
          personaName={original.name}
          platform="Twitter"
        />
      ),
    },
    {
      accessor: 'pinterestAdAudienceId',
      alignment: 'center',
      Header: 'PINTEREST',
      Cell: ({ row: { original } }) => (
        <PlatformAudienceCell
          audienceId={original.pinterestAdAudienceId}
          isAdmin={isAdmin}
          personaId={original.id}
          personaName={original.name}
          platform="Pinterest"
        />
      ),
    },
  ];
  /* eslint-enable react/prop-types */
}
