import { ReactComponent as DuckDuckGoIcon } from '@images/brands/duckduckgo.svg';
import { ReactComponent as Dv360Icon } from '@images/brands/dv360.svg';
import { ReactComponent as MetaIcon } from '@images/brands/meta.svg';
import { ReactComponent as GoogleIcon } from '@images/brands/google.svg';
import { ReactComponent as HubSpotIcon } from '@images/brands/hubspot.svg';
import { ReactComponent as InstagramIcon } from '@images/brands/instagram.svg';
import { ReactComponent as LinkedInIcon } from '@images/brands/linkedin.svg';
import { ReactComponent as MailchimpIcon } from '@images/brands/mailchimp.svg';
import { ReactComponent as PinterestIcon } from '@images/brands/pinterest.svg';
import { ReactComponent as RedditIcon } from '@images/brands/reddit.svg';
import { ReactComponent as ShopifyIcon } from '@images/brands/shopify.svg';
import { ReactComponent as SnapchatIcon } from '@images/brands/snapchat.svg';
import { ReactComponent as SpotifyIcon } from '@images/brands/spotify.svg';
import { ReactComponent as TikTokIcon } from '@images/brands/tiktok.svg';
import { ReactComponent as TmdbIcon } from '@images/brands/themoviedatabase.svg';
import { ReactComponent as TwitchIcon } from '@images/brands/twitch.svg';
import { ReactComponent as TwitterIcon } from '@images/brands/twitter.svg';
import { ReactComponent as YoutubeIcon } from '@images/brands/youtube.svg';
import { FunctionComponent, SVGAttributes } from 'react';

export enum BrandIconBrand {
  DUCKDUCKGO = 'duckduckgo',
  DV360 = 'dv360',
  FACEBOOK = 'facebook',
  META = 'meta',
  GOOGLE = 'google',
  HUBSPOT = 'hubspot',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  MAILCHIMP = 'mailchimp',
  PINTEREST = 'pinterest',
  REDDIT = 'reddit',
  SHOPIFY = 'shopify',
  SNAPCHAT = 'snapchat',
  SPOTIFY = 'spotify',
  TIKTOK = 'tiktok',
  TMDB = 'tmdb',
  TWITCH = 'twitch',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

type IconDescriptor = {
  svg: FunctionComponent<SVGAttributes<SVGElement>>;
  fill: string;
  stroke?: string;
}

const BRAND_ICONS: { [key in BrandIconBrand]: IconDescriptor } = {
  [BrandIconBrand.DUCKDUCKGO]: { svg: DuckDuckGoIcon, fill: '#DE5833' },
  [BrandIconBrand.DV360]: { svg: Dv360Icon, fill: '#2CA455' },
  [BrandIconBrand.FACEBOOK]: { svg: MetaIcon, fill: '#1877F2' },
  [BrandIconBrand.META]: { svg: MetaIcon, fill: '#1877F2' },
  [BrandIconBrand.GOOGLE]: { svg: GoogleIcon, fill: '#2CA455' },
  [BrandIconBrand.HUBSPOT]: { svg: HubSpotIcon, fill: '#FF7A59' },
  [BrandIconBrand.INSTAGRAM]: { svg: InstagramIcon, fill: '#E4405F' },
  [BrandIconBrand.LINKEDIN]: { svg: LinkedInIcon, fill: '#0A66C2' },
  [BrandIconBrand.MAILCHIMP]: { svg: MailchimpIcon, fill: '#FFE01B' },
  [BrandIconBrand.PINTEREST]: { svg: PinterestIcon, fill: '#2CA455' },
  [BrandIconBrand.REDDIT]: { svg: RedditIcon, fill: '#FF4500' },
  [BrandIconBrand.SHOPIFY]: { svg: ShopifyIcon, fill: '#7AB55C' },
  [BrandIconBrand.SNAPCHAT]: { svg: SnapchatIcon, fill: 'white', stroke: 'black' },
  [BrandIconBrand.SPOTIFY]: { svg: SpotifyIcon, fill: '#1ED760' },
  [BrandIconBrand.TIKTOK]: { svg: TikTokIcon, fill: '#000000' },
  [BrandIconBrand.TMDB]: { svg: TmdbIcon, fill: '#01D277' },
  [BrandIconBrand.TWITCH]: { svg: TwitchIcon, fill: '#9146FF' },
  [BrandIconBrand.TWITTER]: { svg: TwitterIcon, fill: '#1DA1F2' },
  [BrandIconBrand.YOUTUBE]: { svg: YoutubeIcon, fill: '#FF0000' },
};

export interface BrandIconProps {

  /** Brand icon. */
  brand: BrandIconBrand;

  /** Tutorial ID. */
  'data-tut'?: string;

  /** True if add right margin. */
  hasRightMargin?: boolean;

  /** Brand icon size. */
  size?: string;
}

export enum BrandIconSize {
  REGULAR = 'regular',
  SMALL = 'small',
}

export default function BrandIcon(props: BrandIconProps): JSX.Element {
  const icon = BRAND_ICONS[props.brand];
  const Icon = icon.svg;
  const classNames = [
    (props.size ?? BrandIconSize.REGULAR) === BrandIconSize.REGULAR ? 'h-8 w-8' : 'h-5 w-5',
    ...(props.hasRightMargin ? ['mr-3'] : []),
    'inline-block',
  ];
  return (
    <Icon
      className={classNames.join(' ')}
      data-tut={props['data-tut']}
      style={{ fill: icon.fill, stroke: icon.stroke }}
    />
  );
}
