import { camelizeKeys, decamelize, decamelizeKeys } from 'humps';

import config from '@root/config';

import { retrieveCredentials } from './credentials';
import { isNil } from 'lodash';

export async function issueRequest<T = void>(url: string, options?: HttpOptions | undefined): Promise<Response<T>> {
  // handle API calls
  let updatedUrl = url;
  const headers: Record<string, string> = {};
  if (url.startsWith('/')) {
    updatedUrl = `${config.API_URL}${url}`;
    const credentials = retrieveCredentials();
    if (!isNil(credentials)) {
      headers['access-token'] = credentials.accessToken;
      headers['client'] = credentials.client;
      headers['expiry'] = credentials.expiry.toString();
      headers['uid'] = credentials.uid;
      headers['resource-class'] = 'user';
      headers['token-type'] = 'Bearer';
    }
  }

  // add query parameters
  if (!isNil(options) && !isNil(options.queryParams)) {
    const encodedParams = Object.entries(options.queryParams)
      .filter((item): item is [key: string, value: number | string] => !isNil(item[1]))
      .map(([key, value]) => [decamelize(key), value.toString()]);
    updatedUrl += '?' + new URLSearchParams(encodedParams).toString();
  }

  // add headers
  if (!isNil(options) && !isNil(options.headers)) {
    Object.assign(headers, options?.headers);
  }

  // prepare request body
  let body: FormData | string | undefined;
  if (!isNil(options) && !isNil(options.body)) {
    if (options.sendAsForm) {
      body = new FormData();
      for (const [key, value] of Object.entries(options.body)) {
        let stringified: string;
        if (typeof value === 'string') {
          stringified = value;
        } else if (typeof value === 'object') {
          stringified = JSON.stringify(decamelizeKeys(value as object));
        } else {
          stringified = JSON.stringify(value);
        }
        body.append(key, stringified);
      }
    } else {
      body = JSON.stringify(decamelizeKeys(options.body));
      headers['Content-Type'] = 'application/json';
    }
  }

  // initiate request
  const fetchOptions: RequestInit = { body, headers, method: options?.method, signal: options?.signal };
  const response = await fetch(updatedUrl, fetchOptions);
  if (!response.ok) {
    if (response.status === 404 && !isNil(options) && !isNil(options.notFoundSurrogate)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return Promise.resolve({ payload: options.notFoundSurrogate, meta: {} });
    }
    const payload: unknown = await response.json();
    return Promise.reject({ status: response.status, payload });
  }

  // parse and return response
  let meta: Record<string, number | string> = {};
  if (!isNil(options) && !isNil(options.extractHeaders)) {
    const responseHeaders = Object.fromEntries(response.headers.entries());
    meta = options.extractHeaders(responseHeaders);
  }
  if (options?.hasEmptyResponse) {
    return { payload: undefined as unknown as T, meta };
  }
  let payload: T;
  if (response.headers.get('Content-Type')?.includes('application/json')) {
    const rawPayload: unknown = await response.json();
    payload = camelizeKeys(rawPayload as object) as unknown as T;
  } else {
    payload = await response.blob() as unknown as T;
  }
  return { payload, meta };
}

type PagedResultsFn<T> = {
  (
    pageIndex: number,
    globalFilter: string,
    filters?: Array<Filter>,
    sortBy?: string,
    sortDescending?: boolean
  ): Promise<Response<Array<T>>>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPagedResults<T>(path: string, extraParams: Record<string, any> = {}): PagedResultsFn<T> {
  return async (
    pageIndex: number,
    globalFilter: string,
    filters?: Array<Filter>,
    sortBy?: string,
    sortDescending?: boolean
  ) => {
    const filterParams = !isNil(filters)
      ? Object.fromEntries(filters.map(filter => [`column_${filter.id}`, filter.value]))
      : {};
    return issueRequest<Array<T>>(
      path,
      {
        extractHeaders: headers => ({
          pageItems: headers['page-items'],
          totalCount: headers['total-count'],
          totalPages: headers['total-pages'],
        }),
        queryParams: {
          page: pageIndex + 1,
          q: globalFilter,
          sort_column: sortBy,
          sort_direction: !isNil(sortBy) ? (sortDescending ? 'desc' : 'asc') : undefined,
          ...filterParams,
          ...extraParams,
        },
      }
    );
  };
}

export interface HttpOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: { [key: string]: any };
  extractHeaders?: (headers: Record<string, string>) => Record<string, number | string>;
  hasEmptyResponse?: boolean;
  headers?: Record<string, string>,
  method?: Method;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notFoundSurrogate?: any;
  queryParams?: Record<string, number | string | undefined>;
  sendAsForm?: boolean;
  signal?: AbortSignal;
}

export enum Method {
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT'
}

export interface Filter {
  id: string;
  value: string;
}

export interface Response<T> {
  payload: T;
  meta: Record<string, number | string>;
}

export interface ErrorResponse {
  message: string;
}
