import { isEmpty, isNil, size } from 'lodash';
import { CellProps, Column } from 'react-table';

import Badge, { BadgeContext, BadgeKind, BadgeSize } from '@common/Badge';
import BrandIcon, { BrandIconBrand, BrandIconSize } from '@common/BrandIcon';
import Button, { ButtonKind } from '@common/Button';
import ProgressBar from '@common/ProgressBar';
import { CellWithAvatar, GenericCell } from '@common/table/TableCells';
import Truncated from '@common/Truncated';
import InsightReportInputLabels from '@components/shared/InsightReportInputLabels';
import TargetAudienceCriteriaLabels from '@components/shared/TargetAudienceCriteriaLabels';
import { ToolTipType } from '@hoc/withToolTip';
import AudiencePlaceholder from '@images/audience_placeholder.png';
import { getUserAvatar } from '@utils/avatar';
import { UserAccount, InsightReport, TargetAudience } from '@utils/commonUtils';
import { formatMonthDayYear } from '@utils/formattingUtils';
import { getUserName } from '@utils/generalUtils';

const AUDIENCE_NAME_CUTOFF = 60;

export function getAdminCreatorColumn<D extends { creator: UserAccount }>(): Column<D> {
  return {
    accessor: 'creator',
    alignment: 'left',
    disableSortBy: true,
    Cell: ({ cell: { value } }: CellProps<D>) => {
      const creator = value as UserAccount;
      return (
        <GenericCell
          description={
            <Button
              kind={ButtonKind.LINK}
              target={`/admin/organizations/${creator.organization.id}`}
            >
              {creator.organization.name}
            </Button>
          }
          image={getUserAvatar(creator)}
        >
          <Button
            kind={ButtonKind.LINK}
            target={`/admin/users/${creator.id}`}
          >
            {getUserName(creator)}
          </Button>
        </GenericCell>
      );
    },
    cellWidth: '200px',
    Header: 'CREATOR',
  };
}

export function getAffinityColumn<D extends { affinity?: number; }>(): Column<D> {
  return {
    accessor: 'affinity',
    alignment: 'right',
    Cell: ({ cell: { value } }: CellProps<D>) => (
      <>
        {value !== undefined ? (value as number).toLocaleString() + 'x' : ''}
      </>
    ),
    Header: 'AFFINITY',
  };
}

export function getAudienceSizeColumn<
  D extends { maximalAudienceSize?: number, minimalAudienceSize?: number }
>(): Column<D> {
  function getAudienceSize(row: { maximalAudienceSize?: number, minimalAudienceSize?: number }): number {
    return isNil(row.minimalAudienceSize) ? 0 : Math.floor((row.minimalAudienceSize + row.maximalAudienceSize!) / 2);
  }

  return {
    accessor: row => row,
    alignment: 'right',
    cellWidth: '250px',
    Cell: ({ row: { original } }: CellProps<D>) => (
      <>
        {original.minimalAudienceSize === 0 ? 'N/A' : getAudienceSize(original).toLocaleString()}
      </>
    ),
    Header: 'AUDIENCE SIZE',
    id: 'minimal_audience_size',
    sortType: (first, second) => getAudienceSize(first.original) - getAudienceSize(second.original),
  };
}

export function getCreatorAvatarColumn<D extends { creator: UserAccount }>(): Column<D> {
  return {
    accessor: 'creator',
    alignment: 'center',
    disableSortBy: true,
    Cell: ({ cell: { value } }: CellProps<D>) => {
      const creator = value as UserAccount;
      return (
        <CellWithAvatar
          src={getUserAvatar(creator)}
          toolTip={creator.name}
        />
      );
    },
    Header: 'CREATOR',
  };
}

export function getCreatedOnColumn<D extends { createdAt: Date }>(): Column<D> {
  return {
    accessor: 'createdAt',
    alignment: 'center',
    cellWidth: '150px',
    Cell: ({ cell: { value } }: CellProps<D>) => (
      <>
        {formatMonthDayYear(value as number)}
      </>
    ),
    disableSortBy: true,
    Header: 'CREATED ON',
  };
}

export function getDeprecatedFacebookInterestsColumn<
  D extends { deprecatedFacebookInterests: Array<string>; }
>(ToolTip: ToolTipType): Column<D> {
  return {
    accessor: 'deprecatedFacebookInterests',
    alignment: 'center',
    Cell: ({ cell: { value } }: CellProps<D>) => {
      const topics = value as Array<string>;
      return !isEmpty(topics) ? (
        <div className="text-red-500 text-lg font-bold">
          <ToolTip content={`${size(topics)} Facebook interest(s) removed`}>
            !
          </ToolTip>
        </div>
      ) : null;
    },
  };
}

export function getDetailedUserColumn(isAdmin = false): Column<UserAccount> {
  return {
    alignment: 'left',
    Cell: ({ row: { original } }: CellProps<UserAccount>) => (
      <GenericCell
        description={original.email}
        image={getUserAvatar(original)}
      >
        {isAdmin ? (
          <Button
            kind={ButtonKind.LINK}
            target={`/admin/users/${original.id}`}
          >
            {getUserName(original)}
          </Button>) : (getUserName(original))}
        {original.admin && (
          <Badge
            context={BadgeContext.WARNING}
            hasLeftMargin
            size={BadgeSize.SMALL}
            text="Admin"
          />
        )}
      </GenericCell>
    ),
    disableSortBy: true,
    Header: 'NAME',
  };
}

export function getIdColumn<D extends { id: number; }>(): Column<D> {
  return {
    accessor: 'id',
    alignment: 'left',
    cellWidth: '90px',
    Header: 'ID',
  };
}

export function getInsightReportNameColumn(isAdmin = false): Column<InsightReport & { progress: number }> {
  return {
    accessor: 'name',
    alignment: 'left',
    Cell: ({ row: { original } }: CellProps<InsightReport & { progress: number }>) => (
      <GenericCell
        description={
          <InsightReportInputLabels
            inputs={original.inputs}
            reportType={original.reportType}
          />
        }
        image={original.avatar?.default ?? AudiencePlaceholder}
      >
        {original.reportType === 'custom' || original.progress === 100 ? (
          <Button
            isTracked
            kind={ButtonKind.LINK}
            target={`${isAdmin ? '/admin' : ''}/insight_reports/${original.id}/`}
            title={original.name}
          />
        ) : original.name}
      </GenericCell>
    ),
    Header: 'INSIGHT REPORT',
  };
}

export function getInsightReportProgressColumn<
  D extends { id: number; progress: number, reportType: string; discardedAt: Date; }
>():
  Column<D> {
  return {
    alignment: 'center',
    cellWidth: '200px',
    Cell: ({ row: { original } }: CellProps<D>) => {
      if (original.reportType === 'custom') {
        return (
          <Badge
            context={BadgeContext.SUCCESS}
            kind={BadgeKind.LINK}
            onClick={() => {
              mixpanel.track('Insight Report Click', { from: 'Badge' });
            }}
            size={BadgeSize.SMALL}
            target={`/insight_reports/${original.id}/`}
            text="View Insight"
          />
        );
      }
      if (original.discardedAt) {
        return (
          <Badge
            context={BadgeContext.DANGEROUS}
            size={BadgeSize.SMALL}
            text="Deleted"
          />
        );
      }
      if (original.progress >= 100) {
        return (
          <Badge
            context={BadgeContext.SUCCESS}
            kind={BadgeKind.LINK}
            onClick={() => {
              mixpanel.track('Insight Report Click', { from: 'Badge' });
            }}
            size={BadgeSize.SMALL}
            target={`/insight_reports/${original.id}/`}
            text="View Insight"
          />
        );
      }
      if (original.progress === -1) {
        return (
          <Badge
            context={BadgeContext.DANGEROUS}
            size={BadgeSize.SMALL}
            text="Failed"
          />
        );
      }
      if (original.progress === -2) {
        return (
          <Badge
            context={BadgeContext.WARNING}
            size={BadgeSize.SMALL}
            text="Queued"
          />
        );
      }
      return (
        <div
          className="grid grid-cols-5 mx-auto w-1/2 items-center"
          data-tut="reactour_reports_progress"
        >
          <ProgressBar progress={original.progress} />
        </div>
      );
    },
    disableSortBy: true,
    Header: 'PROGRESS',
  };
}

export function getPlatformColumn<D extends { platform: string }>(): Column<D> {
  return {
    accessor: 'platform',
    alignment: 'center',
    Cell: ({ cell: { value } }: CellProps<D>) => (
      <BrandIcon
        brand={value as BrandIconBrand}
        size={BrandIconSize.SMALL}
      />
    ),
    disableSortBy: true,
    Header: 'PLATFORM',
  };
}

export function getSocialAccountNameColumn<
  D extends { avatar: string; name: string; topics?: Array<string> }
>(): Column<D> {
  return {
    alignment: 'left',
    Cell: ({ row: { original } }: CellProps<D>) => (
      <GenericCell
        description={original.topics?.join(', ')}
        image={original.avatar}
      >
        {original.name}
      </GenericCell>
    ),
    Header: 'NAME',
    id: 'name',
  };
}

export function getTargetAudienceNameColumn(isAdmin = false): Column<TargetAudience> {
  return {
    alignment: 'left',
    Cell: ({ row: { original }, state: { globalFilter } }: CellProps<TargetAudience>) => (
      <GenericCell
        description={
          <TargetAudienceCriteriaLabels
            parentId={original.parentId}
            targeting={original.unifiedTargeting}
          />
        }
        image={original.avatar?.default ?? AudiencePlaceholder}
      >
        <Button
          kind={ButtonKind.LINK}
          target={`${isAdmin ? '/admin' : ''}/audiences/${original.id}/`}
        >
          <Truncated
            content={original.name}
            filter={globalFilter as string}
            maxContentSize={AUDIENCE_NAME_CUTOFF}
          />
        </Button>
      </GenericCell>
    ),
    Header: 'TARGET AUDIENCE',
    id: 'name',
  };
}
