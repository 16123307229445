import { Credentials } from '@api/authentication';

const CREDENTIALS_KEY = 'credentials';

export function removeCredentials(): void {
  localStorage.removeItem(CREDENTIALS_KEY);
}

export function retrieveCredentials(): Credentials | undefined {
  const rawCredentials = localStorage.getItem(CREDENTIALS_KEY);
  return rawCredentials !== null ? JSON.parse(rawCredentials) as Credentials : undefined;
}

export function storeCredentials(credentials: Credentials): void {
  localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(credentials));
}
