import { CollectionIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import FinalizeCampaignGroup from './FinalizeCampaignGroup';
import SelectCampaigns from './SelectCampaigns';

import { getCampaignGroup } from '@api/campaignGroup';
import ErrorMessage from '@common/ErrorMessage';
import Loader from '@common/Loader';
import Section from '@common/Section';
import { Wizard } from '@common/Wizard';

export default function EditCampaignGroup(props) {
  // acquire campaign group and organization ID
  const { campaignGroupId } = useParams();

  // load campaign group data
  const { data: campaignGroup, error: errorLoadingCampaignGroup, isFetching: isLoadingCampaignGroup } = useQuery(
    ['campaignGroup', campaignGroupId],
    () => getCampaignGroup(campaignGroupId),
    {
      enabled: campaignGroupId !== undefined,
      initialData: campaignGroupId !== undefined ? {} : undefined,
    }
  );

  // data
  const [data, setData] = useState({});

  // render content
  return (
    <>
      <Section
        icon={CollectionIcon}
        title={`${campaignGroupId ? 'Edit' : 'Create'} Campaign Group`}
      >
        {errorLoadingCampaignGroup && <ErrorMessage message={errorLoadingCampaignGroup.message} />}
        {campaignGroupId && isLoadingCampaignGroup ? (
          <Loader loadingText="Retrieving campaign group information..." />
        ) : (
          <div className="bg-white px-4 py-6 rounded-lg shadow">
            <Wizard>
              <SelectCampaigns
                campaignGroup={campaignGroup}
                isAdmin={props.isAdmin}
                setData={setData}
              />
              <FinalizeCampaignGroup
                campaignGroup={campaignGroup}
                data={data}
                isAdmin={props.isAdmin}
              />
            </Wizard>
          </div>
        )}
      </Section>
    </>
  );
}

EditCampaignGroup.propTypes = {
  isAdmin: PropTypes.bool,
};
