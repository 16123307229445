import PropTypes from 'prop-types';

function renderColumnHeader(column, index) {
  // determine class names and rendering type
  const classNames = [
    'bg-gray-50',
    'font-medium',
    'py-3',
    'text-gray-500',
    'text-xs',
    'tracking-wider',
    'uppercase',
  ];
  classNames.push(column.hasSmallPadding ? 'px-2' : 'px-6');
  if (column.alignment) {
    classNames.push(`text-${column.alignment}`);
  }

  // render column header
  return (
    <th
      className={classNames.join(' ')}
      key={index}
      style={{ minWidth: column.cellWidth, maxWidth: column.cellWidth }}
    >
      <div {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        {!column.isSorted ? '' : column.isSortedDesc ? ' 🔽' : ' 🔼'}
      </div>
      {column.Filter && (
        <div>
          {column.render('Filter')}
        </div>
      )}
    </th>
  );
}

export default function TableHeader(props) {
  return (
    <thead>
      {props.headerGroups.map((headerGroup, index) => (
        <tr
          key={index}
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map(renderColumnHeader)}
        </tr>
      ))}
    </thead>
  );
}

TableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(PropTypes.object),
};
