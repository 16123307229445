import { UserCircleIcon, UserGroupIcon } from '@heroicons/react/outline';
import { capitalize, get, isEmpty } from 'lodash';
import { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import getTargetAudienceTableColumns from './targetAudienceTableColumns';
import BulkUpdateModal from './modals/BulkUpdateModal';

import { copyTargetAudience, deleteTargetAudience, getAdAudiences } from '@api/audiences';
import Badge from '@common/Badge';
import Button, { ButtonKind } from '@common/Button';
import DeleteDialog from '@common/DeleteDialog';
import DialogIcon from '@common/DialogIcon';
import DialogWithIcon from '@common/DialogWithIcon';
import ErrorMessage from '@common/ErrorMessage';
import Section from '@common/Section';
import RemoteTable from '@common/RemoteTable';
import Placeholder from '@common/Placeholder';
import PersonaPickerModal from '@components/personaLibrary/PersonaPickerModal';
import PlatformSelector from '@components/shared/PlatformSelector';
import { UserInfoContext } from '@hoc/withUserInfo';
import { ToolTipContext } from '@hoc/withToolTip';
import NoAudiencesPlaceholder from '@images/no_audiences.png';
import { notifySuccess } from '@utils/toaster';
import useViewState from '@utils/viewState';

export default function AudienceSection() {
  // acquire history handler, tooltip and user data
  const history = useHistory();
  const ToolTip = useContext(ToolTipContext);
  const { userInfo } = useContext(UserInfoContext);

  // view state
  const { setState, state } = useViewState('main');

  // get table reference
  const tableRef = useRef({});

  // duplicate audience
  const { error: adAudienceDuplicationError, mutate: duplicateAdAudience } = useMutation(
    adAudienceId => copyTargetAudience(adAudienceId, userInfo.id),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Audience duplicated!' });
        tableRef.current.refetch();
      },
    }
  );

  // internal state
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] = useState({ isOpen: false });
  const [paramsOfBulkUpdateModal, setParamsOfBulkUpdateModal] = useState({});
  const [paramsOfBulkUpdateSuccessModal, setParamsOfBulkUpdateSuccessModal] = useState({});
  const [paramsOfPersonaPickerModal, setParamsOfPersonaPickerModal] = useState({});
  const [isDataEmpty, setIsDataEmpty] = useState();
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedAdAudiences, setSelectedAdAudiences] = useState([]);

  // render audience section
  const columns = getTargetAudienceTableColumns(
    ToolTip,
    false,
    history,
    isSelecting,
    !isEmpty(selectedAdAudiences) ? selectedAdAudiences[0].platform : null,
    {
      duplicateAdAudience,
      filterByLabel: label => setState({ ...state, label }),
      openDeleteDialog: targetAudience =>
        setParamsOfDeleteDialog({ id: targetAudience.id, isOpen: true, name: targetAudience.name }),
    }
  );
  return (
    <>
      <Section
        data-tut={isDataEmpty !== undefined ? 'reactour_audience_table' : null}
        heading={
          <div className="flex justify-end">
            {/* {
              isDataEmpty !== undefined && (
                <Button
                  kind={!isSelecting ? '' : isEmpty(selectedAdAudiences) ? 'dangerous' : 'success'}
                  onClick={() => {
                    if (!isSelecting) {
                      setIsSelecting(true);
                    } else if (isEmpty(selectedAdAudiences)) {
                      setIsSelecting(false);
                    } else {
                      setParamsOfBulkUpdateModal({ adAudiences: selectedAdAudiences, isOpen: true });
                    }
                  }}
                  title={
                    !isSelecting
                      ? 'Select Audiences for Bulk Update'
                      : isEmpty(selectedAdAudiences)
                        ? 'Cancel Bulk Update'
                        : 'Bulk Add to Campaign'
                  }
                />
              )
            } */}
            <Button
              icon={UserCircleIcon}
              kind={ButtonKind.PRIMARY}
              onClick={() => setParamsOfPersonaPickerModal({ isOpen: true })}
              title="Browse Persona Library"
            />
            <Button
              hasLeftMargin
              kind={ButtonKind.PRIMARY}
              onClick={() => history.push('/audiences/new')}
              title="New Target Audience"
            />
          </div>
        }
        icon={UserGroupIcon}
        title="Audience Library"
      >
        {adAudienceDuplicationError && <ErrorMessage message={adAudienceDuplicationError} />}
        <div className="w-full mx-auto">
          <RemoteTable
            columns={columns}
            entitiesName="audiences"
            extra={
              <>
                <div className="w-48">
                  <PlatformSelector
                    onSelect={option => setState({ ...state, platform: option })}
                    options={availablePlatforms}
                    value={state.platform}
                  />
                </div>
                <div>
                  {state.label && (
                    <Badge
                      context="highlight"
                      isRemovable
                      onClick={() => setState({ ...state, label: undefined })}
                      text={`Label: ${state.label}`}
                    />
                  )}
                </div>
              </>
            }
            filters={[
              { id: 'label', value: state.label },
              { id: 'platform', value: get(state.platform, 'value') },
            ]}
            isSearchable
            onRowSelectionChange={selectedRows => setSelectedAdAudiences(Object.values(selectedRows))}
            placeholder={
              <Placeholder
                description="All of your social audiences in one place, ready to supercharge any campaign."
                placeholder={NoAudiencesPlaceholder}
                title="Activate high-performing audiences"
              />
            }
            query={(...args) =>
              getAdAudiences(...args).then(response => {
                const { payload: { data, platforms }, meta } = response;
                setAvailablePlatforms(platforms);
                return { payload: data, meta };
              })
            }
            queryKey="adAudiences"
            ref={tableRef}
            setIsDataEmpty={setIsDataEmpty}
            stateStorageKey="table"
          />
        </div>
      </Section>

      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ ...paramsOfDeleteDialog, isOpen: false })}
        delete={audienceId => deleteTargetAudience(audienceId)}
        entityName="target audience"
        onUpdate={tableRef.current.refetch}
        params={paramsOfDeleteDialog}
      />
      <BulkUpdateModal
        close={() => setParamsOfBulkUpdateModal({ ...paramsOfBulkUpdateModal, isOpen: false })}
        onUpdate={() => {
          setIsSelecting(false);
          setSelectedAdAudiences([]);
          if (tableRef.current) {
            tableRef.current.clearSelection();
          }
          setParamsOfBulkUpdateSuccessModal({ platform: selectedAdAudiences[0].platform });
        }}
        params={paramsOfBulkUpdateModal}
      />
      <PersonaPickerModal
        close={
          () => {
            setParamsOfPersonaPickerModal({ ...paramsOfPersonaPickerModal, isOpen: false });
            tableRef.current.refetch();
          }
        }
        params={paramsOfPersonaPickerModal}
      />
      <DialogWithIcon
        close={() => setParamsOfBulkUpdateSuccessModal(false)}
        description="Ad sets were successfully updated."
        hasNoButtons
        icon={
          <div className="mb-4">
            <DialogIcon
              context="success"
              icon="CheckIcon"
              outline
            />
          </div>
        }
        isOpen={!isEmpty(paramsOfBulkUpdateSuccessModal)}
        title={`Your ${capitalize(paramsOfBulkUpdateSuccessModal.platform)} ad sets were updated!`}
      >
        <div className="mt-8">
          <Button
            kind={ButtonKind.PRIMARY}
            onClick={() => setParamsOfBulkUpdateSuccessModal({})}
            title="Close"
          />
        </div>
      </DialogWithIcon>
    </>
  );
}
