import { range, size, sortBy, startCase, trimStart } from 'lodash';
import { useQuery } from 'react-query';

import { searchInterests, searchLocations } from '@api/targeting_old';

export function useSnapchatBase(values) {
  // query countries
  const { data: countries, isFetching: isLoadingCountries } = useQuery(
    ['getSnapchatCountries'],
    () => searchLocations('snapchat', ''),
    { initialData: [] }
  );

  // prepare country options
  const countryOptions = sortBy(
    countries.map(country => ({ label: startCase(country.name), value: country.id })),
    'label'
  );

  // query interests
  const countryCodes = values.countries.map(country => country.value).sort().join(',');
  const { data: interests, isFetching: isLoadingInterests } = useQuery(
    ['getSnapchatInterests', countryCodes],
    () => searchInterests('snapchat', '', countryCodes),
    {
      enabled: size(values.countries) !== 0,
      initialData: [],
    }
  );

  // prepare interest options
  const interestOptions = [];
  for (const interest of sortBy(interests, 'path')) {
    let current = interestOptions;
    const levels = trimStart(interest.path, '/').split('/');
    for (const [index, item] of levels.entries()) {
      let target = current.find(target => target.label === item);
      const isLast = index === size(levels) - 1;
      if (!target) {
        target = {
          children: [],
          isDisabled: !isLast,
          label: item,
          value: isLast ? interest.id : levels.slice(0, index + 1).join('|'),
        };
        current.push(target);
      }
      current = target.children;
    }
  }
  return { countryOptions, isLoadingCountries, interestOptions, isLoadingInterests };
}

export function getSnapchatFields(values, setValue, base) {
  // adjust minimal or maximal age if necessary
  if (values.minAge && values.maxAge && values.minAge.value > values.maxAge.value) {
    setValue('maxAge', values.minAge);
  }
  if (!values.minAge && values.maxAge) {
    setValue('minAge', { label: '13', value: 13 });
  }

  // destructure base
  const { countryOptions, isLoadingCountries, interestOptions, isLoadingInterests } = base;

  // return fields
  return [
    {
      label: 'Gender',
      name: 'gender',
      options: [
        { label: 'All Genders', value: '' },
        { label: 'Female', value: 'female' },
        { label: 'Male', value: 'male' },
      ],
      placeholder: 'Select a gender',
      type: 'choice',
      width: 4,
    },
    {
      label: 'Minimum Age',
      name: 'minAge',
      options: range(13, 49).map(age => ({ label: age.toString(), value: age })),
      type: 'choice',
      width: 4,
    },
    {
      isClearable: true,
      label: 'Maximum Age',
      name: 'maxAge',
      options: range(13, 49).map(age => ({ label: age.toString(), value: age })),
      type: 'choice',
      width: 4,
    },
    {
      empty: 'No country found',
      isDisabled: isLoadingCountries,
      isMulti: true,
      label: 'Countries',
      name: 'countries',
      options: countryOptions,
      placeholder: 'Select a country',
      required: 'Choose at least one country',
      type: 'choice',
    },
    {
      empty: 'No persona found',
      isCollapsed: true,
      isDisabled: size(values.countries) === 0 || isLoadingInterests,
      isMulti: true,
      isSearchable: true,
      label: 'Personas',
      name: 'interest',
      options: interestOptions,
      type: 'choice',
    },
  ];
}
