import { ComponentType, createContext, ReactNode, useState } from 'react';

import { Credentials, UserInfo } from '@api/authentication';
import { removeCredentials, storeCredentials } from '@utils/credentials';

export interface UserInfoContextType {
  logout: () => void;
  setCredentials: (credentials: Credentials) => void;
  setUserInfo: (userInfo: UserInfo | undefined) => void;
  userInfo: UserInfo | undefined;
}

export const UserInfoContext = createContext<UserInfoContextType>({
  logout: () => undefined,
  setCredentials: () => undefined,
  setUserInfo: () => undefined,
  userInfo: undefined,
});

export default function withUserInfo<T>(WrappedComponent: ComponentType): (props: T) => ReactNode {
  return (props: T) => {
    // user state
    const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);

    // render component
    return (
      <UserInfoContext.Provider
        value={{
          logout: () => {
            setUserInfo(undefined);
            removeCredentials();
          },
          userInfo,
          setCredentials: storeCredentials,
          setUserInfo,
        }}
      >
        <WrappedComponent {...props} />
      </UserInfoContext.Provider>
    );
  };
}
