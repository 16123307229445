import { capitalize, compact, get, isEmpty } from 'lodash';
import { useContext, useState } from 'react';
import GoogleButton from 'react-google-button';

import DisconnectPlatformModal from './DisconnectPlatformModal';

import BrandIcon from '@common/BrandIcon';
import Button, { ButtonKind } from '@common/Button';
import Card from '@common/Card';
import { Menu, MenuItem } from '@common/Menu';
import { UserInfoContext } from '@hoc/withUserInfo';
import connectPlatformsPlaceholder from '@images/connect_platforms.png';
import { oAuthSignIn } from '@root/utils/oAuth';
import { validateToken } from '@api/authentication';

function getAccountName(identity) {
  if (identity.name) {
    return identity.name;
  }
  if (get(identity, 'info.first_name')) {
    return compact([get(identity, 'info.first_name'), get(identity, 'info.last_name')]).join(' ');
  }
  return identity.uid;
}

function renderPlatformCards(identities, setSourceSelector, setOpenDisconnectPlatformModal) {
  return identities.map(identity => (
    <div key={identity.id}>
      <div
        className="col-span-1 border border-gray-300 bg-white rounded-lg divide-y divide-gray-200 h-36"
        platform={identity.provider}
      >
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1">
            <div className="flex items-center space-x-3">
              <span className="text-gray-900 font-medium truncate">
                {getAccountName(identity)}
              </span>
            </div>
            <span className="block text-gray-500 text-xs truncate mb-4">
              {identity.username || get(identity, 'info.email')}
            </span>
            <Button
              kind={ButtonKind.LINK}
              onClick={() => {
                setSourceSelector({
                  identityId: identity.id.toString(),
                  name: identity.name,
                  platform: identity.provider,
                });
                setOpenDisconnectPlatformModal(true);
              }}
              title="Disconnect"
            />
          </div>
          <BrandIcon brand={identity.provider} />
        </div>
      </div>
    </div>
  ));
}

export default function UserConnectedPlatformsTab() {
  // acquire user data
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  const platforms = [
    'google',
    'facebook',

    // 'twitter',
    'tiktok',
    'snapchat',
    'linkedin',

    // 'pinterest',
    // ...(userInfo.features.loginWithTwitch ? ['twitch'] : []),
  ];

  const [sourceSelector, setSourceSelector] = useState({});
  const [openDisconnectPlatformModal, setOpenDisconnectPlatformModal] = useState(false);

  return (
    <>
      <Card
        actions={
          <Menu
            placement="bottom-end"
            title="Connect Platform"
          >
            {platforms.map(platform => {
              if (platform !== 'google') {
                return (
                  <MenuItem
                    icon={() => (
                      <BrandIcon
                        brand={platform}
                        hasRightMargin
                        size="small"
                      />
                    )}
                    key={platform}
                    onClick={async () => {
                      await oAuthSignIn(platform, userInfo.uid);
                      const updatedUserInfo = await validateToken();
                      setUserInfo(updatedUserInfo);
                    }}
                    title={platform === 'facebook' ? 'Meta' : capitalize(platform)}
                  />
                );
              } else {
                return (
                  <div className="mb-2">
                    <GoogleButton
                      onClick={async () => {
                        await oAuthSignIn('google', userInfo.uid);
                        const updatedUserInfo = await validateToken();
                        setUserInfo(updatedUserInfo);
                      }
                      }
                    />
                  </div>
                );
              }
            })
            }
          </Menu>
        }
        subtitle="Add and manage your third-party platforms to TopicDNA."
        title="Connected Platforms"
      >
        {!isEmpty(userInfo.identities) ? (
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
            {renderPlatformCards(userInfo.identities, setSourceSelector, setOpenDisconnectPlatformModal)}
          </div>
        ) : (
          <div className="p-6 text-center">
            <img
              alt=""
              className="mx-auto w-1/3"
              src={connectPlatformsPlaceholder}
            />
            <h2 className="text-2xl font-semibold text-gray-900">No platforms connected.</h2>
            <p className="text-gray-700 text-lg">Add a platform above</p>
          </div>
        )}
      </Card>

      <DisconnectPlatformModal
        close={() => setOpenDisconnectPlatformModal(false)}
        identityId={sourceSelector.identityId}
        isOpen={openDisconnectPlatformModal}
        name={sourceSelector.name}
        platform={sourceSelector.platform}
        userId={userInfo.id}
      />
    </>
  );
}
