import { size, startCase } from 'lodash';

import { getTargetingItem } from '@utils/generalUtils';

export function getSnapchatDefaults(targeting) {
  // extract properties
  const gender = getTargetingItem(targeting, 'gender');
  const minAge = getTargetingItem(targeting, 'minAge');
  const maxAge = getTargetingItem(targeting, 'maxAge');
  const countries = (getTargetingItem(targeting, 'countries') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const interests = (getTargetingItem(targeting, 'personas') || [])
    .map(item => ({ value: item.id, label: item.name }));

  // return default values
  return {
    countries,
    gender: { value: gender || '', label: startCase(gender || 'all genders') },
    interest: interests,
    maxAge: maxAge && { value: maxAge, label: maxAge.toString() },
    minAge: minAge && { value: minAge, label: minAge.toString() },
  };
}

export function calculateSnapchatTargeting(values) {
  const countries = values.countries.map(item => ({ id: item.value, name: item.label }));
  const interests = values.interest.map(item => ({ id: item.value, name: item.label }));
  return {
    unifiedTargeting: {
      include: [
        ...(size(countries) ? [{ countries }] : []),
        ...(values.gender.value ? [{ gender: values.gender.value }] : []),
        ...(size(interests) ? [{ personas: interests }] : []),
        ...(values.maxAge ? [{ maxAge: values.maxAge.value }] : []),
        ...(values.minAge ? [{ minAge: values.minAge.value }] : []),
      ],
    },
  };
}
