import { Campaign } from '@root/models/campaign';
import { Persona } from '@root/models/persona';
import { getPagedResults } from '@utils/fetchUtils';
import { issueRequest, Method } from '@utils/httpUtils';

export const getAdAccounts = getPagedResults('/v3/admin/ad_accounts');

export const getCampaignGroups = getPagedResults('/v3/admin/campaign_groups');

export const getInsightReports = getPagedResults('/v3/admin/insight_reports');

export function getInterests(
  deprecated: boolean
): (
  pageIndex: number,
  globalFilter: string | undefined,
  filters: string[],
  sortBy?: boolean,
  sortDescending?: boolean
) => Promise<Response> {
  return getPagedResults('/v3/admin/interests_explorer', {
    status: deprecated ? 'deprecated' : 'added',
  });
}

export const getAddedInterests = getPagedResults(
  '/v3/admin/interests_explorer?status=added'
);

export const getDeprecatedInterests = getPagedResults(
  '/v3/admin/interests_explorer?status=deprecated'
);

export const getOrganizations = getPagedResults('/v3/organizations');

export async function getRunningCampaigns(): Promise<Array<Campaign>> {
  const response = await issueRequest<Array<Campaign>>(
    '/v3/admin/running_campaigns'
  );
  return response.payload;
}

export async function getPersonaLibrary(): Promise<Array<Persona>> {
  const response = await issueRequest<Array<Persona>>('/v3/personas');
  return response.payload;
}

export async function requestPersonaAudience(
  personaId: number,
  platform: string
): Promise<void> {
  await issueRequest(`/v3/personas/${personaId}/request_audience`, {
    body: { platform },
    method: Method.POST,
  });
}

export async function addPersonaLibraryAudience(
  parentAudienceId: number
): Promise<void> {
  await issueRequest('/v3/personas/add_audience', {
    body: { parentAudienceId },
    method: Method.POST,
  });
}

export const getTargetAudiences = getPagedResults('/v3/admin/target_audiences');

export async function updateCampaignGroup(campaignGroup: {
  id: number;
  comments?: string;
  actions?: string;
}): Promise<void> {
  await issueRequest(`/v3/campaign_groups/${campaignGroup.id}`, {
    body: { ...campaignGroup, id: undefined },
    method: Method.PUT,
  });
}
