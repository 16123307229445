import { OfficeBuildingIcon } from '@heroicons/react/outline';

import getOrganizationTableColumns from './organizationTableColumns';

import { getOrganizations } from '@api/admin';
import RemoteTable from '@common/RemoteTable';
import Section from '@common/Section';

export default function OrganizationAdminSection() {
  return (
    <Section
      icon={OfficeBuildingIcon}
      title="Organizations"
    >
      <RemoteTable
        columns={getOrganizationTableColumns()}
        entitiesName="organizations"
        isSearchable
        query={getOrganizations}
        queryKey="organizations"
      />
    </Section>
  );
}
