import { Switch } from '@headlessui/react';
import PropTypes from 'prop-types';

const SWITCH_BACKGROUND_CLASSES = [
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-indigo-500',
  'focus:ring-offset-2',
  'h-6',
  'inline-flex',
  'items-center',
  'relative',
  'rounded-full',
  'transition-colors',
  'w-11',
];
const SWITCH_BUTTON_CLASSES = [
  'bg-white',
  'h-4',
  'inline-block',
  'rounded-full',
  'transform',
  'transition-transform',
  'w-4',
];

export default function Toggle(props) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch.Label className="mr-4">
          {props.label}
        </Switch.Label>
        <Switch
          checked={props.isEnabled}
          className={`${
            props.isEnabled ? 'bg-indigo-600' : 'bg-gray-200'
          } ${SWITCH_BACKGROUND_CLASSES.join(' ')}`}
          onChange={props.setIsEnabled}
        >
          <span
            className={`${
              props.isEnabled ? 'translate-x-6' : 'translate-x-1'
            } ${SWITCH_BUTTON_CLASSES.join(' ')}`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}

Toggle.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  setIsEnabled: PropTypes.func.isRequired,
};
