import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import drilldown from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';
import { useMemo } from 'react';

const DEFAULT_CONFIG: Highcharts.Options = {
  accessibility: {
    enabled: false,
  },
  chart: {
    style: {
      backgroundColor: 'transparent',
      fontFamily: '"Inter", sans-serif',
      fontWeight: 'normal',
    },
  },
  credits: { enabled: false },
  exporting: { enabled: false },
  lang: { thousandsSep: ',' },
  legend: { enabled: false },
  title: { text: undefined },
  tooltip: { enabled: false },
};

// configure library
drilldown(Highcharts);
highchartsMore(Highcharts);

export interface ChartProps {
  config: Highcharts.Options;
}

export default function Chart(props: ChartProps): JSX.Element {
  // prevent unnecessary re-rendering as chart may have an internal state
  return useMemo(
    () => (
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...DEFAULT_CONFIG, ...props.config }}
      />
    ),
    [props.config]
  );
}
