import { get, isEmpty, isNil, size, sortBy, trim, truncate } from 'lodash';

import COLORS from '@utils/colorsUtils';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function optional(flag, item) {
  if (Array.isArray(item)) {
    return flag ? item : [];
  }
  return flag ? [item] : [];
}

export function getHighlightedText(text, filterValue, maxTextSize = Infinity) {
  // if there is no filter, do nothing
  if (!filterValue) {
    return truncate(text, { length: maxTextSize });
  }

  // partition string
  const filterSize = filterValue.length;
  const nonHits = ('X' + text.toLowerCase() + 'X').split(filterValue.toLowerCase());
  const partitions = [];
  let position = 0;
  for (const [index, nonHit] of nonHits.entries()) {
    const trueNonHit = trim(nonHit, 'X');
    if (trueNonHit) {
      partitions.push([false, text.substring(position, position + trueNonHit.length)]);
      position += trueNonHit.length;
    }
    if (index !== nonHits.length - 1) {
      partitions.push([true, text.substring(position, position + filterSize)]);
      position += filterSize;
    }
  }

  // construct result
  const result = [];
  let remainingSize = maxTextSize;
  const indices = [...partitions.keys()];
  if (partitions.length >= 2 && !partitions[0][0]) {
    indices[0] = 1;
    indices[1] = 0;
  }
  for (const index of indices) {
    // store next fragment
    let [isHit, fragment] = partitions[index];
    if (fragment.length > remainingSize) {
      fragment = index === 0 && !isHit
        ? '...' + fragment.substring(fragment.length - Math.max(remainingSize - (partitions.size >= 3 ? 6 : 3), 0))
        : truncate(fragment, { length: remainingSize });
    }
    if (index === 0) {
      result.splice(0, 0, [isHit, fragment]);
    } else {
      result.push([isHit, fragment]);
    }

    // determine next index
    remainingSize -= fragment.length;
    if (remainingSize < 0) {
      break;
    }
  }

  // return result
  return (
    <>
      {result.map(([isHit, fragment], index) => isHit ? (
        <span
          key={index}
          style={{ backgroundColor: COLORS.indigo[100] }}
        >
          {fragment}
        </span>
      ) : (
        fragment
      ))}
    </>
  );
}

export function getTargetingItem(targeting, name, include = true) {
  return include
    ? (get(targeting, 'include', []).find(item => !isNone(item[name])) || {})[name]
    : get(targeting, 'exclude', {})[name];
}

export function getTargetingItems(targeting, name) {
  return get(targeting, 'include', []).filter(item => !isNone(item[name])).map(item => item[name]);
}

export function collectRepeatableValues(values) {
  const result = [];
  for (const [name, value] of Object.entries(values)) {
    const parts = name.split('__');
    if (size(parts) !== 2 || isNone(value)) {
      continue;
    }
    const index = parseInt(parts[1], 10);
    (result[index] || (result[index] = {}))[parts[0]] = value;
  }
  return result;
}

export function buildTopicHierarchy(topics) {
  // Assume topics are sorted by full path or have a structure that allows building a hierarchy
  const root = { label: 'All Topics', value: 'all', children: [] };
  const topicMap = { all: root };

  topics.forEach(topic => {
    const parts = topic.path.split(' / ');
    let current = root;

    parts.forEach(part => {
      if (!topicMap[part]) {
        const newTopic = { label: part, value: part, children: [] };
        topicMap[part] = newTopic;
        current.children.push(newTopic);
      }
      current = topicMap[part];
    });
  });

  return root.children;
}

export function getTopicHierarchy(topLevel, connections) {
  function collect(topic, mapping) {
    return [topic, ...(mapping[topic] || []).flatMap(related => collect(related, mapping))];
  }

  function getChildren(topic) {
    return (children[topic] || []).map(item => ({ children: getChildren(item), name: item }));
  }

  // collect topics and their children
  const children = Object.fromEntries(connections.map(item => [item.name, item.data.map(child => child.name)]));
  const parents = Object.fromEntries(connections.flatMap(item => item.data.map(child => [child.name, [item.name]])));

  const descendants = {};
  const ancestors = {};
  for (const topic of [...new Set([...Object.keys(children), ...Object.keys(parents)])]) {
    descendants[topic] = collect(topic, children);
    ancestors[topic] = [...new Set(collect(topic, parents))];
  }

  // calculate topic hierarchy
  const firstLevel = [];
  const topics = [{ children: firstLevel, name: 'All Topics' }];
  for (const item of topLevel) {
    firstLevel.push({ children: getChildren(item.name), name: item.name });
  }
  return [topics, ancestors, descendants];
}

export function getUserName(user, separator = ' ') {
  if (user.firstName) {
    return user.lastName ? `${user.firstName}${separator}${user.lastName}` : user.firstName;
  }
  return user.email;
}

export function isNone(value) {
  return typeof value === 'object' ? isEmpty(value) : isNil(value);
}

export function joinHumanReadably(values) {
  return values.map((value, index) =>
    value + (index === size(values) - 1 ? '' : index === size(values) - 2 ? ' or ' : ', ')
  ).join('');
}

export function sortHits(data, field, query) {
  const normalizedQuery = query.toLowerCase();
  return sortBy(
    data,
    item => {
      const normalizedItem = item[field].toLowerCase();
      const index = normalizedItem.indexOf(normalizedQuery);
      const formattedIndex = String(index >= 0 ? index : 999).padStart(4, '0');
      return `${formattedIndex}|${normalizedItem}|${item[field]}`;
    }
  );
}
