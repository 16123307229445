import { ExclamationIcon } from '@heroicons/react/outline';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import FormLabel from './FormLabel';
import defaultAvatar from '@images/default_avatar.png';

const CHANGE_BUTTON_CLASS_NAMES = [
  'bg-white',
  'border',
  'border-gray-300',
  'cursor-pointer',
  'flex',
  'focus-within:outline-none',
  'focus-within:ring-2',
  'focus-within:ring-blue-500',
  'focus-within:ring-offset-2',
  'focus-within:ring-offset-gray-50',
  'hover:bg-gray-50',
  'items-center',
  'py-2',
  'px-3',
  'relative',
  'rounded-md',
  'shadow-sm',
];

const BASE_SIZE_MODIFIERS = ['h-12', 'w-12'];
const HUGE_SIZE_MODIFIERS = ['h-56', 'w-56'];

export default function FormAvatar(props) {
  // set up the drop zone
  const onDrop = useCallback(
    acceptedFiles => {
      if (size(acceptedFiles) !== 0) {
        const file = acceptedFiles[0];
        props.setIsUploading(true);
        const reader = new FileReader();
        reader.onload = () => {
          if (props.name) {
            props.setValue(props.name, reader.result, { shouldValidate: true });
          } else {
            props.setValue(reader.result);
          }
          if (props.onSelection) {
            props.onSelection();
          }
        };
        reader.onloadend = () => props.setIsUploading(false);
        reader.readAsDataURL(file);
      }
    },
    []
  );
  const { fileRejections, getInputProps, getRootProps } = useDropzone({
    accept: '.jpg, .jpeg, .png',
    maxSize: '3145728',
    onDrop,
  });

  // inject an avatar URL if present
  useEffect(
    () => {
      if (typeof props.avatar === 'object') {
        onDrop([props.avatar]);
      }
    },
    []
  );

  // render the avatar
  const avatarClassNames = props.size === 'xl' ? HUGE_SIZE_MODIFIERS : BASE_SIZE_MODIFIERS;
  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      <div
        className={`mt-1 flex items-center focus:outline-none ${props.isCentered && 'justify-center'}`}
        {...getRootProps()}
      >
        {props.avatar ? (
          <img
            alt=""
            className={`inline-block rounded-full ${avatarClassNames.join(' ')}`}
            onError={event => (event.target.src = defaultAvatar)}
            src={props.avatar}
          />
        ) : props.isUploading ? (
          <div className="animate-pulse flex space-x-4">
            <div className={`rounded-full bg-indigo-100 mx-auto ${avatarClassNames.join(' ')}`}></div>
          </div>
        ) : (
          <img
            alt="Default avatar"
            className={`inline-block rounded-full ${avatarClassNames.join(' ')}`}
            onError={event => (event.target.src = defaultAvatar)}
            src={props.placeholder}
          />
        )}
        {!props.hasNoButton && (
          <div className="ml-4 flex items-center">
            <div className={CHANGE_BUTTON_CLASS_NAMES.join(' ')}>
              <label className="relative text-xs font-medium text-gray-900 pointer-events-none">
                <span>Change</span>
              </label>
            </div>
            <div className="text-xs text-gray-500 ml-2">Max file size: 3 MB</div>
          </div>
        )}
        <input
          name={props.name}
          {...getInputProps()}
        />
        {fileRejections.length > 0 && (
          <div className="mt-1">
            <ExclamationIcon
              aria-hidden="true"
              className="inline h-5 w-5 text-red-500"
            />
            <span className="ml-1 text-sm text-red-600">
              {fileRejections[0].errors[0].message}
            </span>
          </div>
        )}
      </div>
    </>
  );
}

FormAvatar.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.instanceOf(Blob), PropTypes.string]),
  hasNoButton: PropTypes.bool,
  isCentered: PropTypes.bool,
  isUploading: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onSelection: PropTypes.func,
  placeholder: PropTypes.string,
  setIsUploading: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['xl']),
};
