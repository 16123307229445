import { trim } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useViewState<T extends Record<string, any>>(
  key: string,
  defaultValue = {} as T
): { clearState: () => void, setState: (state: T) => void, state: T } {
  // acquire current path
  const { pathname } = useLocation();

  // set up state
  type ExtendedT = T & { initial?: boolean };
  const [state, setState] = useState<ExtendedT>({ ...defaultValue, initial: true });
  const storedDefaultValue = useRef(defaultValue);

  // restore state if needed
  const sessionStorageKey = trim(pathname, '/') + ':' + key;
  let initialize = false;
  let restoredState: ExtendedT;
  if (key && state.initial) {
    const storedState = window.sessionStorage.getItem(sessionStorageKey);
    restoredState = { ...(storedState ? JSON.parse(storedState) as ExtendedT : state), initial: false };
    initialize = true;
  } else {
    restoredState = state;
  }
  useEffect(
    () => {
      if (initialize) {
        setState(restoredState);
      }
    },
    [initialize]
  );

  // return state setting function
  return {
    clearState: () => {
      if (key) {
        window.sessionStorage.removeItem(sessionStorageKey);
      }
      setState(storedDefaultValue.current);
    },
    setState: state => {
      if (key) {
        window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(state));
      }
      setState(state);
    },
    state: restoredState,
  };
}
