import { ArrowSmLeftIcon, CheckIcon } from '@heroicons/react/outline';
import mixpanel from 'mixpanel-browser';
import { useRef, useState } from 'react';
import { useMutation, useQueries, useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import InsightsHeaderView from './InsightsHeaderView';

import { createAdAudience } from '@api/audiences';
import { getInsightReport, getInsightReportAvatar } from '@api/insightReport';
import { getTargetingSuggestions } from '@api/insightReports';
import Button, { ButtonKind } from '@common/Button';
import Card from '@common/Card';
import ErrorMessage from '@common/ErrorMessage';
import Loader from '@common/Loader';
import AudienceEditor from '@components/shared/targetingEditor/AudienceEditor';

export default function TargetingInsightView() {
  // get insight report ID, platform and optional insight report data
  const { insightReportId } = useParams();
  const { search, state: cachedInsightReport } = useLocation();
  const queryParams = new URLSearchParams(search);
  const platform = queryParams.get('platform');

  // acquire history handler
  const history = useHistory();

  // load insight report
  const [
    { data: insightReport, error: errorLoadingInsightReport, isFetching: isLoadingInsightReport },
    { data: avatar, error: errorLoadingAvatar, isFetching: isLoadingAvatar },
  ] = useQueries(
    [
      {
        enabled: !cachedInsightReport,
        initialData: cachedInsightReport || { data: {} },
        queryKey: ['insightReport', insightReportId],
        queryFn: () => getInsightReport(insightReportId),
      },
      {
        queryKey: ['insightReportAvatar', insightReportId],
        queryFn: () => getInsightReportAvatar(insightReportId),
      },
    ]
  );
  if (cachedInsightReport) {
    window.history.replaceState(null, '');
  }

  // load targeting suggestions
  const {
    data: targetingSuggestions,
    error: targetingSuggestionsLoadError,
    isFetching: isLoadingTargetingSuggestions,
  } = useQuery(
    ['targetingSuggestions', insightReportId, platform],
    () => getTargetingSuggestions(insightReportId, platform),
    { initialData: { segments: {} } }
  );

  // create audience
  const { error: updateError, isLoading: isUpdating, mutate } = useMutation(
    data => createAdAudience(platform, data),
    { onSuccess: () => history.push('/audiences/') }
  );

  // state
  const submit = useRef(() => true);
  const [isLoading, setIsLoading] = useState(false);

  // render targeting insight
  return isLoadingInsightReport || isLoadingAvatar || isLoadingTargetingSuggestions ? (
    <Loader loadingText={'Generating targeting suggestions...'} />
  ) : (
    <>
      <InsightsHeaderView
        buttons={(
          <Button
            icon={ArrowSmLeftIcon}
            kind={ButtonKind.REGULAR}
            onClick={() => {
              mixpanel.track('Back Button Click', { section: 'Targeting Insights' });
              history.replace(`/insight_reports/${insightReportId}/`);
            }
            }
            title="Back to Insight Report"
          />
        )}
        insightReport={insightReport}
        showMenu={false}
      />

      {errorLoadingInsightReport && <ErrorMessage message="Could not load insight report." />}
      {errorLoadingAvatar && <ErrorMessage message="Could not load insight report avatar." />}
      {targetingSuggestionsLoadError && <ErrorMessage message="Could not load targeting suggestions." />}
      {updateError && <ErrorMessage message="Could not create audience." />}

      <div className="mt-4">
        <Card
          subtitle="Make changes to your audience or use our recommended targeting criteria."
          title="Review Targeting Criteria"
        >
          <AudienceEditor
            adAudience={{
              avatar: { default: avatar },
              insightReport,
              name: insightReport.name,
              unifiedTargeting: targetingSuggestions,
            }}
            platform={platform}
            setIsLoading={setIsLoading}
            setSubmit={func => {
              submit.current = func;
            }}
          >
            <Button
              data-tut={'reactour_create_audience'}
              icon={CheckIcon}
              isDisabled={isUpdating || isLoading}
              isFullWidth
              isLoading={isUpdating}
              kind={ButtonKind.PRIMARY}
              onClick={() => {
                mixpanel.track('Create Target Audience', {
                  platform: platform,
                  audience_type: 'From Insight Report',
                  insight_report_id: insightReport.id,
                });
                submit.current(mutate);
              }}
              title="Create Target Audience"
            />
          </AudienceEditor>
        </Card>
      </div>
    </>
  );
}
