import config from '@root/config';
import { checkNotNull } from '@utils/assertions';

const POPUP_SIZE: { [key: string]: { width: number, height: number } } = {
  /* eslint-disable key-spacing */
  facebook: { width: 580, height: 400 },
  google: { width: 452, height: 633 },
  hubspot: { width: 495, height: 645 },
  linkedin: { width: 495, height: 645 },
  mailchimp: { width: 495, height: 645 },
  pinterest: { width: 595, height: 745 },
  reddit: { width: 1020, height: 618 },
  shopify: { width: 495, height: 645 },
  snapchat: { width: 495, height: 645 },
  spotify: { width: 495, height: 645 },
  tiktok: { width: 1020, height: 800 },
  twitch: { width: 495, height: 645 },
  twitter: { width: 495, height: 645 },
  default: { width: 1020, height: 618 },
  /* eslint-enable key-spacing */
};
const POPUP_SETTINGS = 'scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no';
const DELAY = 500; // in milliseconds

export async function oAuthSignIn(provider: string, userEmail: string, openInNewTab = false): Promise<void> {
  // determine URL
  // when we want to add Shopify, we need to add shop={shop}.myshopify.com
  let encodedParams = new URLSearchParams({
    auth_origin_url: window.location.href,
    config_name: 'default',
    uid: userEmail,
  }).toString();

  if (provider === 'tiktok') {
    encodedParams = encodedParams.concat(`&state=${userEmail}`);
  }

  const url = `${config.API_URL}/auth/${provider}?${encodedParams}`;

  // open popup
  const name = openInNewTab ? '_blank' : provider;
  const { width, height } = POPUP_SIZE[provider] ?? POPUP_SIZE['default'];
  const left = window.screenX + (window.innerWidth - width) / 2;
  const top = window.screenY + (window.innerHeight - height) / 2;
  const dimensions = `width=${width},height=${height},top=${top},left=${left}`;
  const popup = window.open(url, name, `${POPUP_SETTINGS},${dimensions}`);
  checkNotNull(popup, 'Authentication popup');

  // wait until authentication is completed
  while (true) {
    await new Promise(resolve => setTimeout(resolve, DELAY));
    if (popup.closed) {
      throw new Error('Authentication canceled');
    }
    try {
      const params = Object.fromEntries(new URLSearchParams(popup.location.search));
      if ('uid' in params) {
        popup.close();
        return;
      }
    } catch (error) { }
  }
}
