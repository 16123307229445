import { RefreshIcon } from '@heroicons/react/solid';
import toast from 'react-hot-toast';

export function notifyError({ message = undefined, title }) {
  toast.error(
    data => (
      <div className="px-2">
        {title && <div className="font-medium text-base">{title}</div>}
        {message && <div>{message}</div>}
      </div>
    ),
    {
      iconTheme: {
        primary: '#EF4444',
        secondary: '#FFFAEE',
      },
      style: {
        color: '#111827',
        padding: '12px',
      },
    }
  );
}

export function notifyMessage({ message, onClick, title }) {
  toast.success(
    data => (
      <div
        className="px-2"
        onClick={() => {
          toast.dismiss();
          onClick();
        }}
      >
        {title && <div className="font-medium text-base">{title}</div>}
        {message && <div>{message}</div>}
      </div>
    ),
    {
      duration: 60000,
      iconTheme: {
        primary: '#10B981',
        secondary: '#FFFAEE',
      },
      style: {
        color: '#111827',
        padding: '12px',
      },
    }
  );
}

export function notifyRefresh({ entitiesName, onClick }) {
  notifyMessage({
    message: (
      <>
        <div>
          New {entitiesName} available.
        </div>
        <div className="mt-1">
          Click to refresh <RefreshIcon className="inline w-5 h-5" />
        </div>
      </>
    ),
    onClick,
  });
}

export function notifySuccess({ message = undefined, title }) {
  return toast.success(
    data => (
      <div className="px-2">
        {title && <div className="font-medium text-base">{title}</div>}
        {message && <div>{message}</div>}
      </div>
    ),
    {
      iconTheme: {
        primary: '#10B981',
        secondary: '#FFFAEE',
      },
      style: {
        color: '#111827',
        padding: '12px',
      },
    }
  );
}
