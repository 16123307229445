import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { differenceBy, intersectionBy } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { Puff } from 'svg-loaders-react';

import { getInsightReport } from '@api/insightReport';
import { getInsightReports } from '@api/insightReports';
import Button from '@common/Button';
import ErrorMessage from '@common/ErrorMessage';
import { FormAsyncDropdown } from '@common/FormDropdowns';
import Section from '@common/Section';
import Tabs from '@common/Tabs';
import SelectInsightReportOption from '@common/reactSelect/SelectInsightReportOption';
import SelectInsightReportValue from '@common/reactSelect/SelectInsightReportValue';
import TopAccountsView from '@components/shared/TopAccountsView';
import COLORS from '@utils/colorsUtils';
import { getTopicHierarchy } from '@utils/generalUtils';

const TABS = [
  { name: 'Similarities', path: '/insight_reports/comparison/' },
  { name: 'Differences', path: '/insight_reports/comparison/differences' },
];

function getTopics(insightReport, accounts) {
  return getTopicHierarchy(
    insightReport.data.interests.topics.series[0].data,
    insightReport.data.interests.topics.drilldown.series
  );
}

export default function CompareInsightReports() {
  function getOptions(insightReports) {
    return insightReports.map(insightReport => (
      {
        avatar: insightReport.avatar.default,
        id: insightReport.id,
        inputs: insightReport.inputs,
        label: insightReport.name,
        name: insightReport.name,
        reportType: insightReport.reportType,
        value: insightReport.id,
      }
    ));
  }

  // extract arguments
  const { pathname } = useLocation();
  const activeTabIndex = TABS.findIndex(tab => tab.path === pathname);

  // acquire history handler
  const history = useHistory();

  // report selection status
  const [firstInsightReportId, setFirstInsightReportId] = useState();
  const [secondInsightReportId, setSecondInsightReportId] = useState();
  const isValid = !!firstInsightReportId && !!secondInsightReportId && firstInsightReportId !== secondInsightReportId;

  // query first report
  const {
    data: firstInsightReport,
    error: firstInsightReportLoadError,
    isFetching: isLoadingFirstInsightReport,
  } = useQuery(
    ['insightReport', firstInsightReportId],
    () => getInsightReport(firstInsightReportId),
    { enabled: isValid }
  );

  // query second report
  const {
    data: secondInsightReport,
    error: secondInsightReportLoadError,
    isFetching: isLoadingSecondInsightReport,
  } = useQuery(
    ['insightReport', secondInsightReportId],
    () => getInsightReport(secondInsightReportId),
    { enabled: isValid }
  );

  // calculate topic hierarchy
  let accounts1 = [];
  let accounts2 = [];
  let topics1, topicAncestors1, topicDescendants1;
  let topics2, topicAncestors2, topicDescendants2;
  if (isValid && firstInsightReport && secondInsightReport) {
    if (activeTabIndex === 0) {
      accounts1 = intersectionBy(
        firstInsightReport.data.topAccounts,
        secondInsightReport.data.topAccounts,
        account => account.id
      );
      [topics1, topicAncestors1, topicDescendants1] = getTopics(firstInsightReport, accounts1);
    } else {
      accounts1 = differenceBy(
        firstInsightReport.data.topAccounts,
        secondInsightReport.data.topAccounts,
        account => account.id
      );
      [topics1, topicAncestors1, topicDescendants1] = getTopics(firstInsightReport, accounts1);
      accounts2 = differenceBy(
        secondInsightReport.data.topAccounts,
        firstInsightReport.data.topAccounts,
        account => account.id
      );
      [topics2, topicAncestors2, topicDescendants2] = getTopics(secondInsightReport, accounts2);
    }
  }

  // render page

  const error = (firstInsightReportId || secondInsightReportId) && firstInsightReportId === secondInsightReportId
    ? { message: 'You have to select different insight reports' }
    : null;

  return (
    <Section
      heading={
        <Button
          icon={ArrowSmLeftIcon}
          onClick={() => {
            mixpanel.track('Back Button Click', { section: 'Compare Insight Reports' });
            history.push('/insight_reports/');
          }}
          title="Back to Insight Reports"
        />
      }
      title="Compare Audience Insight Reports"
    >
      <div className="bg-white shadow sm:rounded-lg px-6 py-6">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-5">
            <FormAsyncDropdown
              hasInitialLoad
              label="Audience A"
              loadOptions={(query, callback) =>

                // we should not return anything here
                getInsightReports(0, query, [{ id: 'progress', value: 'completed' }])
                  .then(response => callback(getOptions(response.payload.data))) && undefined
              }
              onSelection={option => setFirstInsightReportId(option.value)}
              option={SelectInsightReportOption}
              placeholder="Select the first insight report"
              singleValue={SelectInsightReportValue}
            />
          </div>
          <div className="col-span-2 text-center text-2xl">
            vs.
          </div>
          <div className="col-span-5">
            <FormAsyncDropdown
              error={error}
              hasInitialLoad
              label="Audience B"
              loadOptions={(query, callback) =>

                // we should not return anything here
                getInsightReports(0, query, [{ id: 'progress', value: 'completed' }])
                  .then(response => callback(getOptions(response.payload.data))) && undefined
              }
              onSelection={option => setSecondInsightReportId(option.value)}
              option={SelectInsightReportOption}
              placeholder="Select the second insight report"
              singleValue={SelectInsightReportValue}
            />
          </div>
        </div>

        {(firstInsightReportLoadError || secondInsightReportLoadError) && (
          <ErrorMessage message="Could not load the comparison." />
        )}
        {
          (!firstInsightReport || !secondInsightReport) &&
          (!isLoadingFirstInsightReport && !isLoadingSecondInsightReport) && (
            <div className="my-6 px-10 py-12 text-center">
              <span className="inline-block text-xl text-gray-900">
                Please select two Twitter Audience Insight reports to compare the results.
              </span>
            </div>
          )
        }
        {
          (isLoadingFirstInsightReport || isLoadingSecondInsightReport) && (
            <div className="my-6 px-10 py-12 bg-indigo-50">
              <Puff
                className="inline-block mr-4 text-indigo-500"
                stroke={COLORS.indigo[500]}
              />
              <span className="inline-block text-xl text-indigo-500">
                Comparing audiences...
              </span>
            </div>
          )
        }
        {
          isValid && firstInsightReport && secondInsightReport &&
          !isLoadingFirstInsightReport && !isLoadingSecondInsightReport && (
            <>
              <Tabs
                activeTabIndex={activeTabIndex}
                tabs={TABS}
              />
              {activeTabIndex === 0 &&
                <>
                  <div className="my-8 text-lg">
                    Both audiences have similar affinities towards these accounts:
                  </div>
                  <TopAccountsView
                    accounts={accounts1}
                    isSimplified
                    topicAncestors={topicAncestors1}
                    topicDescendants={topicDescendants1}
                    topics={topics1}
                  />
                </>}
              {activeTabIndex === 1 &&
                (
                  <>
                    <div className="my-8 text-lg">
                      These are the accounts that each set of audiences likes exclusively:
                    </div>
                    <div className="flex">
                      <div className="">
                        <TopAccountsView
                          accounts={accounts1}
                          isSimplified
                          topicAncestors={topicAncestors1}
                          topicDescendants={topicDescendants1}
                          topics={topics1}
                        />
                      </div>
                      <div className="flex-grow-0 mr-36"></div>
                      <div className="flex-grow">
                        <TopAccountsView
                          accounts={accounts2}
                          isSimplified
                          topicAncestors={topicAncestors2}
                          topicDescendants={topicDescendants2}
                          topics={topics2}
                        />
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
      </div>
    </Section>
  );
}
