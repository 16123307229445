import { useLocation } from 'react-router-dom';

import Section from '@common/Section';

import UserConnectedPlatformsTab from './connectedPlatforms/UserConnectedPlatformsTab';
import OrganizationTab from './organization/OrganizationTab';
import UserProfileTab from './UserProfileTab';

const TABS = [
  { name: 'Profile', path: '/user/profile' },
  { name: 'Connected Platforms', path: '/user/connected_platforms' },
  { name: 'Organization', path: '/user/organization' },
];

export default function UserSection(props) {
  // extract pathname
  const { pathname } = useLocation();
  const activeTabIndex = TABS.findIndex(tab => tab.path === pathname);

  // render user section
  return (
    <Section
      activeTabIndex={activeTabIndex}
      tabs={TABS}
      title="User and Account Settings"
    >
      {activeTabIndex === 0 && <UserProfileTab />}
      {activeTabIndex === 1 && <UserConnectedPlatformsTab />}
      {activeTabIndex === 2 && <OrganizationTab />}
    </Section>
  );
}
