import { camelizeKeys, decamelizeKeys } from 'humps';
import { camelCase, pickBy } from 'lodash';

import config from '@root/config';

export function commonFetch(url, options = {}) {
  // handle API calls
  if (url.startsWith('/')) {
    url = `${config.API_URL}${url}`;
    const credentials = JSON.parse(localStorage.getItem('credentials') || '{}');
    options = {
      ...options,
      headers: {
        ...options.headers,
        'access-token': credentials.accessToken,
        client: credentials.client,
        expiry: credentials.expiry,
        'resource-class': 'user',
        'token-type': 'Bearer',
        uid: credentials.uid,
      },
    };
  }

  // add query parameters
  if (options.params) {
    url = url + '?' + new URLSearchParams(pickBy(options.params, value => value !== undefined));
  }

  // prepare payload
  if (options.body) {
    if (options.sendAsForm) {
      const data = new FormData();
      for (const [key, value] of Object.entries(options.body)) {
        data.append(key, typeof value === 'string' ? value : JSON.stringify(decamelizeKeys(value)));
      }
      options = { ...options, body: data };
    } else {
      options = {
        ...options,
        body: JSON.stringify(decamelizeKeys(options.body)),
        headers: { ...options.headers, 'Content-Type': 'application/json' },
      };
    }
  }

  // initiate request
  return fetch(url, options)
    .then(response => {
      if (!response.ok) {
        return response.json().then(error => Promise.reject(error));
      }
      if (options.hasEmptyResponse) {
        return Promise.resolve(null);
      }
      let meta;
      if (options.extractHeaders) {
        meta = {};
        for (const header of options.extractHeaders) {
          meta[camelCase(header)] = parseInt(response.headers.get(header), 10);
        }
      }
      return response.headers.get('Content-Type').includes('application/json')
        ? response.json().then(data =>
          meta ? ({ payload: camelizeKeys(data), meta }) : camelizeKeys(data)
        )
        : response.blob();
    });
}

/**
 * @param path
 * @param baseParams
 * @returns {function(*, *, *, *, *): Promise<Response>}
 */
export function getPagedResults(path, baseParams = {}) {
  return (pageIndex, globalFilter, filters, sortBy, sortDescending) => commonFetch(path, {
    extractHeaders: ['Page-Items', 'Total-Count', 'Total-Pages'],
    params: {
      ...baseParams,
      page: pageIndex + 1,
      q: globalFilter,
      sort_column: sortBy,
      sort_direction: sortBy && (sortDescending ? 'desc' : 'asc'),
      ...(filters ? Object.fromEntries(filters.map(filter => [`column_${filter.id}`, filter.value])) : {}),
    },
  });
}

export function parseResponse(result) {
  const content = result.json();
  return result.ok ? content : content.then(error => Promise.reject(error));
}
