import { Disclosure } from '@headlessui/react';
import {
  AdjustmentsIcon,
  CheckCircleIcon,
  CollectionIcon,
  DatabaseIcon,
  DocumentReportIcon,
  MapIcon,
  MenuIcon,
  OfficeBuildingIcon,
  PlayIcon,
  PuzzleIcon,
  UserCircleIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline';
import mixpanel from 'mixpanel-browser';
import { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import NavbarUserMenu from './NavbarUserMenu';

import Button from '@common/Button';
import { Menu, MenuItem } from '@common/Menu';
import { UserInfoContext } from '@hoc/withUserInfo';
import TopicDNALogo from '@images/logoOnly.png';
import { getUserName } from '@utils/commonUtils';
import { getUserAvatar } from '@root/utils/avatar';
import { isNil } from 'lodash';

const DEFAULT_TITLE_CLASSES = [
  'border',
  'border-transparent',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-indigo-500',
  'focus:ring-offset-2',
  'font-medium',
  'hover:bg-gray-700',
  'inline-flex',
  'items-center',
  'ml-3',
  'px-4',
  'py-2',
  'rounded-md',
  'shadow-sm',
  'text-gray-300',
  'text-sm',
];

const menus = [
  {
    icon: AdjustmentsIcon,
    items: [
      {
        title: 'Ad Accounts',
        path: '/admin/ad_accounts/',
        icon: PuzzleIcon,
      },
      {
        title: 'Audience Library',
        path: '/admin/audience_library/',
        icon: UserGroupIcon,
      },
      {
        title: 'Campaign Performance',
        path: '/admin/campaign_performance/',
        icon: CollectionIcon,
      },
      {
        title: 'Insight Reports',
        path: '/admin/insight_reports/',
        icon: DocumentReportIcon,
      },
      {
        title: 'Organizations',
        path: '/admin/organizations/',
        icon: OfficeBuildingIcon,
      },
      {
        title: 'Users',
        path: '/admin/users/',
        icon: UserCircleIcon,
      },
    ],
    title: 'Manage',
  },
  {
    icon: PlayIcon,
    path: '/admin/running_campaigns/',
    title: 'Running Campaigns',
  },
  {
    icon: UserCircleIcon,
    path: '/admin/persona_library/',
    title: 'Personas',
  },
  {
    icon: DatabaseIcon,
    items: [
      {
        title: 'Verify',
        path: '/admin/queues/',
        icon: CheckCircleIcon,
      },
      {
        title: 'Entity Explorer',
        path: '/admin/entity_explorer/',
        icon: DatabaseIcon,
      },
      {
        title: 'Interest Explorer',
        path: '/admin/interests/',
        icon: MapIcon,
      },
    ],
    title: 'EADB',
  },
];

function renderNavLinks(history, pathname, mobile = false) {
  return (
    <>
      {menus.map((menu, index) => {
        const Icon = menu.icon;
        return isNil(menu.path) ? (
          <Menu
            isDark
            key={index}
            onClick={menu.path && (() => {
              mixpanel.track('Admin Navbar Menu Click', { section: menu.title });
              history.push(menu.path);
            })}
            placement="bottom-start"
            title={(
              <>
                <Icon className="inline mb-1 mr-2 h-5 w-6 text-indigo-300" />
                {menu.title}
              </>
            )}
          >
            {(menu.items || []).map((item, itemIndex) => (
              <MenuItem
                icon={item.icon}
                key={itemIndex}
                onClick={() => {
                  mixpanel.track('Admin Navbar Menu Click', { section: item.title });
                  history.push(item.path);
                }}
                title={item.title}
              />
            ))}
          </Menu>
        ) : (
          <Link
            className={DEFAULT_TITLE_CLASSES.join(' ')}
            to={menu.path}
          >
            <Icon className="inline mb-1 mr-2 h-5 w-6 text-indigo-300" />
            {menu.title}
          </Link>
        );
      })}
    </>
  );
}

export default function AdminNavbar() {
  // acquire location, history handler and user data
  const location = useLocation();
  const history = useHistory();
  const { logout, userInfo } = useContext(UserInfoContext);

  // render navigation bar
  return (
    <Disclosure
      as="nav"
      className="bg-gray-800"
      defaultOpen
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    alt="TopicDNA"
                    className="h-8 w-auto"
                    src={TopicDNALogo}
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {renderNavLinks(history, location.pathname)}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <NavbarUserMenu />
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className={
                    'bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-200 ' +
                    'hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 ' +
                    'focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white'
                  }
                >
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon
                      aria-hidden="true"
                      className="block h-6 w-6"
                    />
                  ) : (
                    <MenuIcon
                      aria-hidden="true"
                      className="block h-6 w-6"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {renderNavLinks(history, location.pathname, true)}
            </div>
            <div className="pt-4 pb-3 border-t border-indigo-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img
                    alt=""
                    className="h-10 w-10 rounded-full"
                    src={getUserAvatar(userInfo)}
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {getUserName(userInfo)}
                  </div>
                  <div className="text-sm font-medium text-indigo-300">{userInfo.email}</div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                <Button
                  onClick={logout}
                  title="Sign out"
                />
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
