import { issueRequest, Method } from '@utils/httpUtils';

export async function deleteOrganizationMember(organizationId: number, memberId: number): Promise<void> {
  await issueRequest<void>(`/v3/organizations/${organizationId}/members/${memberId}`, {
    method: Method.DELETE,
  });
}

export async function getCustomMetrics(organizationId: number, platform: string): Promise<Array<CustomMetric>> {
  const response = await issueRequest<Array<CustomMetric>>(
    `/v3/organizations/${organizationId}/custom_metrics?platform=${platform}`
  );
  return response.payload;
}

export async function getOrganization(organizationId: number): Promise<Organization> {
  const response = await issueRequest<Organization>(`/v3/organizations/${organizationId}`);
  return response.payload;
}

export async function updateOrganization(organization: Organization): Promise<void> {
  await issueRequest(`/v3/organizations/${organization.id}`, {
    body: { organization },
    method: Method.PUT,
  });
}

export interface Organization {
  id: number;
  avatar?: { default?: string; thumb?: string };
  name: string;
}

export interface CustomMetric {
  id: number;
}
