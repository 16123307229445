import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { updateOrganization } from '@api/organization';
import Button, { ButtonKind } from '@common/Button';
import ErrorMessage from '@common/ErrorMessage';
import FormAvatar from '@common/FormAvatar';
import { FormDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import { countryOptions } from '@utils/countryUtils';
import { notifyError, notifySuccess } from '@utils/toaster';
import { isEmail } from '@utils/validations';
import { generateThumbnailAvatar } from '@root/utils/avatar';

const CUSTOMER_STATUS_OPTIONS = [
  { label: 'Free', value: 'free' },
  { label: 'Trial', value: 'trial' },
  { label: 'Paid', value: 'paid' },
];

const PLAN_OPTIONS = [
  { label: 'Starter', value: 'starter' },
  { label: 'Pro', value: 'pro' },
];

const PRIORITY_OPTIONS = [
  { label: 'Superadmin', value: 999 },
  { label: 'High', value: 1 },
  { label: 'Normal', value: 0 },
];


export default function EditOrganizationForm(props) {
  // set up the form
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({ mode: 'onChange' });
  useEffect(
    () => {
      if (!isEmpty(props.organization)) {
        reset({
          adSpendPercentage: props.organization.percentOfSpend,
          country: countryOptions.find(option => option.value === props.organization.country),
          customerStatus: props.organization.status
            ? CUSTOMER_STATUS_OPTIONS.find(option => option.value === props.organization.status)
            : undefined,
          email: props.organization.email,
          name: props.organization.name,
          plan: props.organization.plan
            ? PLAN_OPTIONS.find(option => option.value === props.organization.plan)
            : undefined,
          priority: props.organization.priority
            ? PRIORITY_OPTIONS.find(option => option.value === props.organization.priority)
            : undefined,
          postalCode: props.organization.postalCode,
          photo: get(props.organization, 'avatar.default'),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEmpty(props.organization)]
  );
  const country = watch('country');
  const photo = watch('photo');
  const customerStatus = watch('customerStatus');
  const plan = watch('plan');
  const priority = watch('priority');

  // update organization
  const { error, mutate, isLoading: isUpdating } = useMutation(
    data => updateOrganization(
      {
        id: props.organization.id,
        percentOfSpend: data.adSpendPercentage !== undefined ? parseFloat(data.adSpendPercentage) : undefined,
        country: data.country ? data.country.value : '',
        email: data.email,
        name: data.name,
        photo: data.photo && data.photo.startsWith('data:') ? data.photo : undefined,
        postalCode: data.postalCode ? data.postalCode : '',
        priority: data.priority ? data.priority.value : undefined,
        status: data.customerStatus ? data.customerStatus.value : undefined,
        plan: data.plan ? data.plan.value : undefined,
      }
    ),
    {
      onError: error => notifyError({ title: 'Could not update. Please try again.' }),
      onSuccess: response => {
        notifySuccess({ title: 'Organization updated!' });
        props.onUpdate(response);
      },
    }
  );

  // avatar upload state
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);

  // render form
  return (
    <form onSubmit={handleSubmit(mutate)}>
      {error && <ErrorMessage message={error} />}
      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
        <div className="col-span-4 sm:col-span-3">
          <FormField
            error={errors.name}
            isDisabled={isUpdating}
            label="Name"
            name="name"
            register={() => register('name', { required: 'Organization name is required' })}
            type="text"
          />
        </div>

        <div className="col-span-4 sm:col-span-3">
          <FormField
            error={errors.email}
            isDisabled={isUpdating}
            label="Email address"
            name="email"
            register={() =>
              register('email', {
                required: 'Email is required',
                validate: isEmail,
              })
            }
            type="text"
          />
        </div>

        <div className="col-span-1 sm:col-span-3">
          <FormDropdown
            control={control}
            error={errors.country}
            isSearchable
            label="Country"
            name="country"
            options={countryOptions}
            value={country}
          />
        </div>

        <div className="col-span-4 sm:col-span-3">
          <FormField
            error={errors.postalCode}
            isDisabled={isUpdating}
            label="Postal code"
            name="postalCode"
            register={() => register('postalCode')}
            type="text"
          />
        </div>

        {props.isAdmin && (
          <>
            <div className="sm:col-span-1">
              <FormDropdown
                control={control}
                error={errors.customerStatus}
                label="Customer Status"
                name="customerStatus"
                options={CUSTOMER_STATUS_OPTIONS}
                value={customerStatus}
              />
            </div>

            <div className="sm:col-span-1">
              <FormField
                error={errors.adSpendPercentage}
                isDisabled={isUpdating}
                label="% of Ad Spend"
                max={100}
                min={0}
                name="adSpendPercentage"
                register={() => register('adSpendPercentage')}
                step={0.5}
                type="number"
              />
            </div>

            <div className="sm:col-span-1">
              <FormDropdown
                control={control}
                error={errors.plan}
                label="Plan"
                name="plan"
                options={PLAN_OPTIONS}
                value={plan}
              />
            </div>

            <div className="sm:col-span-1">
              <FormDropdown
                control={control}
                error={errors.priority}
                label="Priority"
                name="priority"
                options={PRIORITY_OPTIONS}
                value={priority}
              />
            </div>

          </>
        )}

        <div className="sm:col-span-3">
          <FormAvatar
            avatar={photo}
            isUploading={isUploadingPhoto}
            label="Photo"
            name="photo"
            placeholder={generateThumbnailAvatar((props.organization || {}).name)}
            setIsUploading={setIsUploadingPhoto}
            setValue={setValue}
          />
        </div>
      </div>
      <div className="mt-3 text-right rounded-b-lg">
        <Button
          isLoading={isUpdating}
          kind={ButtonKind.PRIMARY}
          title={isUpdating ? 'Updating...' : 'Update Organization'}
          type="submit"
        />
      </div>
    </form>
  );
}

EditOrganizationForm.propTypes = {
  isAdmin: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  organization: PropTypes.object,
};
