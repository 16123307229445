import { Steps } from 'antd';
import { pick, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button, { ButtonKind } from '@common/Button';

export function Wizard(props) {
  // index of the currently active step
  const [activeIndex, setActiveIndex] = useState(0);

  // render steps and contents
  const validChildren = props.children.filter(step => !!step);
  return (
    <>
      {/* <Steps
        current={activeIndex}
        progressDot
        size="small"
      >
        {validChildren.map((step, index) => React.cloneElement(step, { key: index, _shouldRenderStep: true }))}
      </Steps> */}
      {React.cloneElement(
        validChildren[activeIndex],
        {
          _goBack: activeIndex !== 0 ? () => setActiveIndex(activeIndex - 1) : null,
          _goForward: () => {
            if (activeIndex < size(validChildren) - 1) {
              setActiveIndex(activeIndex + 1);
            }
          },
          _index: activeIndex,
        }
      )}
    </>
  );
}

Wizard.propTypes = {
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])).isRequired,
};

const SPECIAL_PROPERTIES = [
  'active', 'iconPrefix', 'icons', 'onStepClick', 'prefixCls', 'progressDot', 'status', 'stepIcon', 'stepIndex',
  'stepNumber', 'wrapperStyle',
];

export function WizardStep(props) {
  // render step header
  if (props._shouldRenderStep) {
    return <Steps.Step {...{ ...pick(props, SPECIAL_PROPERTIES), title: props.title }} />;
  }

  // render step body
  const onSubmit = props.hasForm ? data => props.onSubmit(data) : () => props.onSubmit();
  const body = (
    <>
      {props.children}
      <div className="mt-4">
        {props._goBack && (
          <Button
            isDisabled={props.isLoading}
            onClick={() => props._goBack()}
            title="Back to Previous Step"
          />
        )}
        {!props.hasNoForwardButton && (
          <Button
            hasLeftMargin
            isDisabled={props.isForwardButtonDisabled}
            isLoading={props.isLoading}
            kind={ButtonKind.PRIMARY}
            onClick={props.hasForm ? null : onSubmit}
            title={props.forwardButtonTitle || 'Go to Next Step'}
            type={props.hasForm ? 'submit' : 'button'}
          />
        )}
      </div>
    </>
  );
  return props.hasForm ? <form onSubmit={props.onSubmit}>{body}</form> : <>{body}</>;
}

WizardStep.propTypes = {
  _goBack: PropTypes.func,
  _shouldRenderStep: PropTypes.bool,
  children: PropTypes.node.isRequired,
  forwardButtonTitle: PropTypes.string,
  hasForm: PropTypes.bool,
  hasNoForwardButton: PropTypes.bool,
  isForwardButtonDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
