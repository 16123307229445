export const CONVERSATION_TYPES = [
  { label: 'Actors', value: 'ACTORS' },
  { label: 'Athletes', value: 'ATHLETES' },
  { label: 'Book Genres', value: 'BOOK_GENRES' },
  { label: 'Books', value: 'BOOKS' },
  { label: 'Brand Categories', value: 'BRAND_CATEGORIES' },
  { label: 'Brands', value: 'BRANDS' },
  { label: 'Celebrities', value: 'CELEBRITIES' },
  { label: 'Coaches', value: 'COACHES' },
  { label: 'Digital Creators', value: 'DIGITAL_CREATORS' },
  { label: 'Entertainment Brands', value: 'ENTERTAINMENT_BRANDS' },
  { label: 'Entertainment Personalities', value: 'ENTERTAINMENT_PERSONALITIES' },
  { label: 'Fictional Characters', value: 'FICTIONAL_CHARACTERS' },
  { label: 'Journalists', value: 'JOURNALISTS' },
  { label: 'Lifestyles', value: 'LIFESTYLES' },
  { label: 'Movie Genres', value: 'MOVIE_GENRES' },
  { label: 'Movies', value: 'MOVIES' },
  { label: 'Music Genres', value: 'MUSIC_GENRES' },
  { label: 'Musicians', value: 'MUSICIANS' },
  { label: 'News Stories', value: 'NEWS_STORIES' },
  { label: 'News', value: 'NEWS' },
  { label: 'People', value: 'PERSONS' },
  { label: 'Places', value: 'PLACES' },
  { label: 'Podcasts', value: 'PODCASTS' },
  { label: 'Political Affiliations', value: 'POLITICAL_AFFILIATIONS' },
  { label: 'Politicians', value: 'POLITICIANS' },
  { label: 'Products', value: 'PRODUCTS' },
  { label: 'Radio Stations', value: 'RADIO_STATIONS' },
  { label: 'Sports Leagues', value: 'SPORTS_LEAGUES' },
  { label: 'Sports Personalities', value: 'SPORTS_PERSONALITIES' },
  { label: 'Sports Teams', value: 'SPORTS_TEAMS' },
  { label: 'Sports', value: 'SPORTS' },
  { label: 'Trends', value: 'TRENDS' },
  { label: 'TV Shows', value: 'TV_SHOWS' },
  { label: 'Video Game Platforms', value: 'VIDEO_GAME_PLATFORMS' },
  { label: 'Video Game Publishers', value: 'VIDEO_GAME_PUBLISHERS' },
  { label: 'Video Games', value: 'VIDEO_GAMES' },
];
export const EVENT_TYPES = [
  { label: 'Music & Entertainment', value: 'MUSIC_AND_ENTERTAINMENT' },
  { label: 'Sports', value: 'SPORTS' },
  { label: 'Holiday', value: 'HOLIDAY' },
  { label: 'Conference', value: 'CONFERENCE' },
  { label: 'Other', value: 'OTHER' },
];
