import { capitalize } from 'lodash';

import Button, { ButtonKind } from '@common/Button';
import Flag from '@common/Flag';
import { getIdColumn } from '@common/table/TableColumns';

export default function getOrganizationTableColumns() {
  /* eslint-disable react/prop-types */
  return [
    getIdColumn(),
    {
      accessor: 'name',
      alignment: 'left',
      Cell: ({ row: { original } }) => (
        <Button
          kind={ButtonKind.LINK}
          target={`/admin/organizations/${original.id}`}
        >
          {original.name}
        </Button>
      ),
      Header: 'NAME',
    },
    {
      accessor: 'status',
      alignment: 'center',
      Cell: ({ row: { original } }) => (
        <>
          {original.status === 'paid' ? `${capitalize(original.status)} (${original.percentOfSpend}%)` : 'Free'}
        </>
      ),
      Header: 'STATUS',
    },
    {
      accessor: 'plan',
      alignment: 'center',
      Cell: ({ row: { original } }) => (
        <>
          {capitalize(original.plan)}
        </>
      ),
      Header: 'Plan',
    },
    {
      accessor: 'priority',
      alignment: 'center',
      Cell: ({ row: { original } }) => (
        <>
          {original.priority}
        </>
      ),
      Header: 'Priority',
    },
    {
      accessor: 'country',
      alignment: 'center',
      Cell: ({ cell: { value } }) => value && (
        <Flag countryCode={value} />
      ),
      Header: 'COUNTRY',
    },
    {
      accessor: 'membersCount',
      alignment: 'center',
      disableSortBy: true,
      Header: 'MEMBERS',
    },
  ];
  /* eslint-enable react/prop-types */
}
