import PropTypes from 'prop-types';

export default function Placeholder(props) {
  return (
    <>
      <div className="bg-white shadow rounded-lg px-10 py-12 grid grid-cols-3 gap-16 items-center">
        <div className="col-span-2">
          <h2 className="text-3xl text-gray-900 font-medium">{props.title}</h2>
          <p className="mt-2 text-gray-700 leading-8 mb-8 text-xl">{props.description}</p>
          {props.children}
        </div>
        <div>
          <img
            alt=""
            src={props.placeholder}
          />
        </div>
      </div>
    </>
  );
}

Placeholder.propTypes = {
  children: PropTypes.element,
  description: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videoId: PropTypes.string,
  videoTitle: PropTypes.string,
};
