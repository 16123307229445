import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef } from 'react';

const CLASS_NAMES = [
  'border-gray-300',
  'focus:ring-indigo-500',
  'h-4',
  'rounded',
  'text-indigo-600',
  'w-4',
];

// https://react-table.tanstack.com/docs/examples/row-selection
const IndeterminateCheckbox = forwardRef(({ indeterminate, isDisabled, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input
      className={[...CLASS_NAMES, ...(rest.isDisabled ? ['opacity-50'] : [])].join(' ')}
      disabled={isDisabled}
      ref={resolvedRef}
      type="checkbox"
      {...rest}
    />
  );
});
export default IndeterminateCheckbox;

IndeterminateCheckbox.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  indeterminate: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};
