import PropTypes from 'prop-types';
import { useContext } from 'react';
import semver from 'semver';

import { refreshCacheAndReload } from '../../CacheBuster';

import DashboardContent from './DashboardContent';
import UnauthenticatedContent from './UnauthenticatedContent';

import VersionsListener from '@common/WebSocket/VersionsListener';
import withOrganizationData from '@hoc/withOrganizationData';
import withQueryClient from '@hoc/withQueryClient';
import withToolTip from '@hoc/withToolTip';
import { UserInfoContext } from '@hoc/withUserInfo';

function Layout(props) {
  // acquire user data
  const { userInfo } = useContext(UserInfoContext);

  const Content = userInfo !== undefined ? DashboardContent : UnauthenticatedContent;

  // render content
  return (
    <>
      <div className="App min-h-screen bg-gray-100">
        <Content {...props} />
      </div>

      <VersionsListener
        responseCallback={response => {
          const latestVersion = response;
          const currentVersion = global.appVersion;
          const shouldForceRefresh = semver.gt(latestVersion, currentVersion);
          if (shouldForceRefresh) {
            // eslint-disable-next-line no-console
            console.log(`We have a new version - ${latestVersion}. Refreshing...`);
            let caches;
            caches = typeof caches === 'undefined' || !caches ? undefined : caches;
            refreshCacheAndReload(caches);
          }
        }}
        userUid={userInfo?.uid}
      />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withToolTip(withQueryClient(withOrganizationData(Layout)));
