import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { createAdAudience } from '@api/audiences';
import Button, { ButtonKind } from '@common/Button';
import ErrorMessage from '@common/ErrorMessage';
import { WizardStep } from '@common/Wizard';
import AudienceEditor from '@components/shared/targetingEditor/AudienceEditor';

export default function FromScratchForm(props) {
  // acquire history handler
  const history = useHistory();

  // create audience
  const { error, isLoading: isUpdating, mutate } = useMutation(
    data => createAdAudience(props.platform, data),
    { onSuccess: () => history.push('/audiences/') }
  );

  // audience data and loading status
  const submit = useRef(() => true);
  const [isLoading, setIsLoading] = useState(false);

  // render step
  return (
    <WizardStep
      hasNoForwardButton
      title="Create the Target Audience"
      {...props}
    >
      {props.platform && (
        <>
          {error && <ErrorMessage message={error.message} />}
          <h1 className="text-xl font-semibold">
            Create your target audience from scratch
          </h1>
          <AudienceEditor
            isUpdating={isUpdating}
            platform={props.platform}
            setIsLoading={setIsLoading}
            setSubmit={func => {
              submit.current = func;
            }}
          >
            <Button
              isDisabled={isLoading}
              isFullWidth
              isLoading={isUpdating}
              kind={ButtonKind.PRIMARY}
              onClick={() => {
                mixpanel.track('Create Target Audience', { platform: props.platform, audience_type: 'From Scratch' });
                submit.current(mutate);
              }}
              title="Create Target Audience"
            />
          </AudienceEditor>
        </>
      )}
    </WizardStep>
  );
}

FromScratchForm.propTypes = {
  platform: PropTypes.string,
};
