import { Queue, QueueDetails, QueueItem, QueueWithStats } from '@models/queue';
import { issueRequest, Method } from '@utils/httpUtils';

export async function assignItems(queueId: number, userId: number, count: number): Promise<void> {
  const body = { userId, count };
  await issueRequest(`/v3/queues/${queueId}/assignments`, { body, method: Method.POST });
}

export async function createQueue(queue: Queue, data: string): Promise<number> {
  const response = await issueRequest<number>(
    '/v3/queues',
    {
      body: { ...queue, data },
      method: Method.POST,
    }
  );
  return response.payload;
}

export async function deleteQueue(queueId: number): Promise<void> {
  await issueRequest(`/v3/queues/${queueId}`, { method: Method.DELETE });
}

export async function getAssignedItems(queueId: number, userId: number): Promise<QueueItem[]> {
  const response = await issueRequest<QueueItem[]>(`/v3/queues/${queueId}/assignments`, { queryParams: { userId } });
  return response.payload;
}

export async function getQueueDetails(queueId: number): Promise<QueueDetails> {
  const response = await issueRequest<QueueDetails>(`/v3/queues/${queueId}`);
  return response.payload;
}

export async function getQueues(): Promise<QueueWithStats[]> {
  const response = await issueRequest<QueueWithStats[]>('/v3/queues');
  return response.payload;
}

export async function importQueue(queueId: number): Promise<void> {
  await issueRequest(`/v3/queues/${queueId}/import`, { method: Method.POST });
}

export async function submitValues(queueId: number, values: Record<number, Record<string, string>>): Promise<void> {
  await issueRequest(`/v3/queues/${queueId}/verifications`, { body: values, method: Method.POST });
}

export async function unassignItems(queueId: number, userId: number): Promise<void> {
  await issueRequest(`/v3/queues/${queueId}/assignments`, { method: Method.DELETE, queryParams: { userId } });
}

export async function updateQueue(queue: Queue): Promise<void> {
  await issueRequest<void>(`/v3/queues/${queue.id!}`, { body: queue, method: Method.PUT });
}
