import { useContext } from 'react';

import Card from '@common/Card';
import EditUserForm from '@components/shared/EditUserForm';
import { UserInfoContext } from '@hoc/withUserInfo';

export default function UserProfileTab() {
  // acquire user data
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  // render view
  return (
    <Card
      subtitle="Update your account information."
      title="Account Details"
    >
      <EditUserForm
        onUpdate={setUserInfo}
        user={userInfo}
      />
    </Card>
  );
}
