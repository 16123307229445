import { DocumentReportIcon, DuplicateIcon } from '@heroicons/react/outline';
import { AtSymbolIcon, UserGroupIcon } from '@heroicons/react/solid';
import { isNil } from 'lodash';
import { useContext, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import getInsightReportTableColumns from './insightReportTableColumns';
import DeleteInsightReportModal from './modals/DeleteInsightReportModal';

import { getInsightReportPdf, getInsightReports } from '@api/insightReports';
import Button from '@common/Button';
import DownloadDialog from '@common/DownloadDialog';
import { Menu, MenuItem } from '@common/Menu';
import NoDataMessage from '@common/NoDataMessage';
import Placeholder from '@common/Placeholder';
import Section from '@common/Section';
import RemoteTable from '@common/RemoteTable';
import InsightReportListener from '@common/WebSocket/InsightReportListener';
import DownloadSegmentationDialog from '@components/shared/DownloadSegmentationDialog';
import EditInsightReportModal from '@components/shared/EditInsightReportModal';
import { UserInfoContext } from '@hoc/withUserInfo';
import NoReportsPlaceholder from '@images/no_reports.png';
import { FormDropdown } from '@common/FormDropdowns';

const TYPE_OPTIONS = [
  { label: 'Twitter Audience', value: 'twitter_audience' },
  { label: 'Twitter List', value: 'twitter_list' },
  { label: 'Custom', value: 'custom' },
];

export default function InsightReportSection() {
  // acquire query parameters, history handler, user data and view states
  const { userInfo } = useContext(UserInfoContext);

  // get table reference
  const tableRef = useRef({});

  // clear state if required
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const shouldClearState = !isNil(queryParams.get('clearState'));
  const history = useHistory();
  if (shouldClearState && tableRef.current.clearState) {
    tableRef.current.clearState();
    history.replace({ search: '' });
  }

  // view and modal states
  const [isDataEmpty, setIsDataEmpty] = useState();
  const [paramsOfDeleteInsightReportModal, setParamsOfDeleteInsightReportModal] = useState({});
  const [paramsOfDownloadSegmentationDialog, setParamsOfDownloadSegmentationDialog] = useState({ isOpen: false });
  const [paramsOfDownloadSummaryDialog, setParamsOfDownloadSummaryDialog] = useState({ isOpen: false });
  const [paramsOfEditInsightReportModal, setParamsOfEditInsightReportModal] = useState({ isOpen: false });

  // selection state
  const [selectedType, setSelectedType] = useState();

  // report creation menu
  const menu = (
    <Menu
      placement="bottom-end"
      size="lg"
      title="New Insight Report"
    >
      {
        userInfo.admin ? (
          <>
            <MenuItem
              description={
                'Profile the followers of any Twitter account to find out their demographics, interests and passions.'
              }
              icon={UserGroupIcon}
              onClick={() => history.push('new_twitter_report/')}
              title="Twitter Audience Insight"
            />
            {/* <MenuItem
        description={
          'Profile the people in an email list to find out their demographics, interests and passions.'
        }
        icon={MailIcon}
        onClick={() => history.push('new_email_report/')}
        title="Email List Insight"
      /> */}
            <MenuItem
              description={
                'Upload Twitter handles or IDs to find out their demographics, interests and passions.'
              }
              icon={AtSymbolIcon}
              onClick={() => history.push('new_twitter_list_report/')}
              title="Twitter List Insight"
            />

          </>
        ) : (
          <NoDataMessage message="Please contact sales@personalyze.co if you would like to run insight reports." />
        )
      }
    </Menu>
  );

  // render insight report section
  const columns = getInsightReportTableColumns(
    false,
    {
      openDeleteDialog: insightReport => setParamsOfDeleteInsightReportModal({ insightReport }),
      openEditDialog: insightReport => setParamsOfEditInsightReportModal({ isOpen: true, insightReport }),
      openDownloadSegmentationDialog: insightReport =>
        setParamsOfDownloadSegmentationDialog({ isOpen: true, id: insightReport.id }),
      openDownloadSummaryDialog: insightReport =>
        setParamsOfDownloadSummaryDialog({ isOpen: true, id: insightReport.id }),
    }
  );
  return (
    <>
      <Section
        heading={
          <div className="inline-flex items-center">
            {isDataEmpty === false && (
              <Button
                icon={DuplicateIcon}
                target="/insight_reports/comparison/"
                title="Compare Audience Insights"
              />
            )}
            <div>
              {menu}
            </div>
          </div>
        }
        icon={DocumentReportIcon}
        title="Insight Reports"
      >
        {isDataEmpty === true ? (
          <Placeholder
            description="Add colour to your audience with richer insights powered by our TopicDNA Knowledge Graph."
            placeholder={NoReportsPlaceholder}
            title="Bring your audience to life"
          />
        ) : (
          <RemoteTable
            columns={columns}
            entitiesName="insight reports"
            extra={
              <div className="w-56">
                <FormDropdown
                  isClearable
                  onSelection={setSelectedType}
                  options={TYPE_OPTIONS}
                  placeholder="Filter by report type..."
                  value={selectedType}
                />
              </div>
            }
            filters={[{ id: 'report_type', value: selectedType?.value }]}
            isSearchable
            query={(...args) =>
              getInsightReports(...args).then(response => {
                const { payload: { data }, meta } = response;
                return { payload: data, meta };
              })
            }
            queryKey="insightReports"
            ref={tableRef}
            setIsDataEmpty={setIsDataEmpty}
            shouldClearState={shouldClearState}
            stateStorageKey="table"
          />
        )}
      </Section>

      {tableRef.current.refetch && (
        <InsightReportListener
          responseCallback={tableRef.current.refetch}
          userUid={userInfo.uid}
        />
      )}

      <DeleteInsightReportModal
        close={() => setParamsOfDeleteInsightReportModal({})}
        onUpdate={tableRef.current.refetch}
        params={paramsOfDeleteInsightReportModal}
      />
      <EditInsightReportModal
        close={() => setParamsOfEditInsightReportModal({ isOpen: false })}
        onUpdate={tableRef.current.refetch}
        params={paramsOfEditInsightReportModal}
      />
      <DownloadDialog
        close={() => setParamsOfDownloadSummaryDialog({ ...paramsOfDownloadSummaryDialog, isOpen: false })}
        extractDocumentUrl={response => response.downloadUrl}
        params={paramsOfDownloadSummaryDialog}
        query={params => getInsightReportPdf(params.id)}
        queryKey={params => ['insightReportPdf', params.id]}
      />
      <DownloadSegmentationDialog
        close={() => setParamsOfDownloadSegmentationDialog({ ...paramsOfDownloadSegmentationDialog, isOpen: false })}
        params={paramsOfDownloadSegmentationDialog}
      />
    </>
  );
}
