import { Switch } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import FormLabel from './FormLabel';

const OUTER_CLASS_NAMES = ['h-6', 'inline-flex', 'items-center', 'relative', 'rounded-full', 'w-11'];
const INNER_CLASS_NAMES = ['bg-white', 'h-4', 'inline-block', 'rounded-full', 'transform', 'w-4'];

export default function FormToggle(props) {
  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Switch.Group>
            <div className="flex items-center">
              {props.secondaryTitle && (
                <Switch.Label className="mr-4">
                  {props.secondaryTitle}
                </Switch.Label>
              )}
              <Switch
                checked={value}
                className={`${value ? 'bg-blue-600' : 'bg-gray-200'} ${OUTER_CLASS_NAMES.join(' ')}`}
                onChange={onChange}
              >
                <span className={`${value ? 'translate-x-6' : 'translate-x-1'} ${INNER_CLASS_NAMES.join(' ')}`} />
              </Switch>
              {props.primaryTitle && (
                <Switch.Label className="ml-4">
                  {props.primaryTitle}
                </Switch.Label>
              )}
            </div>
          </Switch.Group>
        )}
      />
    </>
  );
}

FormToggle.propTypes = {
  control: PropTypes.object,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  primaryTitle: PropTypes.string,
  secondaryTitle: PropTypes.string,
};
