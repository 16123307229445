import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import { deleteInsightReport } from '@api/insightReports';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { notifyError, notifySuccess } from '@utils/toaster';

export default function DeleteInsightReportModal(props) {
  // extract parameters
  const isOpen = !isEmpty(props.params);
  const { insightReport = {} } = props.params;

  // delete report
  const { error, mutate, isLoading: isDeleting } = useMutation(
    () => deleteInsightReport(insightReport.id),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Report deleted!' });
        props.close();
        props.onUpdate();
      },
      onError: error => notifyError({ title: 'Could not delete. Please try again.' }),
    }
  );

  // render dialog
  return (
    <Dialog
      close={props.close}
      isDangerous
      isOpen={isOpen}
      isUpdating={isDeleting}
      onSubmit={mutate}
      submitTitle="Delete Report"
      title="Delete Report"
      updatingTitle="Deleting..."
    >
      {error && <ErrorMessage message={error.message} />}
      <div className="mt-4 mb-8 text-gray-900">
        Are you sure you want to delete <span className="font-medium">{insightReport.name}</span>? This action cannot be
        undone.
      </div>
    </Dialog>
  );
}

DeleteInsightReportModal.propTypes = {
  close: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
