import { size, startCase } from 'lodash';

import { getTargetingItem } from '@utils/generalUtils';

export function getTikTokDefaults(targeting) {
  // extract properties
  const gender = getTargetingItem(targeting, 'gender');
  const minAge = getTargetingItem(targeting, 'minAge');
  const maxAge = getTargetingItem(targeting, 'maxAge');
  const countries = (getTargetingItem(targeting, 'countries') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const interests = (getTargetingItem(targeting, 'interests') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const topics = (getTargetingItem(targeting, 'topics') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const creatorCategories = (getTargetingItem(targeting, 'creatorCategories') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const videoCategories = (getTargetingItem(targeting, 'videoCategories') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const hashtags = (getTargetingItem(targeting, 'hashtags') || [])
    .map(item => ({ value: item.id, label: item.name }));

  // return default values
  return {
    countries,
    gender: { value: gender || '', label: startCase(gender || 'all genders') },
    interest: interests,
    maxAge: maxAge && { value: maxAge, label: maxAge.toString() },
    minAge: minAge && { value: minAge, label: minAge.toString() },
    topics,
    creatorCategories,
    videoCategories,
    hashtags,
  };
}

export function calculateTikTokTargeting(values) {
  const countries = values.countries.map(item => ({ id: item.value, name: item.label }));
  const interests = values.interest.map(item => ({ id: item.value, name: item.label }));
  const topics = values.topics.map(item => ({ id: item.value, name: item.label }));
  const creatorCategories = values.creatorCategories.map(item => ({ id: item.value, name: item.label }));
  const videoCategories = values.videoCategories.map(item => ({ id: item.value, name: item.label }));
  const hashtags = values.hashtags.map(item => ({ id: item.value, name: item.label }));
  return {
    unifiedTargeting: {
      include: [
        ...(size(countries) ? [{ countries }] : []),
        ...(values.gender.value ? [{ gender: values.gender.value }] : []),
        ...(size(interests) ? [{ interests }] : []),
        ...(values.maxAge ? [{ maxAge: values.maxAge.value }] : []),
        ...(values.minAge ? [{ minAge: values.minAge.value }] : []),
        ...(size(topics) ? [{ topics }] : []),
        ...(size(creatorCategories) ? [{ creatorCategories }] : []),
        ...(size(videoCategories) ? [{ videoCategories }] : []),
        ...(size(hashtags) ? [{ hashtags }] : []),
      ],
    },
  };
}
