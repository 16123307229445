//move all color-related utils into here in a separate ticket

const COLORS = {
  transparent: 'transparent',
  white: '#fff',
  black: '#000',
  indigo: {
    50: '#EEF2FF',
    100: '#E0E7FF',
    200: '#C7D2FE',
    300: '#A5B4FC',
    400: '#818CF8',
    500: '#6366F1',
    600: '#4F46E5',
    700: '#4338CA',
    800: '#3730A3',
    900: '#312E81',
  },
  blue: {
    50: '#EFF6FF',
    100: '#DBEAFE',
    200: '#BFDBFE',
    300: '#93C5FD',
    400: '#60A5FA',
    500: '#3b82f6',
    600: '#2563eb',
    700: '#1D4ED8',
    800: '#1E40AF',
    900: '#1E3A8A',
  },
  cyan: {
    50: 'hsl(171, 82%, 94%)',
    100: 'hsl(172, 97%, 88%)',
    200: 'hsl(174, 96%, 78%)',
    300: 'hsl(176, 87%, 67%)',
    400: 'hsl(178, 78%, 57%)',
    500: 'hsl(180, 77%, 47%)',
    600: 'hsl(182, 85%, 39%)',
    700: 'hsl(184, 90%, 34%)',
    800: 'hsl(186, 91%, 29%)',
    900: 'hsl(188, 91%, 23%)',
  },
  grey: {
    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#d1d5db',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '111827',
  },
  pink: {
    50: 'hsl(341, 100%, 95%)',
    100: 'hsl(338, 100%, 86%)',
    200: 'hsl(336, 100%, 77%)',
    300: 'hsl(334, 86%, 67%)',
    400: 'hsl(330, 79%, 56%)',
    500: 'hsl(328, 85%, 46%)',
    600: 'hsl(326, 90%, 39%)',
    700: 'hsl(324, 93%, 33%)',
    800: 'hsl(322, 93%, 27%)',
    900: 'hsl(320, 100%, 19%)',
  },
  red: {
    50: 'hsl(360, 100%, 95%)',
    100: 'hsl(360, 100%, 87%)',
    200: 'hsl(360, 100%, 80%)',
    300: 'hsl(360, 91%, 69%)',
    400: 'hsl(360, 83%, 62%)',
    500: 'hsl(356, 75%, 53%)',
    600: 'hsl(354, 85%, 44%)',
    700: 'hsl(352, 90%, 35%)',
    800: 'hsl(350, 94%, 28%)',
    900: 'hsl(348, 94%, 20%)',
  },
  yellow: {
    50: 'hsl(49, 100%, 96%)',
    100: 'hsl(48, 100%, 88%)',
    200: 'hsl(48, 95%, 76%)',
    300: 'hsl(48, 94%, 68%)',
    400: 'hsl(44, 92%, 63%)',
    500: 'hsl(42, 87%, 55%)',
    600: 'hsl(36, 77%, 49%)',
    700: 'hsl(29, 80%, 44%)',
    800: 'hsl(22, 82%, 39%)',
    900: 'hsl(15, 86%, 30%)',
  },
  green: {
    50: 'hsl(125, 65%, 93%)',
    100: 'hsl(127, 65%, 85%)',
    200: 'hsl(124, 63%, 74%)',
    300: 'hsl(123, 53%, 55%)',
    400: 'hsl(123, 57%, 45%)',
    500: 'hsl(122, 73%, 35%)',
    600: 'hsl(122, 80%, 29%)',
    700: 'hsl(125, 79%, 26%)',
    800: 'hsl(125, 86%, 20%)',
    900: 'hsl(125, 97%, 14%)',
  },
};

export default COLORS;
