import { size } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';

import ErrorMessage from '@common/ErrorMessage';
import { joinHumanReadably } from '@utils/generalUtils';

const CONTAINER_CLASS_NAMES = [
  'bg-gray-50',
  'border',
  'border-dashed',
  'border-gray-300',
  'cursor-pointer',
  'flex',
  'h-full',
  'items-center',
  'rounded-lg',
  'text-gray-900',
  'w-full',
];

export default function FormFileUpload(props) {
  function render(onChange, onBlur) {
    const humanReadableExtensions = joinHumanReadably(props.extensions);
    return (
      <div
        className={CONTAINER_CLASS_NAMES.join(' ')}
        {...getRootProps()}
      >
        <input
          name={props.name}
          {...getInputProps()}
        />
        {props.isUploading && <p>Uploading...</p>}
        {fileRejections.length > 0 && <ErrorMessage message={fileRejections[0].errors[0].message} />}
        <div className="mx-auto text-center">
          {filename && (
            <h1 className="mt-8 text-lg font-semibold text-indigo-500">
              {filename}
            </h1>
          )}
          <p className="mt-8 text-base text-gray-500">
            {filename
              ? `Drag and drop or select ${props.aLabel} to replace the current one`
              : `Drag and drop your ${props.label} here or click to select a file`}
          </p>
          <span className="text-sm text-indigo-500">
            Only {humanReadableExtensions} files are supported
          </span>
          <div className="mt-8">
            {props.error && <ErrorMessage message={props.error.message} />}
          </div>
        </div>
      </div>
    );
  }

  // set up the drop zone
  const onDrop = useCallback(
    acceptedFiles => {
      if (size(acceptedFiles) !== 0) {
        const file = acceptedFiles[0];
        props.setIsUploading(true);
        const reader = new FileReader();
        reader.onload = () => {
          setFilename(file.name);
          props.setValue(reader.result);
          if (props.setFilename) {
            props.setFilename(file.name);
          }
        };
        reader.onloadend = () => props.setIsUploading(false);
        reader.readAsDataURL(file);
      }
    },
    []
  );
  const { fileRejections, getInputProps, getRootProps } = useDropzone({
    accept: props.extensions.join(', '),
    onDrop,
  });

  // name of uploaded file
  const [filename, setFilename] = useState();

  // render file uploader
  return props.control ? (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field: { onChange, onBlur } }) => render(onChange, onBlur)}
      rules={props.rules}
    />
  ) : (
    render(() => true, () => true)
  );
}

FormFileUpload.propTypes = {
  aLabel: PropTypes.string.isRequired,
  control: PropTypes.object,
  error: PropTypes.object,
  extensions: PropTypes.arrayOf(PropTypes.string),
  isUploading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  rules: PropTypes.object,
  setFilename: PropTypes.func,
  setIsUploading: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};
