import { CheckCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { deleteQueue, getQueues } from '@api/queue';
import Button, { ButtonKind } from '@common/Button';
import { CommonDialogParams } from '@common/commonTypes';
import DeleteDialog, { DeleteDialogParams } from '@common/DeleteDialog';
import Section from '@common/Section';
import Table from '@common/Table';
import { QueueWithStats } from '@models/queue';

import CreateOrEditQueueDialog, { CreateOrEditQueueDialogParams } from './CreateOrEditQueueDialog';
import getQueueTableColumns from './queueTableColumns';
import AssignItemsDialog, { AssignItemsDialogParams } from './AssignItemsDialog';
import { isNil } from 'lodash';
import ErrorMessage from '@common/ErrorMessage';
import { ErrorResponse } from '@utils/httpUtils';
import ImportDialog, { ImportDialogParams } from './ImportDialog';

export default function Queues(): JSX.Element {
  // query queues
  const {
    data: queues = [], // to avoid undefined in the type of queues
    error: errorLoadingQueues,
    isFetching: isLoadingQueues,
    refetch: refetchQueues,
  } = useQuery<QueueWithStats[], ErrorResponse>(
    ['queues'],
    getQueues,
    { initialData: [] }
  );

  // dialog states
  const [paramsOfAssignDialog, setParamsOfAssignDialog] =
      useState<CommonDialogParams & AssignItemsDialogParams>({ isOpen: false });
  const [paramsOfCreateOrEditDialog, setParamsOfCreateOrEditDialog] =
      useState<CommonDialogParams & CreateOrEditQueueDialogParams>({ isOpen: false });
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] =
      useState<CommonDialogParams & DeleteDialogParams>({ isOpen: false });
  const [paramsOfImportDialog, setParamsOfImportDialog] =
      useState<CommonDialogParams & ImportDialogParams>({ isOpen: false });

  // render content
  return (
    <>
      <Section
        heading={(
          <Button
            kind={ButtonKind.SUCCESS}
            onClick={() => setParamsOfCreateOrEditDialog({ isOpen: true })}
            title="Create Queue"
          />
        )}
        icon={CheckCircleIcon}
        title="Queues"
      >
        {!isNil(errorLoadingQueues) && <ErrorMessage message={errorLoadingQueues.message} />}
        <Table
          columns={getQueueTableColumns({
            openAssignDialog: (queue: QueueWithStats) =>
              setParamsOfAssignDialog({
                isOpen: true,
                queueId: queue.id,
                queueName: queue.name,
                unassignedItemCount: queue.unassignedItems,
              }),
            openDeleteDialog: (queue: QueueWithStats) =>
              setParamsOfDeleteDialog({ id: queue.id, isOpen: true, name: queue.name }),
            openEditDialog: (queue: QueueWithStats) => setParamsOfCreateOrEditDialog({ isOpen: true, queue }),
            openImportDialog: (queue: QueueWithStats) => setParamsOfImportDialog({
              isOpen: true,
              queueId: queue.id,
              queueName: queue.name,
              unprocessedItems: queue.unprocessedItems,
            }),
          })}
          data={queues}
          isLoading={isLoadingQueues}
        />
      </Section>

      <AssignItemsDialog
        close={() => setParamsOfAssignDialog({ ...paramsOfAssignDialog, isOpen: false })}
        onUpdate={() => refetchQueues()}
        params={paramsOfAssignDialog}
      />
      <CreateOrEditQueueDialog
        close={() => setParamsOfCreateOrEditDialog({ ...paramsOfCreateOrEditDialog, isOpen: false })}
        onUpdate={() => refetchQueues()}
        params={paramsOfCreateOrEditDialog}
      />
      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ ...paramsOfDeleteDialog, isOpen: false })}
        delete={deleteQueue}
        entityName="queue"
        onUpdate={() => refetchQueues()}
        params={paramsOfDeleteDialog}
      />
      <ImportDialog
        close={() => setParamsOfImportDialog({ ...paramsOfImportDialog, isOpen: false })}
        params={paramsOfImportDialog}
      />
    </>
  );
}
