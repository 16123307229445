import Badge, { BadgeContext, BadgeSize } from '@common/Badge';
import { AdSetCell, StatCell } from '@common/table/TableCells';
import { formatMoneyAmount, formatPercentage } from '@utils/formattingUtils';

function determineAdSetHighlightColor(row) {
  if (row.audienceType === 'prospecting') {
    return row.status === 'PAUSED' ? 'normal' : 'info';
  }
  if (row.audienceType === 'tdna') {
    return row.benchmarkComparison > 0 ? 'danger' : 'success';
  }
  return 'normal';
}

function compareNumericString(rowA, rowB, id, desc) {
  let a = Number.parseFloat(rowA.values[id][id]);
  let b = Number.parseFloat(rowB.values[id][id]);

  if (Number.isNaN(a)) { // Blanks and non-numeric strings to bottom
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
}

export default function getCampaignGroupAdSetTableColumns(currency) {
  /* eslint-disable react/prop-types */
  return [
    {
      accessor: row => row,
      alignment: 'left',
      Cell: ({ row: { original } }) => {
        return (
          <AdSetCell
            context={determineAdSetHighlightColor(original)}
            description={original.campaignName}
            isDisabled={original.status === 'PAUSED'}
          >
            {original.adsetName || 'Unnamed Ad Set'}
          </AdSetCell>
        );
      },
      cellWidth: '400px',
      Header: 'Ad Set',
      id: 'adSet',
    },
    {
      accessor: row => row,
      alignment: 'center',
      Cell: ({ row: { original } }) => {
        return (
          <Badge
            context={original.status === 'ACTIVE' ? BadgeContext.SUCCESS : BadgeContext.WARNING}
            size={BadgeSize.SMALL}
            text={original.status === 'ACTIVE' ? 'ACTIVE' : 'PAUSED'}
          />
        );
      },
      Header: 'Status',
      id: 'status',
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <StatCell
          isDisabled={original.status === 'PAUSED'}
          value={formatMoneyAmount(original.spend, currency)}
        />
      ),
      Header: 'spend',
      id: 'spend',
      sortType: compareNumericString,
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <StatCell
          isDisabled={original.status === 'PAUSED'}
          value={original.results}
        />
      ),
      Header: 'Results',
      id: 'results',
      sortType: compareNumericString,
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <StatCell
          isDisabled={original.status === 'PAUSED'}
          value={formatMoneyAmount(original.cpr, currency)}
        />
      ),
      cellWidth: '120px',
      Header: 'Cost Per Result',
      id: 'cpr',
      sortType: compareNumericString,
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <StatCell
          isDisabled={original.status === 'PAUSED'}
          value={formatPercentage(original.trend * 100, 0)}
        />
      ),
      Header: 'Trend',
      id: 'trend',
      sortType: compareNumericString,
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <StatCell
          isDisabled={original.status === 'PAUSED'}
          value={formatPercentage(original.benchmarkComparison * 100, 0)}
        />
      ),
      Header: 'Benchmark',
      id: 'benchmarkComparison',
      sortType: compareNumericString,
    },
  ];
  /* eslint-enable react/prop-types */
}
