import { DocumentReportIcon, LightningBoltIcon, UserGroupIcon } from '@heroicons/react/outline';

import TopicDNALogo from '@images/logo_dark.png';
import TOUR_STYLES from '@utils/pageUtils';

const config = [
  {
    content: () => {
      return (
        <div>
          <img
            alt="TopicDNA"
            className="h-10 mb-6"
            src={TopicDNALogo}
          />
          <h1 className="mt-4 text-3xl font-medium">Welcome to TopicDNA!</h1>
          <p className="my-4 text-xl font-normal text-gray-700">
            You&apos;re on your way to supercharging your digital ad campaigns with relevant, data-driven audiences.
          </p>
          <p className="text-xl text-gray-700">
            Let&apos;s get started!
          </p>
        </div>
      );
    },
    style: TOUR_STYLES,
  },
  {
    selector: '[data-tut="reactour_audience_library"]',
    // eslint-disable-next-line react/display-name
    content: () => {
      return (
        <>
          <div className="inline-flex">
            <UserGroupIcon className="flex-shrink-0 mr-3 h-6 w-6 text-indigo-700" />
            <p className="text-xl">Audience Library</p>
          </div>
          <p className="my-4 font-normal">
            Create audiences for all leading social ads platforms easily and add them to your campaigns in a few clicks.
          </p>
        </>
      );
    },
    style: TOUR_STYLES,
  },
  {
    selector: '[data-tut="reactour_insight_reports"]',
    // eslint-disable-next-line react/display-name
    content: () => {
      return (
        <>
          <div className="inline-flex">
            <DocumentReportIcon className="flex-shrink-0 mr-3 h-6 w-6 text-indigo-700" />
            <p className="text-xl">Insight Reports</p>
          </div>
          <p className="my-4 font-normal">
            Bring your brand&apos;s Twitter audience or email list to life with our automated insight reports.
          </p>
        </>
      );
    },
    style: TOUR_STYLES,
  },
  {
    selector: '[data-tut="reactour_campaign_performance"]',
    // eslint-disable-next-line react/display-name
    content: () => {
      return (
        <>
          <div className="inline-flex">
            <LightningBoltIcon className="flex-shrink-0 mr-3 h-6 w-6 text-indigo-700" />
            <p className="text-xl">Campaign Performance</p>
          </div>
          <p className="my-4 font-normal">
            Monitor currently running campaigns across all leading social ads platforms and quickly download reports.
          </p>
        </>
      );
    },
    style: TOUR_STYLES,
  },
  {
    selector: '[data-tut="reactour_user_nav"]',
    content: () => {
      return (
        <p className="my-4 font-normal">
          View and update your profile here.
        </p>
      );
    },
    style: TOUR_STYLES,
  },
];

export default config;
