import COLORS from '@utils/colorsUtils';

const AGE_RANGES = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

export default function getAgeByGenderChartConfig(data) {
  return {
    chart: {
      type: 'bar',
      height: '200px',
    },
    xAxis: [
      {
        gridLineWidth: 0,
        categories: AGE_RANGES,
        reversed: false,
        labels: {
          step: 1,
        },
      },
    ],
    yAxis: {
      gridLineWidth: 0.4,
      title: {
        text: null,
      },
      labels: {
        formatter: function () {
          return `${Math.abs(this.value)}%`;
        },
      },
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        let label = `${this.series.name}, ${this.point.category}`;
        let percentOfPopulation = Math.abs(this.point.y).toFixed(2);
        return `<b>${label}</b><br/>Population: ${percentOfPopulation}%`;
      },
    },
    series: [
      {
        name: 'Male',
        data: data[0].map(datum => [datum[0], -datum[1]]),
        color: COLORS.indigo[200],
      },
      {
        name: 'Female',
        data: data[1],
        color: COLORS.red[200],
      },
    ],
  };
}
