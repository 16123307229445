import { issueRequest, Method } from '@utils/httpUtils';

export async function getEntity(entityId: number): Promise<Entity> {
  const response = await issueRequest<Entity>(`/v3/entities/${entityId}`);
  return response.payload;
}

export async function getEntityHistory(entityId: number): Promise<Array<Changes>> {
  const response = await issueRequest<Array<Changes>>(`/v3/entities/${entityId}/history`);
  return response.payload;
}

export async function searchEntities(category: string, query: string): Promise<Array<EntitySearchResult>> {
  const response = await issueRequest<Array<EntitySearchResult>>(
    '/v3/entities/search',
    { queryParams: { category, q: query } }
  );
  return response.payload;
}

export async function updateEntityCategory(entityId: number, category: string): Promise<void> {
  await issueRequest('/v3/entities/categorize', {
    body: { sourceEntityId: entityId, category: category },
    method: Method.PUT,
  });
}

export async function updateEntity(entity: Entity): Promise<void> {
  await issueRequest(`/v3/entities/${entity.id}`, {
    body: { ...entity, id: undefined },
    method: Method.PUT,
  });
}

export interface Entity {
  id: number;
  category?: string;
  instanceGroups: Array<InstanceGroup>;
}

export interface InstanceGroup {
  attributes: Array<Attribute>;
  id?: number;
}

export interface Attribute {
  confidence: number;
  name: string;
  value: number | string;
}

export interface Changes {
  date: string;
  changes: Array<Change>;
}

export interface Change {
  action: string;
  attributeName: string;
  attributeValue: string;
  timestamp: string;
  updatedBy: string;
}

export interface EntitySearchResult {
  audienceSize: number;
  entityId: number;
  name: string;
  type: string;
  deprecatedAt: string | null;
}
