import { get, isEmpty, size } from 'lodash';
import PropTypes from 'prop-types';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useQuery } from 'react-query';

import ErrorMessage from './ErrorMessage';
import NoDataMessage from './NoDataMessage';
import Pagination from './table/Pagination';
import Search from './table/Search';
import TableLoader from './table/TableLoader';
import TableHeader from './table/TableHeader';
import TableRow from './table/TableRow';

import useViewState from '@utils/viewState';

function getRowId(row) {
  return row.id;
}

export default function RemoteTable(props, ref) {
  // table state
  const { clearState, setState, state } = useViewState(props.stateStorageKey, { pageIndex: 0, selectedRows: {} });
  const [isInitialState, setIsInitialState] = useState(true);
  useEffect(
    () => {
      if (isInitialState) {
        setIsInitialState(false);
      }
    },
    []
  );

  // query data
  const { data, error, isFetching: isLoading, refetch } = useQuery(
    [props.queryKey, state.pageIndex, state.globalFilter, props.filters, state.sortBy, state.sortDescending],
    () => props.query(state.pageIndex, state.globalFilter, props.filters, state.sortBy, state.sortDescending),
    {
      placeholderData: { payload: [], meta: {} },
      keepPreviousData: true,
    }
  );

  // set up the table
  const {
    allColumns,
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    previousPage,
    rows,
    prepareRow,
    selectedFlatRows,
    setAllFilters,
    setGlobalFilter,
    setSortBy,
    state: { globalFilter, pageIndex, sortBy },
  } = useTable(
    {
      // autoReset: false,
      autoResetSelectedRows: false,
      columns: props.columns,
      data: data.payload,
      getRowId: props.getRowId || getRowId,
      initialState: {
        filters: props.filters || [],
        globalFilter: state.globalFilter,
        pageIndex: state.pageIndex,
        pageSize: data.meta.pageItems,
        sortBy: state.sortBy ? [{ id: state.sortBy, desc: state.sortDescending }] : [],
      },
      manualFilters: true,
      manualGlobalFilter: true,
      manualPagination: true,
      manualSortBy: true,
      pageCount: data.meta.totalPages,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  // set empty flag
  const hasNoFilters = !globalFilter && (props.filters || []).every(filter => !filter.value);
  useEffect(
    () => {
      if (props.setIsDataEmpty && !isLoading) {
        props.setIsDataEmpty(isEmpty(data.payload) && hasNoFilters);
      }
    },
    [data, hasNoFilters, isLoading]
  );

  // handle row selection
  useEffect(() => {
    if (props.onRowSelectionChange) {
      const visibleRows = Object.fromEntries(data.payload.map(row => [(props.getRowId || getRowId)(row), true]));
      const currentlySelectedRows = selectedFlatRows.map(row =>
        [(props.getRowId || getRowId)(row.original), row.original]
      );
      const updatedSelectedRows = Object.fromEntries(
        Object.entries(state.selectedRows).filter(([id, row]) => !(id in visibleRows)).concat(currentlySelectedRows)
      );
      setState({ ...state, selectedRows: updatedSelectedRows });
      props.onRowSelectionChange(updatedSelectedRows);
    }
  }, [selectedFlatRows.filter(row => (props.getRowId || getRowId)(row.original)).sort().join(' ')]);

  // handle page index and sorting changes
  useEffect(
    () => {
      if (!isInitialState) {
        const updatedState = {
          ...state,
          pageIndex,
          sortBy: get(sortBy, '0.id'),
          sortDescending: get(sortBy, '0.desc'),
        };
        setState(updatedState);
      }
    },
    [pageIndex, sortBy]
  );

  // handle global filter changes
  useEffect(
    () => {
      if (!isInitialState) {
        setState({ ...state, globalFilter });
        gotoPage(0);
      }
    },
    [globalFilter]
  );

  // handle filter changes
  useEffect(
    () => {
      if (!isInitialState) {
        gotoPage(0);
      }
    },
    [(props.filters || []).map(filter => `${filter.id},${filter.value}`).sort().join('|')]
  );

  // export functions. Check if this is set right at the first render or not
  useImperativeHandle(ref, () => ({
    clearState: () => {
      gotoPage(0);
      setAllFilters([]);
      setGlobalFilter();
      setSortBy([]);
      clearState();
    },
    refetch,
  }));

  // render the table
  return props.placeholder && !isLoading && hasNoFilters && isEmpty(data.payload) ? props.placeholder : (
    <>
      {
        error && (
          <div className="my-2">
            <ErrorMessage message={`Could not load ${props.entitiesName}.`} />
          </div>
        )
      }

      {(props.extra || props.isSearchable) && (
        <div className="flex space-x-2 items-center">
          {props.isSearchable && (
            <Search
              searchTerm={globalFilter}
              setSearchTerm={term => {
                setState({ ...state, globalFilter: term });
                setGlobalFilter(term);
              }}
            />
          )}
          {props.extra}
        </div>
      )}

      {!isLoading && isEmpty(data.payload) ? (
        <NoDataMessage message={`No ${props.entitiesName} found.`} />
      ) : (
        <div className="mx-auto">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full shadow rounded-lg overflow-hidden">
              <table
                className="min-w-full divide-y divide-gray-200"
                {...getTableProps()}
              >
                {!props.isHeaderless && <TableHeader headerGroups={headerGroups} />}
                <tbody
                  className="divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {isLoading ? (
                    <TableLoader columns={allColumns} />
                  ) : (
                    rows.map((row, index) => {
                      prepareRow(row);
                      return (
                        <TableRow
                          columnCount={size(allColumns)}
                          key={index}
                          row={row}
                        />
                      );
                    })
                  )}
                </tbody>
              </table>

              {!isLoading && (pageIndex !== 0 || data.meta.totalCount > data.meta.pageItems) && (
                <Pagination
                  hasNextPage={canNextPage}
                  hasPreviousPage={canPreviousPage}
                  nextPage={nextPage}
                  pageIndex={pageIndex}
                  pageSize={data.meta.pageItems}
                  previousPage={previousPage}
                  totalRowCount={data.meta.totalCount}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
RemoteTable = forwardRef(RemoteTable);

RemoteTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  entitiesName: PropTypes.string.isRequired,
  extra: PropTypes.node,
  filters: PropTypes.arrayOf(PropTypes.object),
  getRowId: PropTypes.func,
  isHeaderless: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onRowSelectionChange: PropTypes.func,
  placeholder: PropTypes.node,
  query: PropTypes.func.isRequired,
  queryKey: PropTypes.string.isRequired,
  setIsDataEmpty: PropTypes.func,
  stateStorageKey: PropTypes.string,
};
