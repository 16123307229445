export function businessManagerAdSetLink(adAccountId, adSetId) {
  adAccountId = adAccountId.replace('act_', '');
  return `https://business.facebook.com/adsmanager/manage/adsets/edit?act=${adAccountId}&selected_adset_ids=${adSetId}`;
}

export function platformAdSetUrls(platform, orgId, adAccountId, campaignId, insertionOrderId, adSetId) {
  switch (platform) {
    case 'dv360': {
      const baseUrl = 'https://displayvideo.google.com/#ng_nav/p';
      return `${baseUrl}/${orgId}/a/${adAccountId}/c/${campaignId}/io/${insertionOrderId}/li/${adSetId}/details`;
    }
    case 'facebook':
      return businessManagerAdSetLink(adAccountId, adSetId);
    case 'snapchat':
      return `https://ads.snapchat.com/${adAccountId}/manage`;
    case 'linkedin':
      return `https://www.linkedin.com/campaignmanager/accounts/${adAccountId}/campaigns/${adSetId}/details`;
    case 'pinterest':
      return `https://ads.pinterest.com/advertiser/${adAccountId}/ads/edit/?adGroupId=${adSetId}&view=adgroup`;
    case 'tiktok':
      return `https://ads.tiktok.com/i18n/perf/adgroup?aadvid=${adAccountId}`;
    case 'twitter':
      return `https://ads.twitter.com/campaign_form/${adAccountId}/campaign/${parseInt(campaignId, 36)}` +
        `/adgroup/${parseInt(adSetId, 36)}/targeting_features/edit`;
    default:
      return businessManagerAdSetLink(adAccountId, adSetId);
  }
}
