import { get, isEmpty, size } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import getCampaignTableColumns from './campaignTableColumns';

import { getOrganizations } from '@api/admin';
import { getOrganization } from '@api/organization';
import { getAdAccounts, getCampaigns, getPlatformOrganizations } from '@api/structure';
import { getUser } from '@api/user';
import ErrorMessage from '@common/ErrorMessage';
import { FormAsyncDropdown, FormDropdown } from '@common/FormDropdowns';
import Loader from '@common/Loader';
import SelectIdentityOption from '@common/reactSelect/SelectIdentityOption';
import SelectIdentityValue from '@common/reactSelect/SelectIdentityValue';
import SelectOrganizationOption from '@common/reactSelect/SelectOrganizationOption';
import SelectUserOption from '@common/reactSelect/SelectUserOption';
import SelectUserValue from '@common/reactSelect/SelectUserValue';
import Table from '@common/Table';
import { WizardStep } from '@common/Wizard';
import { OrganizationDataContext } from '@hoc/withOrganizationData';
import { UserInfoContext } from '@hoc/withUserInfo';
import { getUserName } from '@utils/generalUtils';

export default function SelectCampaigns(props) {
  // get user and organization data
  const { userInfo: userData } = useContext(UserInfoContext);
  const { organization: organizationData } = useContext(OrganizationDataContext);

  // selectors
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [identity, setIdentity] = useState({});
  const [selectedSnapchatOrganization, setSelectedSnapchatOrganization] = useState({});
  const [selectedAdAccount, setSelectedAdAccount] = useState({});
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);

  // initialize selectors
  useEffect(
    () => {
      if (!props.isAdmin) {
        setSelectedOrganization({ label: organizationData.name, value: organizationData.id });
      } else {
        setSelectedOrganization();
      }
      if (!props.isAdmin) {
        setSelectedUser({
          label: getUserName(userData),
          value: userData.id,
          ...userData,
        });
      } else {
        setSelectedUser(props.campaignGroup
          ? {
            label: getUserName(props.campaignGroup.creator),
            value: props.campaignGroup.creator.id,
            ...props.campaignGroup.creator,
          }
          : undefined
        );
      }
      if (props.campaignGroup) {
        setIdentity({
          label: props.campaignGroup.adAccount.identity.name,
          provider: props.campaignGroup.adAccount.identity.provider,
          value: props.campaignGroup.adAccount.identity.id,
        });
      } else if (!props.isAdmin && size(userData.identities) === 1) {
        const identity = userData.identities[0];
        setIdentity({
          label: identity.name,
          provider: identity.provider,
          value: identity.id,
        });
      } else {
        setIdentity(undefined);
      }
      setSelectedAdAccount(props.campaignGroup
        ? {
          currency: props.campaignGroup.adAccount.currency,
          label: props.campaignGroup.adAccount.name,
          value: props.campaignGroup.adAccount.adAccountId,
        }
        : undefined
      );
      setSelectedCampaignIds(get(props.campaignGroup, 'campaignIds', []));
    },
    [props.isAdmin, userData, organizationData, props.campaignGroup]
  );

  // query organization
  const { data: organization, error: errorLoadingOrganization, isFetching: isLoadingOrganization } = useQuery(

    // we cannot pass "undefined" as the organization ID because in that case "initialData" will not work
    ['organization', get(selectedOrganization, 'value') || 0],
    () => getOrganization(get(selectedOrganization, 'value')),
    {
      enabled: !!props.isAdmin && !!selectedOrganization,
      initialData: { members: [] },
      onSuccess: data => {
        if (props.isAdmin && !props.campaignGroup && size(data.members) === 1) {
          const user = data.members[0];
          setSelectedUser({ label: getUserName(user), value: user.id, ...user });
        }
      },
    }
  );

  // query user
  const { data: user, error: errorLoadingUser, isFetching: isLoadingUser } = useQuery(
    ['user', get(selectedUser, 'value')],
    () => getUser(get(selectedUser, 'value')),
    {
      enabled: !!props.isAdmin && !isEmpty(selectedUser),
      initialData: { identities: [] },
      onSuccess: data => {
        if (!props.campaignGroup && size(data.identities) === 1) {
          const identity = data.identities[0];
          setIdentity({ label: identity.name, value: identity.id, ...identity });
        }
      },
    }
  );

  // fetch Snapchat organizations
  const { data: snapchatOrganizations, isFetching: isLoadingSnapchatOrganizations } = useQuery(
    ['organizations', get(identity, 'provider'), get(identity, 'value')],
    () => getPlatformOrganizations(get(identity, 'provider'), get(identity, 'value')),
    {
      enabled: ['snapchat', 'dv360'].includes(get(identity, 'provider')),
      initialData: [],
      onError: error => {
        // eslint-disable-next-line no-console
        console.log('Fetching Snapchat organizations failed:', error);
      },
    }
  );

  // query ad accounts
  const { data: adAccounts, error: errorLoadingAdAccounts, isFetching: isLoadingAdAccounts } = useQuery(
    ['adAccounts', get(identity, 'provider'), get(identity, 'value'), get(selectedSnapchatOrganization, 'value')],
    () => getAdAccounts(get(identity, 'provider'), get(identity, 'value'), get(selectedSnapchatOrganization, 'value')),
    {
      enabled: !isEmpty(identity),
      initialData: [],
      onSuccess: data => {
        if (!props.campaignGroup && size(data) === 1) {
          const adAccount = data[0];
          setSelectedAdAccount({ currency: adAccount.currency, label: adAccount.name, value: adAccount.id });
        }
      },
    }
  );

  // query campaigns
  const { data: campaigns, error: errorLoadingCampaigns, isFetching: isLoadingCampaigns } = useQuery(
    ['campaigns', get(identity, 'provider'), get(identity, 'value'), get(selectedAdAccount, 'value')],
    () => getCampaigns(get(identity, 'provider'), get(identity, 'value'), get(selectedAdAccount, 'value')),
    {
      enabled: !isEmpty(selectedAdAccount),
      initialData: [],
    }
  );

  // render content
  const width = props.campaignGroup ? 4 : 12;
  return (
    <WizardStep
      forwardButtonTitle="Next: Edit details"
      isForwardButtonDisabled={isEmpty(selectedCampaignIds)}
      onSubmit={() => {
        const currency =
          selectedAdAccount.currency ||
          campaigns.find(campaign => campaign.id === selectedCampaignIds[0]).currency;
        props.setData({
          adAccountId: selectedAdAccount.value,
          campaignIds: selectedCampaignIds,
          currency,
          identityId: identity.value,
          organizationId: selectedSnapchatOrganization.value,
          platform: identity.provider,
          selectedOrganizationId: (selectedOrganization || {}).value || selectedUser.organization.id,
          userId: selectedUser.value,
        });
        props._goForward();
      }}
      title="Select Campaigns"
      {...props}
    >
      {errorLoadingAdAccounts && <ErrorMessage message="Error retrieving ad account information" />}
      {errorLoadingCampaigns && <ErrorMessage message="Error retrieving campaign information" />}
      {errorLoadingOrganization && <ErrorMessage message="Error retrieving organization information" />}
      {errorLoadingUser && <ErrorMessage message="Error retrieving user information" />}

      <div className="grid grid-cols-12 gap-4 mb-4">
        {props.isAdmin && !props.campaignGroup && (
          <div className={`col-span-${width}`}>
            <FormAsyncDropdown
              hasInitialLoad
              label="Select an Organization"
              loadOptions={(query, callback) =>
                getOrganizations(0, query).then(response =>
                  callback(response.payload.map(organization => (
                    { label: organization.name, value: organization.id, ...organization }
                  )))
                ) && undefined
              }
              onSelection={option => {
                setSelectedOrganization(option);
                setSelectedUser(undefined);
                setIdentity(undefined);
                setSelectedAdAccount(undefined);
                setSelectedCampaignIds([]);
              }}
              option={SelectOrganizationOption}
              placeholder="Type to search..."
              value={isEmpty(selectedOrganization) ? null : selectedOrganization}
            />
          </div>
        )}
        {props.isAdmin && (
          <div className={`col-span-${width}`}>
            <FormDropdown
              isDisabled={!selectedOrganization || isLoadingOrganization || size(organization.members) === 1}
              label="Select a user"
              onSelection={option => {
                setSelectedUser(option);
                setIdentity(undefined);
                setSelectedAdAccount(undefined);
                setSelectedCampaignIds([]);
              }}
              option={SelectUserOption}
              options={
                ((organization || {}).members || [])
                  .map(user => ({ label: getUserName(user), value: user.id, ...user }))
              }
              placeholder="Type to search..."
              singleValue={SelectUserValue}
              value={isEmpty(selectedUser) ? null : selectedUser}
            />
          </div>
        )}
        <div className={`col-span-${width}`}>
          <FormDropdown
            isDisabled={
              (props.isAdmin && !selectedOrganization) ||
              isLoadingOrganization ||
              isLoadingUser ||
              size((props.isAdmin ? user : userData).identities) === 1
            }
            label="Select an identity"
            onSelection={option => {
              setIdentity(option);
              setSelectedAdAccount(undefined);
              setSelectedCampaignIds([]);
            }}
            option={SelectIdentityOption}
            options={(props.isAdmin ? user : userData).identities
              .filter(identity => ['facebook', 'linkedin', 'snapchat', 'tiktok', 'twitter'].includes(identity.provider))
              .map(identity =>
                ({ label: identity.name, provider: identity.provider, value: identity.id, ...identity })
              )
            }
            placeholder="Type to search..."
            singleValue={SelectIdentityValue}
            value={isEmpty(identity) ? null : identity}
          />
        </div>
        {['snapchat', 'dv360'].includes(get(identity, 'provider')) && (
          <div className={`col-span-${width}`}>
            <FormDropdown
              isDisabled={isLoadingSnapchatOrganizations}
              label={`Select a ${get(identity, 'provider')} organization`}
              onSelection={setSelectedSnapchatOrganization}
              options={snapchatOrganizations.map(organization =>
                ({ label: organization.name, value: organization.id })
              )}
              placeholder="Type to search..."
              value={isEmpty(selectedSnapchatOrganization) ? null : selectedSnapchatOrganization}
            />
          </div>
        )}
        <div className={`col-span-${width}`}>
          <FormDropdown
            isDisabled={
              (props.isAdmin && !selectedOrganization) ||
              isLoadingOrganization ||
              isLoadingUser ||
              !identity ||
              isLoadingAdAccounts ||
              size(adAccounts) === 1
            }
            label="Select an ad account"
            onSelection={option => {
              setSelectedAdAccount(option);
              setSelectedCampaignIds([]);
            }}
            options={
              adAccounts.sort((a, b) => a.name.localeCompare(b.name)).map(adAccount => ({
                currency: adAccount.currency,
                label: adAccount.name,
                value: adAccount.id,
              }))
            }
            placeholder="Type to search..."
            value={isEmpty(selectedAdAccount) ? null : selectedAdAccount}
          />
        </div>
      </div>

      {
        isLoadingCampaigns && (
          <div className="mt-14">
            <Loader loadingText="Retrieving campaigns..." />
          </div>
        )
      }

      {!isEmpty(selectedAdAccount) && !isLoadingCampaigns && (
        <Table
          columns={getCampaignTableColumns()}
          data={campaigns}
          entitiesName="campaigns"
          isLoading={isLoadingCampaigns}
          isSearchable
          onRowSelectionChange={selection => {
            setSelectedCampaignIds(Object.keys(selection).map(index => campaigns[index].id));
          }}
          preSelectedRowIds={campaigns.flatMap((campaign, index) =>
            get(props.campaignGroup, 'campaignIds', []).includes(campaign.id) ? [index] : []
          )}
        />
      )}
    </WizardStep>
  );
}

SelectCampaigns.propTypes = {
  _goForward: PropTypes.func,
  campaignGroup: PropTypes.object,
  isAdmin: PropTypes.bool,
  setData: PropTypes.func.isRequired,
};
