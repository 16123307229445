import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { getOrganization } from '@api/organization';
import Button, { ButtonKind } from '@common/Button';
import Card from '@common/Card';
import ErrorMessage from '@common/ErrorMessage';
import Section from '@common/Section';
import EditOrganizationForm from '@components/shared/EditOrganizationForm';

export default function EditOrganization() {
  // extract arguments
  const { organizationId } = useParams();

  // acquire history handler
  const history = useHistory();

  // query organization
  const { data: organization, error: errorLoadingOrganization } = useQuery(
    ['organization', organizationId],
    () => getOrganization(organizationId),
    { initialData: { members: [] } }
  );

  // render form
  return (
    <Section
      heading={
        <div className="flex justify-end">
          <Button
            icon={ArrowSmLeftIcon}
            kind={ButtonKind.REGULAR}
            onClick={() => history.push(`/admin/organizations/${organizationId}`)}
            title="Back to Organization"
          />
        </div>
      }
      title="Edit Organization"
    >
      <section>
        <Card
          subtitle="Update your organization information."
          title="Organization Details"
        >
          <div className="my-2">
            {errorLoadingOrganization && <ErrorMessage message="Could not load organization information." />}
          </div>

          <EditOrganizationForm
            isAdmin
            onUpdate={() => history.push(`/admin/organizations/${organizationId}`)}
            organization={organization}
          />
        </Card>
      </section>
    </Section>
  );
}
