import { DocumentReportIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { getInsightReports } from '@api/admin';
import { copyInsightReport, deleteInsightReport, reRunInsightReport } from '@api/insightReports';
import ConfirmDialog from '@common/ConfirmDialog';
import DeleteDialog from '@common/DeleteDialog';
import OptionGroup from '@common/OptionGroup';
import RemoteTable from '@common/RemoteTable';
import Section from '@common/Section';
import getInsightReportTableColumns from '@components/insightReports/insightReportTableColumns';
import DownloadSegmentationDialog from '@components/shared/DownloadSegmentationDialog';
import EditInsightReportModal from '@components/shared/EditInsightReportModal';
import { notifySuccess } from '@utils/toaster';
import useViewState from '@utils/viewState';

import SelectUserDialog from '../SelectUserDialog';
import { FormDropdown } from '@common/FormDropdowns';
import Button, { ButtonKind } from '@common/Button';
import UploadCustomReportDialog from './UploadCustomReportDialog';

const TYPE_OPTIONS = [
  { label: 'Twitter Audience', value: 'twitter_audience' },
  { label: 'Twitter List', value: 'twitter_list' },
  { label: 'Custom', value: 'custom' },
];

export default function InsightReportAdminSection() {
  // view state
  const { setState, state } = useViewState('main');
  const [selectedType, setSelectedType] = useState();

  // get table reference
  const tableRef = useRef({});

  // modal states
  const [paramsOfCopyDialog, setParamsOfCopyDialog] = useState({ isOpen: false });
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] = useState({ isOpen: false });
  const [paramsOfEditDialog, setParamsOfEditDialog] = useState({ isOpen: false });
  const [paramsOfDownloadSegmentationDialog, setParamsOfDownloadSegmentationDialog] = useState({ isOpen: false });
  const [paramsOfReRunDialog, setParamsOfReRunDialog] = useState({ isOpen: false });
  const [paramsOfUploadCustomReportDialog, setParamsOfUploadCustomReportDialog] = useState({ isOpen: false });

  // copy target audience
  const { mutate: copy, error: errorCopyingInsightReport, isLoading: isCopyingInsightReport, reset } = useMutation(
    userId => copyInsightReport(paramsOfCopyDialog.id, userId),
    {
      onSuccess: () => {
        setParamsOfCopyDialog({ isOpen: false });
        notifySuccess({ title: 'Insight report was copied successfully.' });
        tableRef.current.refetch();
      },
    }
  );

  // render insight report section
  const columns = getInsightReportTableColumns(
    true,
    {
      openCopyDialog: insightReport => setParamsOfCopyDialog({ id: insightReport.id, isOpen: true }),
      openDeleteDialog: insightReport =>
        setParamsOfDeleteDialog({ id: insightReport.id, isOpen: true, name: insightReport.name }),
      openDownloadSegmentationDialog: insightReport =>
        setParamsOfDownloadSegmentationDialog({ id: insightReport.id, isOpen: true }),
      openEditDialog: insightReport => setParamsOfEditDialog({ insightReport, isOpen: true }),
      openReRunDialog: insightReport =>
        setParamsOfReRunDialog({ id: insightReport.id, isOpen: true, name: insightReport.name }),
    }
  );
  return (
    <>
      <Section
        heading={
          <Button
            kind={ButtonKind.PRIMARY}
            onClick={() => setParamsOfUploadCustomReportDialog({ isOpen: true })}
            title="Upload Custom Report"
          />
        }
        icon={DocumentReportIcon}
        title="Insight Reports"
      >
        <RemoteTable
          columns={columns}
          entitiesName="insight reports"
          extra={
            <>
              <OptionGroup
                options={[
                  {
                    isActive: !state.status,
                    onClick: () => setState({ ...state, status: undefined }),
                    title: 'All',
                  },
                  {
                    isActive: state.status === 'in_progress',
                    onClick: () => setState({ ...state, status: 'in_progress' }),
                    title: 'In Progress',
                  },
                  {
                    isActive: state.status === 'queued',
                    onClick: () => setState({ ...state, status: 'queued' }),
                    title: 'Queued',
                  },
                  {
                    isActive: state.status === 'failed',
                    onClick: () => setState({ ...state, status: 'failed' }),
                    title: 'Failed',
                  },
                ]}
              />
              <div className="w-56">
                <FormDropdown
                  isClearable
                  onSelection={setSelectedType}
                  options={TYPE_OPTIONS}
                  placeholder="Filter by report type..."
                  value={selectedType}
                />
              </div>
            </>
          }
          filters={[{ id: 'progress', value: state.status }, { id: 'report_type', value: selectedType?.value }]}
          isSearchable
          query={(...args) =>
            getInsightReports(...args).then(response => {
              const { payload: { data }, meta } = response;
              return { payload: data, meta };
            })
          }
          queryKey="insightReports"
          ref={tableRef}
        />
      </Section>

      <EditInsightReportModal
        close={() => setParamsOfEditDialog({ isOpen: false })}
        isAdmin
        onUpdate={tableRef.current.refetch}
        params={paramsOfEditDialog}
      />
      <DownloadSegmentationDialog
        close={() => setParamsOfDownloadSegmentationDialog({ ...paramsOfDownloadSegmentationDialog, isOpen: false })}
        params={paramsOfDownloadSegmentationDialog}
      />
      <SelectUserDialog
        close={() => {
          setParamsOfCopyDialog({ isOpen: false });
          reset();
        }}
        errorUpdating={errorCopyingInsightReport}
        isUpdating={isCopyingInsightReport}
        onSubmit={copy}
        params={paramsOfCopyDialog}
        submitTitle="Copy Insight Report"
        title="Copy Insight Report For Another User"
      />
      <ConfirmDialog
        close={() => setParamsOfReRunDialog({ isOpen: false })}
        message={params => (
          <>
            Are you sure you want to re-run insight report <span className="medium-font">{params.name}</span>?
          </>
        )}
        onUpdate={tableRef.current.refetch}
        params={paramsOfReRunDialog}
        perform={params => reRunInsightReport(params.id)}
        title="Re-run insight report"
      />
      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ isOpen: false })}
        delete={insightReportId => deleteInsightReport(insightReportId)}
        entityName="insight report"
        onUpdate={tableRef.current.refetch}
        params={paramsOfDeleteDialog}
      />
      <UploadCustomReportDialog
        close={() => setParamsOfUploadCustomReportDialog({ isOpen: false })}
        onUpdate={tableRef.current.refetch}
        params={paramsOfUploadCustomReportDialog}
      />
    </>
  );
}
