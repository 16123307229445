import { QueryClient, QueryClientProvider } from 'react-query';

export default function withQueryClient(WrappedComponent) {
  // create query client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  // render component
  return props => {
    return (
      <QueryClientProvider client={queryClient}>
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  };
}
