import { RefreshIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import Button from '@common/Button';
import { ReactComponent as DNALoader } from '@images/loader.svg';

function LoadingMessage({ loadingText, currentMessageIndex }) {
  if (currentMessageIndex === 0 && loadingText) {
    return loadingText;
  }
  return messages[currentMessageIndex];
}

const messages = [
  '',
  'Just a bit longer... 😇',
  'Any second now...we\'re in this together 😥',
  'This is taking longer than expected 😤 Try reloading the page!',
];

export default function Loader({ loadingText }) {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let currentIdx = currentMessageIndex;
      if (currentIdx < messages.length - 1) {
        setCurrentMessageIndex(currentIdx + 1);
      } else {
        clearInterval(interval);
      }
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <div className="flex w-full h-full -mt-10 text-center">
      <div className="m-auto py-10 px-5">
        <DNALoader />
        <p className="mb-4 font-normal text-lg text-gray-900">
          <LoadingMessage
            currentMessageIndex={currentMessageIndex}
            loadingText={loadingText}
          />
        </p>
        {currentMessageIndex === messages.length - 1 && (
          <Button
            icon={RefreshIcon}
            onClick={() => window.location.reload()}
            title="Reload this page"
          />
        )}
      </div>
    </div>
  );
}

Loader.propTypes = {
  loadingText: PropTypes.string,
};
