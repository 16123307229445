import { useQuery } from 'react-query';

import DialogIcon, { DialogIconContext } from '@common/DialogIcon';
import DialogWithIcon from '@common/DialogWithIcon';
import { notifyError } from '@utils/toaster';

import { CommonDialogProps } from './commonTypes';

export interface DownloadDocumentDialogProps<T> extends CommonDialogProps<T> {
  query: (params: T) => string;
  queryKey: (params: T) => string;
}

export default function DownloadDocumentDialog<T>(props: DownloadDocumentDialogProps<T>): JSX.Element {
  // fetch document
  useQuery(
    props.queryKey(props.params),
    () => props.query(props.params),
    {
      enabled: props.params.isOpen,
      onError: () => notifyError({ title: 'Document generation failed. Please try again.', message: undefined }),
      onSuccess: response => {
        window.open(response);
        props.close();
      },
    }
  );

  // render modal
  return (
    <DialogWithIcon
      close={props.close}
      description={
        'The download will start automatically. ' +
        'If you do not see the document, please check your pop-up blocker settings.'
      }
      icon={
        <div className="mb-4">
          <DialogIcon
            context={DialogIconContext.PRIMARY}
            icon="DocumentIcon"
            outline
          />
        </div>
      }
      isOpen={props.params.isOpen}
      onSubmit={props.close}
      title="Your document is being generated."
    />
  );
}
