import { CellProps, Column } from 'react-table';

import { Menu, MenuItem } from '@common/Menu';
import { ArrowRightIcon, CloudUploadIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { QueueWithStats } from '@models/queue';
import Button, { ButtonKind } from '@common/Button';

export default function getQueueTableColumns(
  { openAssignDialog, openDeleteDialog, openEditDialog, openImportDialog }: {
    openAssignDialog: (queue: QueueWithStats) => void,
    openDeleteDialog: (queue: QueueWithStats) => void,
    openEditDialog: (queue: QueueWithStats) => void,
    openImportDialog: (queue: QueueWithStats) => void
  }
): Column<QueueWithStats>[] {
  return [
    {
      accessor: 'name',
      alignment: 'left',
      Cell: ({ row: { original } }: CellProps<QueueWithStats>) => (
        <Button
          kind={ButtonKind.LINK}
          target={`/admin/queues/${original.id!}`}
        >
          {original.name}
        </Button>
      ),
      Header: 'NAME',
    },
    {
      accessor: 'description',
      alignment: 'left',
      Header: 'DESCRIPTION',
    },
    {
      accessor: 'unassignedItems',
      alignment: 'center',
      Header: 'NOT ASSIGNED',
    },
    {
      accessor: 'unprocessedItems',
      alignment: 'center',
      Header: 'ITEMS LEFT',
    },
    {
      accessor: 'totalItems',
      alignment: 'center',
      Header: 'TOTAL ITEMS',
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }: CellProps<QueueWithStats>) => (
        <Menu size="lg">
          <MenuItem
            icon={ArrowRightIcon}
            onClick={() => openAssignDialog(original)}
            title="Assign Items"
          />
          <MenuItem
            icon={PencilIcon}
            onClick={() => openEditDialog(original)}
            title="Edit Queue"
          />
          <MenuItem
            icon={CloudUploadIcon}
            onClick={() => openImportDialog(original)}
            title="Import Queue to EADB"
          />
          <MenuItem
            icon={TrashIcon}
            isDangerous
            onClick={() => openDeleteDialog(original)}
            title="Delete Queue"
          />
        </Menu>
      ),
      disableSortBy: true,
      Header: '',
      id: 'actions',
    },
  ];
}
