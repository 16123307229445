import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { getUser } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import Card from '@common/Card';
import ErrorMessage from '@common/ErrorMessage';
import Section from '@common/Section';
import EditUserForm from '@components/shared/EditUserForm';

export default function EditUser() {
  // extract arguments
  const { userId } = useParams();

  // acquire history handler
  const history = useHistory();

  // query user
  const { data: user, error: errorLoadingUser } = useQuery(
    ['user', userId],
    () => getUser(userId),
    { initialData: {} }
  );

  // render form
  return (
    <Section
      heading={
        <div className="flex justify-end">
          <Button
            icon={ArrowSmLeftIcon}
            kind={ButtonKind.REGULAR}
            onClick={() => history.push(`/admin/users/${userId}`)}
            title="Back to User"
          />
        </div>
      }
      title="Edit User Form"
    >
      <section>
        <Card
          subtitle="Update the user profile."
          title="User Details"
        >
          <div className="my-2">
            {errorLoadingUser && <ErrorMessage message="Could not load user information." />}
          </div>

          <EditUserForm
            isAdmin
            onUpdate={() => history.push(`/admin/users/${userId}`)}
            user={user}
          />
        </Card>
      </section>
    </Section>
  );
}
