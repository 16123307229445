import { size, startCase } from 'lodash';

import { getTargetingItem } from '@utils/generalUtils';

export function getDv360Defaults(targeting) {
  // extract properties
  const gender = getTargetingItem(targeting, 'gender');
  const parentalStatus = getTargetingItem(targeting, 'parentalStatus');
  const minAge = getTargetingItem(targeting, 'minAge');
  const maxAge = getTargetingItem(targeting, 'maxAge');
  const countries = (getTargetingItem(targeting, 'countries') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const googleAudiences = (getTargetingItem(targeting, 'googleAudiences') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const interests = (getTargetingItem(targeting, 'interests') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const broadKeywords = (getTargetingItem(targeting, 'broadKeywords') || [])
    .map(item => ({ label: item.name, value: item.id }));
  const urls = (getTargetingItem(targeting, 'urls') || [])
    .map(item => ({ label: item.name, value: item.id }));


  // return default values
  return {
    countries,
    gender: { value: gender || '', label: startCase(gender || 'all genders') },
    parentalStatus: { value: parentalStatus || '', label: startCase(parentalStatus || 'all parental statuses') },
    googleAudiences,
    interest: interests,
    maxAge: maxAge && { value: maxAge, label: maxAge.toString() },
    minAge: minAge && { value: minAge, label: minAge.toString() },
    broadKeywords,
    urls,
  };
}

export function calculateDv360Targeting(values) {
  const countries = values.countries.map(item => ({ id: item.value, name: item.label }));
  const googleAudiences = values.googleAudiences.map(item => ({ id: item.value, name: item.label }));
  const interests = values.interest.map(item => ({ id: item.value, name: item.label }));
  const broadKeywords = values.broadKeywords.map(item => ({ id: item.value, name: item.label }));
  const urls = values.urls.map(item => ({ id: item.value, name: item.label }));
  return {
    unifiedTargeting: {
      include: [
        ...(size(countries) ? [{ countries }] : []),
        ...(values.gender.value ? [{ gender: values.gender.value }] : []),
        ...(values.parentalStatus.value ? [{ parentalStatus: values.parentalStatus.value }] : []),
        ...(size(interests) ? [{ interests }] : []),
        ...(size(googleAudiences) ? [{ googleAudiences }] : []),
        ...(values.maxAge ? [{ maxAge: values.maxAge.value }] : []),
        ...(values.minAge ? [{ minAge: values.minAge.value }] : []),
        ...(size(broadKeywords) ? [{ broadKeywords }] : []),
        ...(size(urls) ? [{ urls }] : []),
      ],
    },
  };
}
