import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useMutation } from 'react-query';

import { deleteOrganizationMember } from '@api/organization';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { OrganizationDataContext } from '@hoc/withOrganizationData';
import { notifyError, notifySuccess } from '@utils/toaster';

export default function DeleteOrganizationMemberModal(props) {
  // extract parameters
  const isOpen = !isEmpty(props.params);
  const { member = {} } = props.params;

  // acquire organization data
  const { organization, setOrganization } = useContext(OrganizationDataContext);

  // get member name
  let memberName = null;
  if (member !== null) {
    memberName = member.lastName && member.firstName ? member.firstName + ' ' + member.lastName : member.email;
  }

  // remove member
  const { error, mutate, isLoading: isUpdating } = useMutation(
    () => deleteOrganizationMember(organization.id, member.id),
    {
      onError: () => notifyError({ title: 'Could not remove. Please try again.' }),
      onSuccess: (response, data) => {
        notifySuccess({ title: 'Member removed!' });
        props.close();
        setOrganization(response);
      },
    }
  );

  return (
    <Dialog
      close={props.close}
      isDangerous
      isOpen={isOpen}
      isUpdating={isUpdating}
      onSubmit={mutate}
      submitTitle="Remove"
      title="Remove Member from Organization"
      updatingTitle="Removing..."
    >
      {error && <ErrorMessage message={error} />}
      <div className="mt-4 mb-8 text-gray-900">
        Are you sure you want to remove <span className="font-medium">{memberName}</span> from{' '}
        <span className="font-medium">{(organization || {}).name}</span>?
      </div>
    </Dialog>
  );
}

DeleteOrganizationMemberModal.propTypes = {
  close: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
