import PropTypes from 'prop-types';

import Dialog from './Dialog';

export default function DialogWithIcon(props) {
  return (
    <Dialog
      close={props.close}
      hasNoButtons={!!props.children}
      isOpen={props.isOpen}
      onSubmit={props.onSubmit}
      submitTitle={props.submitTitle || 'Close'}
      title={props.icon}
    >
      <div className="text-center">
        <h2 className="mt-6 mb-4 text-xl font-medium text-gray-900">
          {props.title}
        </h2>
        <p className="mb-8 text-base text-gray-500">
          {props.description}
        </p>
      </div>
      {props.children}
    </Dialog>
  );
}

DialogWithIcon.propTypes = {
  children: PropTypes.node,
  close: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.element,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  submitTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};
