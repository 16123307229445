import { TreeSelect } from 'antd';
import { SelectorIcon, XIcon } from '@heroicons/react/solid';
import { debounce, size } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

import FormLabel from './FormLabel';

import FormError from '@common/FormError';
import { selectStyles } from '@utils/dropdownUtils';

function getNoOptionsComponent(message) {
  if (!message) {
    return null;
  }
  return props => <components.NoOptionsMessage {...props}>{message}</components.NoOptionsMessage>;
}

function resetIndicator(props) {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <span className="text-xs">
        Click to reset
      </span>
    </div>
  );
}

function clearIndicator(props) {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <XIcon
        className="cursor-pointer h-4 w-4 text-gray-400"
      />
    </div>
  );
}

function dropdownIndicator(props) {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('dropdownIndicator', props)}
    >
      <span className="inset-y-0 right-0 flex items-center pointer-events-none">
        <SelectorIcon
          aria-hidden="true"
          className="h-5 w-5 text-gray-400"
        />
      </span>
    </div>
  );
}

export function onSelectionChange(onChange, onSelection, onClear) {
  return option => {
    if (option || !onClear) {
      onChange(option);
      if (onSelection) {
        onSelection(option);
      }
    } else if (!option && onClear) {
      onClear();
    }
  };
}

/*
 * ! = required
 *
 *  control:           form control
 *  error:             error message related to the dropdown value
 *  isClearable:       true if dropdown is clearable
 *  isDisabled:        true if selection is disabled
 *  isLoading:         true if data is loading
 *  isMulti:           true if multiple options can be selected
 *  isSearchable:      true if options can be searched
 *  label:             field label
 *  multiValueLabel:   function to render a selected option in the input field
 *  name:              form field name
 *  noOptionsMessage:  message to show when no options are available
 *  onClear:           function to invoke when the field is cleared
 *  onSelection:       function to invoke when an option is selected
 *  option:            function to render an option in the option list
 * !options:           options
 *  placeholder:       message to show when there are no selected options
 *  rules:             validation rules
 *  value:             currently selected option
 *  width:             width of the dropdown component (e.g. "3")
 */
export function FormDropdown(props) {
  function renderSelector(onChange, onBlur) {
    const noOptionsComponent = getNoOptionsComponent(props.noOptionsMessage);
    const Dropdown = props.isExtensible ? CreatableSelect : Select;
    return (
      <Dropdown
        components={{
          ClearIndicator: props.clearIndicator ? props.clearIndicator : clearIndicator,
          DropdownIndicator: dropdownIndicator,
          ...(noOptionsComponent ? { NoOptionsMessage: noOptionsComponent } : {}),
          ...(props.multiValueLabel ? { MultiValueLabel: props.multiValueLabel } : {}),
          ...(props.option ? { Option: props.option } : {}),
          ...(props.singleValue ? { SingleValue: props.singleValue } : {}),
        }}
        filterOption={props.filterOption}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        isMulti={props.isMulti}
        isOptionDisabled={props.isOptionDisabled}
        isSearchable={props.isSearchable}
        menuPortalTarget={document.body}
        onBlur={onBlur}
        onChange={onSelectionChange(onChange, props.onSelection, props.onClear)}
        options={props.options}
        placeholder={props.placeholder}
        styles={selectStyles}
        value={props.value}
      />
    );
  }

  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      <div className={props.width && `col-span-${props.width}`}>
        {props.control ? (
          <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange, onBlur } }) => renderSelector(onChange, onBlur)}
            rules={props.rules}
          />
        ) : (
          renderSelector(() => true, () => true)
        )}
      </div>
      {props.error && <FormError message={props.error.message} />}
    </>
  );
}

FormDropdown.propTypes = {
  clearIndicator: PropTypes.func,
  control: PropTypes.object,
  error: PropTypes.object,
  filterOption: PropTypes.func,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isExtensible: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  isSearchable: PropTypes.bool,
  label: PropTypes.node,
  multiValueLabel: PropTypes.func,
  name: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  onClear: PropTypes.func,
  onSelection: PropTypes.func,
  option: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.node,
  rules: PropTypes.object,
  singleValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  width: PropTypes.string,
};

/*
 * ! = required
 *
 *  control:            form control
 *  defaultInputValue:  initial value of the "input" element
 *  error:              error message related to the dropdown value
 *  formatOptionLabel:  function to format the label of the option
 *  isClearable:        true if dropdown is clearable
 *  isDisabled:         true if selection is disabled
 *  isMulti:            true if multiple options can be selected
 *  isOpenInitially:    true if dropdown should be open initially
 *  label:              field label
 * !loadOptions:        function to fetch the options
 *  multiValueLabel:    function to render a selected option in the input field
 *  name:               form field name
 *  noOptionsMessage:   message to show when no options are available
 *  onClear:            function to invoke when the field is cleared
 *  onSelection:        function to invoke when an option is selected
 *  option:             function to render an option in the option list
 *  placeholder:        message to show when there are no selected options
 *  value:              currently selected option
 */
export function FormAsyncDropdown(props) {
  // the getOptionValue property is needed as a workaround for https://github.com/JedWatson/react-select/issues/1771
  function renderSelector(onChange, onBlur) {
    const noOptionsComponent = getNoOptionsComponent(props.noOptionsMessage);
    const Dropdown = props.isExtensible ? AsyncCreatableSelect : AsyncSelect;
    return (
      <Dropdown
        components={{
          ClearIndicator: resetIndicator,
          DropdownIndicator: dropdownIndicator,
          ...(noOptionsComponent ? { NoOptionsMessage: noOptionsComponent } : {}),
          ...(props.multiValueLabel ? { MultiValueLabel: props.multiValueLabel } : {}),
          ...(props.option ? { Option: props.option } : {}),
          ...(props.singleValue ? { SingleValue: props.singleValue } : {}),
        }}
        defaultInputValue={props.defaultInputValue}
        defaultMenuIsOpen={props.isOpenInitially}
        defaultOptions={props.hasInitialLoad}
        formatOptionLabel={props.formatOptionLabel}
        getOptionValue={({ value }) => value}
        isClearable
        isDisabled={props.isDisabled}
        isMulti={props.isMulti}
        isSearchable
        loadOptions={debounce(props.loadOptions, 500, { trailing: true })}
        onBlur={onBlur}
        onChange={onSelectionChange(onChange, props.onSelection, props.onClear)}
        placeholder={props.placeholder}
        styles={selectStyles}
        value={props.value}
      />
    );
  }

  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      {props.control ? (
        <Controller
          control={props.control}
          name={props.name}
          render={({ field: { onChange, onBlur } }) => renderSelector(onChange, onBlur)}
          rules={props.rules}
        />
      ) : (
        renderSelector(() => true, () => true)
      )}
      {props.error && <FormError message={props.error.message} />}
    </>
  );
}

FormAsyncDropdown.propTypes = {
  control: PropTypes.object,
  defaultInputValue: PropTypes.string,
  error: PropTypes.object,
  formatOptionLabel: PropTypes.func,
  hasInitialLoad: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isExtensible: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOpenInitially: PropTypes.bool,
  label: PropTypes.node,
  loadOptions: PropTypes.func.isRequired,
  multiValueLabel: PropTypes.func,
  name: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  onClear: PropTypes.func,
  onSelection: PropTypes.func,
  option: PropTypes.func,
  placeholder: PropTypes.node,
  rules: PropTypes.object,
  singleValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

/*
 * ! = required
 *
 *  control:           form control
 *  error:             error message related to the dropdown value
 *  isClearable:       true if dropdown is clearable
 *  isDisabled:        true if selection is disabled
 *  isMulti:           true if multiple options can be selected
 *  label:             field label
 *  multiValueLabel:   function to render a selected option in the input field
 *  name:              form field name
 *  onChange:          function to invoke when the set of values changes
 *  option:            function to render an option in the option list
 *  placeholder:       message to show when there are no selected options
 *  rules:             validation rules
 *  value:             currently selected keywords
 *  width:             width of the dropdown component (e.g. "3")
 */
export function FormCreatableDropdown(props) {
  function handleKeyDown(event) {
    const trimmedValue = inputValue.replace(',', ' ').trim();
    if (!trimmedValue) {
      return;
    }
    if (event.key === 'Enter' || event.key === 'Tab' || event.key === ',') {
      setInputValue('');
      if (!props.value.some(item => item.value === trimmedValue)) {
        props.onChange([...props.value, { label: trimmedValue, value: trimmedValue.toLowerCase() }]);
      }
      event.preventDefault();
    }
  }

  function renderSelector(onChange, onBlur) {
    return (
      <CreatableSelect
        components={{
          DropdownIndicator: dropdownIndicator,
          ...(props.multiValueLabel ? { MultiValueLabel: props.multiValueLabel } : {}),
          ...(props.option ? { Option: props.option } : {}),
        }}
        delimiter=","
        inputValue={inputValue}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        isMulti
        menuIsOpen={false}
        onBlur={event => {
          event.key = 'Enter';
          handleKeyDown(event);
          onBlur();
        }}
        onChange={value => {
          onChange(value);
          props.onChange(value);
        }}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        placeholder={props.placeholder}
        rules={props.rules}
        styles={selectStyles}
        value={props.value}
      />
    );
  }

  // current input
  const [inputValue, setInputValue] = useState('');

  // render field
  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      <div className={props.width && `col-span-${props.width}`}>
        {props.control ? (
          <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange, onBlur } }) => renderSelector(onChange, onBlur)}
            rules={props.rules}
          />
        ) : (
          renderSelector(() => true, () => true)
        )}
      </div>
      {props.error && <FormError message={props.error.message} />}
    </>
  );
}

FormCreatableDropdown.propTypes = {
  control: PropTypes.object,
  error: PropTypes.object,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.node,
  multiValueLabel: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  option: PropTypes.func,
  placeholder: PropTypes.node,
  rules: PropTypes.object,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.string,
};

/*
 * ! = required
 *
 *  collapsedAll:      true if collapse all nodes by default
 *  control:           form control
 *  error:             error message related to the dropdown value
 *  isClearable:       true if dropdown is clearable
 *  isDisabled:        true if selection is disabled
 *  isMulti:           true if multiple options can be selected
 *  isSearchable:      true if options can be searched
 *  label:             field label
 *  name:              form field name
 *  onClear:           function to invoke when the field is cleared
 *  onSelection:       function to invoke when an option is selected
 *  option:            function to render an option in the option list
 * !options:           options
 *  placeholder:       message to show when there are no selected options
 *  rules:             validation rules
 *  value:             currently selected option(s)
 *  width:             width of the dropdown component (e.g. "3")
 */
export function FormTreeDropdown(props) {
  function convertOptions(options) {
    return options.map(option => ({
      disabled: option.isDisabled,
      label: option.label,
      value: option.value,
      ...(size(option.children) !== 0 ? { children: convertOptions(option.children) } : {}),
    }));
  }

  function renderSelector(onChange, onBlur) {
    return (
      <TreeSelect
        allowClear={props.isClearable}
        bordered={false}
        disabled={props.isDisabled}
        labelInValue
        multiple={props.isMulti}
        onBlur={onBlur}
        onChange={onSelectionChange(onChange, props.onSelection, props.onClear)}
        placeholder={props.placeholder}
        showSearch={props.isSearchable}
        style={{ width: '100%' }}
        suffixIcon={<SelectorIcon className="h-5 w-5 text-gray-400" />}
        treeData={convertOptions(props.options)}
        treeDefaultExpandAll={!props.isCollapsed}
        treeNodeFilterProp="label"
        value={props.value}
      />
    );
  }

  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      <div className={props.width && `col-span-${props.width}`}>
        {props.control ? (
          <Controller
            control={props.control}
            name={props.name}
            render={({ field: { onChange, onBlur } }) => renderSelector(onChange, onBlur)}
            rules={props.rules}
          />
        ) : (
          renderSelector(() => true, () => true)
        )}
      </div>
      {props.error && <FormError message={props.error.message} />}
    </>
  );
}

FormTreeDropdown.propTypes = {
  control: PropTypes.object,
  error: PropTypes.object,
  isClearable: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string,
  onClear: PropTypes.func,
  onSelection: PropTypes.func,
  option: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.node,
  rules: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  width: PropTypes.string,
};
