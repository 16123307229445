import PropTypes from 'prop-types';

export default function UnauthenticatedContent(props) {
  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      {props.children}
    </main>
  );
}

UnauthenticatedContent.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};
