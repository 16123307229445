import { PuzzleIcon } from '@heroicons/react/outline';

import getAdAccountTableColumns from './adAccountTableColumns';

import { getAdAccounts } from '@api/admin';
import RemoteTable from '@common/RemoteTable';
import Section from '@common/Section';

export default function AdAccountAdminSection() {
  // render content
  const columns = getAdAccountTableColumns();
  return (
    <>
      <Section
        icon={PuzzleIcon}
        title="Ad Accounts"
      >
        <RemoteTable
          columns={columns}
          entitiesName="ad accounts"
          isSearchable
          query={(...args) =>
            getAdAccounts(...args).then(response => {
              const { payload: { data }, meta } = response;
              return { payload: data, meta };
            })
          }
          queryKey="adAccountsAdmin"
        />
      </Section>
    </>
  );
}
