import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

import AddUser from '@components/admin/user/AddUser';
import AdAccountAdminSection from '@components/admin/adAccount/AdAccountAdminSection';
import EditOrganization from '@components/admin/organization/EditOrganization';
import EditUser from '@components/admin/user/EditUser';
import InsightReportAdminSection from '@components/admin/insightReport/InsightReportAdminSection';
import InterestAdminSection from '@components/admin/interest/InterestAdminSection';
import OrganizationAdminSection from '@components/admin/organization/OrganizationAdminSection';
import OrganizationDetails from '@components/admin/organization/OrganizationDetails';
import TargetAudienceAdminSection from '@components/admin/targetAudience/TargetAudienceAdminSection';
import UserAdminSection from '@components/admin/user/UserAdminSection';
import UserDetails from '@components/admin/user/UserDetails';
import AdAudienceDetailsView from '@components/audiences/details/AdAudienceDetailsView';
import CampaignGroupDetails from '@components/campaigns/CampaignGroupDetails';
import CampaignGroupSection from '@components/campaigns/CampaignGroupSection';
import EditCampaignGroup from '@components/campaigns/EditCampaignGroup';
import InsightsView from '@components/insightReports/insights/InsightsView';
import withBreadcrumb from '@hoc/withBreadcrumb';
import { UserInfoContext } from '@hoc/withUserInfo';
import RunningCampaigns from '@components/admin/runningCampaign/RunningCampaigns';
import PersonaLibrary from '@components/admin/personaLibrary/PersonaLibrary';
import Queues from '@components/admin/queues/Queues';
import QueueItems from '@components/admin/queues/QueueItems';

// create custom Sentry route component
const BreadcrumbRoute = withBreadcrumb(withSentryRouting(Route));

export default function AdminRoutes(props) {
  // switch to admin mode if possible
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  useEffect(
    () => {
      if (userInfo.admin && !userInfo.isCurrentlyAdmin) {
        setUserInfo({ ...userInfo, isCurrentlyAdmin: true });
      }
    },
    [userInfo.admin, userInfo.isCurrentlyAdmin]
  );

  // render router
  if (userInfo.admin === undefined || (userInfo.admin && !userInfo.isCurrentlyAdmin)) {
    return null;
  }
  if (!userInfo.admin) {
    return <Redirect to="/" />;
  }
  return (
    <Switch>
      <BreadcrumbRoute
        exact
        path={`${props.match.path}campaign_performance`}
        render={() => <CampaignGroupSection isAdmin />}
      />
      <BreadcrumbRoute
        exact
        path={[
          `${props.match.path}campaign_performance/new`,
          `${props.match.path}campaign_performance/:campaignGroupId/edit`,
        ]}
        render={() => <EditCampaignGroup isAdmin />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}campaign_performance/:campaignGroupId`}
        render={() => <CampaignGroupDetails isAdmin />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}insight_reports`}
        render={() => <InsightReportAdminSection />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}insight_reports/:insightReportId/*`}
        render={() => <InsightsView isAdmin />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}users`}
        render={() => <UserAdminSection />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}users/new`}
        render={() => <AddUser />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}users/:userId`}
        render={() => <UserDetails />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}users/:userId/edit`}
        render={() => <EditUser />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}organizations`}
        render={() => <OrganizationAdminSection />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}organizations/:organizationId`}
        render={() => <OrganizationDetails />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}organizations/:organizationId/edit`}
        render={() => <EditOrganization />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}audience_library`}
        render={() => <TargetAudienceAdminSection />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}audiences/:adAudienceId/`}
        render={() => <AdAudienceDetailsView isAdmin />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}ad_accounts`}
        render={() => <AdAccountAdminSection />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}interests`}
        render={() => <InterestAdminSection />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}running_campaigns`}
        render={() => <RunningCampaigns />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}persona_library`}
        render={() => <PersonaLibrary />}
      />

      <BreadcrumbRoute
        exact
        path={`${props.match.path}queues`}
        render={() => <Queues />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}queues/:queueId`}
        render={() => <QueueItems />}
      />

      <Redirect
        from="/*"
        to={`${props.match.path}users`}
      />
    </Switch>
  );
}

AdminRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};
