import { Popover as ReactPopover, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

export interface PopoverProps {
  children: ReactNode;
  content: ReactNode;
}

export default function Popover(props: PopoverProps): JSX.Element {
  return (
    <ReactPopover>
      <ReactPopover.Button>
        {props.children}
      </ReactPopover.Button>
      <Transition
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <ReactPopover.Panel className="absolute z-10">
          {props.content}
        </ReactPopover.Panel>
      </Transition>
    </ReactPopover>
  );
}
