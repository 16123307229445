import React, { ReactNode, useContext } from 'react';
import { useQuery } from 'react-query';
import { Redirect, useLocation } from 'react-router-dom';

import { UserInfo, validateToken } from '@api/authentication';
import { UserInfoContext } from '@hoc/withUserInfo';
import { ReactComponent as DNALoader } from '@images/loader.svg';
import { removeCredentials, retrieveCredentials } from '@utils/credentials';

export default function withAuthentication<T>(
  WrappedComponent: React.ComponentType,
  options?: AuthenticationOptions
): (props: T) => ReactNode {
  return (props: T) => {
    // preparations
    const { pathname } = useLocation();
    const { userInfo, setUserInfo } = useContext(UserInfoContext);

    // try to get credentials from session storage
    const credentials = retrieveCredentials();

    // query user info based on stored token
    const { isFetching } = useQuery<UserInfo>(
      ['validateToken', credentials?.accessToken],
      () => validateToken(),
      {
        enabled: credentials !== undefined && userInfo === undefined,
        onError: () => removeCredentials(),
        onSuccess: response => setUserInfo(response),
      }
    );
    if (isFetching) {
      return (
        <div className="my-10 text-center">
          <DNALoader />
          <span className="-mt-10 text-lg">Loading...</span>
        </div>
      );
    }

    // perform redirects if needed
    if (options?.redirectToLoginIfNotLoggedIn && userInfo === undefined) {
      return <Redirect to={`/?redirect=${pathname}`} />;
    }
    if (options?.redirectToIfNotAdmin && userInfo?.admin === false) {
      return <Redirect to={options.redirectToIfNotAdmin} />;
    }
    if (options?.redirectToIfLoggedIn !== undefined && userInfo !== undefined) {
      return <Redirect to={options.redirectToIfLoggedIn} />;
    }

    // render wrapped component
    return <WrappedComponent {...props} />;
  };
}

type AuthenticationOptions = {
  redirectToIfNotAdmin?: string;
  redirectToIfLoggedIn?: string;
  redirectToLoginIfNotLoggedIn?: boolean;
};
