import { issueRequest, Method } from '@root/utils/httpUtils';
import { convertBlobToDataURL, InsightReport } from '@utils/commonUtils';

export async function getInsightReport(insightReportId: number): Promise<InsightReport> {
  const response = await issueRequest<InsightReport>(`/v3/insight_reports/${insightReportId}`);
  return response.payload;
}

export async function getInsightReportAvatar(insightReportId: number): Promise<string | undefined> {
  const response = await issueRequest<Blob>(
    `/v3/insight_reports/${insightReportId}/avatar`,
    { notFoundSurrogate: new Blob() }
  );
  return response.payload.size !== 0 ? convertBlobToDataURL(response.payload) : undefined;
}

export async function uploadCustomInsightReport(customInsightReport: CustomInsightReport): Promise<void> {
  await issueRequest<void>(
    '/v3/insight_reports',
    {
      body: customInsightReport,
      method: Method.POST,
    }
  );
}

export interface CustomInsightReport {
  avatar: string;
  customFile: string;
  inputs: { filename: string };
  name: string;
  reportType: 'custom';
  userId: number;
}

export interface SocialAccount {
  avatar: string;
  entityId: number;
  id: number;
  name: string;
  username: string;
}

export interface TopSocialAccount extends SocialAccount {
  categoryName: 'person' | 'organization' | 'account';
  facebookInterestAudienceSize?: number;
  facebookInterestId?: string;
  facebookInterestName?: string;
  topics: Array<string>;
  type: 'p' | 'n' | '?';
}
