import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import AlreadyRegistered from './AlreadyRegistered';

import { resetUserPassword } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import DialogIcon from '@common/DialogIcon';
import DialogWithIcon from '@common/DialogWithIcon';
import ErrorMessage from '@common/ErrorMessage';
import FormField from '@common/FormField';
import { isEmail } from '@utils/validations';

export default function ForgotPasswordForm() {
  // acquire history handler
  const history = useHistory();

  // set up the form
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm({ mode: 'onChange' });

  // initiate password reset
  const { error: passwordResetRequestError, mutate } = useMutation(
    data => resetUserPassword(data.email),
    {
      onSuccess: () => setParamsOfSuccessModal(true),
    }
  );

  // modal state
  const [paramsOfSuccessModal, setParamsOfSuccessModal] = useState(false);

  // render page
  return (
    <>
      <div className="py-4 px-6">
        {passwordResetRequestError && <ErrorMessage message="Could not send password reset instructions" />}
        <form
          className="space-y-6"
          onSubmit={handleSubmit(mutate)}
        >
          <div>
            <FormField
              error={errors.email}
              label="Email Address"
              name="email"
              placeholder="Email address"
              register={() =>
                register('email', {
                  required: 'Email address is required',
                  validate: isEmail,
                })
              }
              type="text"
            />
          </div>
          <div>
            <Button
              isDisabled={!isValid}
              isFullWidth
              kind={ButtonKind.PRIMARY}
              title="Send Password Reset Email"
              type="submit"
            />
          </div>
        </form>
        <AlreadyRegistered />
      </div>

      <DialogWithIcon
        close={() => setParamsOfSuccessModal(false)}
        description={
          'We\'ve sent instructions on how to reset your password to your email. ' +
          'Please follow these instructions to reset your password.'
        }
        icon={
          <div className="mb-4">
            <DialogIcon
              context="success"
              icon="CheckIcon"
              outline
            />
          </div>
        }
        isOpen={paramsOfSuccessModal}
        onSubmit={() => history.push('/')}
        submitTitle="Back to Sign-in"
        title="Your password instructions have been sent!"
      />
    </>
  );
}
