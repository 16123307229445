import PropTypes from 'prop-types';

import SummaryDemographics from './SummaryDemographics';
import SummaryFavoriteAccounts from './SummaryFavoriteAccounts';
import SummaryPersonas from './SummaryPersonas';
import SummaryTopInterests from './SummaryTopInterests';

function formatData(data) {
  return data ? { name: data[0], value: Number(data[1]).toFixed(2) } : { name: '', value: 0 };
}

function formatInterests(interestsSummary) {
  return Object.entries(interestsSummary).reduce((memo, [interestLevel, values]) => {
    memo.push({
      name: interestLevel === 'firstLevel' ? '1st Level' : '2nd Level',
      data: values.map(formatData),
    });
    return memo;
  }, []);
}

export default function SummaryView(props) {
  const demographics = props.summary.demographics || {};
  const accounts = props.summary.accounts || {};

  return (
    <>
      <div className="mb-4">
        <SummaryDemographics
          ageByGender={demographics.ageByGender || {}}
          cities={demographics.cities || []}
          countries={demographics.countries || []}
          genders={demographics.genders || []}
          regions={demographics.regions || []}
        />
      </div>
      <div className="mb-4">
        <SummaryPersonas personas={props.interests.personas || []} />
      </div>
      <div className="mb-4">
        <SummaryTopInterests
          interests={formatInterests(props.interests.summary || {})}
          title="Top Interests"
        />
      </div>
      <div>
        <SummaryFavoriteAccounts
          favoriteBrands={accounts.favoriteBrands || []}
          favoritePeople={accounts.favoritePeople || []}
        />
      </div>
    </>
  );
}

SummaryView.propTypes = {
  iabInterests: PropTypes.object,
  interests: PropTypes.object,
  summary: PropTypes.object,
};
