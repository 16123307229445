import PropTypes from 'prop-types';

export default function SelectUserOption({ data, innerProps, isFocused }) {
  const searchOptionClassNames = [
    'bg-white',
    'cursor-pointer',
    'flex',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'hover:bg-gray-100',
    'items-center',
    'px-6',
    'py-5',
    'relative',
    'shadow-sm',
    'space-x-3',
  ];

  return (
    <div
      className={searchOptionClassNames.join(' ')}
      focused={isFocused ? 'true' : null}
      key={data.email}
      {...innerProps}
    >
      <div className="flex-shrink-0">
        <img
          alt=""
          className="rounded-full w-10 h-10 float-right bg-cover"
          src={data.avatar.thumb}
        />
      </div>
      <div className="flex-1 min-w-0">
        <span className="text-sm font-medium text-gray-900">
          {data.name} ({data.email})
        </span>
        <p className="text-sm text-gray-500 truncate">{data.organization.name}</p>
      </div>
    </div>
  );
}

SelectUserOption.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
    }),
    email: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
};
