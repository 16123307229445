import { ArchiveIcon, DocumentDownloadIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { startCase } from 'lodash';
import mixpanel from 'mixpanel-browser';

import Badge, { BadgeContext } from '@common/Badge';
import Button, { ButtonKind } from '@common/Button';
import { Menu, MenuItem } from '@common/Menu';
import { GenericCell } from '@common/table/TableCells';
import { getIdColumn, getPlatformColumn } from '@common/table/TableColumns';

export default function getCampaignGroupTableColumns(
  emojis,
  isAdmin,
  history,
  userId,
  organizationId,
  { openArchiveDialog, openDeleteDialog, openDownloadDialog }
) {
  /* eslint-disable react/prop-types */
  return [
    ...(isAdmin ? [getIdColumn()] : []),
    {
      accessor: row => row,
      alignment: 'center',
      Cell: ({ row: { original } }) => {
        const { crawlStatus } = original;

        return crawlStatus === 'pending' ? '' : (
          <div>
            {emojis[original.summary]}
          </div>
        );
      },
      disableGlobalFilter: true,
      filter: (rows, id, filterValue) => rows.filter(row => row.original.summary === filterValue),
      id: 'summary',
    },
    {
      accessor: row => [row.adAccount.name, row.name].join(' '),
      alignment: 'left',
      Cell: ({ row: { original } }) => (
        <GenericCell description={original.adAccount.name}>
          <Button
            kind={ButtonKind.LINK}
            onClick={() =>
              history.push(
                userId ? `/campaign_performance/${original.id}` : `/admin/campaign_performance/${original.id}`
              )
            }
          >
            {original.name}
          </Button>
        </GenericCell>
      ),
      filter: (rows, id, filterValue) => rows.filter(row => row.original.adAccount.adAccountId === filterValue),
      Header: 'NAME',
      id: 'name',
    },
    ...(isAdmin ? [{
      accessor: 'creator.organization.name',
      alignment: 'left',
      disableGlobalFilter: true,
      disableSortBy: true,
      filter: (rows, id, filterValue) => rows.filter(row => row.original.creator.organization.id === filterValue),
      Header: 'ORGANIZATION',
      id: 'organization',
    }] : []),
    getPlatformColumn(),
    {
      accessor: 'status',
      alignment: 'center',
      Cell: ({ row: { original } }) => {
        const { crawlStatus, endsAt, status } = original;

        const textStatus = status === 'archived' ? 'Archived'
          : crawlStatus === 'failed'
            ? 'Crawl Failed'
            : crawlStatus === 'pending'
              ? 'Retrieving Data...'
              : endsAt && new Date(endsAt) < new Date()
                ? 'Ended'
                : status === 'active' ? 'Up-to-date' : startCase(status);

        const badgeContext = status === 'archived' ? BadgeContext.WARNING
          : crawlStatus === 'failed'
            ? BadgeContext.DANGEROUS : crawlStatus === 'pending'
              ? BadgeContext.WARNING
              : endsAt && new Date(endsAt) < new Date()
                ? BadgeContext.REGULAR
                : BadgeContext.SUCCESS;

        return (
          <Badge
            context={badgeContext}
            size="small"
            text={textStatus}
          />
        );
      },
      disableGlobalFilter: true,
      Header: 'STATUS',
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <Menu>
          <div>
            {(!userId || userId === original.creator.id) && (
              <MenuItem
                icon={PencilIcon}
                onClick={() => history.push(userId
                  ? `/campaign_performance/${original.id}/edit`
                  : `/admin/campaign_performance/${original.id}/edit?organizationId=${organizationId}`
                )}
                title="Edit"
              />
            )}
            <MenuItem
              icon={DocumentDownloadIcon}
              onClick={() => {
                mixpanel.track('Download Campaign Group Excel', { from: 'Table' });
                openDownloadDialog(original);
              }}
              title="Download Excel report"
            />
            <MenuItem
              icon={ArchiveIcon}
              onClick={() => openArchiveDialog(original)}
              title={original.status === 'active' ? 'Archive' : 'Unarchive'}
            />
          </div>
          <MenuItem
            icon={TrashIcon}
            isDangerous
            onClick={() => openDeleteDialog(original)}
            title="Delete"
          />
        </Menu>
      ),
      disableSortBy: true,
      Header: '',
      id: 'actions',
    },
  ];
  /* eslint-enable react/prop-types */
}
