import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { createQueue, updateQueue } from '@api/queue';
import { CommonDialogParams, CommonDialogProps } from '@common/commonTypes';
import Dialog, { DialogSize } from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import FormField from '@common/FormField';
import FormFileUpload from '@common/FormFileUpload';
import { Queue } from '@models/queue';
import { ErrorResponse } from '@utils/httpUtils';
import { notifyError, notifySuccess } from '@utils/notification';

export default function CreateOrEditQueueDialog(props: CreateOrEditQueueDialogProps): JSX.Element {
  // set up form
  type FormData = { data: string; description: string; name: string; };
  const { control, formState: { errors, isValid }, handleSubmit, register, reset, setValue } = useForm<FormData>();
  useEffect(
    () => {
      if (props.params.isOpen) {
        if (isNil(props.params.queue)) {
          reset();
        } else {
          const queue = props.params.queue;
          reset({ description: queue.description, name: queue.name });
        }
      }
    },
    [props.params.isOpen]
  );

  const [isUploadingData, setIsUploadingData] = useState(false);

  // create queue
  const {
    error: errorCreatingQueue,
    isLoading: isCreatingQueue,
    mutate: doCreateQueue,
  } = useMutation<number, ErrorResponse, FormData>(
    data => createQueue({ description: data.description, name: data.name }, data.data),
    {
      onError: () => notifyError('Could not create queue. Please try again.'),
      onSuccess: () => {
        notifySuccess('Queue created!');
        props.close();
        props.onUpdate();
      },
    }
  );

  // update queue
  const {
    error: errorUpdatingQueue,
    isLoading: isUpdatingQueue,
    mutate: doUpdateQueue,
  } = useMutation<void, ErrorResponse, FormData>(
    data => updateQueue({ description: data.description, id: props.params.queue!.id, name: data.name }),
    {
      onError: () => notifyError('Could not update queue. Please try again.'),
      onSuccess: () => {
        notifySuccess('Queue updated!');
        props.close();
        props.onUpdate();
      },
    }
  );

  // render dialog
  return (
    <Dialog
      close={props.close}
      hasForm
      isDisabled={isCreatingQueue || isUpdatingQueue || isUploadingData || !isValid}
      isOpen={props.params.isOpen}
      isUpdating={false}
      onSubmit={handleSubmit((data: FormData) => isNil(props.params.queue) ? doCreateQueue(data) : doUpdateQueue(data))}
      size={DialogSize.EXTRA_LARGE}
      submitTitle={isNil(props.params.queue) ? 'Create Queue' : 'Update Queue'}
      title={isNil(props.params.queue) ? 'Create a Queue' : 'Edit Queue'}
    >
      {errorCreatingQueue && <ErrorMessage message={errorCreatingQueue.message} />}
      {errorUpdatingQueue && <ErrorMessage message={errorUpdatingQueue.message} />}
      <div className="mt-4 space-y-4">
        <div className="col-span-6">
          <FormField
            error={errors.name}
            label="Name"
            name="name"
            placeholder="ex. Basketball Player Classifications"
            register={() => register('name', { required: 'Name is required' })}
            type="text"
          />
        </div>
        <div className="col-span-6">
          <FormField
            error={errors.description}
            label="Description"
            name="description"
            placeholder="What will this queue be verifying"
            register={() => register('description')}
            type="text"
          />
        </div>
        {isNil(props.params.queue) && (
          <div className="col-span-6">
            <FormFileUpload
              aLabel="a file"
              control={control}
              error={errors.data}
              extensions={['.csv']}
              isUploading={isUploadingData}
              label="data to verify"
              name="data"
              rules={{ required: 'Data is required' }}
              setIsUploading={setIsUploadingData}
              setValue={(value: string) => setValue('data', value, { shouldValidate: true })}
            />
          </div>
        )}
      </div>
    </Dialog>
  );
}

export interface CreateOrEditQueueDialogProps extends CommonDialogProps<CreateOrEditQueueDialogParams> {
  onUpdate: () => unknown;
}

export interface CreateOrEditQueueDialogParams extends CommonDialogParams {
  queue?: Queue;
}
