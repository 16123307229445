import { Dialog as ReactDialog, Transition } from '@headlessui/react';
import { isNil } from 'lodash';
import { Fragment, ReactElement, ReactNode, useEffect, useRef } from 'react';

import { trackPageView } from '@utils/googleAnalytics';

import { IconType } from './commonTypes';
import Button, { ButtonKind } from './Button';
import { DialogIconProps } from './DialogIcon';

const DEFAULT_CLASSES = [
  'align-middle',
  'bg-white',
  'inline-block',
  'my-8',
  'p-6',
  'relative',
  'rounded-2xl',
  'shadow-xl',
  'sm:my-8',
  'sm:w-full',
  'text-left',
  'transform',
  'transition-all',
];

function renderDialogBody(props: DialogProps, submit: () => void): JSX.Element {
  // render dialog body if the title is not an icon
  if (typeof props.title === 'string' || isNil(props.title)) {
    return (
      <>
        {props.children}
        {!props.hasNoButtons && (
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              hasLeftMargin
              icon={props.submitIcon}
              isDisabled={props.isDisabled}
              isLoading={props.isUpdating}
              kind={props.isDangerous ? ButtonKind.DANGEROUS : ButtonKind.PRIMARY}
              onClick={props.hasForm ? undefined : submit}
              title={props.isUpdating && !isNil(props.updatingTitle) ? props.updatingTitle : props.submitTitle}
              type={props.hasForm ? 'submit' : 'button'}
            />
            {!props.isSubmitOnly && !props.isUpdating &&
              <Button
                kind={ButtonKind.REGULAR}
                onClick={() => props.close(false)}
                title="Cancel"
              />
            }
          </div>
        )}
      </>
    );
  }

  // render dialog body if the title is an icon
  return (
    <>
      <div className="text-center">
        {props.children}
      </div>
      {!props.hasNoButtons && (
        <div className="mt-5 sm:mt-4">
          <Button
            isDisabled={props.isDisabled}
            isFullWidth
            kind={ButtonKind.PRIMARY}
            onClick={submit}
            title={props.submitTitle}
          />
        </div>
      )}
    </>
  );
}

function renderDialog(props: DialogProps, body: JSX.Element, submit: () => void): JSX.Element {
  // if there is no title
  if (isNil(props.title)) {
    return body;
  }

  // if the title is not an icon
  if (typeof props.title === 'string' || props.title === undefined) {
    return (
      <>
        {props.title !== undefined && (
          <ReactDialog.Title className="font-medium text-gray-900 text-2xl">
            {props.title}
            {props.subtitle !== undefined && (
              <p className="text-gray-500 text-base">
                {props.subtitle}
              </p>
            )}
          </ReactDialog.Title>
        )}
        {!isNil(props.description) && (
          <ReactDialog.Description>
            {props.description}
          </ReactDialog.Description>
        )}
        {!props.hasForm ? body : (
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              submit();
              event.preventDefault();
            }}
          >
            {body}
          </form>
        )}
      </>
    );
  }

  // if the title is an icon
  return (
    <>
      <div className="py-4">
        {props.title}
      </div>
      {body}
    </>
  );
}

export interface DialogProps {

  /** Dialog content. */
  children: ReactNode;

  /** Function to call to set <code>isOpen</code> to false. */
  close: (wasSuccessful: boolean) => void;

  /** Description. */
  description?: string;

  /** True if dialog contains a react-hook-form. In this case <code>onSubmit</code> will be set on the form, not on the
      primary button. */
  hasForm?: boolean;

  /** True if dialog contains no primary or cancel buttons. */
  hasNoButtons?: boolean;

  /** True if the primary button has "dangerous" style. */
  isDangerous?: boolean;

  /** True if the primary button is disabled. */
  isDisabled?: boolean;

  /** True if the dialog is open. */
  isOpen: boolean;

  /** True if the dialog has only the primary button. */
  isSubmitOnly?: boolean;

  /** True if the dialog shows "updating" state. */
  isUpdating?: boolean;

  /** Function to call after the dialog has been closed. */
  onClosed?: (wasSuccessful: boolean) => void;

  /** Function to call when the primary button is clicked. Note that the dialog is not closed automatically. */
  onSubmit?: () => Promise<boolean | void> | void;

  /** Dialog size. */
  size?: DialogSize;

  /** Icon on the primary button. */
  submitIcon?: IconType;

  /** Title of the primary button. */
  submitTitle?: string;

  /** Dialog subtitle. */
  subtitle?: string;

  /** Dialog title. */
  title?: string | ReactElement<DialogIconProps>;

  /** ID to track the dialog under for Google Analytics. */
  trackingId?: string;

  /** Title of the primary button when the dialog shows an "updating" state. */
  updatingTitle?: string;
}

export enum DialogSize {
  EXTRA_SMALL = 'sm:max-w-xs',
  SMALL = 'sm:max-w-sm',
  MEDIUM = 'sm:max-w-md',
  LARGE = 'sm:max-w-lg',
  EXTRA_LARGE = 'sm:max-w-xl',
  EXXTRA_LARGE = 'sm:max-w-2xl',
  EXXXTRA_LARGE = 'sm:max-w-3xl',
  EXXXXTRA_LARGE = 'sm:max-w-4xl',
  FULL_WIDTH = 'sm:w-11/12',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Dialog(props: DialogProps): JSX.Element {
  // flag for detecting if the operation initiated by "onSubmit" was successful
  const wasSuccessful = useRef(false);

  // invoke original "onSubmit" and register if the operation was successful
  function submit(): void {
    if (!isNil(props.onSubmit)) {
      const promise = props.onSubmit();
      if (promise !== undefined) { // can't use isNil here
        void promise.then(result => {
          if (result) {
            wasSuccessful.current = true;
          }
        });
      }
    }
  }

  // if tracking is enabled, register the opening of the modal
  useEffect(
    () => {
      if (props.trackingId && props.isOpen) {
        trackPageView(`/dialog/${props.trackingId}`);
      }
    },
    [props.isOpen]
  );

  // render the dialog
  const body = renderDialogBody(props, submit);
  return (
    <Transition.Root
      afterLeave={() => !isNil(props.onClosed) && props.onClosed(wasSuccessful.current)}
      as={Fragment}
      show={props.isOpen}
    >
      <ReactDialog
        as="div"
        className="relative z-10 overflow-y-auto"
        onClose={() => props.close(wasSuccessful.current)}
        open
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ReactDialog.Panel className={`${DEFAULT_CLASSES.join(' ')} ${props.size ?? DialogSize.LARGE}`}>
                {renderDialog(props, body, submit)}
              </ReactDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </ReactDialog>
    </Transition.Root>
  );
}
