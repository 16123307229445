import {
  ArrowLeftIcon, ArrowRightIcon, ArrowSmLeftIcon, DownloadIcon, PencilIcon, UserAddIcon,
} from '@heroicons/react/outline';
import { capitalize, get, isNil } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { PropTypes } from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useQuery } from 'react-query';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import BrandWall from './BrandWall';
import InsightsHeaderView from './InsightsHeaderView';
import InterestsView from './interests/InterestsView';
import PersonaView from './PersonaView';
import SummaryView from './summary/SummaryView';

import { getInsightReport } from '@api/insightReport';
import { getInsightReportPdf } from '@api/insightReports';
import Button, { ButtonKind } from '@common/Button';
import DownloadDialog from '@common/DownloadDialog';
import ErrorMessage from '@common/ErrorMessage';
import Loader from '@common/Loader';
import { Menu, MenuItem } from '@common/Menu';
import DownloadSegmentationDialog from '@components/shared/DownloadSegmentationDialog';
import TopAccountsView from '@components/shared/TopAccountsView';
import { UserInfoContext } from '@hoc/withUserInfo';
import { getTopicHierarchy } from '@utils/generalUtils';

const TABS = [
  { name: 'Summary', path: '/insight_reports/9/' },
  { name: 'Interests', path: '/insight_reports/9/interests' },
  { name: 'Top Accounts', path: '/insight_reports/9/top_accounts' },
  { name: 'Persona', path: '/insight_reports/9/persona' },
  { name: 'Brand Wall', path: '/insight_reports/9/brands' },
  { name: 'Influencer Wall', path: '/insight_reports/9/influencers' },
  { path: '/insight_reports/9/export' },
];
const PLATFORMS = ['facebook', 'dv360', 'twitter'];
const TYPES = { '': '?', organization: 'n', person: 'p' };

function dataURItoBlob(dataURI) {
  const byteString = window.atob(dataURI.replace(/^data:application\/pdf;base64,/, ''));
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  return blob;
}

export default function InsightsView(props) {
  const { userInfo } = useContext(UserInfoContext);

  // extract arguments
  const { insightReportId } = useParams();
  const { pathname } = useLocation();
  const activeTabIndex = TABS.findIndex(tab =>
    tab.path === pathname.replace(/^\/admin\//, '/').replace(/(?<=\/)\d+(?=\/)/g, '9')
  );
  const history = useHistory();

  // load insight report
  const { data: insightReport, error: errorLoadingInsightReport, isFetching: isLoadingInsightReport } = useQuery(
    ['insightReport', insightReportId],
    () => getInsightReport(insightReportId),
    {
      initialData: { data: {} },
      onError: ({ status }) => {
        if (status === 404 || status === 403) {
          history.replace('/');
        }
      },
      retry: false,
    }
  );
  const [version, setVersion] = useState(0);

  // modal state
  const [isEditing, setIsEditing] = useState(false);
  const [paramsOfDownloadDialog, setParamsOfDownloadDialog] = useState(
    { id: insightReportId, isOpen: activeTabIndex === 6 }
  );
  const [paramsOfDownloadSegmentationDialog, setParamsOfDownloadSegmentationDialog] = useState({ isOpen: false });
  const [pageCount, setPageCount] = useState(0);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  // render view
  const [topics, topicAncestors, topicDescendants] = getTopicHierarchy(
    get(insightReport.data, 'interests.topics.series.0.data', []),
    get(insightReport.data, 'interests.topics.drilldown.series', [])
  );
  const allSocialAccounts = [
    ...get(insightReport, 'data.personas.brands', []),
    ...get(insightReport, 'data.personas.influencers', []),
  ];

  useEffect(() => {
    mixpanel.track('View Insight Report', {
      id: insightReportId,
      report_type: insightReport.reportType,
    });
  }, [insightReportId]);

  return isLoadingInsightReport ? (
    <Loader loadingText="Retrieving insight report data..." />
  ) : insightReport.reportType === 'custom' ? (
    <div className="space-y-8">
      <InsightsHeaderView
        buttons={
          <Button
            icon={DownloadIcon}
            kind={ButtonKind.PRIMARY}
            onClick={() => {
              mixpanel.track('Download Custom Report PDF', {
                current_page: selectedPageIndex,
              });

              // data should be your response data in base64 format
              const blob = dataURItoBlob(insightReport.pdf);
              const url = URL.createObjectURL(blob);

              // to open the PDF in a new window
              window.open(url, '_blank');
            }}
          >
            Download
          </Button>
        }
        insightReport={insightReport}
      />
      <Document
        file={insightReport.pdf}
        onLoadSuccess={({ numPages }) => setPageCount(numPages)}
      >
        {pageCount === 0 ? (
          <p>Document is loading...</p>
        ) : (
          <div className="relative flex items-center space-x-2 justify-center">
            <div>
              <Page
                className="shadow rounded-lg"
                pageNumber={selectedPageIndex + 1}
                scale={1.1}
              />
            </div>
            <div className="absolute right-0 top-0">
              <div className="space-x-2">
                <Button
                  icon={ArrowLeftIcon}
                  isDisabled={selectedPageIndex === 0}
                  onClick={() => setSelectedPageIndex(selectedPageIndex - 1)}
                />
                <Button
                  icon={ArrowRightIcon}
                  isDisabled={selectedPageIndex === pageCount - 1}
                  onClick={() => setSelectedPageIndex(selectedPageIndex + 1)}
                />
                <p className="mt-4 text-right">Page {selectedPageIndex + 1} of {pageCount}</p>
              </div>
            </div>
          </div>
        )}
      </Document>
    </div>
  ) : (
    <>
      <InsightsHeaderView
        activeTabIndex={activeTabIndex}
        buttons={(
          <>
            <Button
              icon={ArrowSmLeftIcon}
              kind={ButtonKind.REGULAR}
              onClick={() => {
                mixpanel.track('Back Button Click', { section: 'Insight Report' });
                history.replace(`${props.isAdmin ? '/admin' : ''}/insight_reports/`);
              }}
              title="Back to Insight Reports"
            />
            {(userInfo.admin || !insightReport.isPersonaInsight) && (
              <Menu
                placement="bottom-end"
                size="lg"
                title="Actions"
              >
                {userInfo.organization.plan === 'pro' && PLATFORMS.map(platform => (
                  <MenuItem
                    icon={UserAddIcon}
                    key={platform}
                    onClick={() => history.push(`targeting?platform=${platform}`, insightReport)}
                    title={`Create a ${platform === 'facebook' ? 'Meta' : capitalize(platform)} Target Audience`}
                  />
                ))}
                <MenuItem
                  icon={DownloadIcon}
                  onClick={() => {
                    mixpanel.track('Download PDF Summary', { id: insightReportId, from: 'Insight Report' });
                    setParamsOfDownloadDialog({ id: insightReportId, isOpen: true });
                  }
                  }
                  title="Download PDF Summary"
                />
                <MenuItem
                  icon={DownloadIcon}
                  onClick={() => {
                    mixpanel.track('Download Segmentation Excel', { id: insightReportId, from: 'Insight Report' });
                    setParamsOfDownloadSegmentationDialog({ id: insightReportId, isOpen: true });
                  }}
                  title="Download Segmentation"
                />
                {userInfo.admin && !isEditing && activeTabIndex === 2 && (
                  <MenuItem
                    icon={PencilIcon}
                    onClick={() => setIsEditing(true)}
                    title="Edit Accounts"
                  />
                )}
                {isEditing && activeTabIndex === 2 && (
                  <MenuItem
                    icon={PencilIcon}
                    isDangerous
                    onClick={() => setIsEditing(false)}
                    title="Stop Editing Accounts"
                  />
                )}
              </Menu>
            )}

          </>
        )}
        insightReport={insightReport}
        tabs={TABS}
      />

      {errorLoadingInsightReport && <ErrorMessage message="Could not load insight report." />}
      {(activeTabIndex === 0 || activeTabIndex === 6) && (
        <SummaryView
          iabInterests={insightReport.data.iabInterests}
          interests={insightReport.data.interests}
          summary={insightReport.data.summary}
        />
      )}
      {activeTabIndex === 1 && (
        <InterestsView
          accounts={insightReport.data.topAccounts}
          interests={insightReport.data.interests}
          topicDescendants={topicDescendants}
          topics={topics}
        />
      )}
      {activeTabIndex === 2 && (
        <TopAccountsView
          accounts={insightReport.data.topAccounts}
          coverage={insightReport.data.coverage}
          hasError={!!errorLoadingInsightReport}
          isEditing={isEditing}
          onUpdate={({
            entityId,
            categoryName,
            topics,
            facebookInterestId,
            facebookInterestName,
            facebookAudienceSize,
          }) => {
            insightReport.data.topAccounts = insightReport.data.topAccounts.map(account =>
              account.entityId === entityId
                ? {
                  ...account,
                  categoryName: categoryName ?? account.categoryName,
                  classification: isNil(categoryName) ? account.classification : capitalize(categoryName),
                  facebookInterestAudienceSize: facebookAudienceSize ?? account.facebookInterestAudienceSize,
                  facebookInterestId: facebookInterestId ?? account.facebookInterestId,
                  facebookInterestName: facebookInterestName ?? account.facebookInterestName,
                  topics: topics ?? account.topics,
                  type: isNil(categoryName) ? account.type : TYPES[categoryName],
                }
                : account
            );
            setVersion(version + 1);
          }}
          topicAncestors={topicAncestors}
          topicDescendants={topicDescendants}
          topics={topics}
        />
      )}
      {activeTabIndex === 3 && <PersonaView insightReport={insightReport} />}
      {activeTabIndex === 4 && (
        <BrandWall
          allSocialAccounts={allSocialAccounts}
          avatar={get(insightReport, 'avatar.default')}
          mainSocialAccounts={get(insightReport, 'data.personas.brands', []).slice(0, 62)}
          preferredType="n"
        />
      )}
      {activeTabIndex === 5 && (
        <BrandWall
          allSocialAccounts={allSocialAccounts}
          avatar={get(insightReport, 'avatar.default')}
          mainSocialAccounts={get(insightReport, 'data.personas.influencers', []).slice(0, 62)}
          preferredType="p"
        />
      )}

      <DownloadDialog
        close={() => setParamsOfDownloadDialog({ ...paramsOfDownloadDialog, isOpen: false })}
        extractDocumentUrl={response => response.downloadUrl}
        params={paramsOfDownloadDialog}
        query={params => getInsightReportPdf(params.id)}
        queryKey={params => ['insightReportPdf', params.id]}
      />
      <DownloadSegmentationDialog
        close={() => setParamsOfDownloadSegmentationDialog({ ...paramsOfDownloadSegmentationDialog, isOpen: false })}
        params={paramsOfDownloadSegmentationDialog}
      />
    </>
  );
}

InsightsView.propTypes = {
  isAdmin: PropTypes.bool,
};
