import PropTypes from 'prop-types';

import getPercentageTableColumns from './percentageTableColumns';

import Card from '@common/Card';
import Table from '@common/Table';

export default function SummaryTopInterests(props) {
  return (
    <Card
      subtitle="What is this audience interested in?"
      title={props.title}
    >
      <div className="grid grid-cols-2 gap-6">
        {props.interests.map(interest => (
          <div key={`interest-key-${interest.name}`}>
            <h2 className="font-medium">{`Interests: ${interest.name}`}</h2>
            <Table
              columns={getPercentageTableColumns()}
              data={interest.data.map(item => ({ name: item.name, value: parseFloat(item.value) }))}
              isHeaderless
            />
          </div>
        ))}
      </div>
    </Card>
  );
}

SummaryTopInterests.propTypes = {
  interests: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};
