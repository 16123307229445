import React, { ComponentType, useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { UserInfoContext } from './withUserInfo';

import { getOrganization, Organization } from '@api/organization';

export default function withOrganizationData<T>(WrappedComponent: ComponentType): (props: T) => JSX.Element {
  return (props: T) => {
    // preparations
    const queryClient = useQueryClient();
    const organizationId = useContext(UserInfoContext).userInfo?.organization.id;

    // query organization data
    const queryKey = ['organization', organizationId];
    const { data: organization, isFetching: isLoadingOrganization } = useQuery(
      queryKey,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      () => getOrganization(organizationId!),
      { enabled: organizationId !== undefined }
    );

    // render component
    return (
      <OrganizationDataContext.Provider
        value={{
          isLoadingOrganization,
          organization,
          setOrganization: organization => queryClient.setQueryData(queryKey, organization),
        }}
      >
        <WrappedComponent {...props} />
      </OrganizationDataContext.Provider>
    );
  };
}

export const OrganizationDataContext = React.createContext<OrganizationDataContextType>({
  isLoadingOrganization: true,
  organization: undefined,
  setOrganization: () => undefined,
});

export interface OrganizationDataContextType {
  isLoadingOrganization: boolean;
  organization: Organization | undefined;
  setOrganization: (organization: Organization) => void;
}
