import PropTypes from 'prop-types';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

export default function VersionsListener(props) {
  if (props.userUid) {
    return (
      <ActionCableConsumer
        channel={{ channel: 'VersionsChannel', uid: props.userUid }}
        onReceived={props.responseCallback}
      >
        {props.children}
      </ActionCableConsumer>
    );
  }
  return props.children || null;
}

VersionsListener.propTypes = {
  children: PropTypes.node,
  responseCallback: PropTypes.func.isRequired,
  userUid: PropTypes.string,
};
