import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getOrganizations } from '@api/admin';
import { createUser } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import ErrorMessage from '@common/ErrorMessage';
import FormBuilder from '@common/FormBuilder';
import SelectOrganizationOption from '@common/reactSelect/SelectOrganizationOption';
import Section from '@common/Section';
import { isEmail } from '@utils/validations';

export default function AddUser() {
  // acquire history handler
  const history = useHistory();

  // create user
  const { isLoading: isCreating, error: errorCreatingUser, mutate } = useMutation(
    () => createUser({
      user: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
      },
      organization:
        values.organization.__isNew__ ? { name: values.organization.value } : { id: values.organization.value },
    }),
    { onSuccess: () => history.push('/admin/users') }
  );

  // set up the form
  const {
    control,
    formState: { errors, touchedFields },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({ defaultValues: { password: `topicdna${new Date().getFullYear()}` }, mode: 'onChange' });
  const values = watch();

  // set up form fields
  const fields = [
    {
      label: 'First Name',
      name: 'firstName',
      required: 'Please enter a first name',
      type: 'text',
    },
    {
      label: 'Last Name',
      name: 'lastName',
      required: 'Please enter a last name',
      type: 'text',
    },
    {
      label: 'Email',
      name: 'email',
      required: 'Please enter an email',
      type: 'text',
      validate: isEmail,
    },
    {
      label: 'Password',
      name: 'password',
      required: 'Please enter a password',
      type: 'text',
    },
    {
      isExtensible: true,
      label: 'Organization',
      name: 'organization',
      options: query => getOrganizations(0, query).then(
        response => response.payload.map(item => ({ label: item.name, value: item.id, ...item }))
      ),
      required: 'Please select an organization',
      selectSearchOption: SelectOrganizationOption,
      type: 'lookup',
    },
  ];

  // render form
  return (
    <Section
      heading={
        <div className="flex justify-end">
          <Button
            icon={ArrowSmLeftIcon}
            kind={ButtonKind.REGULAR}
            onClick={() => history.push('/admin/users')}
            title="Back to Users"
          />
        </div>
      }
      title="New User"
    >
      <div className="my-2">
        {errorCreatingUser && <ErrorMessage message="Could not add user." />}
      </div>

      <div className="space-y-2">
        <div className="col-span-7">
          <div>
            <FormBuilder
              control={control}
              errors={errors}
              fields={fields}
              getValues={getValues}
              register={register}
              setValue={setValue}
              touchedFields={touchedFields}
            />
          </div>
          <div className="my-4">
            <Button
              isDisabled={isCreating}
              isFullWidth
              isLoading={isCreating}
              kind={ButtonKind.SUCCESS}
              onClick={() => handleSubmit(mutate)()}
              title="Create New User"
            />
          </div>
        </div>
      </div>
    </Section>
  );
}
