import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';

import Badge, { BadgeContext, BadgeSize } from '@common/Badge';
import { formatAgeRange } from '@utils/formattingUtils';
import { getTargetingItem } from '@utils/generalUtils';

export default function TargetAudienceCriteriaLabels(props) {
  // add countries
  const labels = (getTargetingItem(props.targeting, 'countries') || []).map(
    (country, index) => {
      return (
        <span
          className="mr-2"
          key={index}
        >{country.name}</span>
      );
    }
  );

  // add gender
  const gender = getTargetingItem(props.targeting, 'gender');
  if (gender) {
    const imageUrl = `https://storage.googleapis.com/topicdna-public/${gender}.png`;
    labels.push(
      <img
        alt=""
        className="ml-1 w-4 h-4 inline"
        key={gender}
        src={imageUrl}
      />
    );
  }

  // add age range
  const minAge = getTargetingItem(props.targeting, 'minAge');
  const maxAge = getTargetingItem(props.targeting, 'maxAge');
  const ageRange = minAge ? formatAgeRange(minAge, maxAge) : '';

  // render labels
  return (
    <div className="mt-1 text-gray-500">
      <span className="inline text-xs ">{labels}</span>
      <span className="inline ml-1 text-xs">{ageRange}</span>
      {props.parentId && (
        <Tippy
          content={
            <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow">
              <p>
                This audience is added from the Persona Library and managed by Personalyze.
              </p>
            </div>
          }
        >
          <div className="mt-2">
            <Badge
              context={BadgeContext.HIGHLIGHT}
              size={BadgeSize.SMALL}
              text="Persona Library"
            />
          </div>
        </Tippy>

      )}
    </div>
  );
}

TargetAudienceCriteriaLabels.propTypes = {
  parentId: PropTypes.number,
  targeting: PropTypes.object.isRequired,
};
