import PropTypes from 'prop-types';

import { addDefaultImage } from '@utils/influencersUtils';

const DEFAULT_CLASSES = [
  'col-start-1',
  'col-end-3',
  'font-medium',
  'inline-flex',
  'items-center',
  'px-2',
  'py-0.5',
  'row-start-1',
  'row-end-1',
  'text-sm',
  'text-gray-700',
];

export default function SelectValue({ data }) {
  return (
    <span className={DEFAULT_CLASSES.join(' ')}>
      <img
        alt=""
        className="w-8 h-8 my-1 mr-2 rounded-full"
        onError={addDefaultImage}
        src={data.avatar || data.photoUrl}
      />
      {data.name || data.label}
    </span>
  );
}

SelectValue.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
  }),
};
export function SelectedValueWithIcon({ data }) {
  return (
    <span className={DEFAULT_CLASSES.join(' ')}>
      <img
        alt=""
        className="w-6 h-6 my-1 mr-2 rounded-full"
        onError={addDefaultImage}
        src={data.avatar || data.photoUrl}
      />
      {data.name || data.label}
    </span>
  );
}

SelectedValueWithIcon.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
  }),
};


export function SelectedValue({ data }) {
  return (
    <span className="inline-flex items-center px-2 py-0.5 text-sm font-bold text-gray-700">
      {data.name || data.label}
    </span>
  );
}

SelectedValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
  }),
};

