import { Platform, UserAccount } from '@utils/commonUtils';
import { HttpOptions, issueRequest, Method } from '@utils/httpUtils';
import { camelizeKeys } from 'humps';
import { pick } from 'lodash';

export async function validateToken(): Promise<UserInfo> {
  const response = await issueRequest<UserInfoResponse>('/auth/validate_token');
  return response.payload.data;
}

export async function signIn(email: string, password: string): Promise<LoginInfo> {
  const options: HttpOptions = {
    body: { email, password },
    extractHeaders: headers => {
      const meta = camelizeKeys(pick(headers, ['access-token', 'client', 'expiry', 'uid'])) as {
        [key: string]: number | string;
      };
      meta.expiry = parseInt(meta.expiry as string, 10);
      return meta;
    },
    method: Method.POST,
  };
  const response = await issueRequest<UserInfoResponse>('/auth/sign_in', options);
  return { userInfo: response.payload.data, credentials: response.meta as unknown as Credentials };
}

/// Refers to a logged in user
export interface UserInfo extends UserAccount {
  isCurrentlyAdmin?: boolean;
}

export interface Identity {
  accessToken: string;
  provider: Platform;
  refreshToken?: string;
  tokenSecret?: string;
}

export interface LoginInfo {
  userInfo: UserInfo;
  credentials: Credentials;
}

export interface Credentials {
  accessToken: string;
  client: string;
  expiry: number;
  uid: string;
}

type UserInfoResponse = { data: UserInfo };
