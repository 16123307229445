import countryList from 'iso-3166-country-list';

const OVERRIDE = {
  GB: { label: 'United Kingdom', sortKey: 0 },
  US: { label: 'United States', sortKey: 1 },
};

export const countryOptions = countryList
  .map((country, index) => ({
    label: country.name,
    sortKey: country.code === 'GB' ? 1 : country.code === 'US' ? 2 : 1000 + index,
    value: country.code,
    ...(OVERRIDE[country.code] || {}),
  }))
  .sort((first, second) => first.sortKey - second.sortKey);
