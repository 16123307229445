import ReactGA from 'react-ga4';

/**
 * Track event.
 *
 * @param category the page you are doing the action (i.e. 'Reports'), or 'Navigation' if is a click on another page
 * @param action a description of the behavior, e.g. 'Clicked Delete', 'Added a component', 'Deleted account' etc.
 * @param label optional additional label
 */
export function trackEvent(category: string, action: string, label?: string): void {
  ReactGA.event({ category, action, label });
}

/**
 * Track page view.
 *
 * @param path page path
 */
export function trackPageView(path: string): void {
  ReactGA.send({ hitType: 'pageview', path });
}
