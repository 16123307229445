import PropTypes from 'prop-types';

export default function ProgressBar(props) {
  return (
    <>
      <span className="text-xs font-medium text-indigo-700 whitespace-nowrap">
        {Math.round(props.progress)}%
      </span>
      <div className="col-span-4 ml-4">
        <div className="overflow-hidden h-2 flex rounded bg-indigo-200">
          <div
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"
            style={{ width: `${props.progress}%` }}
          />
        </div>
      </div>
    </>
  );
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};
