import mixpanel from 'mixpanel-browser';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';
import { useContext, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

import Layout from '@components/layout/Layout';

import AdminRoutes from './AdminRoutes';
import AudienceRoutes from './AudienceRoutes';
import CampaignRoutes from './CampaignRoutes';
import InsightReportRoutes from './InsightReportRoutes';
import UserRoutes from './UserRoutes';

import LandingView from '@components/landing/LandingView';
import withAuthentication from '@hoc/withAuthentication';
import { UserInfoContext } from '@hoc/withUserInfo';

// Create Custom Sentry Route component
const SentryRoute = withSentryRouting(Route);

export default function Routing() {
  // Use user info if logged in
  const { userInfo } = useContext(UserInfoContext);

  // Initialize google analytics page view tracking
  const history = useHistory();
  const previousPath = useRef();

  useEffect(
    () => history.listen(location => {
      // sometimes a query parameter is removed and we don't want to log that
      if (previousPath.current !== location.pathname) {
        mixpanel.track('Page View', {
          url: location.pathname,
        });
        ReactGA.send({ hitType: 'pageview', page_location: location.pathname, title: location.pathname });
        previousPath.current = location.pathname;
      }
    }),
    []
  );

  const canAccessAudiences = userInfo ? userInfo.organization.plan === 'pro' : false;

  return (
    <Layout>
      <Switch>
        <SentryRoute
          component={
            withAuthentication(
              LandingView, { redirectToIfLoggedIn: canAccessAudiences ? '/audiences/' : '/insight_reports/' }
            )
          }
          exact
          path={['/', '/register', '/forgot_password', '/update_password']}
        />
        <SentryRoute
          component={withAuthentication(AdminRoutes, {
            redirectToLoginIfNotLoggedIn: true,
            redirectToIfNotAdmin: '/',
          })}
          path="/admin/"
        />
        <SentryRoute
          component={withAuthentication(AudienceRoutes, { redirectToLoginIfNotLoggedIn: true })}
          path="/audiences/"
        />
        <SentryRoute
          component={withAuthentication(CampaignRoutes, { redirectToLoginIfNotLoggedIn: true })}
          path="/campaign_performance/"
        />
        <SentryRoute
          component={withAuthentication(InsightReportRoutes, { redirectToLoginIfNotLoggedIn: true })}
          path="/insight_reports/"
        />
        <SentryRoute
          component={withAuthentication(UserRoutes, { redirectToLoginIfNotLoggedIn: true })}
          path="/user/"
        />

        <Redirect
          from="/*"
          to="/"
        />
      </Switch>
    </Layout>
  );
}
