import {
  ClipboardCopyIcon,
  DownloadIcon,
  DuplicateIcon,
  PencilAltIcon,
  RefreshIcon,
  TrashIcon,
} from '@heroicons/react/solid';

import { Menu, MenuItem } from '@common/Menu';
import {
  getAdminCreatorColumn,
  getCreatedOnColumn,
  getCreatorAvatarColumn,
  getIdColumn,
  getInsightReportNameColumn,
  getInsightReportProgressColumn,
} from '@common/table/TableColumns';
import mixpanel from 'mixpanel-browser';
import config from '@root/config';
import { notifySuccess } from '@utils/toaster';

export default function getInsightReportTableColumns(
  isAdmin,
  {
    openCopyDialog,
    openDeleteDialog,
    openDownloadSegmentationDialog,
    openDownloadSummaryDialog,
    openEditDialog,
    openReRunDialog,
  }
) {
  /* eslint-disable react/prop-types */
  return [
    ...(isAdmin ? [getIdColumn()] : []),
    getInsightReportNameColumn(isAdmin),
    ...(!isAdmin ? [getCreatorAvatarColumn()] : []),
    ...(!isAdmin ? [getCreatedOnColumn()] : []),
    getInsightReportProgressColumn(),
    ...(isAdmin ? [getAdminCreatorColumn()] : []),
    ...(isAdmin ? [{
      accessor: 'jobId',
      alignment: 'center',
      Cell: ({ cell: { value } }) => value,
      cellWidth: '125px',
      id: 'job_id',
      Header: 'JOB ID',
    }] : []),
    {
      accessor: d => d,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <Menu size={isAdmin ? 'lg' : 'sm'} >
          <MenuItem
            icon={PencilAltIcon}
            onClick={() => openEditDialog(original)}
            title="Edit"
          />
          {!isAdmin && (
            <MenuItem
              icon={DownloadIcon}
              onClick={() => {
                mixpanel.track('Download PDF Summary', { id: original.id, from: 'Table' });
                openDownloadSummaryDialog(original);
              }}
              title="Download PDF Summary"
            />
          )}
          <MenuItem
            icon={DownloadIcon}
            onClick={() => {
              mixpanel.track('Download Segmentation Excel', { id: original.id, from: 'Table' });
              openDownloadSegmentationDialog(original);
            }}
            title="Download Segmentation"
          />
          {isAdmin && (
            <>
              <MenuItem
                icon={DuplicateIcon}
                onClick={() => openCopyDialog(original)}
                title="Copy Insight Report for another user"
              />
              <MenuItem
                icon={RefreshIcon}
                onClick={() => openReRunDialog(original)}
                title="Re-run this insight report"
              />
              <MenuItem
                icon={ClipboardCopyIcon}
                onClick={() => {
                  const url = `gs://topicdna-${config.NODE_ENV}/insight-reports/${original.id}/result.json`;
                  navigator.clipboard.writeText(url)
                    .then(() => notifySuccess({ title: 'URL successfully copied to the clipboard' }));
                }}
                title="Copy GCS URL to clipboard"
              />
            </>
          )}
          <MenuItem
            icon={TrashIcon}
            isDangerous
            onClick={() => openDeleteDialog(original)}
            title="Delete"
          />
        </Menu>
      ),
      disableSortBy: true,
      Header: '',
      id: 'actions',
    },
  ];
  /* eslint-enable react/prop-types */
}
