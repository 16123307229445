import mixpanel from 'mixpanel-browser';
import { useHistory, useLocation } from 'react-router-dom';

import { classNames } from '@utils/generalUtils';

const TAB_CLASS_NAMES = [
  'block',
  'border-gray-300',
  'focus:border-indigo-500',
  'focus:outline-none',
  'focus:ring-indigo-500',
  'pl-3',
  'pr-10',
  'py-2',
  'rounded-md',
  'sm:text-sm',
  'text-base',
  'w-full',
];

export interface TabsProps {
  activeTabIndex: number,
  setActiveTabIndex?: (activeTabIndex: number) => void,
  tabs: Array<Tab>,
}

export interface Tab {
  name: string;
  path?: string;
}

export default function Tabs(props: TabsProps): JSX.Element {
  // acquire history handler and current path
  const history = useHistory();
  const { pathname } = useLocation();

  // render component
  return (
    <div>
      <div className="sm:hidden">
        <select
          className={TAB_CLASS_NAMES.join(' ')}
          defaultValue={props.tabs[props.activeTabIndex].name}
          id="tabs"
          name="tabs"
        >
          {props.tabs.filter(tab => tab.name).map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block my-4">
        <div className="border-b border-gray-200">
          <nav
            aria-label="Tabs"
            className="-mb-px flex space-x-8"
          >
            {props.tabs.filter(tab => tab.name).map((tab, index) => (
              <button
                className={classNames(
                  index === props.activeTabIndex
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'focus:outline-none whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                key={tab.name}
                onClick={() => {
                  // eslint-disable-next-line max-len
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                  mixpanel.track('Tab Clicked', { tab: tab.name });

                  if (tab.path !== undefined) {
                    const newPath = pathname.split('/').slice(0, -1).concat(tab.path.split('/').slice(-1)).join('/');
                    history.push(newPath);
                  } else if (props.setActiveTabIndex !== undefined) {
                    props.setActiveTabIndex(index);
                  }
                }}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
