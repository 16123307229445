import { Platform } from '@root/utils/commonUtils';
import { issueRequest } from '@utils/httpUtils';
import { SocialAccount } from './insightReport';

export async function searchSocialInfluencers(
  platform: Platform,
  query: string,
  signal?: AbortSignal
): Promise<Array<SocialAccount>> {
  const response = await issueRequest<Array<SocialAccount>>(`/v3/influencers/search?platform=${platform}&q=${query}`);
  return response.payload;
}
