import { Organization } from '@api/organization';

import { getUserName, UserAccount } from './commonUtils';

const AVATAR_API_URL = 'https://ui-avatars.com/api';

//const DEFAULT_AVATAR_SIZE = '256';
const THUMBNAIL_AVATAR_SIZE = '64';
const COLOR = '4F46E5';
const BACKGROUND_COLOR = 'E0E7FF';

export function getOrganizationAvatar(organization: Organization): string {
  const thumbnail = organization.avatar?.thumb;
  return thumbnail ?? generateThumbnailAvatar(organization.name);
}

export function getUserAvatar(userAccount: UserAccount): string {
  return userAccount?.avatar?.thumb ?? generateThumbnailAvatar(getUserName(userAccount));
}

export function generateThumbnailAvatar(name: string): string {
  const encodedParams = new URLSearchParams({
    name,
    color: COLOR,
    background: BACKGROUND_COLOR,
    size: THUMBNAIL_AVATAR_SIZE,
  });
  return `${AVATAR_API_URL}/?${encodedParams.toString()}`;
}
