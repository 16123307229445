import mixpanel from 'mixpanel-browser';
import { MouseEvent, ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { getBreadcrumb } from '@utils/commonUtils';

import BetaBadge from './BetaBadge';
import { IconType } from './commonTypes';

const BUTTON_CLASS_NAMES = [
  'border',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'font-medium',
  'inline-flex',
  'justify-center',
  'rounded-md',
  'shadow-sm',
  'px-4',
  'py-2',
  'text-sm',
];
const LINK_CLASS_NAMES = [
  'disabled:cursor-not-allowed',
  'disabled:opacity-50',
  'disabled:text-indigo-600',
  'hover:text-indigo-500',
  'text-indigo-700',
  'text-left',
];

const BUTTON_DISABLED_MODIFIERS = [
  'cursor-not-allowed',
  'opacity-40',
];
const BUTTON_PRIMARY_MODIFIERS = [
  'bg-indigo-600',
  'border-transparent',
  'focus:ring-indigo-500',
  'hover:bg-indigo-700',
  'text-white',
];
const BUTTON_DANGEROUS_MODIFIERS = [
  'bg-red-500',
  'border-transparent',
  'focus:ring-red-500',
  'hover:bg-red-400',
  'text-white',
];
const BUTTON_SUCCESS_MODIFIERS = [
  'bg-green-500',
  'border-transparent',
  'focus:ring-green-400',
  'hover:bg-green-600',
  'text-white',
];
const BUTTON_PLAIN_MODIFIERS = [
  'bg-white',
  'border-gray-300',
  'focus:ring-indigo-500',
  'hover:bg-gray-50',
  'text-gray-700',
];

const FULL_WIDTH_MODIFIERS = [
  'w-full',
];
const LEFT_MARGIN_MODIFIERS = [
  'ml-3',
];

function renderSpinner(): JSX.Element {
  return (
    <svg
      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export interface ButtonProps {

  /** Button title. Either this or <code>title</code> should be specified. */
  children?: ReactNode;

  /** True if add a "Beta" badge to the button. */
  hasBetaBadge?: boolean;

  /** True if insert a margin left to the button. */
  hasLeftMargin?: boolean;

  /** Icon to show to the left of the button title. */
  icon?: IconType;

  /** True if disable the button. */
  isDisabled?: boolean;

  /** True if show the button as full width in the container. */
  isFullWidth?: boolean;

  /** True if show the button in a loading state, and also disable it. */
  isLoading?: boolean;

  /** True if the target link is external. */
  isTargetExternal?: boolean;

  /** True if track button clicks with Google Analytics. */
  isTracked?: boolean;

  /** Visual appearance of the button. */
  kind: ButtonKind;

  /** Function to call when the button is clicked. Either this or <code>target</code> should be specified. */
  onClick?: () => void;

  /** Button size. */
  size?: ButtonSize;

  /** Path or URL to navigate to when the button is clicked. */
  target?: string;

  /** Button title. Either this or <code>children</code> should be specified. */
  title?: string;

  /** Button type. */
  type?: 'button' | 'submit';
}

export enum ButtonKind {
  DANGEROUS = 'dangerous',
  LINK = 'link',
  PRIMARY = 'primary',
  REGULAR = 'regular',
  SUCCESS = 'success'
}

export enum ButtonSize {
  LARGE = 'lg',
  SMALL = 'sm'
}

/** Button component. */
export default function Button(props: ButtonProps): JSX.Element {
  // preparations
  const history = useHistory();

  // click handler
  function handleOnClick() {
    if (props.onClick !== undefined) {
      props.onClick();
    }
    if (props.target !== undefined) {
      if (!props.isTargetExternal) {
        history.push(props.target);
      } else {
        window.open(props.target);
      }
    }
    if (props.isTracked) {
      const category = getBreadcrumb(history.location.pathname);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      mixpanel.track('Button Click', { title: props.title, in: category });
    }
  }

  // collect class names
  const classNames = [];
  if (props.kind === ButtonKind.LINK) {
    classNames.push(...LINK_CLASS_NAMES);
    if (props.size !== undefined) {
      classNames.push(`text-${props.size}`);
    }
  } else {
    // eslint-disable-next-line lines-around-comment
    // collect base class names
    classNames.push(...BUTTON_CLASS_NAMES);
    if (props.isDisabled) {
      classNames.push(...BUTTON_DISABLED_MODIFIERS);
    }

    // collect type-specific class names
    switch (props.kind) {
      case ButtonKind.PRIMARY:
        classNames.push(...BUTTON_PRIMARY_MODIFIERS);
        break;
      case ButtonKind.DANGEROUS:
        classNames.push(...BUTTON_DANGEROUS_MODIFIERS);
        break;
      case ButtonKind.SUCCESS:
        classNames.push(...BUTTON_SUCCESS_MODIFIERS);
        break;
      default:
        classNames.push(...BUTTON_PLAIN_MODIFIERS);
    }
  }

  // consider general modifiers
  if (props.hasLeftMargin) {
    classNames.push(...LEFT_MARGIN_MODIFIERS);
  }
  if (props.isFullWidth) {
    classNames.push(...FULL_WIDTH_MODIFIERS);
  }

  // render button content
  const Icon = props.icon;
  const content = (
    <>
      {Icon !== undefined && (
        <Icon
          aria-hidden="true"
          className={'mr-2 h-5 w-5 inline'}
        />
      )}
      {props.isLoading && renderSpinner()}
      {props.title ?? props.children}
      {props.hasBetaBadge && <BetaBadge />}
    </>
  );

  // render button
  return props.kind === ButtonKind.LINK && !props.isDisabled && props.target !== undefined ? (
    <Link
      className={classNames.join(' ')}
      role="button"
      to={props.target}
    >
      {content}
    </Link>
  ) : (
    <button
      className={classNames.join(' ')}
      disabled={props.isDisabled ?? props.isLoading}
      onClick={props.onClick === undefined && props.target === undefined ? undefined
        : (event: MouseEvent<HTMLButtonElement>) => {
          handleOnClick();
          event.stopPropagation();
        }
      }
      type={props.type ?? 'button'}
    >
      {content}
    </button>
  );
}
