import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import { getOrganizationAvatar } from '@utils/avatar';

export default function SelectOrganizationOption({ data, innerProps, isFocused }) {
  const searchOptionClassNames = [
    'bg-white',
    'cursor-pointer',
    'flex',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'hover:bg-gray-100',
    'items-center',
    'px-2',
    'py-2',
    'relative',
    'shadow-sm',
    'space-x-3',
  ];

  if (data.id) {
    return (
      <div
        className={searchOptionClassNames.join(' ')}
        focused={isFocused ? 'true' : null}
        key={data.id}
        {...innerProps}
      >
        <div className="flex-shrink-0">
          <img
            alt=""
            className="rounded-full w-10 h-10 float-right bg-cover"
            src={getOrganizationAvatar(data)}
          />
        </div>
        <div className="flex-1 min-w-0">
          <span className="text-sm font-medium text-gray-900">
            {data.name}
          </span>
          <p className="text-sm text-gray-500 truncate">{pluralize('member', data.membersCount, true)}</p>
        </div>
      </div>
    );
  }

  return (
    <div
      {...innerProps}
      className="px-4 py-4 hover:bg-gray-200 hover:cursor-pointer"
    >
      Create a New Organization
    </div>
  );
}

SelectOrganizationOption.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.shape({
      thumb: PropTypes.string,
    }),
    id: PropTypes.number,
    membersCount: PropTypes.number,
    name: PropTypes.string,
  }),
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
};
