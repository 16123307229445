import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

import CampaignGroupDetails from '@components/campaigns/CampaignGroupDetails';
import CampaignGroupSection from '@components/campaigns/CampaignGroupSection';
import EditCampaignGroup from '@components/campaigns/EditCampaignGroup';
import withBreadcrumb from '@hoc/withBreadcrumb';

// create custom Sentry route component
const BreadcrumbRoute = withBreadcrumb(withSentryRouting(Route));

export default function CampaignRoutes(props) {
  return (
    <Switch>
      <BreadcrumbRoute
        exact
        path={`${props.match.path}`}
        render={() => <CampaignGroupSection />}
      />
      <BreadcrumbRoute
        exact
        path={[`${props.match.path}new`, `${props.match.path}:campaignGroupId/edit`]}
        render={() => <EditCampaignGroup />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}:campaignGroupId`}
        render={() => <CampaignGroupDetails />}
      />

      <Redirect
        from="/*"
        to="/"
      />
    </Switch>
  );
}

CampaignRoutes.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }),
};
