import { Disclosure } from '@headlessui/react';
import {
  DocumentReportIcon,
  LightningBoltIcon,
  MenuIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/react/outline';
import { CheckIcon } from '@heroicons/react/solid';
import { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { NavLink as Link, useLocation } from 'react-router-dom';

import dashboardTourConfig from './dashboardTourConfig';
import dashboardTourConfigStarter from './dashboardTourConfigStarter';
import NavbarUserMenu from './NavbarUserMenu';

import { updateUserPreferences } from '@api/user';
import Button from '@common/Button';
import Tour from '@common/Tour';
import { UserInfoContext } from '@hoc/withUserInfo';
import TopicDNALogo from '@images/logoOnly.png';
import { getUserAvatar } from '@utils/avatar';

const navigation = [
  {
    name: 'Audience Library',
    path: '/audiences/',
    icon: UserGroupIcon,
    planSelector: ['pro'],
    tourSelector: 'reactour_audience_library',
  },
  {
    name: 'Insight Reports',
    path: '/insight_reports/?clearState=1',
    icon: DocumentReportIcon,
    planSelector: ['starter', 'pro'],
    tourSelector: 'reactour_insight_reports',
  },
  {
    name: 'Campaign Performance',
    path: '/campaign_performance/',
    icon: LightningBoltIcon,
    planSelector: ['pro'],
    tourSelector: 'reactour_campaign_performance',
  },
];

// eslint-disable-next-line react/prop-types
const NavLinks = ({ pathname, plan, mobile = false }) => {
  const navLinkClassNames = [
    'font-medium',
    'hover:bg-indigo-500',
    'hover:bg-opacity-75',
    'hover:text-gray-100',
    'px-3',
    'py-2',
    'rounded-md',
    'text-white',
  ];

  const activeClassNames = [
    'bg-indigo-500',
    'bg-opacity-75',
    'text-gray-10',
  ];

  return (
    <>
      {navigation.map(navItem => {
        let NavIcon = navItem.icon;
        // eslint-disable-next-line react/prop-types
        let linkActive = pathname.includes(navItem.path);
        let mobileClasses = mobile ? 'block text-base' : 'text-sm';

        if (navItem.planSelector.includes(plan)) {
          return (
            <Link
              className={
                `${navLinkClassNames.join(' ')} ${linkActive && activeClassNames.join(' ')} ${mobileClasses}`
              }
              data-tut={navItem.tourSelector}
              key={navItem.path}
              onClick={() => mixpanel.track('Navbar Menu Click', { section: navItem.name })}
              to={navItem.path}
            >
              <NavIcon className="inline mb-1 mr-2 h-5 w-6 text-indigo-300" />
              {navItem.name}
            </Link>
          );
        }
        return null;
      })}
    </>
  );
};

export default function Navbar() {
  // acquire location, user data
  const location = useLocation();
  const { logout, userInfo, setUserInfo } = useContext(UserInfoContext);

  // manage dashboard tour
  const showDashboardTour = get(userInfo, 'preference.touredDashboard') === false;
  useEffect(() => {
    if (showDashboardTour) {
      setIsTourOpen(true);
    }
  }, [showDashboardTour]);

  const { mutate: disableTour } = useMutation(
    () => updateUserPreferences(userInfo.id, { user: { preference_attributes: { toured_dashboard: true } } }),
    {
      // eslint-disable-next-line no-console
      onError: error => console.log('Preference update failed', error),
      // eslint-disable-next-line no-console
      onSuccess: response => {
        setUserInfo({ ...userInfo, preference: response });
        // eslint-disable-next-line no-console
        console.log('Preferences updated:', response);
      },
    }
  );

  // modal and tour states
  const [isTourOpen, setIsTourOpen] = useState(false);

  // render navigation bar
  return (
    <>
      <Disclosure
        as="nav"
        className="bg-indigo-600"
        defaultOpen
      >
        {({ open }) => (
          <>
            <div
              className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
            >
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      alt="TopicDNA"
                      className="h-8 w-auto"
                      src={TopicDNALogo}
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      <NavLinks
                        pathname={location.pathname}
                        plan={userInfo.organization.plan}
                      />
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    <NavbarUserMenu />
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button
                    className={
                      'bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-200 ' +
                      'hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 ' +
                      'focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white'
                    }
                  >
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon
                        aria-hidden="true"
                        className="block h-6 w-6"
                      />
                    ) : (
                      <MenuIcon
                        aria-hidden="true"
                        className="block h-6 w-6"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <NavLinks
                  mobile
                  pathname={location.pathname}
                  plan={userInfo.organization.plan}
                />
              </div>
              <div className="pt-4 pb-3 border-t border-indigo-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      alt=""
                      className="h-10 w-10 rounded-full"
                      src={getUserAvatar(userInfo)}
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {[userInfo.firstName, userInfo.lastName].join(' ')}
                    </div>
                    <div className="text-sm font-medium text-indigo-300">{userInfo.email}</div>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  <Button
                    onClick={logout}
                    title="Sign out"
                  />
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <Tour
        close={() => {
          setIsTourOpen(false);
          disableTour();
        }}
        config={userInfo.organization.plan === 'starter' ? dashboardTourConfigStarter : dashboardTourConfig}
        isOpen={isTourOpen}
        lastButtonIcon={CheckIcon}
      />
    </>
  );
}

