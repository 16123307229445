import { useHistory, useLocation } from 'react-router-dom';

import ForgotPasswordForm from './ForgotPasswordForm';
import UpdatePasswordForm from './UpdatePasswordForm';
import UserLoginForm from './UserLoginForm';

import authenticationLoadingHOC from '@common/AuthenticationLoadingHOC';
import RaisedSegment from '@common/RaisedSegment';
import TopicDNALogo from '@images/logo_dark.png';

function pageTitle(pathname) {
  switch (pathname) {
    case '/':
      return 'Sign in to your account';
    case '/forgot_password':
      return 'Reset your password';
    case '/update_password':
      return 'Reset your password';
    default:
      return 'Sign in to your account';
  }
}
function LandingView() {
  const history = useHistory();
  const { pathname } = useLocation();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mx-auto sm:w-full sm:max-w-md">
        <a href="https://www.topicdna.com/">
          <img
            alt="TopicDNA"
            className="mx-auto h-10 w-auto"
            src={TopicDNALogo}
          />
        </a>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{pageTitle(pathname)}</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <RaisedSegment>
          {pathname === '/' && (
            <UserLoginForm
              loginCallback={() => {
                history.push('/');
              }}
            />
          )}
          {pathname === '/forgot_password' && <ForgotPasswordForm />}
          {pathname === '/update_password' && <UpdatePasswordForm />}
        </RaisedSegment>
      </div>
    </div>
  );
}

export default authenticationLoadingHOC(LandingView);
