import { formatDate } from '@utils/formattingUtils';

export default function getInterestTableColumns(isShowingDeprecated) {
  /* eslint-disable react/prop-types */
  return [
    {
      accessor: 'name',
      alignment: 'left',
      Header: 'NAME',
    },
    {
      accessor: 'providedAt',
      alignment: 'left',
      Cell: ({ cell: { value } }) => formatDate(value),
      Header: isShowingDeprecated ? 'DEPRECATED AT' : 'ADDED AT',
    },
  ];
  /* eslint-enable react/prop-types */
}
