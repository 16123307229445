import { capitalize, get, isEmpty, sortBy, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import RaisedSegment from '@common/RaisedSegment';
import { ToolTipContext } from '@hoc/withToolTip';

function renderAccountCard(account) {
  return (
    <div className="text-center flex-1 flex flex-col p-8">
      <img
        alt=""
        className="w-32 h-32 flex-shrink-0 mx-auto bg-black rounded-full"
        src={account.avatar.replace('_normal', '')}
      />
      <span className="mt-6 text-gray-900 text-xl font-bold">{account.name}</span>
      <dl className="mt-1 flex-grow flex flex-col justify-between">
        <dd className="text-gray-500 text-lg font-semibold">@{account.username}</dd>
        <dd className="mt-3">
          {account.topics.map(topic => {
            return (
              <div
                className="m-2 px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800"
                key={topic}
              >
                {topic.split('/').slice(-1)[0]}
              </div>
            );
          })}
        </dd>
      </dl>
    </div>
  );
}

function renderAccountList(ToolTip, accounts) {
  return (
    <div className="col-span-4">
      {accounts.map(([topic, types]) => (
        <div
          className="px-4 py-4 mx-4"
          key={topic}
        >
          <div className="h-36">
            <h3 className="font-semibold text-black text-lg">{startCase(topic)}</h3>
            <div>
              {sortBy(types.people.concat(types.orgs), item => -item.affinity)
                .slice(0, 12)
                .map((account, i) => (
                  [
                    i > 0 && ', ',
                    <div
                      className="inline"
                      key={i}
                    >
                      <ToolTip
                        content={renderAccountCard(account)}
                        options={{ isHtml: true }}
                      >
                        <span className="truncate text-sm font-medium text-gray-900">
                          {account.name}
                        </span>

                      </ToolTip>
                    </div>,
                  ]
                ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function PersonaView(props) {
  // acquire tooltip
  const ToolTip = useContext(ToolTipContext);

  // extract data
  const accounts = Object.entries(get(props.insightReport, 'data.personas.accounts'));
  const ageRanges = get(props.insightReport, 'inputs.ageRanges');
  const countries = get(props.insightReport, 'inputs.countryCodes', []);
  const gender = get(props.insightReport, 'inputs.gender');
  const personaName = get(props.insightReport, 'name');
  const personas = get(props.insightReport, 'data.personas.personas').map(persona => persona[0]).join(' | ');
  const insightReportAvatar = get(props.insightReport, 'avatar.default');

  // render persona view
  return (
    <RaisedSegment>
      <div className="text-center text-2xl font-medium text-black">
        <span className="items-center inline-block mt-6">{countries.map((countryCode, index) => {
          return (
            <img
              alt="country"
              className="inline w-16 rounded-lg mr-4 -mt-1"
              key={index}
              src={`https://storage.googleapis.com/topicdna-public/flags-iso/flat/64/${countryCode}.png`}
            />
          );
        })}
        {personaName}
        </span>
      </div>
      <div className="grid grid-cols-11 gap-4 items-center">
        {renderAccountList(ToolTip, accounts.slice(0, 4))}
        <div className="col-span-3">
          <div className="text-center">
            <div className="mx-auto">
              {insightReportAvatar ? (
                <img
                  alt="persona"
                  className="rounded-full mx-auto h-60 w-60"
                  src={insightReportAvatar}
                />
              ) : (
                <div>
                  <p className="text-indigo-600 text-lg font-bold inline-block">Upload a file</p>{' '}
                  <p className="text-gray-500 text-lg inline-block">or drag and drop.</p>
                  <p className="text-gray-500">PNG, JPG up to 3MB</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {renderAccountList(ToolTip, accounts.slice(-4))}
      </div>
      {
        gender && !isEmpty(ageRanges) &&
        <div className="text-center text-xl leading-6 font-semibold space-y-4 text-black">
          {[capitalize(gender), ageRanges.join(', ')].join(', ')}
        </div>
      }
      <div className="text-center mt-6 mb-8 text-xl leading-6 font-semibold space-y-4 text-black">
        {personas}
      </div>
    </RaisedSegment>
  );
}

PersonaView.propTypes = {
  insightReport: PropTypes.object.isRequired,
};
