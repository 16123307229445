import PropTypes from 'prop-types';

import { addDefaultImage } from '@utils/influencersUtils';

import InsightReportInputLabels from '@components/shared/InsightReportInputLabels';

export default function SelectInsightReportOption({ data, innerProps, isFocused }) {
  const searchOptionClassNames = [
    'bg-white',
    'cursor-pointer',
    'flex',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'hover:bg-gray-100',
    'items-center',
    'px-6',
    'py-5',
    'relative',
    'shadow-sm',
    'space-x-3',
  ];

  return (
    <div
      className={searchOptionClassNames.join(' ')}
      focused={isFocused ? 'true' : null}
      key={data.id}
      {...innerProps}
    >
      <div className="flex-shrink-0">
        <img
          alt=""
          className="rounded-full w-10 h-10 float-right bg-cover"
          onError={addDefaultImage}
          src={data.avatar?.default || data.avatar}
        />
      </div>
      <div className="flex-1 min-w-0">
        <span className="font-medium text-gray-900">
          {data.name}
        </span>
        <InsightReportInputLabels
          inputs={data.inputs}
          reportType={data.reportType}
        />
      </div>
    </div>
  );
}

SelectInsightReportOption.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    id: PropTypes.string,
    inputs: PropTypes.object,
    name: PropTypes.string,
    reportType: PropTypes.string,
  }),
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
};
