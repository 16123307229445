import PropTypes from 'prop-types';
import { Component } from 'react';
import semver from 'semver';

import packageJson from '../package.json';

global.appVersion = packageJson.version;

export function refreshCacheAndReload(caches) {
  // eslint-disable-next-line no-console
  console.log('Clearing cache and hard reloading...');
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(async function (names) {
      for (let name of names) {
        caches.delete(name);
      }
    });
    // eslint-disable-next-line no-console
    console.log('Cache purged');
  }

  // delete browser cache and hard reload
  window.location.reload(true);
}

export default class CacheBuster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        let caches;
        caches = typeof caches === 'undefined' || !caches ? undefined : caches;
        refreshCacheAndReload(caches);
      },
    };
  }

  componentDidMount() {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        if (semver.gt(latestVersion, currentVersion)) {
          // eslint-disable-next-line no-console
          console.log(`There is a new version ${latestVersion}, current is ${currentVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          // eslint-disable-next-line no-console
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    });
  }
}

CacheBuster.propTypes = {
  children: PropTypes.func.isRequired,
};
