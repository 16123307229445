import { get, isEmpty, sum } from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import InterestsAccountsTable from './InterestsAccountsTable';
import getTopInterestsPieChartConfig from './topInterestsPieChartConfig';

import Card from '@common/Card';
import Chart from '@common/Chart';
import COLORS from '@utils/colorsUtils';

const interestsDrilldownColors = [
  COLORS.pink[400],
  COLORS.pink[200],
  COLORS.red[200],
  COLORS.red[400],
  COLORS.red[600],
  COLORS.yellow[600],
  COLORS.yellow[300],
  COLORS.green[300],
  COLORS.green[400],
  COLORS.green[500],
  COLORS.cyan[700],
  COLORS.cyan[500],
  COLORS.cyan[200],
];

function getFirstLevelData(data) {
  const total = sum(data.map(item => item.y));
  return data
    .map((dataPoint, i) => ({
      name: dataPoint.name,
      y: (dataPoint.y / total) * 100,
      drilldown: dataPoint.drilldown,
      color: interestsDrilldownColors[i % interestsDrilldownColors.length],
    }))
    .sort((first, second) => second.y - first.y || first.name.localeCompare(second.name));
}

function getSecondLevelData(data) {
  const total = sum(data.map(item => item.y));
  return data
    .map((value, i) => ({
      color: interestsDrilldownColors[i % interestsDrilldownColors.length],
      name: value.name,
      y: (value.y / total) * 100,
    }))
    .sort((first, second) => second.y - first.y || first.name.localeCompare(second.name));
}

export default function InterestsBreakdownView(props) {
  // selected topic
  const [topic, setTopic] = useState('All Topics');

  // build chart config
  const chartConfig = useMemo(() => {
    let result = {};
    if (props.interests.message !== 'processing' && !isEmpty(props.interests)) {
      // extract first and second level data
      const firstLevelSeries = [
        {
          data: getFirstLevelData(get(props.interests, 'topics.series.0.data', [])),
          name: get(props.interests, 'topics.series.0.name'),
        },
      ];
      const secondLevelSeries = Object.fromEntries(
        (get(props.interests, 'topics.drilldown.series') || [])
          .map(series => [
            series.name,
            { name: series.name, data: getSecondLevelData(series.data) },
          ])
      );

      // construct chart configuration
      result = getTopInterestsPieChartConfig(
        () => setTopic('All Topics'),
        setTopic,
        firstLevelSeries,
        secondLevelSeries
      );
      return result;
    }
  }, [props.interests]);

  // render chart
  const relevantTopics = [topic, ...(props.topicDescendants[topic] || [])];
  return (
    <Card
      subtitle="This audience is interested in the following topics"
      title="Top Interests"
    >
      <div className="grid grid-cols-11 gap-x-10">
        <div className="col-span-7">
          <Chart config={chartConfig} />
        </div>
        <div className="col-span-4">
          <InterestsAccountsTable
            accounts={props.accounts}
            relevantTopics={relevantTopics}
          />
        </div>
      </div>
    </Card>
  );
}

InterestsBreakdownView.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  interests: PropTypes.object.isRequired,
  topicDescendants: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
};
