import toast from 'react-hot-toast';

function getContent(title: string, message?: string): JSX.Element {
  return (
    <div className="px-2">
      <div className="font-medium text-base">
        {title}
      </div>
      {message && <div>{message}</div>}
    </div>
  );
}

export function notifyError(title: string, message?: string): void {
  toast.error(
    data => getContent(title, message),
    {
      iconTheme: {
        primary: '#EF4444',
        secondary: '#FFFAEE',
      },
      style: {
        color: '#111827',
        padding: '12px',
      },
    }
  );
}

export function notifySuccess(title: string, message?: string): void {
  toast.success(
    data => getContent(title, message),
    {
      iconTheme: {
        primary: '#10B981',
        secondary: '#FFFAEE',
      },
      style: {
        color: '#111827',
        padding: '12px',
      },
    }
  );
}
