import { assignItems } from '@api/queue';
import { getUsers } from '@api/user';
import { CommonDialogParams, CommonDialogProps } from '@common/commonTypes';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { FormAsyncDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import SelectUserOption from '@common/reactSelect/SelectUserOption';
import SelectUserValue from '@common/reactSelect/SelectUserValue';
import { getUserName, Option, UserAccount } from '@utils/commonUtils';
import { ErrorResponse } from '@utils/httpUtils';
import { notifySuccess } from '@utils/notification';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

export default function AssignItemsDialog(props: AssignItemsDialogProps): JSX.Element {
  // set up form
  type UserOption = Option<number> & UserAccount;
  type FormData = { count: number; user: UserOption };
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset,
    setValue,
  } = useForm<FormData>({ mode: 'onChange' });
  useEffect(
    () => {
      if (props.params.isOpen) {
        reset();
      }
    },
    [props.params.isOpen]
  );

  // assign items
  const {
    error: errorAssigningItems,
    isLoading: isAssigningItems,
    mutate: doAssignItems,
  } = useMutation<void, ErrorResponse, FormData>(
    data => assignItems(props.params.queueId!, data.user.value, data.count),
    {
      onSuccess: () => {
        notifySuccess('Items assigned!');
        props.close();
        props.onUpdate();
      },
    }
  );

  // render dialog
  return (
    <Dialog
      close={props.close}
      hasForm
      isDisabled={isAssigningItems || !isValid}
      isOpen={props.params.isOpen}
      isUpdating={false}
      onSubmit={handleSubmit((data: FormData) => doAssignItems(data))}
      submitTitle="Assign Items"
      title="Assign Queue Items to User"
    >
      {errorAssigningItems && <ErrorMessage message={errorAssigningItems.message} />}
      <div className="mt-4">
        <FormAsyncDropdown
          control={control}
          error={errors.user}
          label="Select a user"
          loadOptions={(query: string, callback: (data: Array<UserOption>) => void) => {
            // we should return undefined
            void getUsers(0, query).then(response =>
              callback(response.payload.map(user => ({ ...user, label: getUserName(user), value: user.id })))
            );
          }}
          name="user"
          onSelection={(option: UserOption) => setValue('user', option, { shouldValidate: true })}
          option={SelectUserOption}
          placeholder="Type to search..."
          rules={{ required: 'You have to select a user' }}
          singleValue={SelectUserValue}
        />
      </div>
      <div className="mt-4">
        <FormField
          label={`Number of queue items (${props.params.unassignedItemCount!} remaining)`}
          name="count"
          register={() => register('count', { required: 'Specifying the count is mandatory' })}
          type="number"
        />
      </div>
    </Dialog>
  );
}

export interface AssignItemsDialogProps extends CommonDialogProps<AssignItemsDialogParams> {
  onUpdate: () => unknown;
}

export interface AssignItemsDialogParams extends CommonDialogParams {
  queueId?: number;
  queueName?: string;
  unassignedItemCount?: number;
}
