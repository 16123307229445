import PropTypes from 'prop-types';

export default function FormLabel(props) {
  return (
    <div>
      <label
        className="block mb-1 font-medium text-gray-700"
        htmlFor={props.forName}
      >
        {props.children}
      </label>
    </div>
  );
}

FormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  forName: PropTypes.string,
};
