export enum FlagSize {
  EXTRA_LARGE = 'xl',
  LARGE = 'large',
  MEDIUM = 'medium',
  REGULAR = 'regular',
  SMALL = 'small'
}

const FLAG_SIZE: Record<FlagSize, number> = {
  [FlagSize.EXTRA_LARGE]: 64,
  [FlagSize.LARGE]: 48,
  [FlagSize.MEDIUM]: 32,
  [FlagSize.REGULAR]: 24,
  [FlagSize.SMALL]: 16,
};

export interface FlagProps {
  countryCode: string;
  size?: FlagSize;
}

export default function Flag(props: FlagProps): JSX.Element {
  const filename = `${FLAG_SIZE[props.size ?? FlagSize.REGULAR]}/${props.countryCode}.png`;
  return (
    <img
      alt={props.countryCode}
      className="inline mr-1"
      src={`https://storage.googleapis.com/topicdna-public/flags-iso/flat/${filename}`}
    />
  );
}
