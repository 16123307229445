import { CogIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

export default function CrawlingIndicator(props) {
  return (
    <div className="bg-white rounded-lg pl-8 pr-12 py-8 shadow">
      <div className="flex items-center">
        <div className="mr-8 flex-shrink-0">
          <CogIcon className="text-indigo-700 w-20 h-20 animate-spin-slow" />
        </div>
        <div className="flex-grow">
          <span className="text-xl font-medium">
            {props.title}
          </span>
          {props.children}
        </div>
      </div>
    </div>
  );
}

CrawlingIndicator.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
};
