import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withSentryRouting } from '@sentry/react';

import CreateEmailInsightReport from '@components/insightReports/builders/CreateEmailInsightReport';
import CreateTwitterInsightReport from '@components/insightReports/builders/CreateTwitterInsightReport';
import CreateTwitterListInsightReport from '@components/insightReports/builders/CreateTwitterListInsightReport';
import CompareInsightReports from '@components/insightReports/CompareInsightReports';
import InsightReportSection from '@components/insightReports/InsightReportSection';
import InsightsView from '@components/insightReports/insights/InsightsView';
import TargetingInsightView from '@components/insightReports/insights/TargetingInsightView';
import withBreadcrumb from '@hoc/withBreadcrumb';

// create custom Sentry route component
const BreadcrumbRoute = withBreadcrumb(withSentryRouting(Route));

export default function InsightReportRoutes(props) {
  return (
    <Switch>
      <BreadcrumbRoute
        exact
        path={props.match.path}
        render={() => <InsightReportSection />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}comparison/*`}
        render={() => <CompareInsightReports />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}new_twitter_report`}
        render={() => <CreateTwitterInsightReport />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}new_twitter_list_report`}
        render={() => <CreateTwitterListInsightReport />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}new_email_report`}
        render={() => <CreateEmailInsightReport />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}:insightReportId/targeting`}
        render={() => <TargetingInsightView />}
      />
      <BreadcrumbRoute
        exact
        path={`${props.match.path}:insightReportId/*`}
        render={() => <InsightsView />}
      />

      <Redirect
        from="*"
        to={props.match.path}
      />
    </Switch>
  );
}

InsightReportRoutes.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }),
};
