import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import getOrganizationMemberTableColumns from './organizationMemberTableColumns';
import TransferDialog from './TransferDialog';

import { getOrganization } from '@api/organization';
import { deleteUser } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import DeleteDialog from '@common/DeleteDialog';
import Flag from '@common/Flag';
import ErrorMessage from '@common/ErrorMessage';
import Section from '@common/Section';
import Table from '@common/Table';
import { getOrganizationAvatar } from '@root/utils/avatar';

export default function OrganizationDetails() {
  // extract arguments
  const { organizationId } = useParams();

  // acquire history handler
  const history = useHistory();

  // query organization
  const {
    data: organization,
    error: errorLoadingOrganization,
    isFetching: isLoadingOrganization,
    refetch: refetchOrganization,
  } = useQuery(
    ['organization', organizationId],
    () => getOrganization(organizationId),
    { initialData: { members: [] } }
  );

  // modal state
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] = useState({ isOpen: false });
  const [paramsOfTransferDialog, setParamsOfTransferDialog] = useState({ isOpen: false });

  // render campaign section
  const columns = getOrganizationMemberTableColumns({
    openDeleteDialog: member => setParamsOfDeleteDialog({ id: member.id, isOpen: true, name: member.name }),
    openTransferDialog: member => setParamsOfTransferDialog({ id: member.id, isOpen: true, name: member.name }),
  });
  return (
    <>
      <Section
        heading={(
          <div className="flex justify-end">
            <Button
              icon={ArrowSmLeftIcon}
              kind={ButtonKind.REGULAR}
              onClick={() => history.push('/admin/organizations')}
              title="Back to Organizations"
            />
            <Button
              hasLeftMargin
              isDisabled={!organization.id}
              kind={ButtonKind.PRIMARY}
              onClick={() => history.push(`/admin/organizations/${organization.id}/edit`)}
              title="Edit Organization"
            />
          </div>
        )}
        title={(
          <>
            {
              <img
                alt={organization.avatar ? '' : 'Default avatar'}
                className="rounded-full mr-4 h-12 w-12"
                src={getOrganizationAvatar(organization)}
              />
            }
            {organization.name ? `${organization.name} (id: ${organization.id})` : 'Loading...'}
            {organization.country && <span className="ml-2">
              <Flag
                countryCode={organization.country}
                size="medium"
              />
            </span>}
          </>
        )}
      >
        <div className="my-2">
          {errorLoadingOrganization && <ErrorMessage message="Could not load organization information." />}
        </div>

        <Table
          columns={columns}
          data={organization.members}
          entitiesName="members"
          initiallySortedBy="id"
          isLoading={isLoadingOrganization}
          noDataMessage="No members found."
        />
      </Section>

      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ isOpen: false })}
        delete={userId => deleteUser(userId)}
        entityName="organization member"
        onUpdate={refetchOrganization}
        params={paramsOfDeleteDialog}
      />
      <TransferDialog
        close={() => setParamsOfTransferDialog({ isOpen: false })}
        onUpdate={organizationId => history.push(`/admin/organizations/${organizationId}`)}
        params={paramsOfTransferDialog}
      />
    </>
  );
}
