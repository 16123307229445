import { SearchIcon, XIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

export default function Search(props) {
  const DEFAULT_CLASS_NAMES = [
    'focus:ring-blue-500',
    'focus:border-blue-500',
    'block',
    'w-full',
    'pl-10',
    'sm:text-sm',
    'border-gray-300',
    'rounded-md',
    'placeholder:text-gray-400',
  ];

  return (
    <div className="relative">
      <div
        aria-hidden="true"
        className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
      >
        <SearchIcon className="mr-3 h-4 w-4 text-gray-400" />
      </div>
      <input
        className={DEFAULT_CLASS_NAMES.join(' ')}
        onChange={event => props.setSearchTerm(event.target.value)}
        placeholder={'Search...'}
        type="text"
        value={props.searchTerm || ''}
      />
      {props.searchTerm &&
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          <XIcon
            className="cursor-pointer mr-1 h-4 w-4 text-indigo-700"
            onClick={() => props.setSearchTerm('')}
          />
        </div>
      }
    </div>
  );
}

Search.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
};
