import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { signIn } from '@api/authentication';
import Button, { ButtonKind } from '@common/Button';
import FormField from '@common/FormField';
import ErrorMessage from '@common/ErrorMessage';
import { UserInfoContext } from '@hoc/withUserInfo';
import { isEmail } from '@utils/validations';

export default function UserLoginForm() {
  const history = useHistory();
  const { setCredentials, setUserInfo } = useContext(UserInfoContext);

  const { error, isLoading, mutate } = useMutation(
    data => signIn(data.email, data.password),
    {
      onSuccess: loginInfo => {
        setCredentials(loginInfo.credentials);
        setUserInfo(loginInfo.userInfo);
      },
    }
  );

  // set up the form
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ mode: 'onChange' });

  // render the form
  return (
    <div className="p-2">
      {error && (
        <div className="mb-4">
          <ErrorMessage message="Invalid email/password combination" />
        </div>
      )}
      <form
        className="space-y-6"
        onSubmit={handleSubmit(mutate)}
      >
        <div>
          <FormField
            error={errors.email}
            label="Email Address"
            name="email"
            register={() =>
              register('email', {
                required: 'Email address is required',
                validate: isEmail,
              })
            }
            type="text"
          />
        </div>
        <div>
          <FormField
            error={errors.password}
            label="Password"
            name="password"
            register={() => register('password', { required: 'Password is required' })}
            type="password"
          />
        </div>
        <div>
          <Button
            isFullWidth
            isLoading={isLoading}
            kind={ButtonKind.PRIMARY}
            title={isLoading ? 'Signing in...' : 'Sign in'}
            type="submit"
          />
        </div>
      </form>
      <div className="mt-4 text-center">
        <Button
          kind={ButtonKind.LINK}
          onClick={() => history.push('/forgot_password')}
          title="Forgot your password?"
        />
      </div>
    </div>
  );
}
