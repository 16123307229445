import { Menu as ReactMenu, Transition as ReactTransition } from '@headlessui/react';
import { ChevronDownIcon, DotsVerticalIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';

import { classNames } from '../utils/generalUtils';

const DEFAULT_TITLE_CLASSES = [
  'border',
  'border-transparent',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-indigo-500',
  'focus:ring-offset-2',
  'font-medium',
  'inline-flex',
  'items-center',
  'ml-3',
  'px-4',
  'py-2',
  'rounded-md',
  'shadow-sm',
  'text-sm',
];
const NORMAL_TITLE_CLASSES = [
  'bg-indigo-600',
  'hover:bg-indigo-700',
  'text-white',
];
const DARK_TITLE_CLASSES = [
  'hover:bg-gray-700',
  'text-gray-300',
];

const DEFAULT_NO_TITLE_CLASSES = [
  'bg-white',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'focus:ring-purple-500',
  'h-8',
  'hover:text-gray-500',
  'inline-flex',
  'items-center',
  'justify-center',
  'rounded-full',
  'text-gray-400',
  'w-8',
];
const DEFAULT_MENU_ITEM_CLASSES = [
  'bg-white',
  'divide-gray-100',
  'divide-y',
  'focus:outline-none',
  'mt-1',
  'mx-3',
  'p-2',
  'ring-1',
  'ring-black',
  'ring-opacity-5',
  'rounded-md',
  'shadow-lg',
];

export function Menu(props) {
  // calculate class names
  const size = props.size === 'lg' ? 'w-96' : 'w-64';
  const titleClasses = [...DEFAULT_TITLE_CLASSES, ...(props.isDark ? DARK_TITLE_CLASSES : NORMAL_TITLE_CLASSES)];

  // set up Popper
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: props.placement || 'left',
    strategy: 'fixed',
  });

  // render menu
  const Icon = props.icon || DotsVerticalIcon;
  return (
    <ReactMenu
      as="div"
      className="relative flex justify-end items-center"
      onClick={props.onClick}
    >
      {({ open }) => (
        <>
          {props.title ? (
            <ReactMenu.Button
              className={titleClasses.join(' ')}
              data-tut={props['data-tut']}
              ref={setReferenceElement}
            >
              {props.title}
              {typeof props.title === 'string' && (
                <ChevronDownIcon
                  aria-hidden="true"
                  className="-mr-1 ml-2 h-5 w-5"
                />
              )}
            </ReactMenu.Button>
          ) : (
            <ReactMenu.Button
              className={DEFAULT_NO_TITLE_CLASSES.join(' ')}
              data-tut={props['data-tut']}
              ref={setReferenceElement}
            >
              <Icon
                aria-hidden="true"
                className="w-5 h-5"
              />
            </ReactMenu.Button>
          )}
          {!isEmpty(props.children) && (
            <div
              className="z-50"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <ReactTransition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                show={open}
              >
                <ReactMenu.Items
                  className={`${DEFAULT_MENU_ITEM_CLASSES.join(' ')} ${size}`}
                  static
                >
                  {props.children}
                </ReactMenu.Items>
              </ReactTransition>
            </div>
          )}
        </>
      )}
    </ReactMenu>
  );
}

Menu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.node]).isRequired,
  'data-tut': PropTypes.string,
  icon: PropTypes.func,
  isDark: PropTypes.bool,
  onClick: PropTypes.func,
  placement: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.node,
};

export function MenuItem(props) {
  const Icon = props.icon;
  return (
    <ReactMenu.Item>
      {({ active }) => props.custom ? (
        props.custom(active)
      ) : (
        <button
          className={classNames(
            props.isDangerous
              ? active
                ? 'bg-gray-100 text-red-600'
                : 'text-red-500 hover:text-red-500'
              : active
                ? 'bg-gray-100 text-gray-900 hover:text-indigo-600'
                : 'text-gray-700 hover:text-indigo-600',
            'group flex flex-wrap w-full items-center px-4 py-4'
          )}
          onClick={props.onClick}
        >
          <Icon
            aria-hidden="true"
            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          />
          <div className="flex-1 ml-2 text-left">
            <p>{props.title}</p>
            {props.description && <span className="inline-block mt-1 text-gray-400">{props.description}</span>}
          </div>
        </button>
      )}
    </ReactMenu.Item>
  );
}

MenuItem.propTypes = {
  custom: PropTypes.func,
  description: PropTypes.string,
  icon: PropTypes.func,
  isDangerous: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};
