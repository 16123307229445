import { ExclamationIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

export default function FormError(props) {
  return (
    <div className="mt-1">
      <ExclamationIcon
        aria-hidden="true"
        className="inline h-5 w-5 text-red-500"
      />
      <span className="ml-1 text-sm text-red-600">
        {props.message}
      </span>
    </div>
  );
}

FormError.propTypes = {
  message: PropTypes.string.isRequired,
};
