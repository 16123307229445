import { ChevronRightIcon, HomeIcon } from '@heroicons/react/outline';
import { isEmpty, omit, size, startCase, trim } from 'lodash';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import Button, { ButtonKind } from '@common/Button';

function Breadcrumb() {
  // acquire route match
  const match = useRouteMatch();

  // extract paths
  const pathElements = trim(match.path, '/').split('/');
  const urlElements = trim(match.url, '/').split('/');
  if (!isEmpty(pathElements)) {
    const count = size(pathElements);
    if (pathElements[count - 1] === '*') {
      if (count === size(urlElements)) {
        pathElements[count - 1] = urlElements[count - 1];
      } else {
        pathElements.splice(count - 1, 1);
      }
    }
  }

  // render breadcrumb
  return (
    <nav
      aria-label="Breadcrumb"
      className="flex"
    >
      <ol className="flex items-center space-x-4 mb-4">
        <li>
          <div
            onClick={() => mixpanel.track('Breadcrumb Click', { title: 'Home' })}
          >
            <Button
              icon={HomeIcon}
              kind={ButtonKind.LINK}
              target="/"
              title="Home"
            />
          </div>
        </li>
        {pathElements.map((item, index) => {
          const title = item[0] === ':'
            ? `${startCase(item.replace(/Id$/, ''))} #${match.params[item.substring(1)]}`
            : startCase(item);
          return (
            <li
              key={index}
            >
              <div
                className="flex items-center"
                onClick={() => mixpanel.track('Breadcrumb Click', { title })}
              >
                <ChevronRightIcon
                  aria-hidden="true"
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                />
                {index === size(pathElements) - 1 ? <span className="ml-2">{title}</span> : (
                  <Button
                    hasLeftMargin
                    kind={ButtonKind.LINK}
                    target={'/' + urlElements.slice(0, index + 1).join('/')}
                    title={title}
                  />
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

/* eslint-disable react/prop-types */
export default function withBreadcrumb(WrappedComponent) {
  return props => {
    const [isBreadcrumbVisible, setIsBreadcrumbVisible] = useState(false);
    useEffect(
      () => {
        if (!isBreadcrumbVisible) {
          setIsBreadcrumbVisible(true);
        }
      },
      [isBreadcrumbVisible]
    );
    return (
      <WrappedComponent {...omit(props, 'render')}>
        {isBreadcrumbVisible && <Breadcrumb />}
        {typeof props.render === 'function' ? props.render() : React.createElement(props.render, props)}
      </WrappedComponent>
    );
  };
}
/* eslint-enable react/prop-types */
