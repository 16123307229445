import { countBy, isEmpty, transform, trim } from 'lodash';
import { postcodeValidator } from 'postcode-validator';

// Regex from: https://emailregex.com/
// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isRequired = value => {
  return !value || (typeof value === 'string' && value.trim() === '') ? 'This field is required.' : null;
};

export const isRequiredForArrays = value => {
  if (!value) {
    return 'This field is required.';
  } else if (value.length < 1) {
    return 'This field is required.';
  } else {
    return null;
  }
};

export const minLength = length => {
  return value => {
    return value && value.length < length ? `At least ${length} characters required.` : null;
  };
};

export const maxEntries = size => {
  return entries => {
    return entries && entries.length > size
      ? `Too many accounts entered, the maximum number of accounts is ${size}.`
      : null;
  };
};

function splitEmails(emails) {
  if (
    emails.split(',').find(function (email) {
      return !EMAIL_REGEX.test(email.trim()) === true;
    })
  ) {
    return true;
  }
  return false;
}

export const splitAndCheckEmail = value =>
  value && splitEmails(value) ? 'Please use the format: example@domain.com.' : true;

export const isEmail = value => {
  return !EMAIL_REGEX.test(value) ? 'Please use the format: example@domain.com.' : null;
};

const matchingError = (matches, fieldName) => {
  return matches ? `Please make sure your ${fieldName}s match.` : null;
};

export const matchField = fieldName => {
  return (value, allValues) => {
    return matchingError(value !== allValues.get(fieldName), fieldName);
  };
};

export const matchAuthField = (fieldName, allValues) => {
  return value => {
    return matchingError(value !== allValues[fieldName], fieldName);
  };
};

export const fileExtension = acceptableExtensions => {
  return file => {
    if (!file || !file.name) {
      return false;
    }
    const extension = file.name.split('.').pop();
    const acceptableExtensionsRegex = new RegExp(acceptableExtensions.join('|'), 'gi');

    if (!extension.match(acceptableExtensionsRegex)) {
      return `Invalid file type: ${extension}. ${acceptableExtensions.join(', ')} permitted.`;
    }
  };
};

export const maxFileSize = maxSizeInMb => {
  return file => {
    if (!file) {
      return false;
    }
    if (!file || file.size / 1024 / 1024 > maxSizeInMb) {
      return `This file is too large. The maximum allowed size is ${maxSizeInMb}MB.`;
    }
  };
};

export const isValidPostalCode = (value, allValues) => {
  const country = allValues.get('country');
  const errorMessage = `Postal code ${value} is not valid for ${country}.`;

  if (value) {
    const acceptedCountryCode = country === 'GB' ? 'UK' : country;
    const validation = postcodeValidator(value, acceptedCountryCode);
    return validation === true ? null : errorMessage;
  }
  return errorMessage;
};

export function checkForInvalidData(data, type) {
  switch (type) {
    case 'email': {
      const invalidEmails = [];
      data.forEach(value => {
        let isSHA256 = value.match(/^[A-Fa-f0-9]{64}$/);
        let isMD5 = value.match(/^[a-f0-9]{32}$/);
        if (!isSHA256 && !isMD5 && isEmail(trim(value)) && !!value) {
          invalidEmails.push(value);
        }
      });
      return invalidEmails;
    }
    case 'twitter_handles': {
      const re = new RegExp('^@');
      const invalidHandles = [];
      data.forEach(value => {
        const trimmedValue = trim(value);
        if ((!isEmail(trimmedValue) || re.test(trimmedValue) || !isNaN(trimmedValue)) && !!value) {
          invalidHandles.push(value);
        }
      });
      return invalidHandles;
    }
    case 'twitter_id': {
      const invalidIds = [];
      data.forEach(value => {
        if (isNaN(value) && !!value) {
          invalidIds.push(value);
        }
      });
      return invalidIds;
    }
    default:
      return [];
  }
}

function isLessThan100(data) {
  return isEmpty(data) || data.length < 100;
}

function validateData(data, type) {
  const invalidData = checkForInvalidData(data, type);
  if (invalidData.length === 0) {
    return { hasError: false, invalidData: [] };
  }
  return { hasError: true, invalidData, errorProperty: 'valid' };
}

export function getDuplicatedData(data) {
  const datumAppearanceCount = countBy(data);
  return transform(
    datumAppearanceCount,
    (duplicatedDataList, appearance, value) => {
      if (appearance > 1) {
        duplicatedDataList.push(value);
      }
    },
    []
  );
}

function checkForMinimalUniqueData(data, type) {
  const invalidData = getDuplicatedData(data);
  const uniqueData = [...new Set(data)];
  if (uniqueData.length >= 100) {
    return validateData(data, type);
  }
  return { hasError: true, invalidData: invalidData, errorProperty: 'unique' };
}

export const verifyFileData = (data, type) => {
  if (isLessThan100(data)) {
    return { hasError: true, invalidData: [] };
  }
  return checkForMinimalUniqueData(data, type);
};
