import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import Badge from '@common/Badge';
import Button, { ButtonKind } from '@common/Button';
import Emoji from '@common/Emoji';
import { ToolTipContext } from '@hoc/withToolTip';
import COLORS from '@utils/colorsUtils';
import { formatCount, formatFractional, formatMoneyAmount, formatPercentage } from '@utils/formattingUtils';

function renderWithOrWithoutDescription(content, description, dataTut) {
  return description ? (
    <div data-tut={dataTut}>
      <span style={{ display: 'block' }}>
        {content}
      </span>
      {description && (
        <span style={{ color: COLORS.grey[300], fontSize: '11px' }}>
          {description}
        </span>
      )}
    </div>
  ) : dataTut ? (
    <div data-tut={dataTut}>
      {content}
    </div>
  ) : (
    content
  );
}

export function CellWithBadge(props) {
  const ToolTip = useContext(ToolTipContext);
  const badge = (
    <Badge
      color={props.color}
      context={props.context}
      text={props.title}
    />
  );
  const linkedBadge = props.target ? (
    <Button
      isTargetExternal={props.isTargetExternal}
      kind={ButtonKind.LINK}
      target={props.target}
    >
      {badge}
    </Button>
  ) : (
    badge
  );
  return props.toolTip ? (
    <ToolTip
      content={(
        <div className="max-w-sm">
          {props.toolTip}
        </div>
      )}
      isHtml
    >
      {linkedBadge}
    </ToolTip>
  ) : (
    linkedBadge
  );
}

CellWithBadge.propTypes = {
  color: PropTypes.string,
  context: PropTypes.string.isRequired,
  isTargetExternal: PropTypes.bool,
  target: PropTypes.string,
  title: PropTypes.node.isRequired,
  toolTip: PropTypes.node,
};


export function CellWithChange(props) {
  let context;

  if (props.newValue > props.oldValue) {
    context = props.isNegativeGood ? 'negative' : 'positive';
  } else {
    context = props.isNegativeGood ? 'positive' : 'negative';
  }

  const Icon = props.newValue > props.oldValue ? ArrowSmUpIcon : ArrowSmDownIcon;
  return renderWithOrWithoutDescription(
    (
      <>
        {formatMoneyAmount(props.newValue, props.currency)}
        <span className={`text-sm ${context === 'positive' ? 'text-green-500' : 'text-red-500'}`}>
          {props.oldValue !== 0 && (
            <>
              <Icon className="inline mb-0.5 w-4 h-4" />
              {(100 * Math.abs(props.newValue - props.oldValue) / props.oldValue).toFixed(1)}%
            </>
          )}
        </span>
      </>
    ),
    props.description
  );
}

CellWithChange.propTypes = {
  currency: PropTypes.string,
  description: PropTypes.string,
  isNegativeGood: PropTypes.bool,
  newValue: PropTypes.number.isRequired,
  oldValue: PropTypes.number.isRequired,
};

export function CellWithCount(props) {
  const ToolTip = useContext(ToolTipContext);
  return renderWithOrWithoutDescription(
    (
      <ToolTip content={(props.count || 0).toLocaleString()}>
        <span>{formatCount(props.count)}</span>
      </ToolTip>
    ),
    props.description
  );
}

CellWithCount.propTypes = {
  count: PropTypes.number.isRequired,
  description: PropTypes.string,
};

export function CellWithFractional(props) {
  return renderWithOrWithoutDescription(formatFractional(props.value), props.description);
}

CellWithFractional.propTypes = {
  description: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export function CellWithMoneyAmount(props) {
  return renderWithOrWithoutDescription(
    formatMoneyAmount(props.amount, props.currency),
    props.description,
    props['data-tut']
  );
}

CellWithMoneyAmount.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string,
  'data-tut': PropTypes.string,
  description: PropTypes.string,
};

export function CellWithPercentage(props) {
  return renderWithOrWithoutDescription(
    formatPercentage(props.value, props.numberOfDigits), props.description
  );
}

CellWithPercentage.propTypes = {
  description: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export function CellWithAvatar(props) {
  const ToolTip = useContext(ToolTipContext);
  const image = (
    <img
      alt=""
      className="inline-block h-9 w-9 rounded-full"
      src={props.src}
    />
  );

  return props.toolTip ? (
    <ToolTip
      content={(
        <div className="max-w-sm">
          {props.toolTip}
        </div>
      )}
      isHtml
    >
      {image}
    </ToolTip>
  ) : (
    image
  );
}

CellWithAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  toolTip: PropTypes.string,
};

export function LinkCellWithEmoji(props) {
  return (
    <div className="flex items-center">
      {props.emoji && props.emoji !== '' && (
        <div className="flex-none">
          <Emoji symbol={props.emoji} />
        </div>
      )}
      <div className={props.emoji ? 'ml-4' : ''}>
        <span className="text-base font-medium text-gray-700 group-hover:text-gray-900">
          {props.children}
        </span>
        {props.description && (
          <div className="text-sm text-gray-500 group-hover:text-gray-700">
            {props.description}
          </div>
        )}
      </div>
    </div>
  );
}

LinkCellWithEmoji.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.node,
  emoji: PropTypes.string,
};

export function StatCell(props) {
  const { isDisabled, value } = props;

  return (
    <div className={`text-gray-${isDisabled ? '300' : '700'} break-words text-sm`}>
      {value}
    </div>
  );
}

StatCell.propTypes = {
  isDisabled: PropTypes.bool,
  value: PropTypes.node.isRequired,
};

export function AdSetCell(props) {
  const { children, context, description, isDisabled } = props;

  let cellBackground;

  if (context === 'success') {
    cellBackground = 'bg-green-100';
  } else if (context === 'danger') {
    cellBackground = 'bg-red-100';
  } else if (context === 'info') {
    cellBackground = 'bg-blue-100';
  } else {
    cellBackground = 'bg-white-100';
  }

  return (
    <div className={`${cellBackground} flex items-center p-2 rounded`}>
      <div>
        <span className={`text-sm font-medium text-gray-${isDisabled ? '300' : '700'} group-hover:text-gray-900`}>
          {children}
        </span>
        {description && (
          <div className={`text-xs  text-gray-${isDisabled ? '300' : '500'} group-hover:text-gray-700`}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
}

AdSetCell.propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.string,
  description: PropTypes.node,
  isDisabled: PropTypes.bool,
};
export function GenericCell(props) {
  const { children, description, image, isDisabled } = props;

  return (
    <div className="flex items-center">
      {image && (
        <div className="flex-none">
          <img
            alt=""
            className="inline-block h-9 w-9 rounded-full"
            src={image}
          />
        </div>
      )}
      <div className={image ? 'ml-3' : ''}>
        <span className={`text-sm font-medium text-gray-${isDisabled ? '300' : '700'} group-hover:text-gray-900`}>
          {children}
        </span>
        {description && (
          <div className={`text-xs  text-gray-${isDisabled ? '300' : '500'} group-hover:text-gray-700`}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
}

GenericCell.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.node,
  image: PropTypes.string,
  isDisabled: PropTypes.bool,
};
