import { getPersonaLibrary } from '@api/admin';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { sortBy } from 'lodash';

import Button, { ButtonKind } from '@common/Button';
import Dialog, { DialogSize } from '@common/Dialog';
import { FormTreeDropdown } from '@common/FormDropdowns';
import Table from '@common/Table';
import PlatformSelector from '@components/shared/PlatformSelector';

import getPersonaLibraryTableColumns from '@components/admin/personaLibrary/personaLibraryTableColumns';
export default function PersonaPickerModal(props) {
  // extract arguments
  const { isOpen = false } = props.params;
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('All Topics');

  const PLATFORMS = [
    'dv360',
    'facebook',
    'pinterest',
    'tiktok',
    'twitter',
  ];

  // query persona library
  const { data: personas, isFetching: isLoadingPersonaLibrary } = useQuery(
    ['personaLibrary'],
    getPersonaLibrary,
    {
      initialData: [],
      select: data => sortBy(data, 'topic', 'subtopic', 'name'),
    }
  );

  const handlePlatformChange = value => {
    if (value) {
      setSelectedPlatform(value.value);
    } else {
      setSelectedPlatform('');
    }
  };

  const filteredPersonas = useMemo(
    () => personas.filter(persona => {
      const topicPath = `${persona.topic} ${persona.subtopic}`;

      if (selectedPlatform !== '' && selectedTopic !== 'All Topics') {
        return persona[`${selectedPlatform}AdAudienceId`] !== 0 && topicPath.includes(selectedTopic);
      } else if (selectedPlatform !== '') {
        return persona[`${selectedPlatform}AdAudienceId`] !== 0;
      } else if (selectedTopic !== 'All Topics') {
        return topicPath.includes(selectedTopic);
      }
      return true;
    }),
    [personas, selectedPlatform, selectedTopic]
  );

  // render modal
  const columns = getPersonaLibraryTableColumns({ isAdmin: false });
  return (
    <Dialog
      close={props.close}
      gaName="personaPicker"
      hasNoButtons
      isOpen={isOpen}
      size={DialogSize.FULL_WIDTH}
      title="Browse Our Pre-Built Personas"
    >
      <div className="mt-4">
        {personas.length > 0
          ? (
            <Table
              columns={columns}
              data={filteredPersonas}
              entitiesName="personas"
              extra={
                <>
                  <div className="w-64">
                    <FormTreeDropdown
                      isCollapsed
                      onSelection={option => setSelectedTopic(option.value)}
                      options={[
                        {
                          label: 'All Topics',
                          value: 'All Topics',
                          children: [
                            {
                              label: 'Art & Design',
                              value: 'Art & Design',
                              isDisabled: true,
                            },
                            {
                              label: 'Automotive & Vehicles',
                              value: 'Automotive & Vehicles',
                              isDisabled: true,
                            },
                            {
                              label: 'Beauty & Wellness',
                              value: 'Beauty & Wellness',
                              isDisabled: true,
                            },
                            {
                              label: 'Business & Industry',
                              value: 'Business & Industry',
                              isDisabled: true,
                            },
                            {
                              label: 'Education & Careers',
                              value: 'Education & Careers',
                              isDisabled: true,
                            },
                            {
                              label: 'Entertainment',
                              value: 'Entertainment',
                              children: [
                                {
                                  label: 'Movies',
                                  value: 'Movies',
                                },
                                {
                                  label: 'Video Games',
                                  value: 'Video Games',
                                  isDisabled: true,
                                },
                              ],
                            },
                            {
                              label: 'Finance & Investment',
                              value: 'Finance & Investment',
                              children: [
                                {
                                  label: 'Credit & Lending',
                                  value: 'Credit & Lending',
                                },
                                {
                                  label: 'Insurance',
                                  value: 'Insurance',
                                  isDisabled: true,
                                },
                              ],
                            },
                            {
                              label: 'Food & Drink',
                              value: 'Food & Drink',
                              isDisabled: true,
                            },
                            {
                              label: 'Health & Medicine',
                              value: 'Health & Medicine',
                              isDisabled: true,
                            },
                            {
                              label: 'Hobbies & Leisure',
                              value: 'Hobbies & Leisure',
                              isDisabled: true,
                            },
                            {
                              label: 'Home & Garden',
                              value: 'Home & Garden',
                              isDisabled: true,
                            },
                            {
                              label: 'Law, Gov\'t & Politics',
                              value: 'Law, Gov\'t & Politics',
                              isDisabled: true,
                            },
                            {
                              label: 'News, Media & Publications',
                              value: 'News, Media & Publications',
                              isDisabled: true,
                            },
                            {
                              label: 'Pets & Animals',
                              value: 'Pets & Animals',
                              isDisabled: true,
                            },
                            {
                              label: 'Real Estate',
                              value: 'Real Estate',
                              isDisabled: true,
                            },
                            {
                              label: 'Religion & Spirituality',
                              value: 'Religion & Spirituality',
                              isDisabled: true,
                            },
                            {
                              label: 'Science & Humanities',
                              value: 'Science & Humanities',
                              isDisabled: true,
                            },
                            {
                              label: 'Shopping & Retail',
                              value: 'Shopping & Retail',
                              isDisabled: true,
                            },
                            {
                              label: 'Society',
                              value: 'Society',
                              isDisabled: true,
                            },
                            {
                              label: 'Sports & Fitness',
                              value: 'Sports & Fitness',
                              isDisabled: true,
                            },
                            {
                              label: 'Style & Fashion',
                              value: 'Style & Fashion',
                              isDisabled: true,
                            },
                            {
                              label: 'Technology',
                              value: 'Technology',
                              isDisabled: true,
                            },
                            {
                              label: 'Travel',
                              value: 'Travel',
                              isDisabled: true,
                            },
                          ],
                        },
                      ]}
                      placeholder="All Topics"
                      value={{ label: selectedTopic, value: selectedTopic }}
                    />
                  </div>
                  <div className="w-48">
                    <PlatformSelector
                      onSelect={handlePlatformChange}
                      platforms={PLATFORMS}
                    />
                  </div>
                  <div className="w-72">
                    <a
                      className="text-xs ml-1 mr-1 text-indigo-500 hover:text-indigo-600"
                      href="mailto:hello@personalyze.co?subject=Persona Library Request"
                    >Can&apos;t find your audience? Get in touch.</a>
                  </div>
                  <div className="absolute right-6 top-10">
                    <Button
                      kind={ButtonKind.REGULAR}
                      onClick={() => props.close(true)}
                      title="Back to Audiences"
                    />
                  </div>
                </>
              }
              initiallySortedBy="topic"
              isLoading={isLoadingPersonaLibrary}
              isSearchable
              noDataMessage={<>
                <p>
                  No personas found. Get in touch at
                  <a
                    className="ml-1 mr-1 text-blue-500 hover:text-blue-600"
                    href="mailto:hello@personalyze.co?subject=Persona Library Request"
                  >hello@personalyze.co</a>
                  and we will get in touch shortly.
                </p>
              </>}
            />
          )
          : (
            <div>{isLoadingPersonaLibrary ? 'Retrieving personas...' : 'No personas.'}</div>
          )
        }
      </div>
    </Dialog >
  );
}

PersonaPickerModal.propTypes = {
  close: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
