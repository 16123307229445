import { isNil } from 'lodash';

const rawTopics: Array<{ entityId: number, name: string, parentEntityId?: number }> = [
  {
    entityId: 4501396,
    name: 'Painting',
    parentEntityId: 4501418,
  },
  {
    entityId: 4501397,
    name: 'Language Learning',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501398,
    name: 'Post-Secondary Education',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501399,
    name: 'Primary & Secondary Schools (K-12)',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501400,
    name: 'Special Education',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501401,
    name: 'Study Abroad Programs',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501402,
    name: 'Teaching and Classroom Resources',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501403,
    name: 'Test Preparation & Tutoring',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501404,
    name: 'Entertainment',
  },
  {
    entityId: 4501405,
    name: 'Adult Entertainment',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501406,
    name: 'Books & Literature',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501407,
    name: 'Sculpture',
    parentEntityId: 4501418,
  },
  {
    entityId: 4501408,
    name: 'Live Events',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501409,
    name: 'Live Streaming',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501410,
    name: 'Movies',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501411,
    name: 'Music',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501412,
    name: 'On Demand Media',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501413,
    name: 'Performing Arts',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501414,
    name: 'Podcasts',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501415,
    name: 'Radio',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501416,
    name: 'Social Media',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501417,
    name: 'TV',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501418,
    name: 'Visual Art',
    parentEntityId: 4501506,
  },
  {
    entityId: 4501419,
    name: 'Video Games',
    parentEntityId: 4501404,
  },
  {
    entityId: 4501420,
    name: 'Finance and Investment',
  },
  {
    entityId: 4501421,
    name: 'Accounting and Auditing',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501422,
    name: 'Banking Services',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501423,
    name: 'Auto Loans',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501424,
    name: 'Business Loans',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501425,
    name: 'Credit Cards',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501426,
    name: 'Credit Reports & Monitoring Services',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501427,
    name: 'Home Loans',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501428,
    name: 'Mortgage',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501429,
    name: 'Automotive and Vehicles',
  },
  {
    entityId: 4501430,
    name: 'Personal Loans',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501431,
    name: 'Student Loans',
    parentEntityId: 4501432,
  },
  {
    entityId: 4501432,
    name: 'Credit and Lending',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501433,
    name: 'Digital Payments & Currencies',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501434,
    name: 'Economics',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501435,
    name: 'Estate Planning',
    parentEntityId: 4501438,
  },
  {
    entityId: 4501436,
    name: 'Personal Finance Planning',
    parentEntityId: 4501438,
  },
  {
    entityId: 4501437,
    name: 'Retirement Planning',
    parentEntityId: 4501438,
  },
  {
    entityId: 4501438,
    name: 'Financial Planning',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501439,
    name: 'FinTech',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501440,
    name: 'Auto Parts and Accessories',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501441,
    name: 'Foreign Exchange',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501442,
    name: 'Grants, Scholarships and Financial Aid',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501443,
    name: 'Auto Insurance',
    parentEntityId: 4501448,
  },
  {
    entityId: 4501444,
    name: 'Health Insurance',
    parentEntityId: 4501448,
  },
  {
    entityId: 4501445,
    name: 'Home Insurance',
    parentEntityId: 4501448,
  },
  {
    entityId: 4501446,
    name: 'Life Insurance',
    parentEntityId: 4501448,
  },
  {
    entityId: 4501447,
    name: 'Travel Insurance',
    parentEntityId: 4501448,
  },
  {
    entityId: 4501448,
    name: 'Insurance',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501449,
    name: 'Brokerages',
    parentEntityId: 4501454,
  },
  {
    entityId: 4501450,
    name: 'Funds',
    parentEntityId: 4501454,
  },
  {
    entityId: 4501451,
    name: 'Auto Repair and Maintenance',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501452,
    name: 'Stocks',
    parentEntityId: 4501454,
  },
  {
    entityId: 4501453,
    name: 'Venture Capital',
    parentEntityId: 4501454,
  },
  {
    entityId: 4501454,
    name: 'Investing',
    parentEntityId: 4501420,
  },
  {
    entityId: 4501455,
    name: 'Food and Drink',
  },
  {
    entityId: 4501456,
    name: 'Beer',
    parentEntityId: 4501459,
  },
  {
    entityId: 4501457,
    name: 'Distilled Beverages',
    parentEntityId: 4501459,
  },
  {
    entityId: 4501458,
    name: 'Wine and Cocktails',
    parentEntityId: 4501459,
  },
  {
    entityId: 4501459,
    name: 'Alcoholic Beverages',
    parentEntityId: 4501455,
  },
  {
    entityId: 4501460,
    name: 'Baking',
    parentEntityId: 4501464,
  },
  {
    entityId: 4501461,
    name: 'Healthy Eating',
    parentEntityId: 4501464,
  },
  {
    entityId: 4501462,
    name: 'Bicycles and Accessories',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501463,
    name: 'Recipes',
    parentEntityId: 4501464,
  },
  {
    entityId: 4501464,
    name: 'Cooking',
    parentEntityId: 4501455,
  },
  {
    entityId: 4501465,
    name: 'Chinese Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501466,
    name: 'French Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501467,
    name: 'German Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501468,
    name: 'Greek Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501469,
    name: 'Indian Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501470,
    name: 'Italian Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501471,
    name: 'Japanese Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501472,
    name: 'Korean Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501473,
    name: 'Boats and Watercraft',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501474,
    name: 'Latin American Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501475,
    name: 'Mexican Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501476,
    name: 'Middle Eastern Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501477,
    name: 'Spanish Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501478,
    name: 'Thai Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501479,
    name: 'Vietnamese Cuisine',
    parentEntityId: 4501480,
  },
  {
    entityId: 4501480,
    name: 'Cuisine',
    parentEntityId: 4501455,
  },
  {
    entityId: 4501481,
    name: 'Barbecue',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501482,
    name: 'Chocolate',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501483,
    name: 'Dairy',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501484,
    name: 'Campers and RVs',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501485,
    name: 'Desserts',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501486,
    name: 'Fast Food',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501487,
    name: 'Fruits and Vegetables',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501488,
    name: 'Organic Food',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501489,
    name: 'Pizza',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501490,
    name: 'Seafood',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501491,
    name: 'Snack Foods',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501492,
    name: 'Veganism',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501493,
    name: 'Vegetarianism',
    parentEntityId: 4501494,
  },
  {
    entityId: 4501494,
    name: 'Food',
    parentEntityId: 4501455,
  },
  {
    entityId: 4501495,
    name: 'Commercial Vehicles',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501496,
    name: 'Infant Feeding Supplies',
    parentEntityId: 4501498,
  },
  {
    entityId: 4501497,
    name: 'Toddler Meals',
    parentEntityId: 4501498,
  },
  {
    entityId: 4501498,
    name: 'Infant & Toddler Feeding',
    parentEntityId: 4501455,
  },
  {
    entityId: 4501499,
    name: 'Bottled Water',
    parentEntityId: 4501505,
  },
  {
    entityId: 4501500,
    name: 'Coffee',
    parentEntityId: 4501505,
  },
  {
    entityId: 4501501,
    name: 'Juice',
    parentEntityId: 4501505,
  },
  {
    entityId: 4501502,
    name: 'Soft Drinks',
    parentEntityId: 4501505,
  },
  {
    entityId: 4501503,
    name: 'Sports and Energy Drinks',
    parentEntityId: 4501505,
  },
  {
    entityId: 4501504,
    name: 'Tea',
    parentEntityId: 4501505,
  },
  {
    entityId: 4501505,
    name: 'Non-alcoholic Beverages',
    parentEntityId: 4501455,
  },
  {
    entityId: 4501506,
    name: 'Art and Design',
  },
  {
    entityId: 4501507,
    name: 'Hybrid and Alternative Vehicles',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501508,
    name: 'Restaurants',
    parentEntityId: 4501455,
  },
  {
    entityId: 4501509,
    name: 'Health and Medicine',
  },
  {
    entityId: 4501510,
    name: 'Addiction',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501511,
    name: 'Aging',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501512,
    name: 'Alternative Medicine',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501513,
    name: 'Dental Care',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501514,
    name: 'Disease Centres and Information',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501515,
    name: 'Drug Centres and Information',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501516,
    name: 'Health Care & Medicine',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501517,
    name: 'Health Technology',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501518,
    name: 'Luxury Vehicles',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501519,
    name: 'Men\'s Health',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501520,
    name: 'Mental Health',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501521,
    name: 'Sports Medicine',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501522,
    name: 'Therapy',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501523,
    name: 'Women\'s Health',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501524,
    name: 'Weight Loss',
    parentEntityId: 4501509,
  },
  {
    entityId: 4501525,
    name: 'Hobbies and Leisure',
  },
  {
    entityId: 4501526,
    name: 'Arts and Crafts',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501527,
    name: 'Astrology and Horoscopes',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501528,
    name: 'Birdwatching',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501529,
    name: 'Motor Vehicles',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501530,
    name: 'Cigars and Cigarettes',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501531,
    name: 'Collecting',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501532,
    name: 'Gambling',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501533,
    name: 'Games',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501534,
    name: 'Home Recording',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501535,
    name: 'Jewelry Making',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501536,
    name: 'Magic and Illusion',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501537,
    name: 'Modeling',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501538,
    name: 'Musical Instruments',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501539,
    name: 'Needlework',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501540,
    name: 'Motorcycles',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501541,
    name: 'Paranormal Phenomena',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501542,
    name: 'Photography',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501543,
    name: 'Scrapbooking',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501544,
    name: 'Screenwriting',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501545,
    name: 'Stamps and Coins',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501546,
    name: 'Toys',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501547,
    name: 'Writing',
    parentEntityId: 4501525,
  },
  {
    entityId: 4501548,
    name: 'Home and Garden',
  },
  {
    entityId: 4501549,
    name: 'Bed and Bath',
    parentEntityId: 4501548,
  },
  {
    entityId: 4501550,
    name: 'DIY',
    parentEntityId: 4501548,
  },
  {
    entityId: 4501551,
    name: 'Other Vehicles',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501552,
    name: 'Architectural Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501553,
    name: 'Carpet Installation',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501554,
    name: 'Door & Window Installation',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501555,
    name: 'Electrician Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501556,
    name: 'Flooring Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501557,
    name: 'Gardening and Landscape Design',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501558,
    name: 'General Contracting & Remodeling Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501559,
    name: 'Home Cleaning Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501560,
    name: 'Home Inspection Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501561,
    name: 'Interior Design & Decorating Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501562,
    name: 'Scooters and Mopeds',
    parentEntityId: 4501429,
  },
  {
    entityId: 4501563,
    name: 'Locksmith Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501564,
    name: 'Painting Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501565,
    name: 'Pest Control Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501566,
    name: 'Plumbing Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501567,
    name: 'Roofing Services',
    parentEntityId: 4501568,
  },
  {
    entityId: 4501568,
    name: 'Home and Garden Services',
    parentEntityId: 4501548,
  },
  {
    entityId: 4501569,
    name: 'Home Appliances',
    parentEntityId: 4501548,
  },
  {
    entityId: 4501570,
    name: 'Home Furniture and Accessories',
    parentEntityId: 4501548,
  },
  {
    entityId: 4501571,
    name: 'Home Security',
    parentEntityId: 4501548,
  },
  {
    entityId: 4501572,
    name: 'Nursery and Playrooms',
    parentEntityId: 4501548,
  },
  {
    entityId: 4501573,
    name: 'Beauty and Wellness',
  },
  {
    entityId: 4501574,
    name: 'Law, Government and Politics',
  },
  {
    entityId: 4501575,
    name: 'Activism',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501576,
    name: 'Armed Forces',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501577,
    name: 'Espionage and Intelligence',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501578,
    name: 'Government and Politics',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501579,
    name: 'Immigration',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501580,
    name: 'Environmental',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501581,
    name: 'Humanitarian',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501582,
    name: 'Finance',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501583,
    name: 'Law Enforcement',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501584,
    name: 'Bath and Body Products',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501585,
    name: 'Legal Services',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501586,
    name: 'Political Commentary',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501587,
    name: 'Veterans',
    parentEntityId: 4501574,
  },
  {
    entityId: 4501588,
    name: 'News, Media and Publications',
  },
  {
    entityId: 4501589,
    name: 'Animal and Pet News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501590,
    name: 'Art & Design News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501591,
    name: 'Automotive and Vehicle News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501592,
    name: 'Beauty & Wellness News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501593,
    name: 'Blogs and Vlogs',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501594,
    name: 'Business and Economic News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501595,
    name: 'Face Care Products',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501596,
    name: 'Education News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501597,
    name: 'Entertainment and Celebrity News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501598,
    name: 'Fashion and Lifestyle News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501599,
    name: 'Finance and Investment News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501600,
    name: 'Food and Drink News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501601,
    name: 'Health & Medicine News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501602,
    name: 'Hobbies & Leisure News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501603,
    name: 'Home & Garden News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501604,
    name: 'Local and Regional News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501605,
    name: 'Men\'s Media',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501606,
    name: 'Perfumes & Fragrances',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501607,
    name: 'Music News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501608,
    name: 'Political News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501609,
    name: 'Real Estate News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501610,
    name: 'Religion & Spirituality News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501611,
    name: 'Retail News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501612,
    name: 'Science News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501613,
    name: 'Society News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501614,
    name: 'Sports News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501615,
    name: 'Technology News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501616,
    name: 'Travel News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501617,
    name: 'Architectural Design',
    parentEntityId: 4501968,
  },
  {
    entityId: 4501618,
    name: 'Hair Care Products',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501619,
    name: 'Women\'s Media',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501620,
    name: 'World News',
    parentEntityId: 4501588,
  },
  {
    entityId: 4501621,
    name: 'Pets and Animals',
  },
  {
    entityId: 4501622,
    name: 'Animal Humour',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501623,
    name: 'Animal Welfare',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501624,
    name: 'Aquariums',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501625,
    name: 'Birds',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501626,
    name: 'Cats',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501627,
    name: 'Dogs',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501628,
    name: 'Farm Animals',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501629,
    name: 'Makeup and Cosmetics',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501630,
    name: 'Horses',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501631,
    name: 'Large Animals',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501632,
    name: 'Pet Food',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501633,
    name: 'Rabbits',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501634,
    name: 'Reptiles',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501635,
    name: 'Sea Animals',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501636,
    name: 'Small Animals',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501637,
    name: 'Zoo',
    parentEntityId: 4501621,
  },
  {
    entityId: 4501638,
    name: 'Real Estate',
  },
  {
    entityId: 4501639,
    name: 'Apartments',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501640,
    name: 'Meditation',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501641,
    name: 'Commercial Properties',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501642,
    name: 'Luxury Homes',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501643,
    name: 'Moving & Relocation',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501644,
    name: 'Property Developers',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501645,
    name: 'Property Surveyor',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501646,
    name: 'Real Estate Agents',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501647,
    name: 'Renting Properties',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501648,
    name: 'Residential Properties',
    parentEntityId: 4501638,
  },
  {
    entityId: 4501649,
    name: 'Religion and Spirituality',
  },
  {
    entityId: 4501650,
    name: 'Alternative Religions',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501651,
    name: 'Skin Care Products',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501652,
    name: 'Atheism and Agnosticism',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501653,
    name: 'Buddhism',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501654,
    name: 'Catholicism',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501655,
    name: 'Christianity',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501656,
    name: 'Hinduism',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501657,
    name: 'Islam',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501658,
    name: 'Judaism',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501659,
    name: 'Sikhism',
    parentEntityId: 4501649,
  },
  {
    entityId: 4501660,
    name: 'Science and Humanities',
  },
  {
    entityId: 4501661,
    name: 'Biology',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501662,
    name: 'Spas and Beauty Services',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501663,
    name: 'Chemistry',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501664,
    name: 'Ecology',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501665,
    name: 'Engineering',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501666,
    name: 'Geography',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501667,
    name: 'Genealogy',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501668,
    name: 'Geology',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501669,
    name: 'History and Historic Sites',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501670,
    name: 'Inventors and Patents',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501671,
    name: 'Mathematics',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501672,
    name: 'Museums',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501673,
    name: 'Tanning and Sun Care Products',
    parentEntityId: 4501573,
  },
  {
    entityId: 4501674,
    name: 'Philosophy',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501675,
    name: 'Physics',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501676,
    name: 'Social Science',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501677,
    name: 'Space and Astronomy',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501678,
    name: 'Weather',
    parentEntityId: 4501660,
  },
  {
    entityId: 4501679,
    name: 'Shopping and Retail',
  },
  {
    entityId: 4501680,
    name: 'Auctions',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501681,
    name: 'Child Car Seats',
    parentEntityId: 4501686,
  },
  {
    entityId: 4501682,
    name: 'Diapers & Baby Hygiene Products',
    parentEntityId: 4501686,
  },
  {
    entityId: 4501683,
    name: 'Strollers and Baby Carriages',
    parentEntityId: 4501686,
  },
  {
    entityId: 4501684,
    name: 'Business and Industry',
  },
  {
    entityId: 4501685,
    name: 'Toys & Games',
    parentEntityId: 4501686,
  },
  {
    entityId: 4501686,
    name: 'Baby & Children\'s Products',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501687,
    name: 'Bakeries',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501688,
    name: 'Butchers',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501689,
    name: 'Buying and Selling Cars',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501690,
    name: 'Classifieds',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501691,
    name: 'Convenience Stores',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501692,
    name: 'Department Stores',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501693,
    name: 'eCommerce',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501694,
    name: 'Flowers',
    parentEntityId: 4501702,
  },
  {
    entityId: 4501695,
    name: 'Defense Industry',
    parentEntityId: 4501717,
  },
  {
    entityId: 4501696,
    name: 'Gift Baskets',
    parentEntityId: 4501702,
  },
  {
    entityId: 4501697,
    name: 'Holiday & Seasonal Items',
    parentEntityId: 4501702,
  },
  {
    entityId: 4501698,
    name: 'Party Supplies & Planning',
    parentEntityId: 4501702,
  },
  {
    entityId: 4501699,
    name: 'Personalized Gifts',
    parentEntityId: 4501702,
  },
  {
    entityId: 4501700,
    name: 'Photo & Video Services',
    parentEntityId: 4501702,
  },
  {
    entityId: 4501701,
    name: 'Wedding Planning',
    parentEntityId: 4501702,
  },
  {
    entityId: 4501702,
    name: 'Gifts & Occasions',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501703,
    name: 'Grocery Stores',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501704,
    name: 'Luxury Stores',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501705,
    name: 'Outlet Stores',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501706,
    name: 'Space Technology',
    parentEntityId: 4501717,
  },
  {
    entityId: 4501707,
    name: 'Shopping Centres',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501708,
    name: 'Consumer Protection',
    parentEntityId: 4501714,
  },
  {
    entityId: 4501709,
    name: 'Contests and Freebies',
    parentEntityId: 4501714,
  },
  {
    entityId: 4501710,
    name: 'Coupons and Savings',
    parentEntityId: 4501714,
  },
  {
    entityId: 4501711,
    name: 'Deals',
    parentEntityId: 4501714,
  },
  {
    entityId: 4501712,
    name: 'Loyalty Programs',
    parentEntityId: 4501714,
  },
  {
    entityId: 4501713,
    name: 'Product Reviews',
    parentEntityId: 4501714,
  },
  {
    entityId: 4501714,
    name: 'Shopping Resources',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501715,
    name: 'Specialty Stores',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501716,
    name: 'Value and Second-hand Stores',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501717,
    name: 'Aerospace and Defense',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501718,
    name: 'Wholesalers',
    parentEntityId: 4501679,
  },
  {
    entityId: 4501719,
    name: 'Society',
  },
  {
    entityId: 4501720,
    name: 'Community Issues',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501721,
    name: 'Crime and Prevention',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501722,
    name: 'Dating',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501723,
    name: 'Divorce',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501724,
    name: 'Family',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501725,
    name: 'Fatherhood',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501726,
    name: 'Friendship',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501727,
    name: 'Marriage',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501728,
    name: 'Fashion Design',
    parentEntityId: 4501968,
  },
  {
    entityId: 4501729,
    name: 'Agriculture',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501730,
    name: 'Motherhood',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501731,
    name: 'Parenting',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501732,
    name: 'Teens',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501733,
    name: 'Weddings',
    parentEntityId: 4501734,
  },
  {
    entityId: 4501734,
    name: 'Family and Relationships',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501735,
    name: 'LGBT',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501736,
    name: 'Senior Living',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501737,
    name: 'Sex Education and Information',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501738,
    name: 'Social Clubs',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501739,
    name: 'Charity and Causes',
    parentEntityId: 4501743,
  },
  {
    entityId: 4501740,
    name: 'Architecture',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501741,
    name: 'Sustainability',
    parentEntityId: 4501743,
  },
  {
    entityId: 4501742,
    name: 'Volunteering',
    parentEntityId: 4501743,
  },
  {
    entityId: 4501743,
    name: 'Social Impact',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501744,
    name: 'Social Issues and Rights',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501745,
    name: 'Social Well-being',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501746,
    name: 'Work Issues and Rights',
    parentEntityId: 4501719,
  },
  {
    entityId: 4501747,
    name: 'Sports and Fitness',
  },
  {
    entityId: 4501748,
    name: 'American Football',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501749,
    name: 'Archery',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501750,
    name: 'Australian Football',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501751,
    name: 'Automation',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501752,
    name: 'Auto Racing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501753,
    name: 'Badminton',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501754,
    name: 'Baseball',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501755,
    name: 'Basketball',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501756,
    name: 'Billiards',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501757,
    name: 'Boating and Sailing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501758,
    name: 'Bobsled',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501759,
    name: 'Bodybuilding',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501760,
    name: 'Bowling',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501761,
    name: 'Boxing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501762,
    name: 'Aviation',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501763,
    name: 'Canoeing and Kayaking',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501764,
    name: 'Cheerleading',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501765,
    name: 'Climbing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501766,
    name: 'Collegiate Sports',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501767,
    name: 'Cricket',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501768,
    name: 'Curling',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501769,
    name: 'Cycling',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501770,
    name: 'Darts',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501771,
    name: 'Diving',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501772,
    name: 'Fencing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501773,
    name: 'Biomedical',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501774,
    name: 'Field Hockey',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501775,
    name: 'Fishing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501776,
    name: 'Exercise Equipment',
    parentEntityId: 4501779,
  },
  {
    entityId: 4501777,
    name: 'Fitness Classes & Personal Training Services',
    parentEntityId: 4501779,
  },
  {
    entityId: 4501778,
    name: 'Gyms & Athletic Clubs',
    parentEntityId: 4501779,
  },
  {
    entityId: 4501779,
    name: 'Fitness Products & Services',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501780,
    name: 'Formula 1',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501781,
    name: 'Golf',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501782,
    name: 'Gymnastics',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501783,
    name: 'Handball',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501784,
    name: 'Advertising and Marketing Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501785,
    name: 'Horse Racing and Equestrian',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501786,
    name: 'Hunting and Shooting',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501787,
    name: 'Ice Hockey',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501788,
    name: 'Lacrosse',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501789,
    name: 'Martial Arts',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501790,
    name: 'Mixed Martial Arts',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501791,
    name: 'Motorcycling',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501792,
    name: 'NASCAR',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501793,
    name: 'Olympic Games',
    parentEntityId: 4501796,
  },
  {
    entityId: 4501794,
    name: 'Paralympic Games',
    parentEntityId: 4501796,
  },
  {
    entityId: 4501795,
    name: 'Business Financial Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501796,
    name: 'Olympics',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501797,
    name: 'Paintball',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501798,
    name: 'Physical Fitness & Exercise',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501799,
    name: 'Polo',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501800,
    name: 'Racquetball',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501801,
    name: 'Rowing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501802,
    name: 'Rugby',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501803,
    name: 'Running and Jogging',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501804,
    name: 'Skateboarding',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501805,
    name: 'Skating',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501806,
    name: 'Business Printing & Document Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501807,
    name: 'Skiing',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501808,
    name: 'Snowboarding',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501809,
    name: 'Soccer',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501810,
    name: 'Softball',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501811,
    name: 'Sports Equipment & Accessories',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501812,
    name: 'Surfing and Bodyboarding',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501813,
    name: 'Swimming',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501814,
    name: 'Table Tennis and Ping-pong',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501815,
    name: 'Tennis',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501816,
    name: 'Volleyball',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501817,
    name: 'Business Technology',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501818,
    name: 'Water Polo',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501819,
    name: 'Water Sports',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501820,
    name: 'Weight Training',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501821,
    name: 'Winter Sports',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501822,
    name: 'Wrestling',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501823,
    name: 'Yoga and Pilates',
    parentEntityId: 4501747,
  },
  {
    entityId: 4501824,
    name: 'Style and Fashion',
  },
  {
    entityId: 4501825,
    name: 'Accessories',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501826,
    name: 'Activewear',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501827,
    name: 'Baby & Toddler Apparel',
    parentEntityId: 4501830,
  },
  {
    entityId: 4501828,
    name: 'Consulting Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501829,
    name: 'Children\'s Apparel',
    parentEntityId: 4501830,
  },
  {
    entityId: 4501830,
    name: 'Baby & Children\'s Apparel',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501831,
    name: 'Costumes',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501832,
    name: 'Fashion',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501833,
    name: 'Bridal Wear',
    parentEntityId: 4501835,
  },
  {
    entityId: 4501834,
    name: 'Suits & Business Attire',
    parentEntityId: 4501835,
  },
  {
    entityId: 4501835,
    name: 'Formal Wear',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501836,
    name: 'Fine Jewelry',
    parentEntityId: 4501841,
  },
  {
    entityId: 4501837,
    name: 'Watches',
    parentEntityId: 4501841,
  },
  {
    entityId: 4501838,
    name: 'Wedding & Engagement Rings',
    parentEntityId: 4501841,
  },
  {
    entityId: 4501839,
    name: 'Graphic Design',
    parentEntityId: 4501968,
  },
  {
    entityId: 4501840,
    name: 'Corporate Event Planning',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501841,
    name: 'Jewlery & Watches',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501842,
    name: 'Lingerie and Underwear',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501843,
    name: 'Luggage',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501844,
    name: 'Luxury Goods',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501845,
    name: 'Men\'s Apparel',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501846,
    name: 'Athletic Shoes',
    parentEntityId: 4501849,
  },
  {
    entityId: 4501847,
    name: 'Boots',
    parentEntityId: 4501849,
  },
  {
    entityId: 4501848,
    name: 'Dress Shoes',
    parentEntityId: 4501849,
  },
  {
    entityId: 4501849,
    name: 'Shoes and Footwear',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501850,
    name: 'Swimwear',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501851,
    name: 'Network Systems & Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501852,
    name: 'Tattoos and Body Art',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501853,
    name: 'Women\'s Apparel',
    parentEntityId: 4501824,
  },
  {
    entityId: 4501854,
    name: 'Technology',
  },
  {
    entityId: 4501855,
    name: 'Audio Technology',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501856,
    name: 'Computer Science',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501857,
    name: 'Consumer Electronics',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501858,
    name: 'Data',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501859,
    name: 'Enterprise Technology',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501860,
    name: 'Hardware',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501861,
    name: 'Internet Technology',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501862,
    name: 'Office Supplies',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501863,
    name: 'Security',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501864,
    name: 'Software',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501865,
    name: 'Technical Support',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501866,
    name: 'Cable & Satellite TV Providers',
    parentEntityId: 4501869,
  },
  {
    entityId: 4501867,
    name: 'Internet Service Providers',
    parentEntityId: 4501869,
  },
  {
    entityId: 4501868,
    name: 'Mobile Phone Service Providers',
    parentEntityId: 4501869,
  },
  {
    entityId: 4501869,
    name: 'Telecommunications',
    parentEntityId: 4501854,
  },
  {
    entityId: 4501870,
    name: 'Travel',
  },
  {
    entityId: 4501871,
    name: 'Bed and Breakfast',
    parentEntityId: 4501876,
  },
  {
    entityId: 4501872,
    name: 'Boutique Hotels',
    parentEntityId: 4501876,
  },
  {
    entityId: 4501873,
    name: 'Payment Processing & Merchant Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501874,
    name: 'Hotels',
    parentEntityId: 4501876,
  },
  {
    entityId: 4501875,
    name: 'Motels',
    parentEntityId: 4501876,
  },
  {
    entityId: 4501876,
    name: 'Accommodations',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501877,
    name: 'Budget Travel',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501878,
    name: 'Business Travel',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501879,
    name: 'Car and Vehicle Rental',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501880,
    name: 'Cruises',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501881,
    name: 'Honeymoons and Getaways',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501882,
    name: 'Luxury Travel',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501883,
    name: 'Beaches',
    parentEntityId: 4501889,
  },
  {
    entityId: 4501884,
    name: 'Payroll Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501885,
    name: 'Lakes',
    parentEntityId: 4501889,
  },
  {
    entityId: 4501886,
    name: 'Mountains',
    parentEntityId: 4501889,
  },
  {
    entityId: 4501887,
    name: 'National Parks',
    parentEntityId: 4501889,
  },
  {
    entityId: 4501888,
    name: 'Nature',
    parentEntityId: 4501889,
  },
  {
    entityId: 4501889,
    name: 'Outdoor Travel',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501890,
    name: 'Ski Resorts',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501891,
    name: 'Adventure Travel',
    parentEntityId: 4501893,
  },
  {
    entityId: 4501892,
    name: 'Ecotourism',
    parentEntityId: 4501893,
  },
  {
    entityId: 4501893,
    name: 'Specialty Tourism',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501894,
    name: 'Theme Parks',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501895,
    name: 'Staffing and Recruitment Services',
    parentEntityId: 4501906,
  },
  {
    entityId: 4501896,
    name: 'Air Travel',
    parentEntityId: 4501903,
  },
  {
    entityId: 4501897,
    name: 'Bus Travel',
    parentEntityId: 4501903,
  },
  {
    entityId: 4501898,
    name: 'Other Travel',
    parentEntityId: 4501903,
  },
  {
    entityId: 4501899,
    name: 'Rail Travel',
    parentEntityId: 4501903,
  },
  {
    entityId: 4501900,
    name: 'Ridesharing',
    parentEntityId: 4501903,
  },
  {
    entityId: 4501901,
    name: 'Road Travel',
    parentEntityId: 4501903,
  },
  {
    entityId: 4501902,
    name: 'Water Travel',
    parentEntityId: 4501903,
  },
  {
    entityId: 4501903,
    name: 'Transportation',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501904,
    name: 'Travel Agencies',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501905,
    name: 'Travel Guides',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501906,
    name: 'Business Services',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501907,
    name: 'Travelling with Kids',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501908,
    name: 'Australia',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501909,
    name: 'China',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501910,
    name: 'Hong Kong',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501911,
    name: 'India',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501912,
    name: 'Indonesia',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501913,
    name: 'Japan',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501914,
    name: 'Malaysia',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501915,
    name: 'New Zealand',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501916,
    name: 'Philippines',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501917,
    name: 'Chemicals Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501918,
    name: 'Singapore',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501919,
    name: 'South Korea',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501920,
    name: 'Taiwan',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501921,
    name: 'Thailand',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501922,
    name: 'Vietnam',
    parentEntityId: 4501923,
  },
  {
    entityId: 4501923,
    name: 'Trips to Asia-Pacific',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501924,
    name: 'Belgium',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501925,
    name: 'Croatia',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501926,
    name: 'Czech Republic',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501927,
    name: 'France',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501928,
    name: 'Conglomerate',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501929,
    name: 'Germany',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501930,
    name: 'Greece',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501931,
    name: 'Ireland',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501932,
    name: 'Italy',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501933,
    name: 'Netherlands',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501934,
    name: 'Portugal',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501935,
    name: 'Russia',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501936,
    name: 'Spain',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501937,
    name: 'Turkey',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501938,
    name: 'United Kingdom',
    parentEntityId: 4501940,
  },
  {
    entityId: 4501939,
    name: 'Construction Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501940,
    name: 'Trips to Europe',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501941,
    name: 'Argentina',
    parentEntityId: 4501946,
  },
  {
    entityId: 4501942,
    name: 'Brazil',
    parentEntityId: 4501946,
  },
  {
    entityId: 4501943,
    name: 'Caribbean',
    parentEntityId: 4501946,
  },
  {
    entityId: 4501944,
    name: 'Costa Rica',
    parentEntityId: 4501946,
  },
  {
    entityId: 4501945,
    name: 'Mexico',
    parentEntityId: 4501946,
  },
  {
    entityId: 4501946,
    name: 'Trips to Latin America',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501947,
    name: 'Egypt',
    parentEntityId: 4501954,
  },
  {
    entityId: 4501948,
    name: 'Israel',
    parentEntityId: 4501954,
  },
  {
    entityId: 4501949,
    name: 'Morocco',
    parentEntityId: 4501954,
  },
  {
    entityId: 4501950,
    name: 'Interior Design',
    parentEntityId: 4501968,
  },
  {
    entityId: 4501951,
    name: 'Energy Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501952,
    name: 'South Africa',
    parentEntityId: 4501954,
  },
  {
    entityId: 4501953,
    name: 'United Arab Emirates',
    parentEntityId: 4501954,
  },
  {
    entityId: 4501954,
    name: 'Trips to Middle East & Africa',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501955,
    name: 'Canada',
    parentEntityId: 4501957,
  },
  {
    entityId: 4501956,
    name: 'United States',
    parentEntityId: 4501957,
  },
  {
    entityId: 4501957,
    name: 'Trips to North America',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501958,
    name: 'Vacation Rentals',
    parentEntityId: 4501870,
  },
  {
    entityId: 4501959,
    name: 'Entrepreneurship & Innovation',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501960,
    name: 'FMCG',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501961,
    name: 'Forestry Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501962,
    name: 'General Business',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501963,
    name: 'Iron and Steel Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501964,
    name: 'Logistics',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501965,
    name: 'Management',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501966,
    name: 'Manufacturing',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501967,
    name: 'Metals Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501968,
    name: 'Design',
    parentEntityId: 4501506,
  },
  {
    entityId: 4501969,
    name: 'Mining Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501970,
    name: 'Digital Marketing',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501971,
    name: 'Display Advertising',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501972,
    name: 'Email Marketing',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501973,
    name: 'Online Advertising',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501974,
    name: 'Search Engine Optimization',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501975,
    name: 'Social Media Marketing',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501976,
    name: 'Web Design',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501977,
    name: 'Web Development',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501978,
    name: 'Web Hosting',
    parentEntityId: 4501980,
  },
  {
    entityId: 4501979,
    name: 'Drawing',
    parentEntityId: 4501418,
  },
  {
    entityId: 4501980,
    name: 'Online Services',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501981,
    name: 'Paper Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501982,
    name: 'Pharmaceutical Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501983,
    name: 'Publishing Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501984,
    name: 'Shipbuilding Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501985,
    name: 'Sustainable Solutions',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501986,
    name: 'Textile Industry',
    parentEntityId: 4501684,
  },
  {
    entityId: 4501987,
    name: 'Education and Careers',
  },
  {
    entityId: 4501988,
    name: 'Adult Education',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501989,
    name: 'Alternative Learning Methods',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501990,
    name: 'Fine Art',
    parentEntityId: 4501418,
  },
  {
    entityId: 4501991,
    name: 'Alumni and Reunions',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501992,
    name: 'Career Services',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501993,
    name: 'Childcare',
    parentEntityId: 4501995,
  },
  {
    entityId: 4501994,
    name: 'Early Childhood Education',
    parentEntityId: 4501995,
  },
  {
    entityId: 4501995,
    name: 'Childcare & Education',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501996,
    name: 'Distance Learning',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501997,
    name: 'EdTech',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501998,
    name: 'English As a Second Language',
    parentEntityId: 4501987,
  },
  {
    entityId: 4501999,
    name: 'Executive Education',
    parentEntityId: 4501987,
  },
  {
    entityId: 4502000,
    name: 'Homeschooling',
    parentEntityId: 4501987,
  },
  {
    entityId: 4502002,
    name: 'Comedy',
  },
  {
    entityId: 4502003,
    name: 'Comics and Animation',
  },
];

// calculate final topic list
const mapping = Object.fromEntries(
  rawTopics.map(topic => [topic.entityId, { name: topic.name, parentEntityId: topic.parentEntityId }])
);
const topics: Array<{ entityId: number, name: string, path: string }> = [];
for (const topic of rawTopics) {
  let current: { name: string, parentEntityId?: number } = topic;
  const path = [];
  while (true) {
    path.unshift(current.name);
    if (isNil(current.parentEntityId)) {
      break;
    }
    current = mapping[current.parentEntityId];
  }
  topics.push({ entityId: topic.entityId, name: topic.name, path: path.join(' / ') });
}

export default topics;
