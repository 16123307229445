import { isEmpty, size } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { generateAudienceSuggestions } from '@api/audiences';
import ErrorMessage from '@common/ErrorMessage';
import { FormCreatableDropdown, FormDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import FormSlider from '@common/FormSlider';
import { WizardStep } from '@common/Wizard';
import { countryOptions } from '@utils/countryUtils';

const GENDER_OPTIONS = [
  { label: 'Any gender', value: '' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];
const AGE_RANGE_OPTIONS = [
  { label: '13-17', value: '13-17' },
  { label: '18-24', value: '18-24' },
  { label: '25-34', value: '25-34' },
  { label: '35-44', value: '35-44' },
  { label: '45-54', value: '45-54' },
  { label: '55-64', value: '55-64' },
  { label: '65+', value: '65-' },
];

function collectData(data, terse) {
  const sortedAgeRanges = data.ageRanges.map(ageRange => ageRange.value).sort();
  return {
    audienceCount: data.numberOfAudiences,
    ageRanges: !isEmpty(sortedAgeRanges)
      ? [sortedAgeRanges[0].split('-')[0] + '-' + sortedAgeRanges[size(sortedAgeRanges) - 1].split('-')[1]]
      : [],
    countries: data.countries.map(country => terse ? country.value : ({ id: country.value, name: country.label })),
    desiredReach: data.desiredReach,
    gender: data.gender.value,
    phrases: data.keywords.map(keyword => keyword.label),
  };
}

export default function KeywordAudienceForm(props) {
  // set up form
  const defaultValues = {
    ageRanges: [],
    countries: [],
    desiredReach: 2000000,
    gender: { value: '' },
    keywords: [],
    numberOfAudiences: 3,
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm({ mode: 'onChange', defaultValues });
  const desiredReach = watch('desiredReach');
  const keywords = watch('keywords');

  // start generation of suggestions
  const { error, isLoading, mutate } = useMutation(
    data => generateAudienceSuggestions(collectData(data, true)),
    {
      onSuccess: (_, data) => {
        props.setAudienceParams(collectData(data, false));
        props._goForward();
      },
    }
  );

  // render form
  return (
    <WizardStep
      forwardButtonTitle="Generate Keyword Audiences"
      hasForm
      isLoading={isLoading}
      onSubmit={handleSubmit(mutate)}
      title="Generate Keyword Suggestions"
      {...props}
    >
      <div>
        {error && <ErrorMessage message={error.message} />}
        <h1 className="text-xl font-semibold">
          What keywords describe your target audience?
        </h1>
        <div className="my-8">
          <FormCreatableDropdown
            control={control}
            error={errors.keywords}
            label="Keywords or phases (limit of 2)"
            name="keywords"
            onChange={value => setValue('keywords', value, { shouldValidate: true })}
            placeholder={
              <p>Enter up to 2 keywords or phrases separated by a comma
                (e.g. football, super bowl)
              </p>
            }
            rules={{
              required: 'Keyword is required',
              validate: value => size(value) <= 2 || 'Only 2 keywords are allowed at the moment',
            }}
            value={keywords}
          />
          <div className="rounded py-2 mt-4">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
              <div>
                <FormDropdown
                  control={control}
                  error={errors.countries}
                  isMulti
                  isSearchable
                  label="Target Country"
                  name="countries"
                  noOptionsMessage="No countries found."
                  options={countryOptions}
                  placeholder="Select one or more countries"
                  rules={{ required: 'You have to select at least one country' }}
                />
              </div>
              <div>
                <FormDropdown
                  control={control}
                  label="Target Gender"
                  name="gender"
                  options={GENDER_OPTIONS}
                  placeholder="Select a gender"
                />
              </div>
              <div>
                <FormDropdown
                  control={control}
                  error={errors.ageRanges}
                  isMulti
                  label="Target Age Range"
                  name="ageRanges"
                  options={AGE_RANGE_OPTIONS}
                  placeholder="Select one or more age ranges"
                  rules={{
                    validate: options => {
                      const sorted = options.map(option => option.value).sort().map(value => value.split('-'));
                      for (let index = 0; index < size(options) - 1; index++) {
                        if (parseInt(sorted[index][1], 10) + 1 !== parseInt(sorted[index + 1][0], 10)) {
                          return 'Age ranges must be consecutive';
                        }
                      }
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-4 grid grid-cols-1 gap-8 sm:grid-cols-3">
          <div>
            <FormSlider
              label="Desired Reach Per Audience"
              maximum={30000000}
              minimum={500000}
              name="desiredReach"
              register={() => register('desiredReach', { valueAsNumber: true })}
              step={500000}
              value={desiredReach}
            />
          </div>
          <div>
            <FormField
              label="Number of Audiences"
              name="numberOfAudiences"
              register={() => register('numberOfAudiences', { valueAsNumber: true })}
              type="number"
            />
          </div>
        </div>
      </div>
    </WizardStep>
  );
}

KeywordAudienceForm.propTypes = {
  _goForward: PropTypes.func,
  setAudienceParams: PropTypes.func,
};
