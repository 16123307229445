import PropTypes from 'prop-types';

import FormLabel from './FormLabel';

export default function FormSlider(props) {
  return (
    <>
      {props.label && <FormLabel forName={props.name}>{props.label}</FormLabel>}
      <div className="flex items-center">
        <input
          disabled={props.isDisabled}
          max={props.maximum}
          min={props.minimum}
          onChange={props.onChange}
          step={props.step || 1}
          type="range"
          {...(props.register ? props.register() : null)}
        />
        <span className="ml-2">
          {parseInt(props.value, 10).toLocaleString()}
        </span>
      </div>
    </>
  );
}

FormSlider.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  maximum: PropTypes.number.isRequired,
  minimum: PropTypes.number.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  register: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
