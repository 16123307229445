import PropTypes from 'prop-types';

import BrandIcon from '@common/BrandIcon';
import { FormDropdown } from '@common/FormDropdowns';

const PLATFORMS = {
  dv360: 'DV360',
  facebook: 'Meta',
  linkedin: 'LinkedIn',
  pinterest: 'Pinterest',
  snapchat: 'Snapchat',
  tiktok: 'TikTok',
  twitter: 'Twitter',
};

function SelectedPlatformValue({ data }) {
  const DEFAULT_CLASS_NAMES = [
    'bg-white',
    'cursor-pointer',
    'flex',
    'flex-grow-0',
    'row-start-1',
    'row-end-1',
    'col-start-1',
    'col-end-3',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'items-center',
    'relative',
    'shadow-sm',
    'space-x-1',
    'text-sm',
  ];


  return (
    <div className={DEFAULT_CLASS_NAMES.join(' ')}>
      <span className="inline-flex px-2 text-xs font-medium text-gray-700">
        <BrandIcon
          brand={data.value}
          size="small"
        />
        <span className="text-sm ml-2">
          {data.label}
        </span>
      </span>
    </div>
  );
}

SelectedPlatformValue.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default function PlatformSelector(props) {
  return (
    <FormDropdown
      isClearable
      isDisabled={props.isDisabled}
      onSelection={props.onSelect}
      option={({ data, innerProps }) => (
        <div
          className="cursor-pointer hover:bg-indigo-50 px-4 py-4 text-left"
          key={data.value}
          {...innerProps}
        >
          <BrandIcon
            brand={data.value}
            size="small"
          />
          <span className="text-sm ml-4">
            {data.label}
          </span>
        </div>
      )}
      options={
        (props.platforms || Object.keys(PLATFORMS)).sort().map(platform =>
          ({ label: PLATFORMS[platform], value: platform })
        )
      }
      placeholder="Platform"
      singleValue={SelectedPlatformValue}
      value={props.value}
    />
  );
}

PlatformSelector.propTypes = {
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.object,
};
