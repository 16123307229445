import { isEmpty } from 'lodash';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import toast, { Toaster, useToaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';

import AdminNavbar from '@components/navbar/AdminNavbar';
import Navbar from '@components/navbar/Navbar';
import { UserInfoContext } from '@hoc/withUserInfo';
import config from '@root/config';
import { scrollTop } from '@utils/pageUtils';

// https://stackoverflow.com/questions/67308674/hide-react-bootstrap-toast-when-user-clicks-outside
function useToastCloser(ref) {
  const { toasts } = useToaster();
  const toastsRef = useRef();
  toastsRef.current = toasts;

  const handleClickOutside = useCallback(event => {
    if (ref.current && !ref.current.contains(event.target) && !isEmpty(toastsRef.current)) {
      toast.dismiss();
    }
  }, [ref]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClickOutside, ref]);
}

const StyledReactTooltip = styled(ReactTooltip)`
  opacity: 1 !important;
  word-break: break-all;
`;

export default function DashboardContent(props) {
  // acquire pathname and user data
  const { pathname } = useLocation();
  const { userInfo } = useContext(UserInfoContext);

  // configure Google Analytics
  ReactGA.set({
    userId: userInfo.id,
    userEmail: userInfo.email,
  });

  // configure Mixpanel
  mixpanel.identify(userInfo.id);
  mixpanel.people.set({
    $distinct_id: userInfo.id,
    $first_name: userInfo.firstName,
    $last_name: userInfo.lastName,
    $name: userInfo.name,
    $email: userInfo.email,
    Company: userInfo.organization.name,
  });

  // set up tooltip clearing and top scrolling for each path change
  useEffect(() => {
    ReactTooltip.hide();
    scrollTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // set up toast closer
  const toastRef = useRef(null);
  useToastCloser(toastRef);

  // initialize Sentry
  if (['staging', 'production'].indexOf(config.REACT_APP_SENTRY_ENV) >= 0) {
    Sentry.init({
      dsn: config.REACT_APP_RAVEN_URL,
      environment: config.REACT_APP_SENTRY_ENV,
      release: config.REACT_APP_RELEASE_VERSION,
      normalizeDepth: config.NODE_ENV === 'production' ? 5 : 10,
      initialScope: {
        user: { id: userInfo.id, email: userInfo.email },
      },
    });
  }

  // render dashboard
  return (
    <>
      {userInfo.isCurrentlyAdmin ? <AdminNavbar /> : <Navbar />}
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {props.children}
          <StyledReactTooltip
            border
            borderColor="#D1D5DB"
            html
            textColor="#111827"
            type="light"
          />
        </div>
      </main>

      <div ref={toastRef}>
        <Toaster position="top-right" />
      </div>
    </>
  );
}

DashboardContent.propTypes = {
  children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  user: PropTypes.object,
};
