import { useContext } from 'react';

import OrganizationMemberTable from './OrganizationMemberTable';

import Card from '@common/Card';
import EditOrganizationForm from '@components/shared/EditOrganizationForm';
import { OrganizationDataContext } from '@hoc/withOrganizationData';

export default function OrganizationView() {
  // acquire organization data
  const { organization, setOrganization } = useContext(OrganizationDataContext);

  // render form
  return (
    <div>
      <section>
        <Card
          subtitle="Update your organization information."
          title="Organization Details"
        >
          <EditOrganizationForm
            onUpdate={setOrganization}
            organization={organization}
          />
        </Card>
      </section>
      <div className="mt-4">
        <OrganizationMemberTable />
      </div>
    </div>
  );
}
