import { size } from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import NoDataMessage from './NoDataMessage';
import Pagination from './table/Pagination';
import Search from './table/Search';
import TableLoader from './table/TableLoader';
import TableHeader from './table/TableHeader';
import TableRow from './table/TableRow';

export default function Table(props, ref) {
  // export function
  useImperativeHandle(ref, () => ({
    clearSelection: () => toggleAllRowsSelected(false),
    resetPageIndex: () => gotoPage(0),
  }));

  // handle row selection
  const [change, setChange] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState();

  useEffect(() => {
    if (change) {
      props.onRowSelectionChange(change);
      setChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  // determine initial state
  const pageSize = props.pageSize || 10;
  const sortBy = props.initiallySortedBy ? [{ id: props.initiallySortedBy, desc: props.isInitiallySortedByDesc }] : [];
  let initialState = {
    hiddenColumns: props.hiddenColumns || [],
    pageIndex: props.pageIndex || 0,
    pageSize,
    selectedRowIds: Object.fromEntries((props.preSelectedRowIds || []).map(id => [id, true])),
    sortBy,
  };

  // set up state reducer if necessary
  const stateReducer = props.onRowSelectionChange
    ? (newState, action, prevState) => {
      if (action.type === 'toggleRowSelected') {
        setChange(newState.selectedRowIds);
      }
      return newState;
    }
    : undefined;

  // set up the table
  const {
    allColumns,
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    prepareRow,
    previousPage,
    rows,
    setFilter,
    setGlobalFilter,
    state: { globalFilter, pageIndex },
    toggleAllRowsSelected,
  } = useTable(
    {
      autoResetPage: props.shouldAutoResetPage,
      autoResetSelectedRows: false,
      columns: props.columns,
      data: props.data,
      globalFilter: props.customFilter,
      initialState,
      stateReducer,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => props.setPageIndex && props.setPageIndex(pageIndex), [pageIndex]);
  useEffect(
    () => {
      if (props.filter) {
        setGlobalFilter(props.filter);
      }
    },
    [props.filter]
  );
  useEffect(
    () => {
      if (props.setFilter) {
        props.setFilter(globalFilter);
      }
    },
    [globalFilter]
  );
  useEffect(
    () => {
      for (const filter of (props.columnFilters || [])) {
        setFilter(filter[0], filter[1]);
      }
    },
    [(props.columnFilters || []).join(' ')]
  );
  useEffect(
    () => {
      if (props.setFilteredRows) {
        props.setFilteredRows(rows);
      }
    },
    [rows.map(row => row.original.id || '.').sort().join(' ')]
  );

  // render the table
  const noData = !props.isLoading && !size(props.data);
  return (
    <>
      {(props.extra || props.isSearchable) && (
        <div className={`${props.isFullWidthControls ? 'space-y-2' : 'flex'} w-full gap-x-2 items-center`}>
          {props.isSearchable && (
            <Search
              searchTerm={globalFilter}
              setSearchTerm={setGlobalFilter}
            />
          )}
          {props.extra}
        </div>
      )}

      {noData ? (
        <NoDataMessage message={props.noDataMessage || 'No data.'} />
      ) : (
        <>
          <div className="mx-auto">
            <div className="flex flex-col mt-2">
              <div className="align-middle min-w-full shadow rounded-lg overflow-auto">
                <table
                  className="min-w-full divide-y divide-gray-200"
                  {...getTableProps()}
                >
                  {!props.isHeaderless && <TableHeader headerGroups={headerGroups} />}
                  <tbody
                    className="divide-y divide-gray-200"
                    {...getTableBodyProps()}
                  >
                    {props.isLoading ? (
                      <TableLoader columns={allColumns} />
                    ) : (
                      page.map((row, index) => {
                        prepareRow(row);
                        return (
                          <TableRow
                            columnCount={allColumns.length}
                            hasSmallText={props.hasSmallText}
                            isSelected={selectedRowId !== undefined && selectedRowId === row.id}
                            key={index}
                            onRowClick={rowId => {
                              if (props.onRowClick && props.onRowClick(rowId)) {
                                setSelectedRowId(rowId);
                              }
                            }}
                            row={row}
                            subComponent={props.subComponent}
                          />
                        );
                      })
                    )}
                  </tbody>
                </table>

                {!props.isLoading &&
                  !page.length &&
                  <NoDataMessage message={
                    props.noDataMessage ? props.noDataMessage : `No ${props.entitiesName} found.`
                  }
                  />
                }
                {!props.isLoading && rows.length > pageSize && (
                  <Pagination
                    hasNextPage={canNextPage}
                    hasPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    totalRowCount={rows.length}
                  />
                )}

              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
Table = forwardRef(Table);

Table.propTypes = {
  columnFilters: PropTypes.arrayOf(PropTypes.array),
  columns: PropTypes.array.isRequired,
  customFilter: PropTypes.func,
  data: PropTypes.array.isRequired,
  entitiesName: PropTypes.string,
  extra: PropTypes.node,
  filter: PropTypes.string,
  hasSmallText: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  indicator: PropTypes.node,
  initiallySortedBy: PropTypes.string,
  isFullWidthControls: PropTypes.bool,
  isHeaderless: PropTypes.bool,
  isInitiallySortedByDesc: PropTypes.bool,
  isLoading: PropTypes.bool,
  isScrollable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  noDataMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  preSelectedRowIds: PropTypes.arrayOf(PropTypes.number),
  setFilter: PropTypes.func,
  setFilteredRows: PropTypes.func,
  setPageIndex: PropTypes.func,
  shouldAutoResetPage: PropTypes.bool,
  subComponent: PropTypes.func,
};
