import { DownloadIcon } from '@heroicons/react/outline';
import { isEmpty, isNil, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { updateEntity } from '@api/entity';
import Button, { ButtonKind } from '@common/Button';
import { FormDropdown, FormTreeDropdown } from '@common/FormDropdowns';
import NoDataMessage from '@common/NoDataMessage';
import RaisedSegment from '@common/RaisedSegment';
import Table from '@common/Table';
import Toggle from '@common/Toggle';
import { ToolTipContext } from '@hoc/withToolTip';
import { notifySuccess } from '@utils/notification';
import topics from '@utils/topics';

import EditFacebookInterestDialog from './EditFacebookInterestDialog';
import EditTopAccountDialog from './EditTopAccountDialog';
import getTopAccountTableColumns from './topAccountTableColumns';

function exportCsv(accounts) {
  // create CSV
  const data = 'Name,User name,Topics,Affinity\n' + accounts.map(account =>
    [account.name, account.username, account.topics.join(', '), `${account.affinity.toFixed(2)}x`].map(value =>
      `"${value.replace('"', '""')}"`
    ).join(',')
  ).join('\n');

  // trigger download of the CSV
  const link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
  link.setAttribute('download', 'top_accounts.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
}


// Form tree dropdown filtering
function buildTopicHierarchy(topics) {
  // Assume topics are sorted by full path or have a structure that allows building a hierarchy
  const root = { label: 'All Topics', value: 'All  Topics', children: [] };
  const topicMap = { all: root };

  topics.forEach(topic => {
    const parts = topic.path.split(' / ');
    let current = root;

    parts.forEach(part => {
      if (!topicMap[part]) {
        const newTopic = { label: part, value: part, children: [] };
        topicMap[part] = newTopic;
        current.children.push(newTopic);
      }
      current = topicMap[part];
    });
  });

  return root.children;
}

function topAccountTopicCounts(accounts) {
  const counts = {};
  accounts.forEach(account => {
    account.topics.forEach(topic => {
      if (counts[topic]) {
        counts[topic]++;
      } else {
        counts[topic] = 1;
      }
    });
  });
  return counts;
}

function integrateAndFilterCounts(topics, topicCounts) {
  function integrate(topic) {
    let totalCount = topicCounts[topic.label] || 0;

    // Process and sort children, then sum their counts
    const filteredChildren = topic.children
      .map(child => integrate(child))
      .filter(child => child !== null)
      .sort((a, b) => a.label.localeCompare(b.label)); // Sort children alphabetically

    filteredChildren.forEach(child => {
      // Add child's count to the total count for the parent
      totalCount += child.count || 0;
    });

    if (totalCount > 0 || filteredChildren.length > 0) {
      // Append total count to the label if there are any counts to show
      const updatedLabel = `${topic.label} (${totalCount})`;
      return {
        ...topic,
        label: updatedLabel,
        count: totalCount,
        children: filteredChildren,
      };
    }

    return null; // Exclude the topic if it has no counts and no children
  }

  // Start the process for each top-level topic, sort them, and return the result
  return topics
    .map(integrate)
    .filter(topic => topic !== null)
    .sort((a, b) => a.label.localeCompare(b.label));
}

export default function TopAccountsView(props) {
  // update entity category
  const { mutate: updateCategory } = useMutation(
    ({ entityId, category }) => updateEntity({ id: entityId, category: category }).then(() => ({ entityId, category })),
    {
      onSuccess: ({ entityId, category }) => {
        notifySuccess('Entity category was updated.');
        props.onUpdate({ entityId, categoryName: category });
      },
    }
  );

  // acquire tooltip
  const ToolTip = useContext(ToolTipContext);

  // extend topics with uncategorized entry
  // const extendedTopics = [...props.topics, { name: 'Uncategorized' }];

  // currently selected topic, unique toggle button state
  const [selectedTopic, setSelectedTopic] = useState('All Topics');
  const [selectedAccountType, setSelectedAccountType] = useState();
  const [uniqueToggle, setUniqueToggle] = useState(false);
  const [paramsOfEditTopAccountDialog, setParamsOfEditTopAccountDialog] = useState({ isOpen: false });
  const [paramsOfEditFacebookInterestDialog, setParamsOfEditFacebookInterestDialog] = useState({ isOpen: false });


  // reset page index of table when filtering changes
  const [globalFilter, setGlobalFilter] = useState('');

  const tableRef = useRef({});
  useEffect(
    () => {
      if (tableRef.current) {
        tableRef.current.resetPageIndex();
      }
    },
    [selectedAccountType?.value, globalFilter, selectedTopic.value, uniqueToggle]
  );


  // filter accounts
  const baseAccounts = useMemo(
    () => props.accounts.filter(item =>
      (
        isNil(selectedAccountType) ||
        item.classification === selectedAccountType ||
        (selectedAccountType === 'None' && item.classification === '')
      ) &&
      (!uniqueToggle || item.notInGeneralAudience === true)
    ),
    [props.accounts, selectedAccountType]
  );

  // Calculate topic hierarchy
  const hierarchy = buildTopicHierarchy(topics);
  const topicHierarchyCounts = topAccountTopicCounts(baseAccounts);
  const filteredHierarchy = integrateAndFilterCounts(hierarchy, topicHierarchyCounts);
  const totalAccountsCount = baseAccounts.length;

  // Assuming filteredHierarchy is already prepared with the topic counts integrated
  const hierarchyWithAllTopics = useMemo(() => {
    // Prepend "All Topics" to the hierarchy with the total count
    const allTopics = {
      label: `All Topics (${totalAccountsCount})`,
      value: 'All Topics',
      children: [], // No children for "All Topics"
    };

    // Return the new hierarchy with "All Topics" at the top
    return [allTopics, ...filteredHierarchy];
  }, [filteredHierarchy, totalAccountsCount]);

  const filteredAccounts = useMemo(() => {
    // Function to find the selected topic and its descendants
    function findTopicAndDescendants(topicHierarchy, value) {
      let topics = [];

      topicHierarchy.forEach(topic => {
        if (topic.value === value) {
          topics.push(topic.value);
          if (topic.children) {
            // Use map and flat to concatenate child topics without modifying 'topics' in a loop
            topics = topics.concat(topic.children.flatMap(child => findTopicAndDescendants([child], child.value)));
          }
        } else if (topic.children) {
          // Use concat to accumulate child topics recursively
          topics = topics.concat(findTopicAndDescendants(topic.children, value));
        }
      });

      return topics;
    }

    // Get relevant topics based on the selected topic
    const relevantTopics = selectedTopic === 'All Topics'
      ? baseAccounts.map(account => account.topics).flat()
      : findTopicAndDescendants(filteredHierarchy, selectedTopic);

    // Filter accounts based on topic and global search
    return baseAccounts.filter(account => {
      const matchesTopic = selectedTopic === 'All Topics' ||
        account.topics.some(topic => relevantTopics.includes(topic)) ||
        (selectedTopic === 'Uncategorized' && isEmpty(account.topics));

      const searchValue = (globalFilter || '').toLowerCase();
      const accountData = (account.name + ' ' + account.username + ' ' + (account.category || '')).toLowerCase();
      const matchesSearch = searchValue === '' || accountData.includes(searchValue);

      return matchesTopic && matchesSearch;
    });
  }, [baseAccounts, selectedTopic]);

  const getSelectedTopicLabel = (selectedTopic, hierarchy) => {
    // Function to find the topic in the hierarchy and return its full label
    const findTopicLabel = (topics, value) => {
      for (const topic of topics) {
        if (topic.value === value) {
          return topic.label; // Return the label with count included
        }
        if (topic.children) {
          const found = findTopicLabel(topic.children, value);
          if (found) {
            return found;
          }
        }
      }
      return null;
    };

    if (selectedTopic === 'All Topics') {
      // Directly return the label for "All Topics" from the root of the hierarchy
      return hierarchy.label;
    }

    const fullLabel = findTopicLabel(hierarchy, selectedTopic);
    return fullLabel || `${selectedTopic} (0)`; // Fallback to 0 if not found
  };

  // calculate type counts
  const typeOptions = useMemo(
    () => {
      const typeCounts = baseAccounts.reduce(
        (acc, item) => {
          acc[item.classification || 'None'] = (acc[item.classification || 'None'] || 0) + 1;
          return acc;
        },
        {}
      );
      return Object.entries(typeCounts)
        .sort(([firstKey], [secondKey]) =>
          firstKey === 'None' ? 1 : secondKey === 'None' ? -1 : firstKey.localeCompare(secondKey)
        )
        .map(([key, value]) => ({ label: `${key} (${value})`, value: key }));
    },
    [baseAccounts]
  );

  // render top accounts
  const columns = getTopAccountTableColumns(
    ToolTip,
    props.isSimplified,
    !props.isEditing ? {} : {
      openCategorizationDialog: socialAccount => setParamsOfEditTopAccountDialog({ isOpen: true, socialAccount }),
      openInterestDialog: socialAccount => setParamsOfEditFacebookInterestDialog({ isOpen: true, socialAccount }),
      updateCategory: (entityId, category) => updateCategory({ entityId, category }),
    }
  );

  const COVERAGE_CLASS_NAMES =
    'mb-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden md:grid-cols-3 md:divide-y-0 md:divide-x';

  // Coverage stats
  return props.hasError ? (
    <NoDataMessage message="No data available." />
  ) : (
    <>
      <RaisedSegment>
        {
          props.isEditing && (
            <dl className={COVERAGE_CLASS_NAMES}>
              <div
                className="px-4 py-5 sm:p-6"
              >
                <dt className="text-base font-normal text-gray-900">Total Accounts</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {props.coverage.totalAccounts.toLocaleString()}
                  </div>
                </dd>
              </div>
              <div
                className="px-4 py-5 sm:p-6"
              >
                <dt className="text-base font-normal text-gray-900">Topic Assignment Coverage</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {Math.round(props.coverage.topicCoverage * 100)}%
                  </div>
                </dd>
              </div>
              <div
                className="px-4 py-5 sm:p-6"
              >
                <dt className="text-base font-normal text-gray-900">Targeting Criteria Match Coverage</dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {Math.round(props.coverage.matchCoverage * 100)}%
                  </div>
                </dd>
              </div>
            </dl>
          )
        }

        <Table
          columns={columns}
          customFilter={(rows, _columnIds, globalFilterValue) => rows.filter(row => {
            const value = row.original.name + ' ' + row.original.username + ' ' + (row.original.category || '');
            return value.toLowerCase().includes(globalFilterValue.toLowerCase());
          })}
          data={filteredAccounts}
          entitiesName="accounts"
          extra={
            <>
              <div className={props.isSimplified ? '' : 'w-96'}>
                <FormTreeDropdown
                  isCollapsed
                  onSelection={option => setSelectedTopic(option.value)}
                  options={hierarchyWithAllTopics}
                  placeholder={'All Topics'}
                  value={{
                    label: getSelectedTopicLabel(selectedTopic, filteredHierarchy),
                    value: selectedTopic,
                  }}
                />
              </div>
              <div className={props.isSimplified ? '' : 'w-48'}>
                <FormDropdown
                  isClearable
                  onClear={() => setSelectedAccountType(null)}
                  onSelection={option => setSelectedAccountType(option.value)}
                  options={typeOptions}
                  placeholder="Category"
                  value={typeOptions.find(option => option.value === selectedAccountType)}
                />
              </div>

              <div className={props.isSimplified ? '' : 'ml-2'}>
                {!props.isSimplified &&
                  <Toggle
                    isEnabled={uniqueToggle}
                    label="Unique only"
                    setIsEnabled={setUniqueToggle}
                  />
                }
              </div>
              <div className={props.isSimplified ? '' : 'ml-auto'}>
                <Button
                  icon={DownloadIcon}
                  kind={ButtonKind.PRIMARY}
                  onClick={() => exportCsv(sortBy(filteredAccounts, item => -item.affinity))}
                  title="Export to CSV"
                />
              </div>
            </>
          }
          hiddenColumns={props.isEditing ? [] : []}
          initiallySortedBy="affinity"
          isFullWidthControls={props.isSimplified}
          isInitiallySortedByDesc
          isSearchable
          noDataMessage="No accounts available."
          ref={tableRef}
          setFilter={setGlobalFilter}
          shouldAutoResetPage={false}
        />
      </RaisedSegment>

      <EditTopAccountDialog
        close={() => setParamsOfEditTopAccountDialog({ ...paramsOfEditTopAccountDialog, isOpen: false })}
        onUpdate={props.onUpdate}
        params={paramsOfEditTopAccountDialog}
      />
      <EditFacebookInterestDialog
        close={() => setParamsOfEditFacebookInterestDialog({ ...paramsOfEditFacebookInterestDialog, isOpen: false })}
        onUpdate={props.onUpdate}
        params={paramsOfEditFacebookInterestDialog}
      />
    </>
  );
}

TopAccountsView.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  coverage: PropTypes.object.isRequired,
  hasError: PropTypes.bool,
  insightId: PropTypes.string,
  isEditing: PropTypes.bool,
  isSimplified: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  topicAncestors: PropTypes.object.isRequired,
  topicDescendants: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(PropTypes.object).isRequired,
};
