import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';

import { updateUserPassword } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import DialogIcon from '@common/DialogIcon';
import DialogWithIcon from '@common/DialogWithIcon';
import ErrorMessage from '@common/ErrorMessage';
import FormField from '@common/FormField';

export default function UpdatePasswordForm() {
  // acquire history handler
  const history = useHistory();

  // set up the form
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm({ mode: 'onChange' });
  const password = watch('password');

  // update password
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const resetPasswordToken = urlParams.get('reset_password_token');
  const { error: updatePasswordError, mutate } = useMutation(
    data => updateUserPassword(resetPasswordToken, data.password, data.confirmPassword),
    {
      onSuccess: () => setParamsOfSuccessModal(true),
    }
  );

  // modal state
  const [paramsOfSuccessModal, setParamsOfSuccessModal] = useState(false);

  // render the form
  return (
    <>
      <div className="py-4 px-6">
        {updatePasswordError && <ErrorMessage message="Could not reset your password" />}
        <form
          className="space-y-6"
          onSubmit={handleSubmit(mutate)}
        >
          <div>
            <FormField
              error={errors.password}
              label="Password"
              register={() =>
                register('password', {
                  minLength: {
                    value: 8,
                    message: 'Password is too short',
                  },
                })
              }
              type="password"
            />
          </div>
          <div>
            <FormField
              error={errors.confirmPassword}
              label="Confirm Password"
              register={() =>
                register('confirmPassword', {
                  validate: value => value === password || 'The passwords do not match',
                })
              }
              type="password"
            />
          </div>
          <div>
            <Button
              isDisabled={!isValid}
              isFullWidth
              kind={ButtonKind.PRIMARY}
              title="Set New Password"
              type="submit"
            />
          </div>
        </form>
      </div>

      <DialogWithIcon
        close={() => setParamsOfSuccessModal(false)}
        description="You can now go back to the sign in page to log in with your new password."
        icon={
          <div className="mb-4">
            <DialogIcon
              context="success"
              icon="CheckIcon"
              outline
            />
          </div>
        }
        isOpen={paramsOfSuccessModal}
        onSubmit={() => history.push('/')}
        submitTitle="Back to Sign-in"
        title="Your password has been reset!"
      />
    </>
  );
}
