import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CheckIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { Fragment, Suspense } from 'react';
import ReactTour from 'reactour';

import Button, { ButtonKind } from '@common/Button';
import COLORS from '@utils/colorsUtils';

export default function Tour(props) {
  return (
    <Suspense fallback={<Fragment />}>
      <ReactTour
        accentColor={COLORS.indigo[600]}
        badgeContent={(currentStep, totalSteps) => `${currentStep} of ${totalSteps}`}
        isOpen={props.isOpen}
        lastStepNextButton={
          <Button
            icon={props.lastButtonIcon || CheckIcon}
            kind={ButtonKind.PRIMARY}
            title={props.lastButtonTitle || 'Finish tour'}
          />
        }
        onAfterOpen={disableBodyScroll}
        onBeforeClose={enableBodyScroll}
        onRequestClose={props.close}
        startAt={0}
        steps={props.config}
      />
    </Suspense>
  );
}

Tour.propTypes = {
  close: PropTypes.func.isRequired,
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  lastButtonIcon: PropTypes.func,
  lastButtonTitle: PropTypes.string,
};
