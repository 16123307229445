import { isEmpty, size, startCase } from 'lodash';
import { collectRepeatableValues, getTargetingItem } from '@utils/generalUtils';

import { CONVERSATION_TYPES, EVENT_TYPES } from './data/twitterData';

export function getTwitterDefaults(targeting) {
  // extract repeatable properties
  const conversations = Object.entries(
    (getTargetingItem(targeting, 'conversations') || []).reduce(
      (result, item) => {
        (result[item.type] || (result[item.type] = [])).push(item);
        return result;
      },
      {}
    )
  );
  const events = Object.entries(
    (getTargetingItem(targeting, 'events') || []).reduce(
      (result, item) => {
        (result[item.type] || (result[item.type] = [])).push(item);
        return result;
      },
      {}
    )
  );

  // extract regular properties
  const gender = getTargetingItem(targeting, 'gender');
  const minAge = getTargetingItem(targeting, 'minAge');
  const maxAge = getTargetingItem(targeting, 'maxAge');
  const locations = (getTargetingItem(targeting, 'countries') || [])
    .map(item => ({ label: item.name, type: 'country', value: item.id }));
  const interests = (getTargetingItem(targeting, 'interests') || [])
    .map(item => ({ avatar: item.avatar, label: item.name, username: item.username, value: item.id }));
  const topics = (getTargetingItem(targeting, 'topics') || []).map(item => ({ label: item.name, value: item.id }));
  const broadKeywords = (getTargetingItem(targeting, 'broadKeywords') || [])
    .map(item => ({ label: item.name, value: item.id }));
  const phraseKeywords = (getTargetingItem(targeting, 'phraseKeywords') || [])
    .map(item => ({ label: item.name, value: item.id }));

  // return default values
  return {
    ...Object.fromEntries(conversations.flatMap(([type, items], index) => [
      [`conversationType__${index}`, CONVERSATION_TYPES.find(item => item.value === type)],
      [`conversation__${index}`, items.map(item => ({ label: item.name, value: item.id }))],
    ])),
    broadKeywords,
    ...Object.fromEntries(events.flatMap(([type, items], index) => [
      [`eventType__${index}`, EVENT_TYPES.find(item => item.value === type)],
      [`event__${index}`, items.map(item => ({ label: item.name, value: item.id }))],
    ])),
    gender: { value: gender || '', label: startCase(gender || 'all genders') },
    interests,
    locations,
    maxAge: maxAge && { value: maxAge, label: maxAge.toString() },
    minAge: minAge && { value: minAge, label: minAge.toString() },
    phraseKeywords,
    topics,
  };
}

export function calculateTwitterTargeting(values) {
  // extract repeatable values
  const repeatables = collectRepeatableValues(values);
  const conversations = repeatables.filter(item => item.conversation)
    .flatMap(item =>
      item.conversation.map(option => ({
        id: option.value,
        name: option.label,
        type: item.conversationType.value,
      }))
    );
  const events = repeatables.filter(item => item.event)
    .flatMap(item =>
      item.event.map(option => ({
        id: option.value,
        name: option.label,
        type: item.eventType.value,
      }))
    );

  // extract other values
  const broadKeywords = values.broadKeywords.map(item => ({ id: item.value, name: item.label }));
  const countries = values.locations.filter(location => location.type === 'country')
    .map(location => ({ id: location.value, name: location.label }));
  const interests = values.interests.map(item =>
    ({ avatar: item.avatar, id: item.value, name: item.label, username: item.username })
  );
  const phraseKeywords = values.phraseKeywords.map(item => ({ id: item.value, name: item.label }));
  const topics = values.topics.map(item => ({ id: item.value, name: item.label }));

  // construct targeting object
  return {
    unifiedTargeting: {
      include: [
        ...(size(broadKeywords) ? [{ broadKeywords }] : []),
        ...(!isEmpty(conversations) ? [{ conversations }] : []),
        ...(size(countries) ? [{ countries }] : []),
        ...(!isEmpty(events) ? [{ events }] : []),
        ...(values.gender.value ? [{ gender: values.gender.value }] : []),
        ...(size(interests) ? [{ interests }] : []),
        ...(values.maxAge ? [{ maxAge: values.maxAge.value }] : []),
        ...(values.minAge ? [{ minAge: values.minAge.value }] : []),
        ...(size(phraseKeywords) ? [{ phraseKeywords }] : []),
        ...(size(topics) ? [{ topics }] : []),
      ],
    },
  };
}
