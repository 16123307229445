import { CellProps, Column } from 'react-table';

import { QueueItem } from '@models/queue';
import { FormDropdown } from '@common/FormDropdowns';
import { uniq } from 'lodash';
import { Option } from '@utils/commonUtils';
import { GenericCell } from '@common/table/TableCells';

export default function getQueueItemTableColumns(
  attributeNames: string[],
  values: Record<number, Record<string, Option<string>>>,
  setValues: (values: Record<number, Record<string, Option<string>>>) => void
): Column<QueueItem>[] {
  return [
    {
      accessor: row => row,
      alignment: 'left',
      Cell: ({ row: { original } }: CellProps<QueueItem>) => (
        <GenericCell
          description={(
            <>
              <p>@{original.username}</p>
            </>
          )}
          image={original.avatar}
        >
          {original.name ?? '-'}
        </GenericCell>
      ),
      cellWidth: '200px',
      Header: 'ACCOUNT',
    },
    {
      accessor: row => row,
      alignment: 'left',
      Cell: ({ row: { original } }: CellProps<QueueItem>) => (
        <>
          {original.description}
        </>
      ),
      cellWidth: '400px',
      Header: 'DESCRIPTION',
    },
    ...attributeNames.map(attributeName => ({
      accessor: row => row,
      alignment: 'left',
      Cell: ({ row: { original } }: CellProps<QueueItem>) => {
        const proposals = uniq(Object.values(original.attributes[attributeName].proposals));
        return (
          <div className="w-64">
            <FormDropdown
              isClearable
              isExtensible
              onSelection={(option: Option<string>) =>
                setValues({ ...values, [original.entityId]: { ...values[original.entityId], [attributeName]: option } })
              }
              options={proposals.map(proposal => ({ label: proposal, value: proposal }))}
              value={values[original.entityId]?.[attributeName]}
            />
          </div>
        );
      },
      Header: attributeName.toLocaleUpperCase(),
    } as Column<QueueItem>)),
  ];
}
