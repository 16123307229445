import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

import UserSection from '@components/user/UserSection';
import withBreadcrumb from '@hoc/withBreadcrumb';

// create custom Sentry route component
const BreadcrumbRoute = withBreadcrumb(withSentryRouting(Route));

export default function UserRoutes(props) {
  return (
    <Switch>
      <BreadcrumbRoute
        exact
        path={[
          `${props.match.path}profile`,
          `${props.match.path}connected_platforms`,
          `${props.match.path}organization`,
        ]}
        render={() => <UserSection />}
      />
      <Redirect
        from="/*"
        to="/"
      />
    </Switch>
  );
}

UserRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};
