import { get } from 'lodash';
import { UserGroupIcon } from '@heroicons/react/outline';
import { useContext, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import { getTargetAudiences } from '@api/admin';
import { copyTargetAudience, deleteTargetAudience } from '@api/audiences';
import getTargetAudienceTableColumns from '@components/audiences/targetAudienceTableColumns';
import DeleteDialog from '@common/DeleteDialog';
import RemoteTable from '@common/RemoteTable';
import Section from '@common/Section';
import PlatformSelector from '@components/shared/PlatformSelector';
import { ToolTipContext } from '@hoc/withToolTip';
import { notifySuccess } from '@utils/toaster';
import useViewState from '@utils/viewState';

import SelectUserDialog from '../SelectUserDialog';

export default function TargetAudienceAdminSection() {
  // acquire tooltip
  const ToolTip = useContext(ToolTipContext);

  // view state
  const { setState, state } = useViewState('main');

  // get table reference
  const tableRef = useRef({});

  // modal state
  const [paramsOfDeleteDialog, setParamsOfDeleteDialog] = useState({ isOpen: false });
  const [paramsOfCopyDialog, setParamsOfCopyDialog] = useState({ isOpen: false });
  const [availablePlatforms, setAvailablePlatforms] = useState([]);

  // copy target audience
  const { mutate: copy, error: errorCopyingTargetAudience, isLoading: isCopyingTargetAudience, reset } = useMutation(
    userId => copyTargetAudience(paramsOfCopyDialog.id, userId),
    {
      onSuccess: () => {
        setParamsOfCopyDialog({ isOpen: false });
        notifySuccess({ title: 'Target audience was copied successfully.' });
        tableRef.current.refetch();
      },
    }
  );

  // render campaign section
  const columns = getTargetAudienceTableColumns(
    ToolTip,
    true,
    undefined,
    undefined,
    undefined,
    {
      openCopyDialog: targetAudience =>
        setParamsOfCopyDialog({ id: targetAudience.id, isOpen: true, name: targetAudience.name }),
      openDeleteDialog:
      targetAudience => setParamsOfDeleteDialog({ id: targetAudience.id, isOpen: true, name: targetAudience.name }),
    });
  return (
    <>
      <Section
        icon={UserGroupIcon}
        title="Audience Library"
      >
        <RemoteTable
          columns={columns}
          entitiesName="target audiences"
          extra={
            <div className="w-48">
              <PlatformSelector
                onSelect={option => setState({ ...state, platform: option })}
                platforms={availablePlatforms}
                value={state.platform}
              />
            </div>
          }
          filters={[{ id: 'platform', value: get(state.platform, 'value') }]}
          isSearchable
          query={(...args) =>
            getTargetAudiences(...args).then(response => {
              const { payload: { data, platforms }, meta } = response;
              setAvailablePlatforms(platforms);
              return { payload: data, meta };
            })
          }
          queryKey="targetAudiences"
          ref={tableRef}
        />
      </Section>

      <SelectUserDialog
        close={() => {
          setParamsOfCopyDialog({ isOpen: false });
          reset();
        }}
        errorUpdating={errorCopyingTargetAudience}
        isUpdating={isCopyingTargetAudience}
        onSubmit={copy}
        params={paramsOfCopyDialog}
        submitTitle="Copy Target Audience"
        title="Copy Target Audience For Another User"
      />
      <DeleteDialog
        close={() => setParamsOfDeleteDialog({ isOpen: false })}
        delete={audienceId => deleteTargetAudience(audienceId)}
        entityName="target audience"
        onUpdate={tableRef.current.refetch}
        params={paramsOfDeleteDialog}
      />
    </>
  );
}
