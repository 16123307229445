import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import mixpanel from 'mixpanel-browser';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { getAdAudience } from '@api/adAudience';
import { updateAdAudience } from '@api/audiences';
import Button, { ButtonKind } from '@common/Button';
import Loader from '@common/Loader';
import Section from '@common/Section';
import AudienceEditor from '@components/shared/targetingEditor/AudienceEditor';
import { notifyError, notifySuccess } from '@utils/toaster';

export default function EditAudience() {
  // extract arguments
  const { adAudienceId } = useParams();

  // acquire history handler
  const history = useHistory();

  // query ad audience
  const { data: adAudience, isFetching: isLoadingAdAudience } = useQuery(
    ['audience', adAudienceId],
    () => getAdAudience(adAudienceId),
    { initialData: { targeting: {} } }
  );

  // update ad audience
  const { mutate, isLoading: isUpdating } = useMutation(
    data => updateAdAudience(adAudienceId, data),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Audience updated!' });
        history.push('/audiences/');
      },
      onError: error => notifyError({ title: 'Could not update. Please try again.' }),
    }
  );

  // state
  const submit = useRef(() => true);
  const [isLoadingEditor, setIsLoadingEditor] = useState(false);

  // render form
  return (
    <>
      {isLoadingAdAudience ? (
        <Loader loadingText="Retrieving target audience to edit..." />
      ) : (
        <Section
          heading={
            <div className="flex justify-end">
              <Button
                icon={ArrowSmLeftIcon}
                kind={ButtonKind.REGULAR}
                onClick={() => {
                  mixpanel.track('Back Button Click', { section: 'Edit Audience' });
                  history.push('/audiences/');
                }}
                title="Back to Audiences"
              />
            </div>
          }
          title="Edit Target Audience"
        >
          <div className="bg-white shadow sm:rounded-lg px-6 py-4">
            <AudienceEditor
              adAudience={adAudience}
              isUpdating={isUpdating}
              platform={adAudience.platform}
              setIsLoading={setIsLoadingEditor}
              setSubmit={func => {
                submit.current = func;
              }}
            >
              <Button
                isDisabled={isLoadingEditor}
                isFullWidth
                isLoading={isUpdating}
                kind={ButtonKind.PRIMARY}
                onClick={() => submit.current(mutate)}
                title="Update Target Audience"
              />
            </AudienceEditor>
          </div>
        </Section>
      )}
    </>
  );
}
