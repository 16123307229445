import PropTypes from 'prop-types';

const DEFAULT_CLASS_NAMES = [
  'cursor-pointer',
  'flex',
  'flex-col',
  'hover:bg-gray-100',
  'px-4',
  'py-3',
];

export default function SelectTopicOption({ data, innerProps, isFocused }) {
  return (
    <div
      className={DEFAULT_CLASS_NAMES.join(' ')}
      focused={isFocused ? 'true' : null}
      key={data.value}
      {...innerProps}
    >
      <div className="font-semibold">{data.label}</div>
      <div className="text-xs text-gray-700">
        {data.path}
      </div>
    </div>
  );
}

SelectTopicOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
    value: PropTypes.number,
  }),
  innerProps: PropTypes.object,
  isFocused: PropTypes.bool,
};
