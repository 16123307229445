import { commonFetch, getPagedResults } from '@utils/fetchUtils';

export const getInsightReports = getPagedResults('/v3/insight_reports');

export function copyInsightReport(insightReportId, userId) {
  return commonFetch(`/v3/insight_reports/${insightReportId}/copy?user_id=${userId}`, {
    body: { userId },
    method: 'POST',
  });
}

export function createInsightReport(insightReport) {
  return commonFetch('/v3/insight_reports', {
    method: 'POST',
    body: insightReport,
  });
}

export function updateInsightReport(insightReport) {
  return commonFetch(`/v3/insight_reports/${insightReport.id}`, {
    method: 'PUT',
    body: insightReport,
  });
}

export function deleteInsightReport(insightReportId) {
  return commonFetch(`/v3/insight_reports/${insightReportId}`, {
    method: 'DELETE',
  });
}

export function getInsightReportPdf(insightReportId) {
  return commonFetch(`/v3/insight_reports/${insightReportId}/export`);
}

export function getSegmentationUrl(insightReportId, count) {
  return commonFetch(`/v3/insight_reports/${insightReportId}/segments?count=${count}`);
}

export function getTargetingSuggestions(insightReportId, platform) {
  return commonFetch(`/v3/insight_reports/${insightReportId}/targeting_suggestions?platform=${platform}`);
}

export function reRunInsightReport(insightReportId) {
  return commonFetch(`/v3/insight_reports/${insightReportId}/rerun`, {
    method: 'PUT',
  });
}
