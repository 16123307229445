import PropTypes from 'prop-types';

import BrandIcon from '@common/BrandIcon';

export default function SelectIdentityValue({ data }) {
  const DEFAULT_CLASS_NAMES = [
    'cursor-pointer',
    'flex',
    'flex-grow-0',
    'row-start-1',
    'row-end-1',
    'col-start-1',
    'col-end-3',
    'focus-within:ring-2',
    'focus-within:ring-indigo-500',
    'focus-within:ring-offset-2',
    'items-center',
    'px-2',
    'py-2',
    'relative',
    'shadow-sm',
    'space-x-1',
    'text-sm',
  ];


  return (
    <div className={DEFAULT_CLASS_NAMES.join(' ')}>
      <div className="flex-shrink-0 mr-2">
        <BrandIcon
          brand={data.provider}
          size="small"
        />
      </div>
      <div className="flex-1 min-w-0">
        <span className="font-medium text-gray-900">
          {data.name}
        </span>
        <p className="text-sm text-gray-500 truncate">{data.email}</p>
      </div>
    </div>
  );
}

SelectIdentityValue.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    provider: PropTypes.string,
  }),
};
