import { cloneDeepWith, isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getInterestSuggestions, updateAdAudience } from '@api/audiences';
import { getTargetingAudienceSize } from '@api/targeting_old';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { FormDropdown } from '@common/FormDropdowns';

function replaceInterests(adAudience, mapping) {
  return cloneDeepWith(
    adAudience.unifiedTargeting,
    (value, key) => {
      if (key === 'interests') {
        return value.flatMap(interest => {
          if (!(interest.id in mapping)) {
            return [interest];
          }
          const replacement = mapping[interest.id];
          if (replacement.value === 'remove') {
            return [];
          }
          return [{ id: replacement.value, name: replacement.label }];
        });
      }
      return undefined;
    }
  );
}

function renderAudienceSize(isLoading, audienceSize) {
  const { minimalAudienceSize, maximalAudienceSize } = audienceSize;
  if (isLoading) {
    return 'Loading...';
  }
  if (minimalAudienceSize === undefined || maximalAudienceSize === undefined) {
    return 'n/a';
  }
  if (minimalAudienceSize === maximalAudienceSize) {
    return minimalAudienceSize.toLocaleString();
  }
  return `${minimalAudienceSize.toLocaleString()} - ${maximalAudienceSize.toLocaleString()} people`;
}

export default function InterestSuggestions(props) {
  // query suggestions
  const {
    data: interestSuggestions,
    error: errorLoadingInterestSuggestions,
    isFetching: isLoadingInterestSuggestions,
  } = useQuery(
    ['interestSuggestions', props.adAudience.id],
    () => getInterestSuggestions(props.adAudience.id),
    {
      enabled: props.params.isOpen,
      initialData: { deprecatedInterestAlternatives: {} },
      select: data => data.deprecatedInterestAlternatives,
    }
  );

  // selected alternatives
  const [alternatives, setAlternatives] = useState({});
  useEffect(
    () => {
      if (props.params.isOpen) {
        setAlternatives({});
      }
    },
    [props.params.isOpen]
  );

  // query audience size
  const { data: audienceSize, error: errorLoadingAudienceSize, isFetching: isLoadingAudienceSize } = useQuery(
    [
      'targetingAudienceSize',
      'facebook',
      sortBy(Object.entries(alternatives), ([key, _value]) => key.id).map(([_key, value]) => value.value).join('|'),
    ],
    () => {
      const body = { unifiedTargeting: replaceInterests(props.adAudience, alternatives) };
      return getTargetingAudienceSize('facebook', body);
    },
    {
      enabled: props.params.isOpen,
      initialData: {},
    }
  );

  // update ad audience
  const { isLoading: isUpdatingAdAudience, error: errorUpdatingAdAudience, mutate } = useMutation(
    () => {
      const body = {
        deprecatedFacebookInterests: {},
        unifiedTargeting: replaceInterests(props.adAudience, alternatives),
        ...audienceSize,
      };
      updateAdAudience(props.adAudience.id, body);
    },
    { onSuccess: props.onUpdate }
  );

  // render modal
  return (
    <Dialog
      close={props.close}
      isDisabled={
        isLoadingInterestSuggestions || Object.keys(interestSuggestions).some(id => isEmpty(alternatives[id]))
      }
      isOpen={props.params.isOpen}
      isUpdating={isUpdatingAdAudience}
      onSubmit={mutate}
      size="2xl"
      submitTitle="Update audience"
      title="Replace Deprecated Facebook Interests"
    >
      {errorLoadingInterestSuggestions && <ErrorMessage message={errorLoadingInterestSuggestions.message} />}
      {errorLoadingAudienceSize && <ErrorMessage message={errorLoadingAudienceSize.message} />}
      {errorUpdatingAdAudience && <ErrorMessage message={errorUpdatingAdAudience.message} />}
      <div className="my-2">
        The following Facebook interests have been removed, please select their replacements or remove the interest.
      </div>
      <div className="my-4">
        <div className="my-4 text-base font-medium">
          Estimated audience size:{' '}{renderAudienceSize(isLoadingAudienceSize, audienceSize)}
        </div>
        <div className="grid grid-cols-2 gap-4 items-center">
          {Object.entries(interestSuggestions).map(([id, suggestions]) => (
            <div key={id}>
              <span className="mt-4 font-semibold">
                {props.deprecatedInterests[id]}
              </span><br/>
              <div>
                <FormDropdown
                  onSelection={option => setAlternatives({ ...alternatives, [id]: option })}
                  options={[
                    { label: 'Remove this interest', value: 'remove' },
                    ...suggestions.map(suggestion => ({ label: suggestion.name, value: suggestion.id })),
                  ]}
                  placeholder="Select a replacement..."
                  value={alternatives[id]}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
}

InterestSuggestions.propTypes = {
  adAudience: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  deprecatedInterests: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
