import { isEmpty, range, startCase } from 'lodash';
import React from 'react';

import LANGUAGES from './data/facebookLanguages';

import { searchDetailedInterests } from '@api/targeting';
import { searchLocations } from '@api/targeting_old';
import { sortHits } from '@utils/generalUtils';

export default function getFacebookFields(values, setValue, _base, deprecatedInterests, showSuggestions) {
  // adjust minimal or maximal age if necessary
  if (values.minAge && values.maxAge && values.minAge.value > values.maxAge.value) {
    setValue('maxAge', values.minAge);
  }
  if (!values.minAge && values.maxAge) {
    setValue('minAge', { label: '13', value: 13 });
  }

  // check which deprecated interests are still selected
  const reduced = {};
  for (const [key, value] of Object.entries(values)) {
    if ((key.startsWith('interest__') || key === 'excludedInterests') && value) {
      for (const interest of value) {
        if (interest.value in deprecatedInterests) {
          reduced[interest.value] = interest.name;
        }
      }
    }
  }

  // return fields
  return [
    {
      error:
        !Object.entries(values).some(([key, value]) => key.startsWith('location__') && !isEmpty(value))
          ? 'At least one location has to be specified'
          : null,
      items: [
        {
          empty: 'No location found',
          label: 'Location',
          name: 'location',
          options: query => searchLocations('facebook', query)
            .then(locations => {
              const mapped = locations.map(location => {
                let name;
                if (location.type === 'city') {
                  name = `${location.name} (city in ${location.region}, ${location.countryName})`;
                } else if (location.type === 'region') {
                  name = `${location.name} (region in ${location.countryName})`;
                } else {
                  name = location.name;
                }
                return { label: name, type: location.type, value: location.key };
              });
              return sortHits(mapped, 'label', query);
            }),
          placeholder: 'Select a location',
          type: 'lookup',
          width: 6,
        },
        {
          label: 'Radius',
          maximum: 50,
          minimum: 10,
          name: 'radius',
          skip: index => (values[`location__${index}`] || {}).type !== 'city',
          type: 'slider',
          width: 3,
        },
        {
          label: 'Metric',
          name: 'metric',
          primary: 'mi',
          secondary: 'km',
          skip: index => (values[`location__${index}`] || {}).type !== 'city',
          type: 'toggle',
          width: 3,
        },
      ],
      mainName: 'location',
      type: 'repeatable',
    },
    {
      label: 'Gender',
      name: 'gender',
      options: [
        { label: 'All Genders', value: '' },
        { label: 'Female', value: 'female' },
        { label: 'Male', value: 'male' },
      ],
      placeholder: 'Select a gender',
      type: 'choice',
      width: 4,
    },
    {
      label: 'Minimum Age',
      name: 'minAge',
      options: range(13, 66).map(age => ({ label: age.toString(), value: age })),
      type: 'choice',
      width: 4,
    },
    {
      isClearable: true,
      label: 'Maximum Age',
      name: 'maxAge',
      options: range(values.minAge ? values.minAge.value : 13, 66).map(age => ({ label: age.toString(), value: age })),
      type: 'choice',
      width: 4,
    },
    {
      isMulti: true,
      label: 'Languages',
      name: 'languages',
      options: LANGUAGES,
      type: 'choice',
    },
    {
      content: !isEmpty(reduced) && (
        <>
          These interests have been removed from Facebook. Please remove them before continuing:{' '}
          {Object.values(reduced).map((name, index) => (
            <React.Fragment key={index}>
              {index !== 0 ? ', ' : ''}
              <span style={{ color: 'red' }}>{name}</span>
            </React.Fragment>
          ))}.
        </>
      ),
      type: 'auxiliary',
    },
    {
      addNewTitle: 'Narrow this audience',
      auxiliaryLink: {
        onClick: showSuggestions,
        title: 'Get suggestions',
      },
      items: [
        {
          empty: 'No interest found',
          isMulti: true,
          label: 'Interest',
          name: 'interest',
          options: query => searchDetailedInterests('facebook', query)
            .then(interests => {
              const mapped = interests.map(interest => ({
                label: `${interest.name} (${startCase(interest.type)})`,
                name: interest.name,
                type: interest.type,
                value: interest.id,
              }));
              return sortHits(mapped, 'label', query);
            }),
          secondaryLabel: 'and must also match',
          type: 'lookup',
        },
      ],
      mainName: 'interest',
      type: 'repeatable',
    },
    {
      empty: 'No interest found',
      isMulti: true,
      label: 'Excluded interests',
      name: 'excludedInterests',
      options: query => searchDetailedInterests('facebook', query)
        .then(interests => {
          const mapped = interests.map(interest => ({
            label: `${interest.name} (${startCase(interest.type)})`,
            name: interest.name,
            type: interest.type,
            value: interest.id,
          }));
          return sortHits(mapped, 'label', query);
        }),
      type: 'lookup',
    },
  ];
}
