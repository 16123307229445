import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { uploadCustomInsightReport } from '@api/insightReport';
import { getUsers } from '@api/user';
import { CommonDialogProps } from '@common/commonTypes';
import Dialog, { DialogSize } from '@common/Dialog';
import FormAvatar from '@common/FormAvatar';
import { FormAsyncDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import FormFileUpload from '@common/FormFileUpload';
import SelectUserOption from '@common/reactSelect/SelectUserOption';
import SelectUserValue from '@common/reactSelect/SelectUserValue';
import AudiencePlaceholder from '@images/audience_placeholder.png';
import { getUserName, Option, UserAccount } from '@utils/commonUtils';

export interface UploadCustomReportDialogProps extends CommonDialogProps {
  onUpdate: () => void;
}

export default function UploadCustomReportDialog(props: UploadCustomReportDialogProps): JSX.Element {
  type FormData = {
    avatar: string;
    filename: string;
    name: string;
    photo: string;
    report: string;
    userAccount: UserAccount;
  };

  const { isLoading, mutate } = useMutation<void, unknown, FormData>(
    data => uploadCustomInsightReport({
      avatar: data.avatar,
      customFile: data.report,
      inputs: { filename: data.filename },
      name: data.name,
      reportType: 'custom',
      userId: data.userAccount.id,
    }),
    {
      onSuccess: () => {
        props.close();
        props.onUpdate();
      },
    }
  );

  // states
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  // set up form
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    reset,
    setValue,
    watch,
  } = useForm<FormData>({ mode: 'onChange' });
  register('report', { required: 'You have to upload a report' });
  register('userAccount', { required: 'You have to select a user' });
  const photo = watch('photo');
  const userAccount = watch('userAccount');

  // clear form when the dialog is re-opened
  useEffect(
    () => {
      if (props.params.isOpen) {
        reset();
      }
    },
    [props.params.isOpen]
  );

  return (
    <Dialog
      close={props.close}
      hasForm
      isDisabled={isUploadingAvatar || isUploadingFile || !isValid || isLoading}
      isOpen={props.params.isOpen}
      isUpdating={isLoading}
      onSubmit={handleSubmit((data: FormData) => mutate(data))}
      size={DialogSize.EXXTRA_LARGE}
      submitTitle="Upload Report"
      title="Upload a Custom Report"
    >
      <div className="mt-4 space-y-4">
        <div>
          <FormAsyncDropdown
            error={errors.userAccount}
            label="Select a user for this report"
            loadOptions={(query: string, callback: (data: Array<Option<number> & UserAccount>) => void) => {
              // we should return undefined
              void getUsers(0, query).then(response =>
                callback(response.payload.map(user => ({ label: getUserName(user), value: user.id, ...user })))
              );
            }}
            onSelection={(option: UserAccount) => setValue('userAccount', option, { shouldValidate: true })}
            option={SelectUserOption}
            placeholder="Type to search..."
            singleValue={SelectUserValue}
            value={userAccount}
          />
        </div>
        <div>
          <FormField
            error={errors.name}
            label="Enter a name for this report"
            name="name"
            placeholder="ex. My Report"
            register={() => register('name', { required: 'Report name is required' })}
            type="text"
          />
        </div>
        <div>
          <FormAvatar
            avatar={photo}
            isUploading={isUploadingAvatar}
            label="Report Image"
            name="photo"
            placeholder={AudiencePlaceholder}
            setIsUploading={setIsUploadingAvatar}
            setValue={setValue}
          />
        </div>
        <div>
          <FormFileUpload
            aLabel="a custom report"
            error={errors.report}
            extensions={['.pdf']}
            isUploading={isUploadingFile}
            label="custom report"
            setFilename={(filename: string) => setValue('filename', filename)}
            setIsUploading={setIsUploadingFile}
            setValue={(value: string) => setValue('report', value, { shouldValidate: true })}
          />
        </div>
      </div>
    </Dialog>
  );
}
