import { CogIcon, TrashIcon, XIcon } from '@heroicons/react/solid';

import Button, { ButtonKind } from '@common/Button';
import { Menu, MenuItem } from '@common/Menu';
import { getDetailedUserColumn, getIdColumn } from '@common/table/TableColumns';

export default function getOrganizationTableColumns({
  openChangeRoleDialog,
  openDeleteDialog,
  openResetUserPreferencesDialog,
}) {
  /* eslint-disable react/prop-types */
  return [
    getIdColumn(),
    getDetailedUserColumn(true),
    {
      accessor: 'organization',
      alignment: 'left',
      Cell: ({ cell: { value } }) => (
        <Button
          kind={ButtonKind.LINK}
          target={`/admin/organizations/${value.id}`}
        >
          {value.name}
        </Button>
      ),
      disableSortBy: true,
      Header: 'ORGANIZATION',
    },
    {
      accessor: row => row,
      alignment: 'right',
      Cell: ({ row: { original } }) => (
        <Menu>
          <MenuItem
            icon={XIcon}
            onClick={() => openResetUserPreferencesDialog(original)}
            title="Reset User Preferences"
          />
          <MenuItem
            icon={CogIcon}
            onClick={() => openChangeRoleDialog(original)}
            title={original.admin ? 'Revoke Admin Role' : 'Grant Admin Role'}
          />
          <MenuItem
            icon={TrashIcon}
            isDangerous
            onClick={() => openDeleteDialog(original)}
            title="Delete User"
          />
        </Menu>
      ),
      disableSortBy: true,
      Header: '',
      id: 'actions',
    },
  ];
  /* eslint-enable react/prop-types */
}
