import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import FileInfoColumn from './FileInfoColumn';
import GenericCreateInsightReport from './GenericCreateInsightReport';

import { createInsightReport } from '@api/insightReports';
import FormFileUpload from '@common/FormFileUpload';
import Section from '@common/Section';
import { notifyError, notifySuccess } from '@utils/toaster';

function validateCsv(data) {
  let idCount = 0;
  let totalCount = 0;
  for (const line of atob(data.split('base64,', 2)[1]).split('\n').splice(0, 20)) {
    if (!/^\s*"?[a-zA-Z0-9_]+"?\s*$/.test(line)) {
      return `Line [${line}] is not valid`;
    }
    if (/^\s*"?[0-9]+"?\s*$/.test(line)) {
      idCount++;
    }
    totalCount++;
  }
  return idCount === 0 || idCount === totalCount ? true : 'File contains both Twitter handles and Twitter IDs';
}

export default function CreateTwitterListInsightReport() {
  // acquire history handler
  const history = useHistory();

  // set up form
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({ mode: 'onChange', defaultValues: { name: '', list: '' } });
  const avatar = watch('avatar');

  // create insight report
  const { isLoading: isUpdating, mutate } = useMutation(
    data => createInsightReport({
      avatar: data.avatar,
      inputs: { list: data.list, filename: data.filename },
      name: data.name,
      reportType: 'twitter_list',
    }),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Report created' });
        history.push('/insight_reports/');
      },
      onError: () => notifyError({ title: 'Could not create. Please try again.' }),
    }
  );

  // uploading states
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  // render form
  return (
    <Section title="Create an Twitter List Insight Report">
      <div className="bg-white shadow sm:rounded-lg px-6 py-6">
        <form onSubmit={handleSubmit(mutate)}>
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-10 gap-6">
            <div className="col-span-10">
              Securely upload your list of either Twitter handles or Twitter IDs and bring your audience to life.
            </div>
            <div className="col-span-6">
              <FormFileUpload
                aLabel="a new list of either Twitter handles or Twitter IDs"
                control={control}
                error={errors.list}
                extensions={['.csv']}
                isUploading={isUploadingFile}
                label="list of Twitter handles or IDs"
                name="list"
                rules={{ required: true, validate: validateCsv }}
                setFilename={value => setValue('filename', value)}
                setIsUploading={setIsUploadingFile}
                setValue={value => setValue('list', value, { shouldValidate: true })}
              />
            </div>
            <div className="col-span-4">
              <FileInfoColumn />
            </div>
          </div>
          <GenericCreateInsightReport
            avatar={avatar}
            errors={errors}
            isCreationDisabled={!isValid || isUpdating}
            isUpdating={isUpdating}
            isUploadingAvatar={isUploadingAvatar}
            register={register}
            setIsUploadingAvatar={setIsUploadingAvatar}
            setValue={setValue}
          />
        </form>
      </div>
    </Section>
  );
}
