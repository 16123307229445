import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import getAdAccountTableColumns from './adAccountTableColumns';

import { getAdAccounts, getPlatformOrganizations } from '@api/structure';
import Dialog from '@common/Dialog';
import ErrorMessage from '@common/ErrorMessage';
import { FormDropdown } from '@common/FormDropdowns';
import Table from '@common/Table';

export default function AdAccountsDialog(props) {
  // query organizations
  const { data: organizations, error: errorLoadingOrganizations, isFetching: isLoadingOrganizations } = useQuery(
    ['organizations', props.params.provider, props.params.id],
    () => getPlatformOrganizations(props.params.provider, props.params.id),
    {
      enabled: ['snapchat', 'dv360'].includes(props.params.provider),
      initialData: [],
    }
  );

  // selected organization
  const [organization, setOrganization] = useState({});
  useEffect(
    () => {
      if (props.params.isOpen) {
        setOrganization({});
      }
    },
    [props.params.isOpen]
  );

  // query ad accounts
  const { data: adAccounts, error: errorLoadingAdAccounts, isFetching: isLoadingAdAccounts } = useQuery(
    ['adAccounts', props.params.provider, props.params.id, organization.value],
    () => getAdAccounts(props.params.provider, props.params.id, organization.value),
    {
      enabled: props.params.isOpen && (props.params.provider !== 'snapchat' || !isEmpty(organization)),
      initialData: [],
    }
  );

  // render modal
  return (
    <Dialog
      close={props.close}
      hasNoButtons
      isOpen={props.params.isOpen}
      title="Ad Accounts"
    >
      {errorLoadingOrganizations && <ErrorMessage message={errorLoadingOrganizations} />}
      {errorLoadingAdAccounts && <ErrorMessage message={errorLoadingAdAccounts} />}
      {['snapchat', 'dv360'].includes(props.params.provider) && (
        <FormDropdown
          isDisabled={isLoadingOrganizations}
          label="Select an organization"
          onSelection={setOrganization}
          options={
            sortBy(
              organizations.map(organization => ({ label: organization.name, value: organization.id })),
              option => option.label.toLowerCase()
            )
          }
          placeholder={isLoadingOrganizations ? 'Loading organization information' : 'Select an organization'}
          value={isEmpty(organization) ? null : organization}
        />
      )}
      <div className="mt-4">
        <Table
          columns={getAdAccountTableColumns()}
          customFilter={(rows, columnIds, globalFilterValue) =>
            rows.filter(row => {
              const value = row.original.id + ' ' + row.original.name;
              return value.toLowerCase().includes(globalFilterValue.toLowerCase());
            })
          }
          data={adAccounts}
          entitiesName="ad accounts"
          isLoading={isLoadingAdAccounts}
          isSearchable
        />
      </div>
    </Dialog>
  );
}

AdAccountsDialog.propTypes = {
  close: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
