import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { debounce, isNil, size } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import GenericCreateInsightReport from './GenericCreateInsightReport';

import { createInsightReport } from '@api/insightReports';
import { searchSocialInfluencers } from '@api/miscellaneous';
import Button from '@common/Button';
import { FormAsyncDropdown, FormDropdown } from '@common/FormDropdowns';
import FormField from '@common/FormField';
import { SelectedValueWithIcon } from '@common/reactSelect/SelectValue';
import SelectSearchOption from '@common/reactSelect/SelectSearchOption';
import Section from '@common/Section';
import { countryOptions } from '@utils/countryUtils';
import { notifyError, notifySuccess } from '@utils/toaster';
import { Platform } from '@root/utils/commonUtils';

const GENDER_OPTIONS = [
  { label: 'Any gender', value: '' },
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];
const AGE_RANGE_OPTIONS = [
  { label: '13-17', value: '13-17' },
  { label: '18-24', value: '18-24' },
  { label: '25-34', value: '25-34' },
  { label: '35-44', value: '35-44' },
  { label: '45-54', value: '45-54' },
  { label: '55-64', value: '55-64' },
  { label: '65+', value: '65+' },
];

export default function TwitterInsightReport() {
  // acquire history handler
  const history = useHistory();

  // set up form
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      accounts: [],
      ageRanges: [],
      country: undefined,
      minimalCount: 1,
      gender: GENDER_OPTIONS[0],
      name: '',
    },
  });
  const accounts = watch('accounts');
  const avatar = watch('avatar');
  const gender = watch('gender');

  // search for accounts
  const queryClient = useQueryClient();
  const runningQueryKey = useRef();
  const [search, setSearch] = useState({});

  useQuery(
    ['searchSocialInfluencer', 'twitter', search.term],
    ({ queryKey, signal }) => {
      if (!isNil(runningQueryKey.current)) {
        queryClient.cancelQueries(runningQueryKey.current);
      }
      runningQueryKey.current = queryKey;
      return searchSocialInfluencers(Platform.TWITTER, search.term, signal);
    },
    {
      enabled: !!search.term,
      onSuccess: result => {
        runningQueryKey.current = null;
        search.callback(result.map(item => ({ ...item, value: item.id })));
      },
    }
  );

  // create insight report
  const { isLoading: isUpdating, mutate } = useMutation(
    data => createInsightReport({
      avatar: data.avatar,
      inputs: {
        age_ranges: data.ageRanges.map(({ value }) => value),
        country_codes: data.country ? [data.country.value] : [],
        gender: data.gender ? data.gender.value.toUpperCase() : '',
        twitter_accounts: data.accounts.map(({ id, username }) => ({ id, username })),
        minimalCount: data.minimalCount,
      },
      name: data.name,
      reportType: 'twitter_audience',
    }),
    {
      onSuccess: () => {
        notifySuccess({ title: 'Report created' });
        history.push('/insight_reports/');
      },
      onError: () => notifyError({ title: 'Could not create. Please try again.' }),
    }
  );

  // if an account is selected, set the avatar to it
  useEffect(
    () => {
      if (!avatar && size(accounts) !== 0) {
        fetch(accounts[0].avatarOriginal).then(response => {
          if (response.ok) {
            response.blob().then(blob => {
              const reader = new FileReader();
              reader.onload = () => {
                setValue('avatar', reader.result);
                setIsAvatarAutofilled(true);
              };
              reader.readAsDataURL(blob);
            });
          }
        });
      } else if (size(accounts) === 0 && isAvatarAutofilled) {
        setValue('avatar', null);
        setIsAvatarAutofilled(false);
      }
    },
    [size(accounts)]
  );

  // avatar state
  const [isAvatarAutofilled, setIsAvatarAutofilled] = useState(false);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);

  // render form
  return (
    <div>
      <div className="mb-8">
        <Button
          icon={ArrowSmLeftIcon}
          onClick={() => {
            mixpanel.track('Back Button Click', { section: 'Create Insight Report' });
            history.replace('/insight_reports/');
          }}
          title="Back to Insight Reports"
        />
      </div>

      <Section title="Create a Twitter Audience Insight Report">
        <div className="bg-white shadow sm:rounded-lg px-6 py-6">
          <form onSubmit={handleSubmit(mutate)}>
            <FormAsyncDropdown
              control={control}
              error={errors.accounts}
              isMulti
              label="Get audience insights for the followers of (max 5)"
              loadOptions={debounce(
                (value, callback) => setSearch({ term: value, callback: callback }),
                500,
                { trailing: true }
              )}
              multiValueLabel={SelectedValueWithIcon}
              name="accounts"
              noOptionsMessage="No accounts found."
              option={SelectSearchOption}
              placeholder="Search Twitter accounts by name or @handle..."
              rules={{ required: 'Specifying at least one Twitter account is required' }}
            />
            <div className="mt-4">
              <FormField
                error={errors.minimalCount}
                label="Only consider followers who follow at least this many accounts"
                min={1}
                name="minimalCount"
                register={() => register('minimalCount', {
                  validate: value => (value >= 1 && value <= Math.min(size(accounts), 5)) ||
                    'Minimal count cannot be higher than the number of selected accounts or 5',
                })}
                type="number"
              />
            </div>

            <div className="rounded py-2 mt-4">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
                <div>
                  <FormDropdown
                    control={control}
                    isClearable
                    isSearchable
                    label="Country (optional)"
                    name="country"
                    noOptionsMessage="No countries found."
                    options={countryOptions}
                    placeholder="Select a country"
                  />
                </div>
                <div>
                  <FormDropdown
                    control={control}
                    label="Gender"
                    name="gender"
                    options={GENDER_OPTIONS}
                    placeholder="Select a gender"
                    value={gender}
                  />
                </div>
                <div>
                  <FormDropdown
                    control={control}
                    isMulti
                    label="Age Range (optional)"
                    name="ageRanges"
                    options={AGE_RANGE_OPTIONS}
                    placeholder="Select one or more age ranges"
                  />
                </div>
              </div>
            </div>
            <GenericCreateInsightReport
              avatar={avatar}
              errors={errors}
              isCreationDisabled={!isValid || isUpdating}
              isUpdating={isUpdating}
              isUploadingAvatar={isUploadingAvatar}
              onAvatarSelection={() => setIsAvatarAutofilled(false)}
              register={register}
              setIsUploadingAvatar={setIsUploadingAvatar}
              setValue={setValue}
            />
          </form>
        </div>
      </Section>
    </div>
  );
}
