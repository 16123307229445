import { capitalize, get, isEmpty, startCase } from 'lodash';

import { collectRepeatableValues, getTargetingItem, getTargetingItems } from '@utils/generalUtils';

function getInterestOption(interest, isDeprecated) {
  return ({
    isDangerous: isDeprecated,
    label: `${interest.name} (${startCase(interest.type) || 'Interests'})`,
    name: interest.name,
    type: interest.type || 'interests',
    value: interest.id,
  });
}
function getInterestDescriptor(option) {
  return ({
    id: option.value,
    name: option.name,
    type: option.type,
  });
}

export function getFacebookDefaults(targeting, deprecatedInterests) {
  // extract properties
  const excludedInterests = (getTargetingItem(targeting, 'interests', false) || [])
    .map(item => getInterestOption(item, item.id in deprecatedInterests));
  const gender = getTargetingItem(targeting, 'gender');
  const interests = (getTargetingItems(targeting, 'interests') || [])
    .map(slot => slot.map(item => getInterestOption(item, item.id in deprecatedInterests)));
  const languages = (getTargetingItem(targeting, 'languages') || [])
    .map(item => ({ value: item.id, label: item.name }));
  const locations = (getTargetingItem(targeting, 'countries') || [])
    .map(location => ({ value: location.id, label: location.name, type: 'country' }));
  const maxAge = getTargetingItem(targeting, 'maxAge');
  const minAge = getTargetingItem(targeting, 'minAge');

  // return default values
  return {
    gender: { value: gender || '', label: capitalize(gender || 'all genders') },
    ...Object.fromEntries(interests.map((item, index) => [`interest__${index}`, item])),
    languages,
    ...Object.fromEntries(locations.map((item, index) => [`location__${index}`, item])),
    maxAge: maxAge && { value: maxAge, label: maxAge.toString() },
    minAge: minAge && { value: minAge, label: minAge.toString() },
    excludedInterests,
  };
}

export function calculateFacebookTargeting(values, deprecatedInterests) {
  // extract repeatable values
  const repeatables = collectRepeatableValues(values);
  const countries = repeatables.filter(item => get(item, 'location.type') === 'country')
    .map(item => ({ id: item.location.value, name: item.location.label }));
  const interests = repeatables.filter(item => item.interest).map(item => item.interest.map(getInterestDescriptor));

  // extract regular values
  const excludedInterests = values.excludedInterests.map(getInterestDescriptor);
  const languages = values.languages.map(language => ({ id: language.value, name: language.label }));

  // re-calculate deprecated interests
  const reduced = {};
  for (const slot of interests) {
    for (const interest of slot) {
      if (interest.id in deprecatedInterests) {
        reduced[interest.id] = interest.name;
      }
    }
  }
  for (const interest of excludedInterests) {
    reduced[interest.id] = interest.name;
  }

  // return targeting
  return {
    deprecatedFacebookInterests: reduced,
    unifiedTargeting: {
      include: [
        { countries },
        ...(values.maxAge ? [{ maxAge: values.maxAge.value }] : []),
        ...(values.minAge ? [{ minAge: values.minAge.value }] : []),
        ...(values.gender.value ? [{ gender: values.gender.value }] : []),
        ...interests.map(item => ({ interests: item })),
        ...(!isEmpty(languages) ? [{ languages }] : []),
      ],
      exclude: {
        ...(!isEmpty(excludedInterests) ? { interests: excludedInterests } : {}),
      },
    },
  };
}
