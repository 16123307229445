import PropTypes from 'prop-types';

export default function RaisedSegment(props) {
  return (
    <div className="border-0 px-4 py-4 mb-8 bg-white shadow rounded-lg">
      <div
        disabled={props.isDisabled}
        {...props}
      >
        {props.children}
      </div>
    </div>
  );
}

RaisedSegment.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
};
