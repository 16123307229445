import { issueRequest } from '@utils/httpUtils';

export async function getAdAudience(adAudienceId: number): Promise<AdAudience> {
  const response = await issueRequest<AdAudience>(`/v3/target_audiences/${adAudienceId}`);
  return response.payload;
}

export interface AdAudience {
  id: number;
}
