import { range } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getSegmentationUrl } from '@api/insightReports';
import Dialog from '@common/Dialog';
import { FormDropdown } from '@common/FormDropdowns';
import { notifyError } from '@utils/toaster';

export default function DownloadSegmentationDialog(props) {
  // extract parameters
  const { id: insightReportId, isOpen } = props.params;

  // state
  const [count, setCount] = useState({ label: '1', value: 1 });
  const [isStarted, setIsStarted] = useState(false);
  useEffect(
    () => {
      if (props.params.isOpen) {
        setCount({ label: '1', value: 1 });
        setIsStarted(false);
      }
    },
    [props.params.isOpen]
  );

  // query URL
  useQuery(
    ['segmentationUrl', insightReportId, count.value],
    () => getSegmentationUrl(insightReportId, count.value),
    {
      enabled: isStarted,
      onError: () => notifyError({ title: 'Document generation failed. Please try again.' }),
      onSuccess: response => {
        window.open(response.downloadUrl);
        props.close();
      },
    }
  );

  // render dialog
  return (
    <Dialog
      close={props.close}
      isOpen={isOpen}
      isSubmitOnly={isStarted}
      onSubmit={isStarted ? props.close : () => setIsStarted(true)}
      submitTitle={isStarted ? 'Close' : 'Download'}
      title="Download Audience Segmentation"
    >
      <div className="my-2">
        {isStarted ? (
          <div>
          The download has been started.
          If you do not see the document, please check your pop-up blocker settings.
          </div>
        ) : (
          <FormDropdown
            label="Please select how many segments you want"
            onSelection={setCount}
            options={range(1, 7).map(index => ({ label: index.toString(), value: index }))}
            value={count}
          />
        )}
      </div>
    </Dialog>
  );
}

DownloadSegmentationDialog.propTypes = {
  close: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};
