import { camelizeKeys } from 'humps';
import PropTypes from 'prop-types';
import { ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';

export default function CampaignGroupListener(props) {
  if (props.userUid) {
    return (
      <ActionCableConsumer
        channel={{ channel: 'CampaignGroupsChannel', uid: props.userUid }}
        onReceived={response => props.responseCallback(camelizeKeys(response))}
      />
    );
  }
  return props.children || null;
}

CampaignGroupListener.propTypes = {
  children: PropTypes.node,
  responseCallback: PropTypes.func.isRequired,
  userUid: PropTypes.string,
};
