import { RadioGroup } from '@headlessui/react';
import { Option } from '@utils/commonUtils';
import { classNames } from '@utils/generalUtils';

const CLASSES = [
  'bg-white',
  'block',
  'border',
  'cursor-pointer',
  'focus:outline-none',
  'px-6',
  'py-4',
  'relative',
  'rounded-lg',
  'shadow-sm',
  'sm:justify-between',
];
type StackedCardOption<T> = Option<T> & { description?: string, isDisabled?: boolean; };

export interface StackedCardsProps<T> {
  isDisabled?: boolean;
  onSelection: (option: StackedCardOption<T>) => void,
  options: Array<StackedCardOption<T>>;
  value: StackedCardOption<T>;
}

export default function StackedCards<T extends number | string>(props: StackedCardsProps<T>): JSX.Element {
  return (
    <RadioGroup
      disabled={props.isDisabled}
      onChange={props.onSelection}
      value={props.value}
    >
      <div className="space-y-4">
        {props.options.map(option => (
          <RadioGroup.Option
            className={({ checked, active }) =>
              classNames(
                active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                checked ? 'border-transparent' : 'border-gray-300',
                ...CLASSES
              )
            }
            disabled={option.isDisabled}
            key={option.value.toString()}
            value={option}
          >
            {({ active, checked }) => (
              <>
                <RadioGroup.Label
                  as="div"
                  className={`font-medium ${option.isDisabled ? 'text-gray-300' : 'text-gray-900'}`}
                >
                  {option.label}
                </RadioGroup.Label>
                {option.description && (
                  <RadioGroup.Description
                    as="div"
                    className={`mt-2 text-xs ${option.isDisabled ? 'text-gray-400' : 'text-gray-500'}`}
                  >
                    {option.description}
                  </RadioGroup.Description>
                )}
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
