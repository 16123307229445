import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { updateUser } from '@api/user';
import Button, { ButtonKind } from '@common/Button';
import ErrorMessage from '@common/ErrorMessage';
import FormAvatar from '@common/FormAvatar';
import FormField from '@common/FormField';
import { notifyError, notifySuccess } from '@utils/toaster';
import { isEmail } from '@utils/validations';
import { getUserAvatar } from '@root/utils/avatar';

export default function EditUserForm(props) {
  // update user
  const { error: errorUpdatingUser, mutate, isLoading: isUpdating } = useMutation(
    data => updateUser(
      {
        firstName: data.firstName,
        id: props.user.id,
        lastName: data.lastName,
        email: data.email,
        photo: data.photo && data.photo.startsWith('data:') ? data.photo : undefined,
        password: data.password ? data.password : undefined,
        passwordConfirmation: data.passwordConfirmation ? data.password : undefined,
      }
    ),
    {
      onError: () => notifyError({ title: 'Could not update. Please try again.' }),
      onSuccess: response => {
        notifySuccess({ title: 'User updated!' });
        props.onUpdate(response);
      },
    }
  );

  // set up form
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({ mode: 'onChange' });
  useEffect(
    () => reset({
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      email: props.user.email,
      photo: get(props.user, 'avatar.default'),
    }),
    [props.user]
  );
  const password = useRef({});
  password.current = watch('password');
  const photo = watch('photo');

  // avatar upload status
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);

  // render user profile
  const canChangePassword = props.user.provider === 'email';
  return (
    <form onSubmit={handleSubmit(mutate)}>
      {errorUpdatingUser && <ErrorMessage message={errorUpdatingUser} />}
      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
        <div className="col-span-1 sm:col-span-3">
          <FormField
            error={errors.firstName}
            label="First Name"
            name="firstName"
            placeholder="First name"
            register={() => register('firstName', { required: 'First name is required' })}
            type="text"
          />
        </div>
        <div className="col-span-1 sm:col-span-3">
          <FormField
            error={errors.lastName}
            label="Last Name"
            name="lastName"
            placeholder="Last name"
            register={() => register('lastName', { required: 'Last name is required' })}
            type="text"
          />
        </div>
        <div className="col-span-1 sm:col-span-6">
          <FormField
            error={errors.firstName}
            label="Email"
            name="firstName"
            placeholder="First name"
            register={() => register('email', { required: 'Email is required', validate: isEmail })}
            type="text"
          />
        </div>
        <div className="col-span-1 sm:col-span-6">
          <FormAvatar
            avatar={photo}
            isUploading={isUploadingPhoto}
            label="Photo"
            name="photo"
            placeholder={getUserAvatar(props.user)}
            setIsUploading={setIsUploadingPhoto}
            setValue={setValue}
          />
        </div>
        <div className="col-span-1 sm:col-span-3">
          <FormField
            disabled={!canChangePassword}
            error={errors.password}
            label="New Password"
            name="password"
            placeholder="New password"
            register={() => register('password', { minLength: { value: 8, message: 'Password is too short' } })}
            type="password"
          />
        </div>
        <div className="col-span-1 sm:col-span-3">
          <FormField
            disabled={!canChangePassword}
            error={errors.passwordConfirmation}
            label="Retype New Password"
            name="passwordConfirmation"
            placeholder="Retype new password"
            register={() =>
              register(
                'passwordConfirmation',
                {
                  validate: value =>
                    (isEmpty(value) && isEmpty(password.current)) ||
                    value === password.current ||
                    'The passwords do not match',
                }
              )
            }
            type="password"
          />
        </div>
      </div>
      <div className="mt-6 text-right rounded-b-lg">
        <Button
          isLoading={isUpdating}
          kind={ButtonKind.PRIMARY}
          title={isUpdating ? 'Saving...' : 'Update Account'}
          type="submit"
        />
      </div>
    </form>
  );
}

EditUserForm.propTypes = {
  isAdmin: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  user: PropTypes.object,
};
